<template>
  <div class="demo">
    <vs-row class="banner-wrapper">
      <div
        v-if="showBanner"
        class="banner-class"
      >
        <div
          class="banner-text"
          @click="handleTour"
        >
          <span>Take an Interactive product tour of Explorazor</span>
          <!--          <span><img-->
          <!--            src="../assets/svgs/app-icons/Icon-Arrow.svg"-->
          <!--            alt="arrow"-->
          <!--          ></span>-->
        </div>
        <div
          class="banner-cross"
          @click="closeBanner"
        >
          <img
            src="../assets/svgs/app-icons/cross-icon.svg"
            alt="cross-icon"
          >
        </div>
      </div>
    </vs-row>
    <vs-row
      class="unified-data-access-container"
      vs-justify="center"
    >
      <vs-col
        class="unified-data-access"
        style="margin-top:50px;"
      >
        <vs-row vs-justify="center">
          <h3
            class="unified-data-header"
          >
            EXPLORAZOR FEATURES
          </h3>
        </vs-row>
        <app-text-render
          class-name="change-app-text-paragraph"
          sub-content="Instead of scouring through multiple Excel datasets, Explorazor
          provides the option for Brand and Sales teams to analyze data on a single, integrated dataset
          and extract desired data cuts instantly."
          title="Brand and Sales teams, Obtain Unified Data Access"
          type="button"
        />
        <div class="wrapper-button">
          <vs-button
            class="request-demo-button btn-primary"
            @click="handleTour"
          >
            Take Product Tour
          </vs-button>
        </div>
      </vs-col>
    </vs-row>
    <vs-row
      id="brand-managers"
      class="glimpse-of-search-container"
    >
      <div>
        <div>
          <h2>
            Here’s What You Can Explore on Explorazor
          </h2>
        </div>
        <app-text-render
          sub-content="A glimpse of search queries that Brand and Sales teams can run on Explorazor. Experience
       unprecedented acceleration in your hypotheses testing rates:"
        />
      </div>
      <div class="glimpse-of-img">
        <image-renderer
          client="here's-what-explorazor-can-do"
          type="client"
        />
      </div>
    </vs-row>
    <vs-row class="integrated-View-container">
      <vs-row vs-justify="center">
        <h2 class="simple-fast-heading">
          Simple, Fast, and Convenient
        </h2>
      </vs-row>
      <div class="sub-container">
        <div>
          <div class="integrated-View-img">
            <img
              src="@/assets/backgrounds/check.svg"
              alt=""
            >
          </div>
          <app-text-render
            content="Simple"
            sub-content="All KPIs are combined on the master dataset, so
            pivot tables are obtained instantly using simple search
            keywords and column names"
          />
        </div>
        <div>
          <div class="integrated-View-img">
            <img
              src="@/assets/backgrounds/check.svg"
              alt=""
            >
          </div>
          <app-text-render
            content="Fast"
            sub-content="The entire data is accessible via browser, so
            there’s no need to switch between sheets or constantly communicate
            with BI teams"
          />
        </div>
        <div>
          <div class="integrated-View-img">
            <img
              src="@/assets/backgrounds/check.svg"
              alt=""
            >
          </div>
          <app-text-render
            content="Convenient"
            sub-content="Pivots can be visualized as charts, all data
            cuts are downloadable as CSV files, and the learning curve
            for Explorazor is just a few hours"
          />
        </div>
      </div>
    </vs-row>
    <vs-row
      class="case-study-wrapper"
      style="margin-top:0 !important;"
    >
      <div
        class="case-study-container"
        :class="showForm?'border-radius-class':''"
        :style="!showForm?{'border-radius':'12px'}:{}"
      >
        <div class="case-study-title">
          Case Study
        </div>
        <div class="case-study-main-content">
          <div class="case-study-content-container">
            <!--            <div class="want-to-know">-->
            <!--              Want to know...-->
            <!--            </div>-->
            <div class="want-to-know-content">
              How Explorazor Helped <span class="mid-para">Danone India Ltd.</span> Revolutionize Data Analysis
            </div>
            <div v-if="!showForm">
              <vs-button
                class="case-study-button"
                @click.native="downloadCaseStudy"
              >
                <a
                  href="#download-whitepaper"
                  style="color:white !important;justify-content: center"
                >
                  Download Case Study
                </a>
              </vs-button>
            </div>
          </div>
          <div
            id="download-whitepaper"
            class="case-study-image-container"
          >
            <img
              src="../assets/svgs/card-image/CaseStudy.svg"
              alt="case-study"
              class="case-study-image"
            >
          </div>
        </div>
      </div>
    </vs-row>
    <vs-row
      v-if="showForm"
      style="display:flex;justify-content: center;align-content: center"
    >
      <div
        id="whitepaper-download"
        style="width:80%"
      >
        <div
          class="whitepaper-form-container"
          :class="showForm?'form-radius':''"
        >
          <div class="form-title">
            Download Case Study
          </div>
          <div class="form-container">
            <form
              action=""
              class="form-class"
              @submit="handleFormSubmit"
            >
              <vs-row
                vs-w="12"
                vs-justify="center"
              >
                <vs-col
                  :vs-w="screenWidth>768?3.5:12"
                  :class="screenWidth<768?'column-class':''"
                >
                  <div class="form-label">
                    <label
                      for="name"
                    >Name *</label>
                  </div>
                  <div>
                    <input
                      id="name"
                      v-model="name"
                      type="text"
                      name="name"
                      class="form-input-class"
                      placeholder="Name"
                      required
                    >
                  </div>
                </vs-col>
                <vs-col
                  :vs-w="screenWidth>768?3.5:12"
                  :class="screenWidth<768?'column-class':''"
                >
                  <div class="form-label">
                    <label
                      for="email"
                    >Business Email ID *</label>
                  </div>
                  <div>
                    <input
                      id="email"
                      v-model="email"
                      type="text"
                      name="email"
                      placeholder="Business Email ID"
                      class="form-input-class"
                      required
                    >
                  </div>
                </vs-col>
                <vs-col
                  :vs-w="screenWidth>769?1.5:12"
                >
                  <input
                    type="submit"
                    value="Submit"
                    class="form-button"
                  >
                </vs-col>
              </vs-row>
            </form>
          </div>
        </div>
      </div>
    </vs-row>
    <vs-row>
      <vs-row
        vs-justify="center"
        style="margin-top:120px"
      >
        <h2 class="height">
          Explorazor Features
        </h2>
      </vs-row>

      <div class="datasets-wrapper why-explorazor">
        <vs-col class="search-pivots">
          <div class="visualize-img-number">
            <img
              src="@/assets/png/search.png"
              alt=""
            >
          </div>
          <app-text-render

            content="Search"
            sub-content="Search for the pivots you need using
            the column names as keywords and obtain the data cut you want. "
          />
        </vs-col>
        <vs-col class="companies-container">
          <image-renderer
            class="search-image"
            client="search-for-pivotes"
            type="client"
          />
        </vs-col>
      </div>

      <!--    THIS IS REVERTED COMPONENT-->
      <div
        id="dwt-12"
        class="datasets-wrapper why-explorazor"
      >
        <vs-col class="datasets-wrapper-img">
          <image-renderer
            change="{true}"
            client="visualize-it-as-chart"
            type="client"
          />
        </vs-col>
        <vs-col>
          <div class="visualize-img">
            <img
              src="@/assets/png/visualize.png"
              alt=""
            >
          </div>
          <app-text-render
            change="{true}"
            content="Visualize"
            sub-content="Visualize the data pivot as charts and graphs using simple customization options."
          />
        </vs-col>
      </div>

      <div class="datasets-wrapper why-explorazor">
        <vs-col>
          <div class="visualize-img-number">
            <img
              src="@/assets/png/pin.png"
              alt=""
            >
          </div>
          <app-text-render
            content="Pin"
            sub-content="View all your search responses at a single place
            by pinning them
            to the dashboard. Control separate dashboards for each project."
          />
        </vs-col>
        <vs-col class="companies-container">
          <image-renderer
            client="pin"
            type="client"
          />
        </vs-col>
      </div>
      <div
        id="dwt-12"
        class="datasets-wrapper why-explorazor"
      >
        <vs-col>
          <image-renderer
            change="{true}"
            client="download-as-csv"
            type="client"
          />
        </vs-col>
        <vs-col>
          <div class="visualize-img">
            <img
              src="@/assets/png/download.png"
              alt=""
            >
          </div>
          <app-text-render
            change="{true}"
            content="Download"
            sub-content="Download anything you need as a CSV file and take it to Excel for further analysis."
          />
        </vs-col>
      </div>
    </vs-row>
    <div
      id="ucc-12"
      class="use-cases-container banner-container-read-about"
    >
      <vs-col class="application-text-container">
        <h2>
          Use Cases :
          Applications of Explorazor
        </h2>
        <p class="brand-and-sales">
          See how Explorazor helps resolve many such challenges faced by Brand and Sales
          teams
        </p>
        <vs-col class="application-btn ">
          <vs-button
            class="button"
            @click="routeTo({name:'UseCase'})"
          >
            Read Use Cases
          </vs-button>
        </vs-col>
      </vs-col>
      <vs-col class="application-sub-container">
        <image-renderer
          client="pivots"
          type="client"
        />
      </vs-col>
    </div>
  </div>
</template>

<script>
  import '@/assets/scss/components/change.scss';
  import '@/assets/scss/components/route-pages.scss';
  import imageRenderer from '@/components/helper/ImageRenderer';
  import { imageMixin } from '@/mixins/image';
  import AppTextRender from '@/components/wrapper/TextRender';
  import { gTagMixin } from '@/mixins/gTagMixin';

  export default {
    name: 'RequestDemo',
    components: {
      AppTextRender,
      imageRenderer,
    },
    mixins: [imageMixin, gTagMixin],
    data () {
      return {
        screenWidth: '',
        name: '',
        email: '',
        showForm: false,
        showBanner: true,
      };
    },
    beforeMount () {
      document.title = 'Product - Explorazor';
      document.querySelector('meta[name="description"]').setAttribute('content', 'Explorazor is a AI ' +
        'and Search driven data exploration platform, that empowers users to ask any data questions in Natural ' +
        'language with our No-SQL interface to get accuration answers to take Action. Explore the features' +
        ' and and use cases of Explorazor.');
      console.log(document.querySelector('meta[name="description"]'));
    },
    mounted () {
      this.updatePageView();
      this.screenWidth = screen.availWidth;
    },
    methods: {
      closeBanner: function () {
        this.showBanner = false;
      },
      handleTour: function () {
        window.open('https://app.explorazor.com/#/playground', '_blank');
      },
      downloadCaseStudy: function () {
        this.showForm = true;
      },
      handleFormSubmit: function (e) {
        e.preventDefault();
        let link = location.href;
        let utmSource = link.includes('utm_source=')
          ? link.substring(link.indexOf('utm_source=') + 11, link.lastIndexOf('&utm_medium'))
          : '';
        let utmMedium = link.includes('utm_medium=')
          ? link.substring(link.indexOf('utm_medium=') + 11, link.lastIndexOf('&utm_campaign')) : '';
        let utmCampaign = link.includes('utm_campaign=')
          ? link.substring(link.indexOf('utm_campaign=') + 13)
          : '';
        const params = {
          api_key: process.env.VUE_APP_SENDY_API_KEY,
          name: this.name,
          email: this.email,
          boolean: true,
          list: process.env.VUE_APP_SENDY_LIST_KEY,
          Lead_Source_1: 'Website',
          Lead_Source_2: 'Asset',
          Lead_Source_3: 'case_study',
          Lead_Source_4: 'f_and_b_industry',
          utm_source: utmSource.length ? utmSource : '',
          utm_medium: utmMedium.length ? utmMedium : '',
          utm_campaign_name: utmCampaign.length ? utmCampaign : '',
        };
        let formBody = [];
        for (let property in params) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(params[property]);
          formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');
        const xhr = new XMLHttpRequest();
        xhr.open('POST',
          'https://email.pcube.net/subscribe', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Headers',
          'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
          'Access-Control-Allow-Methods', 'OPTIONS,POST',
          'Access-Control-Allow-Credentials', true,
          'Access-Control-Allow-Origin', '*',
          'X-Requested-With', '*',);
        xhr.send(formBody);
        // sendy subscription call code end
        // hubspot form api call code start
        const xhrHubspot = new XMLHttpRequest();
        // eslint-disable-next-line max-len
        const url = `https://api.hsforms.com/submissions/v3/integration/submit/${ process.env.VUE_APP_HUBSPOT_PORTAL_ID }/${ process.env.VUE_APP_HUBSPOT_FORM_ID }`;
        //  request JSON:
        let data = {
          'fields': [
            {
              'name': 'email',
              'value': this.email
            },
            {
              'name': 'firstname',
              'value': this.name
            },
            {
              'name': 'ga_source',
              'value': 'Website'
            },
            {
              'name': 'ga_medium',
              'value': 'Asset'
            },
            {
              'name': 'ga_campaign',
              'value': 'Case_study_f_and_b_industry'
            }
          ],
        };

        let final_data = JSON.stringify(data);

        xhrHubspot.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhrHubspot.setRequestHeader('Content-Type', 'application/json');

        // Sends the request

        xhrHubspot.send(final_data);
        // hubspot form api call code end
        window.open('https://www.vphrase.com/explorazor-assets/case_study/Explorazor%20Case%20Study%20(Danone).pdf',
          '_blank');
        document.getElementById('whitepaper-download').style.padding = '50px';
        document.getElementById('whitepaper-download').style.color = 'white';
        document.getElementById('whitepaper-download').style.background = '#191948';
        document.getElementById('whitepaper-download').style.fontSize = '22px';
        document.getElementById('whitepaper-download').style.fontWeight = '600';
        document.getElementById('whitepaper-download').style.fontFamily = 'Poppins';
        document.getElementById('whitepaper-download').innerHTML = 'Thank You for downloading the Case Study';
      },
    }
  };
</script>

<style>
  button:hover {
    background: rgba(var(--vs-success), 5) !important;
  }

  .use-cases-container {
    background-image: url("../assets/backgrounds/Intersect.png");
    background-position: 100% 100%;
    height: fit-content;
  }

  .integrated-View-container {
    padding: 5% 15%;
  }

  .banner-class {
    background: linear-gradient(to right, #0242EA, #0334B4);
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100% !important;
    justify-content: center;
    font-weight: 600;
    font-family: "Poppins",
    serif;
    display: flex;
  }

  .banner-class:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .banner-text {

  }

  .banner-cross {
    position: relative;
    right: -38%

  }

  .banner-wrapper {
  //padding-left: 20px; //padding-right: 20px;
  }

  @media screen and (max-width: 1700px) {
    .banner-cross {
      right: -35% !important;
    }
  }

  @media screen and (max-width: 1366px) {
    .banner-cross {
      right: -30% !important
    }
  }

  @media screen and (max-width: 1050px) {
    .banner-cross {
      right: -25% !important
    }
  }

  @media screen and (max-width: 850px) {
    .banner-cross {
      right: -20% !important
    }
  }

  @media screen and (max-width: 650px) {
    .banner-cross {
      right: -15% !important
    }
  }

  @media screen and (max-width: 560px) {
    .banner-cross {
      right: -10% !important
    }
  }

  @media screen and (max-width: 480px) {
    .banner-cross {
      right: -5% !important
    }
  }

  @media screen and (max-width: 414px) {
    .banner-cross {
      right: -1% !important
    }
  }
</style>