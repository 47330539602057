<template>
  <div class="contact">
    <vs-row class="banner-wrapper">
      <div
        v-if="showBanner"
        class="banner-class"
      >
        <div
          class="banner-text"
          @click="handleTour"
        >
          Take an Interactive product tour of Explorazor
        </div>
        <div
          class="banner-cross"
          @click="closeBanner"
        >
          <img
            src="../assets/svgs/app-icons/cross-icon.svg"
            alt="cross-icon"
          >
        </div>
      </div>
    </vs-row>
    <vs-row
      class="unified-data-access-container"
      vs-justify="center "
    >
      <vs-col class="unified-data-access">
        <vs-row vs-justify="center">
          <h3 class="unified-data-header ">
            ANY QUESTIONS?
          </h3>
        </vs-row>
        <app-text-render
          class-name="change-app-text-paragraph"
          sub-content=" Our team is here to assist you with any questions you may have regarding Explorazor"
          title="Let’s Connect"
          type="button"
        />
      </vs-col>
      <div class="understand-better-container">
        <div class="sub-container">
          <div
            v-show="!message"
            class="alert-success"
          >
            <div>
              <img
                src="@/assets/png/success.svg"
                alt=""
              >
              <h3>Thank you for reaching out</h3>
              <p>We will get back to you at the earliest</p>
            </div>
          </div>
          <div v-show="message">
            <vs-row>
              <h3>
                Fill out this form and we'll get back to you shortly.
              </h3>
            </vs-row>
            <div>
              <div id="customForm">
                <div
                  id="SIGNUP_PAGE"
                  class="SIGNUP_PAGE large_form_2_css"
                  name="SIGNUP_PAGE"
                >
                  <div>
                    <div
                      changeid=""
                      changename=""
                      name=""
                    >
                      <div
                        id="imgBlock"
                        logo="true"
                        name="LOGO_DIV"
                      />
                    </div>
                    <br>
                    <div
                      id="signupMainDiv"
                      changeid="SIGNUPFORM"
                      changename="SIGNUPFORM"
                      name="SIGNUPFORM"
                    >
                      <div>
                        <div style="position:relative;">
                          <div
                            id="Zc_SignupSuccess"
                            style="display:none;position:absolute;margin-left:4%;
                                 width:90%;background-color:
                                  white; padding: 3px; border: 3px solid rgb(194, 225, 154);
                                   margin-top: 10px;margin-bottom:10px;word-break:break-all "
                          >
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td width="10%">
                                    <img
                                      align="absmiddle"
                                      alt="thumsup"
                                      class="successicon"
                                      src="https://vphr-zc1.maillist-manage.in/images/challangeiconenable.jpg"
                                    >
                                  </td>
                                  <td>
                                    <span
                                      id="signupSuccessMsg"
                                      style="color: rgb(73, 140, 132); font-family: sans-serif; font-size:
                                             14px;word-break:break-word"
                                    >&nbsp;&nbsp;Thank you for Signing Up</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <iframe
                          border="0"
                          height="0"
                          name="hiddenFrame"
                          style="display: none;"
                          width="0"
                        />
                        <form
                          id="zcampaignOptinForm"
                          action="https://vphr-zc1.maillist-manage.in/weboptin.zc"
                          method="POST"
                          target="hiddenFrame"
                          @submit="message=!message"
                        >
                          <div
                            id="SIGNUP_BODY_ALL"
                            name="SIGNUP_BODY_ALL"
                          >
                            <h1
                              id="SIGNUP_HEADING"
                              changeid="SIGNUP_MSG"
                              changetype="SIGNUP_HEADER"
                              name="SIGNUP_HEADING"
                            />
                            <div
                              id="SIGNUP_BODY"
                              name="SIGNUP_BODY"
                            >
                              <div>
                                <div
                                  id="SIGNUP_DESCRIPTION"
                                  changeid="SIGNUP_MSG"
                                  changetype="SIGNUP_DESCRIPTION"
                                  class=""
                                />
                                <div id="errorMsgDiv">
                                  &nbsp;&nbsp;
                                </div>
                                <div>
                                  <div
                                    class="zcsffieldsdiv"
                                    name="fieldsdivSf"
                                  >
                                    <div
                                      class="zcsffield "
                                      fieldid="94075000000000029"
                                    >
                                      <div>
                                        <div name="SIGNUP_FORM_LABEL">
                                          First name&nbsp;

                                          <span name="SIGNUP_REQUIRED">*</span>
                                        </div>
                                        <div>
                                          <!-- check to mark emailid field
                                            as type email, and other mandatory fields as type required -->
                                          <input
                                            v-model="userInput"
                                            changeitem="SIGNUP_FORM_FIELD"
                                            maxlength="100"
                                            name="FIRSTNAME"
                                            placeholder="First name"
                                            required="true"
                                            type="text"
                                          >
                                          <span class="message">{{ isEmpty }}</span>
                                        </div>
                                      </div>


                                      <div />
                                    </div>
                                    <div
                                      class="zcsffield "
                                      fieldid="94075000000000023"
                                    >
                                      <div>
                                        <div name="SIGNUP_FORM_LABEL">
                                          Business Email ID&nbsp;
                                          <span
                                            name="SIGNUP_REQUIRED"
                                          >*</span>
                                        </div>
                                        <div>
                                          <!-- check to mark emailid field
                                            as type email, and other mandatory fields as type required -->
                                          <input
                                            v-model="userEmail"
                                            changeitem="SIGNUP_FORM_FIELD"
                                            maxlength="100"
                                            name="CONTACT_EMAIL"
                                            placeholder="Business Email ID"
                                            required="true"
                                            type="email"
                                          >
                                          <span class="message">{{ isEmptyMessage }}</span>
                                        </div>
                                      </div>
                                      <div />
                                    </div>
                                    <div
                                      class="zcsffield "
                                      fieldid="94075000000137464"
                                    >
                                      <div>
                                        <div name="SIGNUP_FORM_LABEL">
                                          Message&nbsp;
                                        </div>
                                        <div>
                                          <!-- check to mark emailid field
                                            as type email, and other mandatory fields as type required -->
                                          <input
                                            v-model="formMessage"
                                            changeitem="SIGNUP_FORM_FIELD"
                                            maxlength="100"
                                            name="CONTACT_CF5"
                                            placeholder="What are you looking for?"
                                            type="text"
                                          >
                                        </div>
                                      </div>
                                      <div />
                                    </div>
                                    <div
                                      ishidden="true"
                                      style="display:none"
                                    >
                                      <div>
                                        <div name="SIGNUP_FORM_LABEL">
                                          Lead Source 1&nbsp;
                                        </div>
                                        <div>
                                          <!-- check to mark emailid field
                                            as type email, and other mandatory fields as type required -->
                                          <input
                                            changeitem="SIGNUP_FORM_FIELD"
                                            maxlength="100"
                                            name="CONTACT_CF68"
                                            type="text"
                                            value="Website"
                                          >
                                        </div>
                                      </div>
                                      <div />
                                    </div>
                                    <div
                                      ishidden="true"
                                      style="display:none"
                                    >
                                      <div>
                                        <div name="SIGNUP_FORM_LABEL">
                                          Lead Source 2&nbsp;
                                        </div>
                                        <div>
                                          <!-- check to mark emailid field as
                                            type email, and other mandatory fields as type required -->
                                          <input
                                            changeitem="SIGNUP_FORM_FIELD"
                                            maxlength="100"
                                            name="CONTACT_CF69"
                                            type="text"
                                            value="Meeting"
                                          >
                                        </div>
                                      </div>
                                      <div />
                                    </div>
                                    <div
                                      ishidden="true"
                                      style="display:none"
                                    >
                                      <div>
                                        <div name="SIGNUP_FORM_LABEL">
                                          Lead Source 3&nbsp;
                                        </div>
                                        <div>
                                          <!-- check to mark emailid field as type email,
                                             and other mandatory fields as type required -->
                                          <input
                                            changeitem="SIGNUP_FORM_FIELD"
                                            maxlength="100"
                                            name="CONTACT_CF70"
                                            type="text"
                                            value="Contact Us"
                                          >
                                        </div>
                                      </div>
                                      <div />
                                    </div>
                                  </div><!-- Captcha for Signup -->
                                  <div
                                    id="recapDiv"
                                    class="recapDivlight recaptcha"
                                  />
                                  <input
                                    id="secretid"
                                    type="hidden"
                                  >
                                  <!-- Captcha for Signup End--><!-- Other Lists Subscription Start-->
                                  <!-- Other Lists Subscription End-->
                                  <div />
                                  <div
                                    id=" REQUIRED_FIELD_TEXT"
                                    changetype="REQUIRED_FIELD_TEXT"
                                    name="SIGNUP_REQUIRED"
                                  />
                                  <label>
                                    <vue-recaptcha
                                      :sitekey="captchaKey"
                                      class="captcha-container"
                                      @expired="onCaptchaExpired"
                                      @verify="validate"
                                    />
                                    <span class="message">{{ captchaMessage }}</span>
                                  </label>
                                  <div class="request-demo">
                                    <input
                                      id="zcWebOptin"
                                      action="Save"
                                      changetype="SIGNUP_SUBMIT_BUTTON_TEXT"
                                      :class="enableDemoButton?'request-demo-button btn-primary':'disabled-class'"
                                      :disabled="!enableDemoButton"
                                      name="SIGNUP_SUBMIT_BUTTON"
                                      type="submit"
                                      value="Submit"
                                      @click="isSubmit"
                                    >
                                  </div>
                                  <!--                                    &lt;!&ndash;
                                    Do not edit the below Zoho Campaigns hidden tags &ndash;&gt;-->
                                  <input
                                    id="fieldBorder"
                                    type="hidden"
                                    value="rgb(222, 222, 222)"
                                  >
                                  <input
                                    id="zc_trackCode"
                                    name="zc_trackCode"
                                    onload=""
                                    type="hidden"
                                    value="ZCFORMVIEW"
                                  >
                                  <input
                                    id="submitType"
                                    name="submitType"
                                    type="hidden"
                                    value="optinCustomView"
                                  >
                                  <input
                                    id="lD"
                                    name="lD"
                                    type="hidden"
                                    value="114e38b6cd69627b"
                                  >
                                  <input
                                    id="emailReportId"
                                    name="emailReportId"
                                    type="hidden"
                                    value=""
                                  >
                                  <input
                                    id="cmpZuid"
                                    name="zx"
                                    type="hidden"
                                  >
                                  <input
                                    name="zcvers"
                                    type="hidden"
                                    value="2.0"
                                  >
                                  <input
                                    id="allCheckedListIds"
                                    name="oldListIds"
                                    type="hidden"
                                    value=""
                                  >
                                  <input
                                    id="mode"
                                    name="mode"
                                    type="hidden"
                                    value="OptinCreateView"
                                  >
                                  <input
                                    id="zcld"
                                    name="zcld"
                                    type="hidden"
                                    value="114e38b6cd69627b"
                                  >
                                  <input
                                    id="zctd"
                                    name="zctd"
                                    type="hidden"
                                    value="114e38b6cd3edee1"
                                  >
                                  <input
                                    id="document_domain"
                                    type="hidden"
                                    value="zoho.in"
                                  >
                                  <input
                                    id="zc_Url"
                                    type="hidden"
                                    value="vphr-zc1.maillist-manage.in"
                                  >
                                  <input
                                    id="new_optin_response_in"
                                    type="hidden"
                                    value="2"
                                  >
                                  <input
                                    id="duplicate_optin_response_in"
                                    type="hidden"
                                    value="2"
                                  >
                                  <input
                                    id="zc_formIx"
                                    name="zc_formIx"
                                    type="hidden"
                                  >
                                  <!-- End of the campaigns hidden tags -->
                                </div>
                              </div>
                              <input
                                id="isCaptchaNeeded"
                                type="hidden"
                                value="false"
                              >
                            </div>
                          </div>
                          <input
                            id="scriptless"
                            name="scriptless"
                            type="hidden"
                            value="yes"
                          >
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="zcOptinOverLay"
              oncontextmenu="return false"
              style="display:none;text-align: center; background-color: rgb(0, 0, 0);
                    opacity: 0.5; z-index: 100; position: fixed; width: 100%; top: 0px; left: 0px; height: 988px;"
            />
            <div
              id="zcOptinSuccessPopup"
              style="display:none;z-index: 9999;width: 800px; height: 40%;top: 84px;position:
                    fixed; left: 26%;background-color: #FFFFFF;border-color: #E6E6E6; border-style:
                     solid; border-width: 1px;  box-shadow: 0 1px 10px #424242;padding: 35px;"
            >
              <span
                id="closeSuccess"
                style="position: absolute;top: -16px;right:-14px;z-index:99999;cursor: pointer;"
              >
                <img
                  alt="close"
                  src="https://vphr-zc1.maillist-manage.in/images/videoclose.png"
                >
              </span>
              <div id="zcOptinSuccessPanel" />
            </div>
          </div>
        </div>
      </div>
    </vs-row>

    <vs-row class="sales-specific-container">
      <div class="email-container">
        <h3>
          If you want to email us,
          kindly reach out at
        </h3>
        <div class="sub-container">
          <p>for general queries</p>
          <div class="email-link">
            <h3><a href="mailto:contact@vphrase.com">contact@vphrase.com</a></h3>
          </div>
        </div>
      </div>
      <div class="commercial-inquiries">
        <div>
          <p>for sales-specific queries / commercial inquiries</p>
          <div class="email-link">
            <h3><a href="mailto:sales@vphrase.com">sales@vphrase.com</a></h3>
          </div>
        </div>
      </div>
    </vs-row>
    <vs-row class="integrated-View-container">
      <vs-row vs-justify="center">
        <h3>vPhrase Analytics Global Locations</h3>
      </vs-row>
      <div class="vphrase-container">
        <div class="mumbai-container">
          <div class="img-container">
            <img
              src="@/assets/png/mumbai.png"
              alt=""
            >
          </div>
          <div class="address-section">
            <p class="access-header">
              <b>Mumbai</b>
            </p>
            <p>
              301, T-Square, Saki Vihar Road,<br>
              Andheri (E), Mumbai, India 400072<br>
              +91 7977370636
            </p>
          </div>
        </div>
        <div class="new-york-container">
          <div class="img-container">
            <img
              src="@/assets/png/new-york.png"
              alt=""
            >
          </div>
          <div class="address-section">
            <p class="access-header">
              <b>New York</b>
            </p>
            <p>
              1562 First Avenue,<br>
              #394, New York, NY 10028.<br>
              +917 993-9882
            </p>
          </div>
        </div>
      </div>
    </vs-row>
  </div>
</template>

<script>
  import '@/assets/scss/components/change.scss';
  import { VueRecaptcha } from 'vue-recaptcha';
  import { imageMixin } from '@/mixins/image';
  import AppTextRender from '@/components/wrapper/TextRender';
  import { gTagMixin } from '@/mixins/gTagMixin';

  export default {
    name: 'AppContact',
    components: {
      AppTextRender,
      VueRecaptcha,
    },
    mixins: [imageMixin, gTagMixin],
    data () {
      return {
        captchaKey: process.env.VUE_APP_CAPTCHA_KEY,
        message: true,
        validateData: false,
        userInput: '',
        userEmail: '',
        isEmptyMessage: '',
        isEmpty: '',
        captchaMessage: '',
        formMessage: '',
        showBanner: true
      };
    },
    computed: {
      enableDemoButton: function () {
        let show = false;
        if (this.userEmail.length && this.userInput.length && this.validateData) {
          show = true;
        }
        return show;
      }
    },
    beforeMount () {
      document.title = 'Explorazor | Contact Us';
      document.querySelector('meta[name="description"]').setAttribute('content', 'Explorazor is a ' +
        'AI and Search driven data exploration platform, that empowers users to ask any data ' +
        'questions in Natural language. Connect with us to get more information regarding Explorazor.');
    },
    mounted () {
      this.updatePageView();
    },
    methods: {
      handleTour: function () {
        window.open('https://app.explorazor.com/#/playground', '_blank');
      },
      closeBanner: function () {
        this.showBanner = false;
      },
      onCaptchaExpired: function () {
        this.validateData = false;

      },
      validate: function () {
        this.validateData = true;
      },
      isSubmit: function () {
        if (this.userInput.length === 0) {
          this.isEmpty = 'this field is required';
          this.captchaMessage = '';
        }
        if (this.userEmail.length === 0) {
          this.isEmptyMessage = 'this field is required';
          this.captchaMessage = '';
        } else {
          this.isEmpty = '';
          this.isEmptyMessage = '';
          this.captchaMessage = 'captcha is required';
        }
        // sendy subscription call code start
        let link = location.href;
        let utmSource = link.includes('utm_source=')
          ? link.substring(link.indexOf('utm_source=') + 11, link.lastIndexOf('&utm_medium'))
          : '';
        let utmMedium = link.includes('utm_medium=')
          ? link.substring(link.indexOf('utm_medium=') + 11, link.lastIndexOf('&utm_campaign')) : '';
        let utmCampaign = link.includes('utm_campaign=')
          ? link.substring(link.indexOf('utm_campaign=') + 13)
          : '';
        const params = {
          api_key: process.env.VUE_APP_SENDY_API_KEY,
          name: this.userInput.charAt(0).toUpperCase() + this.userInput.slice(1),
          email: this.userEmail,
          boolean: true,
          list: process.env.VUE_APP_SENDY_LIST_KEY,
          Lead_Source_1: 'Website',
          Lead_Source_2: 'Meeting',
          Lead_Source_3: 'Contact Us',
          utm_source: utmSource.length ? utmSource : '',
          utm_medium: utmMedium.length ? utmMedium : '',
          utm_campaign_name: utmCampaign.length ? utmCampaign : ''
        };
        let formBody = [];
        for (let property in params) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(params[property]);
          formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');
        const xhr = new XMLHttpRequest();
        xhr.open('POST',
          'https://email.pcube.net/subscribe', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Headers',
          'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
          'Access-Control-Allow-Methods', 'OPTIONS,POST',
          'Access-Control-Allow-Credentials', true,
          'Access-Control-Allow-Origin', '*',
          'X-Requested-With', '*',);
        xhr.send(formBody);
        // sendy subscription call code end
        // hubspot form api call code start
        const xhrHubspot = new XMLHttpRequest();
        // eslint-disable-next-line max-len
        const url = `https://api.hsforms.com/submissions/v3/integration/submit/${ process.env.VUE_APP_HUBSPOT_PORTAL_ID }/${ process.env.VUE_APP_HUBSPOT_FORM_ID }`;
        //  request JSON:
        let data = {
          'fields': [
            {
              'name': 'email',
              'value': this.userEmail
            },
            {
              'name': 'firstname',
              'value': this.userInput
            },
            {
              'name': 'message',
              'value': this.formMessage
            },
            {
              'name': 'ga_source',
              'value': 'Website'
            },
            {
              'name': 'ga_medium',
              'value': 'Meeting'
            },
            {
              'name': 'ga_campaign',
              'value': 'Contact Us'
            }
          ],

        };

        let final_data = JSON.stringify(data);

        xhrHubspot.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhrHubspot.setRequestHeader('Content-Type', 'application/json');

        // Sends the request

        xhrHubspot.send(final_data);
        // hubspot form api call code end
      },
    }
  };
</script>

<style scoped>
  .integrated-View-container {
    padding-bottom: 0;
    padding-top: 8%;
  }

</style>