<template>
  <div
    class="vs-row"
    :style="getStyles"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VsRow',
  props: {
    vsType: {
      type: String,
      default: 'flex'
    },
    vsJustify: {
      type: String,
      default: ''
    },
    vsAlign: {
      type: String,
      default: ''
    },
    vsW: {
      type: [Number, String],
      default: 12
    },
  },
  computed: {
    getWidth: function () {
      if (this.vsW < 12) return (this.vsW * 8.3) + '%';
      else return 100 + '%';
    },
    getStyles: function () {
      let styles = {
        display: this.vsType,
        'width': this.getWidth,
      };
      if (this.vsJustify) styles['justify-content'] = this.vsJustify;
      if (this.vsAlign) styles['align-items'] = this.vsAlign;
      return styles;
    }
  }
};
</script>

<style scoped lang="scss">
.vs-row {
  flex-wrap: wrap;
  clear: both;
}
</style>