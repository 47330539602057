<template>
  <div class="img-container">
    <img
      :src="getImage"
      class="image-png"
      alt=""
    >
  </div>
</template>

<script>

  export default {
    props: {
      image: {
        type: String,
        default: null,
      },
    },
    computed: {
      getImage: function () {
        try {
          return require(`@/assets/png/${ this.image }.png`);
        } catch (error) {
          console.log(error);
          return '';
        }
      }
    }
  };
</script>


