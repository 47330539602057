import { pageview } from 'vue-gtag';

export const gTagMixin = {
  props: {},
  methods: {
    updatePageView: function () {
      pageview({
        page_title: this.$route['name'],
        page_path: window.location.pathname,
        page_location: window.location.href
      });
    }
  }
};