<template>
  <div
    ref="decision-maker"
    class="decision-maker-class"
  >
    <!--    <img-->
    <!--      src="@/assets/png/data-platform.png"-->
    <!--      alt="data-platform"-->
    <!--    >-->
    <div
      v-show="showDecisions"
      class="decision-maker-content-class"
    >
      <p class="decision-maker-header">
        Decision-makers love Explorazor
      </p>
      <div class="decision-maker-tabs">
        <div
          id="brand-manager"
          class="decision-maker-tab"
          @click="brandManagerTabSelection"
        >
          Brand Managers
        </div>
        <div
          id="consumer-insights"
          class="decision-maker-tab"
          @click="consumerInsightsTabSelection"
        >
          Consumer Insights Managers
        </div>
        <div
          id="sales-manager"
          class="decision-maker-tab"
          @click="salesManagerTabSelection"
        >
          Sales Managers
        </div>
        <div
          id="data-analytics"
          class="decision-maker-tab"
          @click="dataTabSelection"
        >
          Data & Analytics Leaders
        </div>
      </div>
      <div class="decision-maker-content-container">
        <div class="decision-maker-image-container">
          <img
            :src="imagesOnTabSelection"
            alt="data-platform-layer"
            class="decision-image"
          >
        </div>
        <div class="decision-maker-tab-content">
          <div v-if="brandManagerTab">
            <ul class="list-container">
              <p class="decision-list-para">
                Get ready-to-consume insights like:
              </p>
              <ul class="list-container">
                <li class="decision-list list-type-disclosure-closed">
                  Know when & how the price elasticity is changing and how they can
                  leverage it
                </li>
                <li class="decision-list list-type-disclosure-closed">
                  Understand if advertising spends are giving enough ROI in terms of SOV and how well are the sales
                  trending because of this
                </li>
                <li class="decision-list list-type-disclosure-closed">
                  Understand which are the opportunity regions to build new strategy and beat
                  the competitor’s MS
                </li>
              </ul>
            </ul>
            <!--            <div class="button-container">-->
            <!--              <vs-button class="decision-button">-->
            <!--                Know More-->
            <!--              </vs-button>-->
            <!--            </div>-->
          </div>
          <div v-if="consumerTab">
            <ul class="list-container">
              <li class="decision-list-para list-type-disclosure-closed">
                Avoid having to answer daily requests for data and pivots from brand managers
              </li>
              <li class="decision-list-para list-type-disclosure-closed">
                Get time to do your primary job i.e. finding great insights:
              </li>
              <ul class="list-container">
                <li class="decision-list">
                  Measure how your MS is moving with change in your Brand penetration levels
                </li>
                <li class="decision-list">
                  Check effects of promotional campaigns on your brand loyalty
                </li>
                <li class="decision-list">
                  Easily track changes in new triers and lapsers for your brand, with increased demand generation
                  efforts
                </li>
              </ul>
            </ul>
            <!--            <div class="button-container">-->
            <!--              <vs-button class="decision-button">-->
            <!--                Know More-->
            <!--              </vs-button>-->
            <!--            </div>-->
          </div>
          <div v-if="salesTab">
            <ul class="list-container">
              <p class="decision-list-para">
                Get ready-to-consume insights like:
              </p>
              <ul class="list-container">
                <li class="decision-list list-type-disclosure-closed">
                  Understand in which markets they are losing out on MS, how are they trailing vs the category and
                  fairing against the competitors
                </li>
                <li class="decision-list list-type-disclosure-closed">
                  Know the root cause of low market performance by gaining clarity on markets where they lack
                  distribution and need to improve upon the secondary sales
                </li>
              </ul>
            </ul>
            <!--            <div class="button-container">-->
            <!--              <vs-button class="decision-button">-->
            <!--                Know More-->
            <!--              </vs-button>-->
            <!--            </div>-->
          </div>
          <div v-if="dataTab">
            <ul class="list-container">
              <li class="decision-list list-type-disclosure-closed">
                Help build an integrated data platform on a modern data stack
              </li>
              <li class="decision-list list-type-disclosure-closed">
                Put a unified data strategy in place
              </li>
              <li class="decision-list list-type-disclosure-closed">
                Democratize access to data and facilitate data driven decision making
              </li>
            </ul>
            <!--            <div class="button-container">-->
            <!--              <vs-button class="decision-button">-->
            <!--                Know More-->
            <!--              </vs-button>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="!showDecisions"
      class="responsive-decision-container"
    >
      <div>
        <h2 class="responsive-decision-header">
          Decision-makers love Explorazor
        </h2>
      </div>
      <div class="responsive-decision-content">
        <p class="responsive-decision-para">
          Brand Managers
        </p>
        <img
          src="../../assets/svgs/clients/brand-managers.svg"
          alt="harmonized"
          class="responsive-decision-image"
        >
        <div class="responsive-decision-description">
          <ul class="list-container">
            <p
              class="list-para"
            >
              Get ready-to-consume insights like:
            </p>
            <ul class="list-container">
              <li class="decision-list list-type-disclosure-closed">
                Know when & how the price elasticity is changing and how they can
                leverage it
              </li>
              <li class="decision-list list-type-disclosure-closed">
                Understand if advertising spends are giving enough ROI in terms of SOV and how well are the sales
                trending because of this
              </li>
              <li class="decision-list list-type-disclosure-closed">
                Understand which are the opportunity regions to build new strategy and beat
                the competitor’s MS
              </li>
            </ul>
          </ul>
        </div>
      </div>
      <div class="responsive-decision-content">
        <p class="responsive-decision-para">
          Consumer Insights Managers
        </p>
        <img
          src="../../assets/svgs/clients/consumer-insights-managers.svg"
          alt="harmonized"
          class="responsive-decision-image"
        >
        <div
          class="responsive-decision-description"
        >
          <ul class="list-container">
            <li class="decision-list list-type-disclosure-closed">
              Avoid having to answer daily requests for data and pivots from brand managers
            </li>
            <li class="decision-list list-type-disclosure-closed">
              Get time to do your primary job i.e. finding great insights:
            </li>
            <ul class="list-container">
              <li class="decision-list">
                Measure how your MS is moving with change in your Brand penetration levels
              </li>
              <li class="decision-list">
                Check effects of promotional campaigns on your brand loyalty
              </li>
              <li class="decision-list">
                Easily track changes in new triers and lapsers for your brand, with increased demand generation
                efforts
              </li>
            </ul>
          </ul>
        </div>
      </div>
      <div class="responsive-decision-content">
        <p class="responsive-decision-para">
          Sales Managers
        </p>
        <img
          src="../../assets/svgs/clients/sales-managers.svg"
          alt="harmonized"
          class="responsive-decision-image"
        >
        <div
          class="responsive-decision-description"
        >
          <ul class="list-container">
            <p
              class="list-para"
            >
              Get ready-to-consume insights like:
            </p>
            <ul class="list-container">
              <li class="decision-list list-type-disclosure-closed">
                Understand in which markets they are losing out on MS, how are they trailing vs the category and
                fairing against the competitors
              </li>
              <li class="decision-list list-type-disclosure-closed">
                Know the root cause of low market performance by gaining clarity on markets where they lack
                distribution and need to improve upon the secondary sales
              </li>
            </ul>
          </ul>
        </div>
      </div>
      <div class="responsive-decision-content">
        <p class="responsive-decision-para">
          Data & Analytics Leaders
        </p>
        <img
          src="../../assets/svgs/clients/data-analytics.svg"
          alt="harmonized"
          class="responsive-decision-image"
        >
        <div
          class="responsive-decision-description"
        >
          <ul class="list-container">
            <p
              class="list-para"
            >
              Get ready-to-consume insights like:
            </p>
            <ul class="list-container">
              <li class="decision-list list-type-disclosure-closed">
                Know when & how the price elasticity is changing and how they can
                leverage it
              </li>
              <li class="decision-list list-type-disclosure-closed">
                Understand if advertising spends are giving enough ROI in terms of SOV and how well are the sales
                trending because of this
              </li>
              <li class="decision-list list-type-disclosure-closed">
                Understand which are the opportunity regions to build new strategy and beat
                the competitor’s MS
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DecisionMakers',
  components: {},
  data: function () {
    return {
      brandManagerTab: false,
      consumerTab: false,
      salesTab: false,
      dataTab: false,
      showDecisions: false
    };
  },
  computed: {
    imagesOnTabSelection: function () {
      let imgSource = '';
      if (this.brandManagerTab) {
        imgSource = require('@/assets/svgs/clients/brand-managers.svg');
      } else if (this.consumerTab) {
        imgSource = require('@/assets/svgs/clients/consumer-insights-managers.svg');
      } else if (this.salesTab) {
        imgSource = require('@/assets/svgs/clients/sales-managers.svg');
      } else if (this.dataTab) {
        imgSource = require('@/assets/svgs/clients/data-analytics.svg');
      }
      return imgSource;
    }
  },
  mounted () {
    this.brandManagerTabSelection();
    this.getDecisionClientWidth();
  },
  methods: {
    brandManagerTabSelection: function () {
      this.brandManagerTab = true;
      this.salesTab = false;
      this.consumerTab = false;
      this.dataTab = false;
      document.getElementById('brand-manager').style.borderBottom = '6px solid #0243EC';
      document.getElementById('consumer-insights').style.borderBottom = 'none';
      document.getElementById('sales-manager').style.borderBottom = 'none';
      document.getElementById('data-analytics').style.borderBottom = 'none';
      document.getElementById('brand-manager').style.fontWeight = '700';
      document.getElementById('consumer-insights').style.fontWeight = '400';
      document.getElementById('sales-manager').style.fontWeight = '400';
      document.getElementById('data-analytics').style.fontWeight = '400';

    },
    consumerInsightsTabSelection: function () {
      this.brandManagerTab = false;
      this.salesTab = false;
      this.consumerTab = true;
      this.dataTab = false;
      document.getElementById('brand-manager').style.borderBottom = 'none';
      document.getElementById('consumer-insights').style.borderBottom = '6px solid #0243EC';
      document.getElementById('sales-manager').style.borderBottom = 'none';
      document.getElementById('data-analytics').style.borderBottom = 'none';
      document.getElementById('brand-manager').style.fontWeight = '400';
      document.getElementById('consumer-insights').style.fontWeight = '700';
      document.getElementById('sales-manager').style.fontWeight = '400';
      document.getElementById('data-analytics').style.fontWeight = '400';
    },
    salesManagerTabSelection: function () {
      this.brandManagerTab = false;
      this.salesTab = true;
      this.consumerTab = false;
      this.dataTab = false;
      document.getElementById('brand-manager').style.borderBottom = 'none';
      document.getElementById('consumer-insights').style.borderBottom = 'none';
      document.getElementById('sales-manager').style.borderBottom = '6px solid #0243EC';
      document.getElementById('data-analytics').style.borderBottom = 'none';
      document.getElementById('brand-manager').style.fontWeight = '400';
      document.getElementById('consumer-insights').style.fontWeight = '400';
      document.getElementById('sales-manager').style.fontWeight = '700';
      document.getElementById('data-analytics').style.fontWeight = '400';
    },
    dataTabSelection: function () {
      this.brandManagerTab = false;
      this.salesTab = false;
      this.consumerTab = false;
      this.dataTab = true;
      document.getElementById('brand-manager').style.borderBottom = 'none';
      document.getElementById('consumer-insights').style.borderBottom = 'none';
      document.getElementById('sales-manager').style.borderBottom = 'none';
      document.getElementById('data-analytics').style.borderBottom = '6px solid #0243EC';
      document.getElementById('brand-manager').style.fontWeight = '400';
      document.getElementById('consumer-insights').style.fontWeight = '400';
      document.getElementById('sales-manager').style.fontWeight = '400';
      document.getElementById('data-analytics').style.fontWeight = '700';
    },
    getDecisionClientWidth: function () {
      let clientWidth = screen.availWidth;
      if (clientWidth >= 820) {
        this.showDecisions = true;
      } else if (clientWidth < 820) {
        this.showDecisions = false;
      }
    }
  }
};
</script>

<style scoped>
.decision-list-para {
  color: #191948;
  font-size: 17px !important;
  margin-bottom: 20px;
  font-weight: 500 !important;
}

.list-para {
  font-size: 18px !important;
  font-weight: 500;
  line-height: 28px !important;
  position: relative;
  left: -20px;
  color: #191948;
}

.responsive-decision-para {
  margin-top: 50px;
  margin-bottom: 20px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px !important;
  line-height: 30px !important;
  text-align: center !important;
  color: #191948;
}

.responsive-decision-container {
  padding: 0 10%
}

.responsive-decision-image {
  width: 100%
}

.responsive-decision-header {
  margin-top: 60px;
  text-align: center
}

.decision-maker-image-class {
  display: flex;
  align-content: end;
  justify-content: end;
  width: 50%;
  margin-top: 3%
}

.decision-maker-image-container {
  position: relative;
  top: 40px;
  width: 50% !important;
  padding-left: 12%;
}

.decision-maker-tab {
  font-family: 'Poppins', serif;
  font-weight: 400;
  font-size: 16px;
  padding: 20px;
  width: 280px;
  margin-right: 15px;
  color: #191948;
  text-align: center;
  cursor: pointer;
}

.decision-maker-class {
  background-color: #ffff;
  width: 100%;
}

.decision-maker-content-class {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: auto;
  flex-direction: column;
}

.decision-maker-header {
  font-family: 'Fraunces', serif;
  font-weight: 600;
  padding-right: 10%;
  padding-left: 10%;
  color: #191948;
  font-size: 40px !important;
  padding-top: 80px;
  padding-bottom: 40px;
  line-height: 45px !important;
  text-align: center
}

.decision-maker-tabs {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 100%;
}

.list-container {
  font-family: 'Poppins', serif;
  text-align: left;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 28px;
  padding-left: 50px
}

.decision-image {
  width: 100%;
  height: 390px;
  padding-left: 5%;
}

.decision-maker-content-container {
  display: flex;
  margin-bottom: 40px;
}

.decision-maker-tab-content {
  width: 50%;
  display: flex;
  align-content: start;
  justify-content: start;
  margin-top: 50px;
  padding-right: 20%;
  padding-left: 30px
}

.decision-button {
  float: left;
  margin-left: 50px;
  margin-top: 20px;
  border-radius: 4px;
  width: 146px;
  height: 52px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.decision-list {
  padding-bottom: 20px;
  font-family: 'Poppins';
  font-style: normal;
  list-style: disc;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.list-type-disclosure-closed{
  list-style-type: disclosure-closed;
}

/*  media query start */

@media only screen and (max-width: 1456px) {
  .decision-maker-tab-content {
    padding-right: 15%
  }
}

@media only screen and (max-width: 420px) {
  .decision-button {
    width: 80% !important;
  }
}

@media only screen and (max-width: 1366px) {
  .decision-maker-image-container {
    top: 15px;
    padding-left: 4.8% !important;
    padding-top: 35px;
  }

  .decision-maker-tab-content {
    padding-right: 9.5% !important;
    margin-top: 30px
  }
}

@media only screen and (max-width: 1280px) {
  .decision-maker-tab {
  }

  .decision-maker-image-container {
    width: 100% !important;
    max-width: 100% !important;
  }

  .decision-maker-tab-content {
    width: 100%;
    max-width: 100%
  }

  .decision-image {
    width: 100% !important;
    height: auto
  }

  .decision-maker-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding-left: 5%;
    padding-right: 5%
  }

  .decision-button {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 155px;
    height: 52px;
    font-weight: 600;
    font-size: 18px
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .decision-image {
    width: 100% !important;
    padding-left: 0 !important
  }
}


@media only screen and (max-width: 900px) {
  .decision-maker-tab {
    width: 150px
  }
}

@media only screen and (max-width: 821px) {
  .list-para {
    font-weight: 600 !important;
  }

  .list-container {
    padding-top: 20px !important;
    padding-left: 20px !important;
  }

  .decision-maker-header {
    padding-top: 60px !important;
    padding-bottom: 40px !important
  }

  .decision-maker-tab {
    font-size: 18px !important;
  }

  .decision-list {
    font-size: 18px;
  }

  .decision-maker-image-container {
    width: 100% !important;
    padding-left: 0 !important
  }

  .decision-image {
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 415px) {
  .list-para {
    font-size: 16px !important;
    margin: 10px 0;
  }

  .decision-maker-image-container {
    width: 100% !important;
    padding-left: 0 !important
  }

  .decision-list {
    font-size: 14px !important;
  }

  .decision-maker-content-container {
    padding: 0 0 !important;
  }

  .decision-image {
    padding: 0 10% !important;
    width: 100%;
  }

  .decision-maker-tab-content {
    padding: 0 10%
  }

  .decision-button {
    margin-left: 10% !important;
    width: 100% !important;
  }

  .list-container {
    padding-left: 20px !important;
    padding-top: 0 !important;
  }

  .decision-maker-tabs {
    flex-direction: column !important;
  }

  .decision-maker-tab {
    width: 80% !important;
    margin-right: 0 !important
  }

  .decision-maker-tab-content {
    padding: 0 0;
  }
}

/* media query end */
</style>