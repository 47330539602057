<template>
  <div style="margin-top:60px;overflow-x:hidden">
    <vs-row class="banner-wrapper">
      <div
        v-if="showBanner"
        class="banner-class"
      >
        <div
          class="banner-text"
          @click="handleTour"
        >
          Take an Interactive product tour of Explorazor
        </div>
        <div
          class="banner-cross"
          @click="closeBanner"
        >
          <img
            src="../assets/svgs/app-icons/cross-icon.svg"
            alt="cross-icon"
          >
        </div>
      </div>
    </vs-row>
    <div class="whitepaper-text-container">
      <div class="whitepaper-heading">
        White paper
      </div>
      <div class="whitepaper-title">
        How to truly leverage Nielsen Data to improve sales
      </div>
      <div class="whitepaper-content">
        Learn how to use Nielsen data to increase sales and improve decision-making, including understanding Nielsen
        metrics and how to integrate them with other datasets for richer analysis & generating actionable insights
      </div>
    </div>
    <div class="whitepaper-button-container">
      <vs-button
        class="whitepaper-button"
      >
        <a
          href="#download-whitepaper"
          style="color:white"
        > Download White paper</a>
      </vs-button>
    </div>
    <div
      class="about-whitepaper"
    >
      <div class="about-whitepaper-image">
        <img
          src="../assets/svgs/card-image/Ellipse.svg"
          alt="eclipse"
          class="eclipse-class"
        >
        <img
          src="../assets/svgs/card-image/smartmockups.svg"
          alt="mockups"
          class="main-image-class"
        >
      </div>
      <div class="about-whitepaper-content">
        <div class="about-whitepaper-text">
          <div class="about-whitepaper-title">
            About this white paper:
          </div>
          <div class="about-whitepaper-subcontent">
            The use of Nielsen data in the CPG industry has been critical for making informed business decisions.
            Enterprises have been leveraging this data to gain insights into consumer behavior, identify trends, and
            make
            data-driven decisions that drive sales growth. However, many Enterprises struggle to extract maximum value
            from the vast amounts of data provided by Nielsen.
          </div>
        </div>
        <div>
          <div class="about-whitepaper-title">
            Why read this white paper?
          </div>
          <div>
            <ul class="about-whitepaper-subcontent">
              <li style="font-weight:400;margin-left:25px">
                To understand Nielsen data for CPG brand managers' decision-making
              </li>
              <li style="font-weight:400;margin-left:25px">
                Recognize the importance of Nielsen data for CPG Enterprises
              </li>
              <li style="font-weight:400;margin-left:25px">
                Comprehend Nielsen metrics and their value
              </li>
              <li
                id="download-whitepaper"
                style="font-weight:400;margin-left:25px"
              >
                To leverage Nielsen and data exploration tools to enhance sales
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div id="whitepaper-download">
      <div class="whitepaper-form-container">
        <div class="form-title">
          Download White Paper
        </div>
        <div class="form-container">
          <form
            action=""
            class="form-class"
            @submit="handleFormSubmit"
          >
            <vs-row
              vs-w="12"
            >
              <vs-col
                :vs-w="screenWidth>768?3.5:12"
                :class="screenWidth<768?'column-class':''"
              >
                <div class="form-label">
                  <label
                    for="name"
                  >Name *</label>
                </div>
                <div>
                  <input
                    id="name"
                    v-model="name"
                    type="text"
                    name="name"
                    class="form-input-class"
                    placeholder="Name"
                    required
                  >
                </div>
              </vs-col>
              <vs-col
                :vs-w="screenWidth>768?3.5:12"
                :class="screenWidth<768?'column-class':''"
              >
                <div class="form-label">
                  <label
                    for="email"
                  >Business Email ID *</label>
                </div>
                <div>
                  <input
                    id="email"
                    v-model="email"
                    type="text"
                    name="email"
                    placeholder="Business Email ID"
                    class="form-input-class"
                    required
                  >
                </div>
              </vs-col>
              <vs-col
                :vs-w="screenWidth>768?3.5:12"
                :class="screenWidth<768?'column-class':''"
              >
                <div class="form-label">
                  <label
                    for="organisation"
                  >Organisation *</label><br>
                </div>
                <div>
                  <input
                    id="organisation"
                    v-model="organisation"
                    type="text"
                    placeholder="Organisation"
                    class="form-input-class"
                    required
                  >
                </div>
              </vs-col>
              <vs-col
                :vs-w="screenWidth>769?1.5:12"
              >
                <input
                  type="submit"
                  value="Submit"
                  class="form-button"
                >
              </vs-col>
            </vs-row>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WhitePaper',
    data: function () {
      return {
        screenWidth: '',
        name: '',
        email: '',
        organisation: '',
        showBanner: true,
      };
    },
    beforeMount () {
      document.title = 'White Paper | Explorazor';
      document.querySelector('meta[name="description"]').setAttribute('content', 'In this White' +
        ' Paper, you will learn how CPG companies leverage Nielsen Data to improve Sales using Explorazor');
    },
    mounted () {
      this.screenWidth = screen.availWidth;
    },
    methods: {
      handleTour: function () {
        window.open('https://app.explorazor.com/#/playground', '_blank');
      },
      closeBanner: function () {
        this.showBanner = false;
      },
      handleFormSubmit: function (e) {
        e.preventDefault();
        let link = location.href;
        let utmSource = link.includes('utm_source=')
          ? link.substring(link.indexOf('utm_source=') + 11, link.lastIndexOf('&utm_medium'))
          : '';
        let utmMedium = link.includes('utm_medium=')
          ? link.substring(link.indexOf('utm_medium=') + 11, link.lastIndexOf('&utm_campaign')) : '';
        let utmCampaign = link.includes('utm_campaign=')
          ? link.substring(link.indexOf('utm_campaign=') + 13)
          : '';
        const params = {
          api_key: process.env.VUE_APP_SENDY_API_KEY,
          name: this.name,
          email: this.email,
          Company: this.organisation,
          Lead_Source_4: 'Whitepaper on Nielsen Sales',
          boolean: true,
          list: process.env.VUE_APP_SENDY_LIST_KEY,
          Lead_Source_1: 'Website',
          Lead_Source_2: 'Asset',
          Lead_Source_3: 'Whitepaper',
          utm_source: utmSource.length ? utmSource : '',
          utm_medium: utmMedium.length ? utmMedium : '',
          utm_campaign_name: utmCampaign.length ? utmCampaign : '',
        };
        let formBody = [];
        for (let property in params) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(params[property]);
          formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');
        const xhr = new XMLHttpRequest();
        xhr.open('POST',
          'https://email.pcube.net/subscribe', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Headers',
          'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
          'Access-Control-Allow-Methods', 'OPTIONS,POST',
          'Access-Control-Allow-Credentials', true,
          'Access-Control-Allow-Origin', '*',
          'X-Requested-With', '*',);
        xhr.send(formBody);
        // sendy subscription call code end
        // hubspot form api call code start
        const xhrHubspot = new XMLHttpRequest();
        // eslint-disable-next-line max-len
        const url = `https://api.hsforms.com/submissions/v3/integration/submit/${ process.env.VUE_APP_HUBSPOT_PORTAL_ID }/${ process.env.VUE_APP_HUBSPOT_FORM_ID }`;
        //  request JSON:
        let data = {
          'fields': [
            {
              'name': 'email',
              'value': this.email
            },
            {
              'name': 'message',
              'value': this.organisation
            },
            {
              'name': 'firstname',
              'value': this.name
            },
            {
              'name': 'ga_source',
              'value': 'Website'
            },
            {
              'name': 'ga_medium',
              'value': 'Asset'
            },
            {
              'name': 'ga_campaign',
              'value': 'Whitepaper on Nielsen Sales'
            }
          ],

        };

        let final_data = JSON.stringify(data);

        xhrHubspot.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhrHubspot.setRequestHeader('Content-Type', 'application/json');

        // Sends the request

        xhrHubspot.send(final_data);
        // hubspot form api call code end
        window.open('https://www.vphrase.com/explorazor-assets/whitepaper/' +
          'Explorazor%20-%20How%20To%20Truly%20Leverage%20Nielsen%20Data%20To%20Improve%20Sales.pdf', '_blank');
        document.getElementById('whitepaper-download').style.padding = '50px';
        document.getElementById('whitepaper-download').style.color = 'white';
        document.getElementById('whitepaper-download').style.background = '#191948';
        document.getElementById('whitepaper-download').style.fontSize = '22px';
        document.getElementById('whitepaper-download').style.fontWeight = '600';
        document.getElementById('whitepaper-download').style.fontFamily = 'Poppins';
        document.getElementById('whitepaper-download').innerHTML = 'Thank You for downloading the whitepaper';
      },
      downloadWhitepaper: function () {
        window.open('https://www.vphrase.com/explorazor-assets/whitepaper/' +
          'Explorazor%20-%20How%20To%20Truly%20Leverage%20Nielsen%20Data%20To%20Improve%20Sales.pdf', '_blank');
      }
    }
  };
</script>

<style scoped>
  .whitepaper-heading {
    margin-top: 80px;
    color: #0243EC;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    width: 80%
  }

  .whitepaper-title {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 62px;
    line-height: 76px;
    text-align: center;
    color: #191948;
    padding-top: 20px;
    width: 60%;
  }

  .whitepaper-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */
    text-align: center;
    color: #6A7591;
    width: 55%;
    padding-top: 20px;
  }

  .about-whitepaper {
    background: #E6EEFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .whitepaper-text-container {
    display: flex;
    justify-content: center;
    text-align: left;
    flex-direction: column;
    align-items: center;
  }

  .whitepaper-button {
    margin-top: 35px;
    margin-bottom: 100px;
    padding: 12px 23px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    border-radius: 4px
  }

  .about-whitepaper-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #191948;
    padding-bottom: 20px;

  }

  .about-whitepaper-content {
    padding: 60px 0;
    width: 50%;
    text-align: left;
    padding-left: 20%;
    order: 1
  }

  .about-whitepaper-image {
    order: 2;
    width: 50%;
  }

  .about-whitepaper-text {
    padding-bottom: 35px
  }

  .about-whitepaper-subcontent {
    list-style: url('../assets/backgrounds/check-small.svg');
    font-family: 'Poppins';
    font-style: normal;
    font-size: 16px;
    line-height: 32px;
    color: #191948;
  }

  li::marker {
    font-size: 0.3rem !important
  }

  .main-image-class {
    position: relative;
    top: -15px;
    right: 20%
  }

  .eclipse-class {
    position: absolute;
    top: 600px;
    right: 20%;
  }

  .whitepaper-form-container {
    background: #191948;
    padding-top: 45px
  }

  .form-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    color: #FFFFFF;
  }

  .form-container {
    padding-top: 20px;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .whitepaper-demo-container {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .whitepaper-demo-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 12px 23px !important;
    border-radius: 4px;
  }

  .whitepaper-demo-title {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #191948;
    padding-bottom: 45px;
  }

  .form-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 10px 20px !important;
    border-radius: 4px;
    margin-top: 30px;
    margin-left: 10px;
    background: #0243EC;
    border: none;
    cursor: pointer;
  }

  .form-label {
    margin-bottom: 10px !important;
    text-align: left !important;
    margin-left: 8%;
    width: 100%
  }

  label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #D0DDFF;
  }

  .form-input-class {
    height: 44px;
    padding-left: 10px;
    width: 90%;
    margin-left: 10px !important;
    border-radius: 4px;
    border: none
  }

  .form-class {
    width: 50%
  }

  @media screen and (max-width: 1367px) {
    .eclipse-class {
      right: 13%
    }

    .form-container {
      width: 100%
    }

    .form-class {
      width: 80%
    }

    .whitepaper-title {
      width: 80%
    }

    .whitepaper-content {
      width: 70%
    }

    .about-whitepaper-content {
      padding-left: 15%
    }
  }

  @media screen and (max-width: 768px) {
    .form-label {
      margin-left: 5.5%
    }

    .form-button {
      padding: 12px 24px !important;
    }

    .about-whitepaper-image {
      width: 80%;
      order: 1
    }

    .about-whitepaper-content {
      width: 80%;
      order: 2;
      padding-left: 0
    }

    .about-whitepaper {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-direction: column;
    }

    .eclipse-class {
      right: 20%;
      top: 720px !important;
    }

    .form-input-class {
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 414px) {
    .form-class {
      width: 95% !important
    }

    .form-button {
      width: 90% !important;
      margin-left: 0 !important
    }

    .form-input-class {
      margin-left: 0 !important;
    }

    .whitepaper-title {
      width: 90%
    }

    .whitepaper-content {
      width: 90%
    }

    .whitepaper-button {
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-content: center;
      padding: 0 5%;
    }

    .whitepaper-button-container {
      display: flex;
      justify-content: center;
      align-content: center;
      padding: 0 5%
    }

    .about-whitepaper-image {
      width: 100%;
    }

    .main-image-class {
      right: 5%;
      width: 90%;
      top: 50px !important;
    }

    .eclipse-class {
      width: 40% !important;
      top: 85% !important;
      right: 10% !important;
    }

    .whitepaper-title {
      font-family: 'Fraunces';
      font-style: normal;
      font-weight: 600;
      font-size: 42px;
      line-height: 52px;
      text-align: center;
      color: #191948;
    }

    .whitepaper-content {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #6A7591;
    }

    .about-whitepaper-content {
      width: 90% !important
    }
  }

  @media screen and (max-width: 820px) {
    .form-label {
      margin-left: 5.5%
    }

    .form-button {
      padding: 12px 24px !important;
    }

    .about-whitepaper-image {
      width: 80%;
      order: 1
    }

    .about-whitepaper-content {
      width: 80%;
      order: 2;
      padding-left: 0
    }

    .about-whitepaper {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-direction: column;
    }

    .eclipse-class {
      right: 15% !important;
      top: 720px !important;
    }

    .form-input-class {
      margin-bottom: 30px;
    }

    .main-image-class {
      right: 0 !important
    }
  }

  @media screen and (max-width: 395px) {
    .eclipse-class {
      width: 40% !important;
      top: 780px !important;
      right: 10% !important;
    }
  }
</style>