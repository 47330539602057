<template>
  <div class="card">
    <img
      :src="getCardImage"
      alt="Avatar"
    >
    <div class="container">
      <p><b>{{ content }}</b></p>
    </div>
  </div>
</template>

<script>

  export default {

    props: {
      image: {
        type: String,
        default: null,
      },
      content: {
        type: String,
        default: null,
      }
    },
    computed: {
      getCardImage: function () {
        try {
          return require(`@/assets/svgs/card-image/${ this.image }.svg`);
        } catch (error) {
          console.log(error);
          return '';
        }
      }
    }
  };
</script>
<style scoped>
  p {
    color: #191948;
  }
</style>