export const imageMixin = {
  props: {},
  methods: {
    getBlogs: function () {
      return [{
        title: '4 Ways Explorazor Differs from Power BI',
        image: require('../assets/svgs/clients/explorazor-differs.svg')
      }, {
        title: 'What is %ACV?',
        image: require('../assets/svgs/clients/acv.svg')
      }, {
        title: 'Interested in Becoming a Brand Manager?',
        image: require('../assets/svgs/clients/nielson.svg')
      }];
    },
    getClientLogos: function () {
      return ['danone', 'sanofi', 'gsk', 'abbott_laboratories', 'alkem', 'olam'];
    },
    getEnterpriseFeatureImages: function () {
      return ['key', 'security', 'privacy', 'cloud'];
    },
    getPartnerLogos: function () {
      return ['deloitte', 'pws', '' + 'accenture', 'capgemini', 'microsoft', 'intel'];
    },
    featuredIn: function () {
      const arrayPartner = ['forbes', 'dna', 'fintech', 'inside-big-data',
        'economics-times', 'the-hindu',];
      return arrayPartner;
    }


  }
};