<template>
  <div style="margin-top:60px;width:100% !important;overflow-x:hidden ">
    <div>
      <div class="pricing-title align-center">
        testimonials
      </div>
      <div
        class="pricing-subheader"
        style="width:100% !important;"
      >
        What our customers are saying
      </div>
    </div>
    <div class="testimony-container">
      <div class="testimony-quote-container">
        <app-icon
          icon="app-icons/quote"
          width="60"
        />
      </div>
      <div
        class="testimony-comment"
        style="padding-top:30px;"
      >
        The most striking aspect of Explorazor is its exceptional time-saving capabilities. With a few simple
        queries, I can retrieve precise data cuts within seconds, and generate insights with remarkable efficiency.
      </div>
      <div class="usecase-testimony-container">
        <div
          v-if="screenWidth < 415"
          class="mobile-testimony"
        >
          <div style="display:flex;margin-bottom:20px">
            <span class="">
              <img
                src="../assets/svgs/clients/testimony-client.svg"
                alt="client"
              >
            </span>
            <span
              style="padding-top:5px;padding-left:15px"
            >
              <div
                class="client-name"
                style="color:#6A7591 !important;"
              >
                <span>Rahil Shaikh</span>
                <span
                  class="linkedin-class"
                  @click="routeToLinkedIn"
                ><img
                  src="../assets/png/LinkedIn.png"
                  alt="linkedIn"
                ></span>
              </div>
              <div
                class="client-designation"
                style="color:#6A7591 !important;"
              >Head of Strategy & Insights</div>
            </span>
          </div>
          <div class="testimony-seperation" />
          <div>
            <span class="testimony-image-container"><img
              src="../assets/svgs/clients/danone-testimony.svg"
              alt="danone"
            ></span>
          </div>
        </div>
        <div
          v-if="screenWidth > 415"
          class="desktop-testimony"
        >
          <span
            v-if="screenWidth > 415"
          >
            <img
              src="../assets/svgs/clients/testimony-client.svg"
              alt="client"
            >
          </span>
          <span
            v-if="screenWidth > 415"
            style="padding-top:5px;padding-left:15px"
          >
            <div
              class="client-name"
              style="color:#6A7591 !important;"
            ><span>Rahil Shaikh</span><span
              class="linkedin-class"
              @click="routeToLinkedIn"
            ><img
              src="../assets/png/LinkedIn.png"
              alt="linkedIn"
            ></span></div>
            <div
              class="client-designation"
              style="color:#6A7591 !important;"
            >Head of Strategy & Insights</div>
          </span>
          <span
            v-if="screenWidth > 415"
            class="testimony-seperation"
          />
          <span
            v-if="screenWidth > 415"
            class="testimony-image-container"
          ><img
            src="../assets/svgs/clients/danone-testimony.svg"
            alt="danone"
          ></span>
        </div>
      </div>
    </div>
    <div class="usecase-content-container">
      <div class="usecase-text">
        As a Head of Strategy & Insights, my role revolves around enabling valuable insights from vast amounts of
        data. The introduction of Explorazor into our analytics toolkit has been nothing short of transformative.
      </div>
      <div
        class="usecase-text"
      >
        The most striking aspect of Explorazor is its exceptional time-saving capabilities. With a few simple queries
        in natural language, we can now retrieve precise data cuts within seconds, enabling us to generate insights with
        remarkable efficiency. In the past, my team would spend days, and sometimes even weeks, working with Excel
        spreadsheets, performing repetitive tasks.
      </div>
      <div class="usecase-border">
        <div />
        <div class="usecase-border-text">
          Explorazor has liberated us from the tedious task of following multiple
          excel sheets to carry out analysis,allowing our teams to focus their energy on in-depth analysis
          and strategic decision-makingon a single source of data having all the different datasets harmonized
          into one.
        </div>
      </div>
      <div class="usecase-text">
        One of the standout features of Explorazor is its advanced root cause analysis functionality. This powerful tool
        enables me to delve deep into multiple datasets, uncovering the underlying factors behind performance metrics.
        With this ability to drill down and pinpoint the root cause of issues, teams can swiftly identify opportunities
        for improvement and address challenges with precision. Explorazor has become our trusted companion, unraveling
        the hidden story within the data.
      </div>
      <div class="usecase-text">
        From a business perspective, the single source of truth provided by Explorazor is
        a true advantage. Harmonizing diverse datasets from sales, marketing, and other teams into one cohesive and
        reliable resource which eliminates the risk of data manipulation.
      </div>
      <div class="usecase-text">
        This unified data ecosystem empowers our organization to make data-driven decisions
        confidently. With everyone accessing the same accurate and comprehensive data, we can align our strategies
        seamlessly and drive collective growth.
      </div>
      <div class="usecase-border">
        <div />
        <div class="usecase-border-text">
          <span style="font-weight:400 !important">In summary,
          </span> Explorazor has revolutionized our approach to data analysis. It has unlocked newfound efficiency,
          enabling us to extract valuable insights swiftly and effectively. Explorazor has undoubtedly become an
          indispensable tool for any insights manager seeking to maximize the potential of their data assets.
        </div>
      </div>
    </div>
    <div
      class="danone-help-container"
      :style="showForm?{'height':'auto'}:{}"
    >
      <span>
        <div class="danone-help-text">
          Read how Explorazor Helped <span style="color:#ABC9FD">Danone India Ltd.</span> Revolutionize Data Analysis
        </div>
        <div
          v-if="!showForm"
          class="danone-help-button-container"
        >
          <vs-button
            class="danone-help-button"
            @click.native="downloadCaseStudy"
          >Download Case Study</vs-button>
        </div>
        <vs-row
          v-if="showForm"
          style="display:flex;justify-content: center;align-content: center"
        >
          <div
            id="whitepaper-download"
            style="width:100%"
          >
            <div
              class="whitepaper-form-container"
              :class="showForm?'form-radius':''"
            >
              <div class="form-title">
                Download Case Study
              </div>
              <div class="form-container">
                <form
                  action=""
                  class="form-class"
                  @submit="handleFormSubmit"
                >
                  <vs-row
                    vs-w="12"
                    vs-justify="center"
                  >
                    <vs-col
                      :vs-w="screenWidth>768?3.5:12"
                      :class="screenWidth<768?'column-class':''"
                    >
                      <div class="form-label">
                        <label
                          for="name"
                        >Name *</label>
                      </div>
                      <div>
                        <input
                          id="name"
                          v-model="name"
                          type="text"
                          name="name"
                          class="form-input-class"
                          placeholder="Name"
                          required
                        >
                      </div>
                    </vs-col>
                    <vs-col
                      :vs-w="screenWidth>768?3.5:12"
                      :class="screenWidth<768?'column-class':''"
                    >
                      <div class="form-label">
                        <label
                          for="email"
                        >Business Email ID *</label>
                      </div>
                      <div>
                        <input
                          id="email"
                          v-model="email"
                          type="text"
                          name="email"
                          placeholder="Business Email ID"
                          class="form-input-class"
                          required
                        >
                      </div>
                    </vs-col>
                    <vs-col
                      :vs-w="screenWidth>769?1.5:12"
                    >
                      <input
                        type="submit"
                        value="Submit"
                        class="form-button"
                      >
                    </vs-col>
                  </vs-row>
                </form>
              </div>
            </div>
          </div>
        </vs-row>
      </span>
      <div class="danone-help-image">
        <img
          src="../assets/svgs/card-image/Business_analysis.svg"
          alt="business-analysis"
          class="usecase-image"
        >
      </div>
    </div>
    <div class="danone-exploration-container">
      <div class="danone-exploration-wrapper">
        <div class="danone-exploration-text">
          Data Exploration tool for Analysts
        </div>
        <div class="danone-exploration-subtext">
          Connect multiple datasets and effortlessly analyze them with our "Google - like" search interface.
        </div>
        <div class="danone-started-button-container">
          <vs-button class="danone-started-button">
            Get started for free
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AppIcon from '@/components/helper/AppIcon.vue';

  export default {
    name: 'DanoneUsecase',
    components: { AppIcon },
    data: function () {
      return {
        screenWidth: null,
        showForm: false,
        name: '',
        email: ''
      };
    },
    mounted () {
      this.screenWidth = screen.availWidth;
    },
    methods: {
      routeToLinkedIn: function () {
        window.open('https://www.linkedin.com/in/rahil-shaikh-33564067/', '_blank');
      },
      downloadCaseStudy: function () {
        this.showForm = true;
      },
      handleFormSubmit: function (e) {
        e.preventDefault();
        let link = location.href;
        let utmSource = link.includes('utm_source=')
          ? link.substring(link.indexOf('utm_source=') + 11, link.lastIndexOf('&utm_medium'))
          : '';
        let utmMedium = link.includes('utm_medium=')
          ? link.substring(link.indexOf('utm_medium=') + 11, link.lastIndexOf('&utm_campaign')) : '';
        let utmCampaign = link.includes('utm_campaign=')
          ? link.substring(link.indexOf('utm_campaign=') + 13)
          : '';
        const params = {
          api_key: process.env.VUE_APP_SENDY_API_KEY,
          name: this.name,
          email: this.email,
          boolean: true,
          list: process.env.VUE_APP_SENDY_LIST_KEY,
          Lead_Source_1: 'Website',
          Lead_Source_2: 'Asset',
          Lead_Source_3: 'case_study',
          Lead_Source_4: 'f_and_b_industry',
          utm_source: utmSource.length ? utmSource : '',
          utm_medium: utmMedium.length ? utmMedium : '',
          utm_campaign_name: utmCampaign.length ? utmCampaign : '',
        };
        let formBody = [];
        for (let property in params) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(params[property]);
          formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');
        const xhr = new XMLHttpRequest();
        xhr.open('POST',
          'https://email.pcube.net/subscribe', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Headers',
          'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
          'Access-Control-Allow-Methods', 'OPTIONS,POST',
          'Access-Control-Allow-Credentials', true,
          'Access-Control-Allow-Origin', '*',
          'X-Requested-With', '*',);
        xhr.send(formBody);
        // sendy subscription call code end
        // hubspot form api call code start
        const xhrHubspot = new XMLHttpRequest();
        // eslint-disable-next-line max-len
        const url = `https://api.hsforms.com/submissions/v3/integration/submit/${ process.env.VUE_APP_HUBSPOT_PORTAL_ID }/${ process.env.VUE_APP_HUBSPOT_FORM_ID }`;
        //  request JSON:
        let data = {
          'fields': [
            {
              'name': 'email',
              'value': this.email
            },
            {
              'name': 'firstname',
              'value': this.name
            },
            {
              'name': 'ga_source',
              'value': 'Website'
            },
            {
              'name': 'ga_medium',
              'value': 'Asset'
            },
            {
              'name': 'ga_campaign',
              'value': 'Case_study_f_and_b_industry'
            }
          ],

        };

        let final_data = JSON.stringify(data);

        xhrHubspot.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhrHubspot.setRequestHeader('Content-Type', 'application/json');

        // Sends the request

        xhrHubspot.send(final_data);
        // hubspot form api call code end
        window.open('https://www.vphrase.com/explorazor-assets/case_study/Explorazor%20Case%20Study%20(Danone).pdf',
          '_blank');
        document.getElementById('whitepaper-download').style.padding = '50px';
        document.getElementById('whitepaper-download').style.color = 'white';
        document.getElementById('whitepaper-download').style.background = '#191948';
        document.getElementById('whitepaper-download').style.fontSize = '22px';
        document.getElementById('whitepaper-download').style.fontWeight = '600';
        document.getElementById('whitepaper-download').style.fontFamily = 'Poppins';
        document.getElementById('whitepaper-download').innerHTML = 'Thank You for downloading the Case Study';
      },
    }
  };
</script>
<style scoped>
  .linkedin-class {
    position: relative;
    left: 10px;
    top: 5px;
    cursor: pointer
  }

  .align-center {
    justify-content: center;
    text-align: center;
  }

  .testimony-container {
    background: #191948;
    margin-top: 80px;
  }

  .testimony-quote-container {
    position: relative;
    top: -30px;
    left: 48%;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .usecase-testimony-container {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .desktop-testimony {
    display: flex;
    width: 32%;
    background: #DFE8F7;
    position: relative;
    bottom: -50px;
    border-radius: 6px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .usecase-text {
    color: #6A7591;
    text-align: justify;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 170% */
    padding-top: 30px;
  }

  .usecase-content-container {
    margin-top: 100px;
    padding-right: 15%;
    padding-left: 15%;
    margin-bottom: 50px;
  }

  .usecase-border-text {
    color: #6A7591;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; /* 170% */
    margin-top: 30px;
    text-align: left !important;
    justify-content: start;
    padding-left: 40px;
  }

  .usecase-border {
    border-left: 6px solid #0243EC;
  }

  .danone-help-container {
    background: #191948;
    margin-right: 15% !important;
    margin-left: 15% !important;
    border-radius: 6px;
    display: flex;
    height: 250px;
    position: relative;
    bottom: -60px;
  }

  .danone-help-text {
    color: #FFF;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    padding-left: 50px;
    padding-top: 50px
  }

  .danone-help-image {
    position: relative;
    right: -80px;
    top: -80px;
  }

  .danone-help-button-container {
    display: flex;
    justify-content: start;
    align-items: start;
    align-content: start;
    padding-left: 40px;
    padding-top: 20px;
  }

  .danone-help-button {
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 4px;
    padding: 10px 20px !important;
  }

  .danone-exploration-container {
    background: #0243EC;
  }

  .danone-started-button {
    color: #0243EC !important;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: #FFFFFF !important;
    padding: 10px 20px !important;
    border-radius: 4px;
  }

  .danone-exploration-text {
    padding-top: 130px;
    padding-bottom: 25px;
    color: #FFF;
    font-family: Fraunces;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .danone-exploration-subtext {
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    padding-bottom: 25px;
    text-align: left;
  }

  .danone-started-button-container {
    padding-bottom: 100px;
  }

  .danone-exploration-wrapper {
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    align-content: start
  }

  @media screen and (max-width: 1750px) {
    .desktop-testimony {
      width: 40%
    }
  }

  @media screen and (max-width: 1500px) {
    .desktop-testimony {
      width: 45%
    }
  }

  @media screen and (max-width: 820px) {
    .desktop-testimony {
      width: 75%
    }

    .danone-help-container {
      flex-direction: column;
      height: 400px;
    }

    .danone-help-image {
      right: 0 !important;
      top: 50px !important;
    }

    .usecase-image {
      width: 50%
    }

    .danone-exploration-text {
      padding-top: 300px;
      text-align: left;
    }
  }

  @media screen and (max-width: 414px) {
    .mobile-testimony {
      background: #DFE8F7;
      padding: 20px 20px;
      border-radius: 6px;
      position: relative;
      bottom: -50px;
    }

    .testimony-quote-container {
      left: 45%;
    }

    .usecase-text {
      color: #6A7591;
      text-align: justify;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 200% */
    }

    .usecase-border-text {
      color: #6A7591;
      text-align: justify;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 200% */
    }

    .danone-help-text {
      color: #FFFFFF;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      padding-left: 20px;
      padding-right: 20px;
      line-height: normal;
    }

    .danone-exploration-text {
      padding-top: 180px;
      color: #FFF;
      font-family: Fraunces;
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .danone-exploration-subtext {
      color: #FFF;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 200% */
    }

    .danone-help-button-container {
      padding-left: 20px;
    }

    .usecase-content-container {
      padding-left: 10% !important;
      padding-right: 10% !important
    }

    .danone-help-container {
      margin-left: 10% !important;
      margin-right: 10% !important
    }

    .usecase-image {
      width: 60% !important
    }
  }
</style>