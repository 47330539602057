<template>
  <vs-row class="image-wrapper">
    <!--    THIS will CHECK TYPE if clients-side-img THAN IT WILL PASS THE CONTENT TO ClientSideImage-->
    <vs-row
      v-if="type==='client'"
      vs-w="12"
      vs-justify="center"
    >
      <large-image
        :change="change"
        :image="client"
      />
    </vs-row>
    <!--     THIS will CHECK TYPE if group-img THAN IT WILL PASS THE CONTENT TO Grouped-image-->
    <group-image
      v-if="type==='group'"
      :image="group"
      class="img-logos-these-companies"
    />
    <!--    THIS will CHECK TYPE if cards THAN IT WILL PASS THE CONTENT TO CARD-->
    <vs-row
      v-if="type==='card'"
      vs-justify="center"
    >
      <vs-col
        v-for="(card,index) in cards"
        :key="card.id"
        class="built-container"
        vs-justify="center"
      >
        <app-card
          :content="content[index]"
          :image="card"
        />
      </vs-col>
    </vs-row>
  </vs-row>
</template>

<script>
  import groupImage from '@/components/wrapper/GroupImage';
  import largeImage from '@/components/wrapper/LargeImage';
  import appCard from '@/components/card/AppCard';

  export default {
    components: {
      largeImage,
      groupImage,
      appCard,
    },
    props: {
      type: {
        type: String,
        default: null,
      },
      change: {
        type: String,
        default: null,
      },
      client: {
        type: String,
        default: null,
      },
      group: {
        type: String,
        default: null,
      },
      cards: {
        type: Array,
        default: () => []
      },
      content: {
        type: Array,
        default: null,
      }
    }
  };
</script>



