<template>
  <div ref="explorazor-helps">
    <vs-row
      style="display:flex;justify-content:center;align-content:center;
      background:#DFE8F7;margin-top:0px;padding-bottom:53px !important;"
    >
      <vs-col class="explorazor-helps-wrapper">
        <vs-row class="explorazor-heading-container">
          <h2 class="explorazor-helps-heading">
            Your Ad-hoc Queries on steroids
          </h2>
        </vs-row>
        <div class="grid-class">
          <vs-col v-if="!showImage">
            <img
              src="../../assets/svgs/card-image/spend-less.svg"
              alt=""
              class="grid-image"
            >
          </vs-col>
          <vs-col class="grid-list-container">
            <h2 class="grid-list-heading">
              Spend less time preparing dashboards and more time analyzing your data
            </h2>
            <div>
              <div class="grid-para">
                Prioritize brainstorming by reducing your time spent on Multiple excels. Simply connect them with
                Explorazor and perform criss cross analysis on a single harmonized data set
              </div>
              <div class="flex-box ">
                <vs-button
                  class="get-started-button"
                  @click.native="getStarted"
                >
                  Get Started For Free
                </vs-button>
              </div>
            </div>
          </vs-col>
          <vs-col v-show="showImage">
            <img
              src="../../assets/svgs/card-image/spend-less.svg"
              alt=""
              class="grid-image"
            >
          </vs-col>
        </div>
        <!--    THIS IS REVERTED COMPONENT-->
        <div
          id="dwt-12"
          class="grid-class"
        >
          <vs-col class="">
            <img
              src="../../assets/svgs/card-image/stop-wasting.svg"
              alt=""
              class="grid-image"
            >
          </vs-col>
          <vs-col class="grid-list-container">
            <h2 class="grid-list-heading">
              Stop wasting your days on answering repetitive queries
            </h2>
            <div>
              <div class="grid-para">
                Empower your business users to ask those questions on a simple No-SQL interface
              </div>
              <div class="flex-box ">
                <vs-button
                  class="get-started-button"
                  @click.native="getStarted"
                >
                  Get Started For Free
                </vs-button>
              </div>
            </div>
          </vs-col>
        </div>

        <div
          class="grid-class"
        >
          <vs-col v-if="!showImage">
            <img
              src="../../assets/svgs/card-image/no-need.svg"
              alt=""
              class="grid-image"
            >
          </vs-col>
          <vs-col class="grid-list-container">
            <h2 class="grid-list-heading">
              No need to navigate through multiple complex datasets to find patterns and co-relations
            </h2>
            <div>
              <div class="grid-para">
                On a single query you can drill down to the root cause of issues and identify hidden opportunities
                across multiple datasets.
              </div>
              <div class="flex-box">
                <vs-button
                  class="get-started-button"
                  @click.native="getStarted"
                >
                  Get Started For Free
                </vs-button>
              </div>
            </div>
          </vs-col>
          <vs-col v-if="showImage">
            <img
              src="../../assets/svgs/card-image/no-need.svg"
              alt=""
              class="grid-image"
            >
          </vs-col>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
  export default {
    name: 'AdHocQueries',
    data: function () {
      return {
        showImage: false
      };
    },
    mounted () {
      this.getClientWidthOfContainer();
    },
    methods: {
      getStarted: function () {
        window.open('https://app.explorazor.com/#/register', '_blank');
      },
      getClientWidthOfContainer: function () {
        let clientWidth = screen.availWidth;
        console.log(clientWidth);
        if (clientWidth >= 1280) {
          this.showImage = true;
        } else if (clientWidth < 1280) {
          this.showImage = false;
        }
      }
    }
  };
</script>

<style scoped>
  .explorazor-helps-wrapper {
    width: 70% !important
  }

  .list {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }

  .explorazor-helps-heading {
    text-align: center;
    margin-bottom: 40px;
    font-size: 40px !important
  }

  .grid-class {
    display: grid;
    width: 100% !important;
    margin-bottom: 40px;
    grid-template-columns: repeat(2, 2fr) !important;
  }

  .explorazor-heading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }

  .grid-list-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 10%;
  }

  .grid-list-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 20px;
  }

  .grid-list-style {
    padding-left: 15px;
    text-align: left;
    list-style: url('../../assets/svgs/app-icons/success.svg');
  }

  @media only screen and (max-width: 1366px) {
    .explorazor-helps-wrapper {
      width: 90% !important
    }

    .grid-list-container {
      padding: 0 12% 0 12%;
    }

    .grid-list-heading {
      line-height: 40px;
    }
  }

  @media only screen and (max-width: 821px) {
    .get-started-button {
      margin-left: 12% !important
    }

    .grid-para {
      padding: 0 12% !important;
    }

    .explorazor-helps-wrapper {
      width: 100% !important
    }

    .grid-list-heading {
      font-size: 24px !important;
    }

    .list {
      font-size: 18px !important;
    }

    .grid-image {
      margin-bottom: 0 !important;
    }
  }

  @media only screen and (max-width: 415px) {
    .explorazor-helps-wrapper {
      width: 100% !important
    }

    .grid-list-heading {
      font-size: 18px !important;
    }

    .list {
      font-size: 14px !important;
    }
  }

  @media only screen and (max-width: 1280px) {
    .list {

    }

    .grid-image {
      width: 80%;
      margin-bottom: 27px;
    }

    .grid-list-container {
      padding: 0 !important
    }

    .grid-class {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
    }

    .grid-list-heading {
      display: flex;
      text-align: left;
      padding: 0 12%;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
    }

    .grid-list-style {
      padding: 0 12%;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400 !important;
      font-size: 14px;
      line-height: 28px;
      margin-left: 15px
    }

    .grid-list {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  .grid-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    /* or 175% */
    color: #191948;
    text-align: left
  }

  .get-started-button {
    border-radius: 4px;
    margin-top: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    padding: 15px 30px;
    color: #FFFFFF;
  }
</style>