<template>
  <div :class="change?'image-container-align':'image-container'">
    <img
      :src="getImage"
      class="image-gif"
      alt=""
    >
  </div>
</template>
<script>
  export default {
    props: {
      image: {
        type: String,
        default: null,
      },
      change: {
        type: String,
        default: null,
      },
    },
    computed: {
      getImage: function () {
        try {
          return require(`@/assets/svgs/clients/${ this.image }.svg`);
        } catch (error) {
          console.log(error);
          return '';
        }
      }
    }
  };
</script>


