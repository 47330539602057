<template>
  <div class="use-case">
    <vs-row class="banner-wrapper">
      <div
        v-if="showBanner"
        class="banner-class"
      >
        <div
          class="banner-text"
          @click="handleTour"
        >
          Take an Interactive product tour of Explorazor
        </div>
        <div
          class="banner-cross"
          @click="closeBanner"
        >
          <img
            src="../../assets/svgs/app-icons/cross-icon.svg"
            alt="cross-icon"
          >
        </div>
      </div>
    </vs-row>
    <vs-row
      class="unified-data-access-container"
      vs-justify="center"
    >
      <vs-col
        class="unified-data-access"
        style="margin-top:50px"
      >
        <!--      <h1 class="companies-header">Hypotheses Testing on Steroids</h1>-->
        <vs-row vs-justify="center">
          <h3 class="unified-data-header ">
            EXPLORAZOR USE CASES
          </h3>
        </vs-row>
        <app-text-render
          class-name="change-app-text-paragraph"
          sub-content="Here are some examples of how Explorazor Helps CPG and Pharma Companies to solve specific
           cases that Brand and Sales teams face with ease.

"
          title="Explorazor in Action"
          type="button"
        />
      </vs-col>
    </vs-row>
    <vs-row
      class="insight-content-container"
    >
      <div
        class="insight-container"
      >
        <div
          v-for="(insight) in insights"
          :key="insight['id']"
          class="insight-card"
          @click="handleModal(insight['title'],insight['description'])"
        >
          <div
            class="insight-content"
            @click="showDescription = !showDescription"
          >
            <!--            <img-->
            <!--              v-if="!openModal"-->
            <!--              :src="insight['image']"-->
            <!--              alt="image"-->
            <!--              class="insight-image"-->
            <!--            >-->
            <iframe
              class="insight-image"
              :src="insight['video']"
            />
            <div class="insight-title">
              {{ insight['title'] }}
            </div>
            <div
              class="insight-description"
              :title="insight['description']"
            >
              {{
                insight['description']
              }}
            </div>
          </div>
        </div>
      </div>
    </vs-row>
    <vs-row class="cases-container">
      <div
        v-for="eachCase in cases"
        :key="eachCase['id']"
        class="cases-card"
      >
        <div
          class="insight-content"
          @click="routeTo({name:eachCase['link']})"
        >
          <div>
            <img
              :src="eachCase['image']"
              alt="image"
              class=""
              style="width:95% !important;object-fit: cover;contain: content;margin-top:2.5%"
            >
          </div>
          <div class="tag-container">
            <div
              class="tag-class"
            >
              {{ eachCase['tagText'] }}
            </div>
          </div>
          <div
            class="insight-title"
            style="color:black;padding-top:25px"
          >
            {{ eachCase['title'] }}
          </div>
          <div
            class="insight-description"
            style="color:black"
          >
            {{ eachCase['description'] }}
          </div>
        </div>
        <div
          :style="eachCase['tagText'].toLowerCase().includes('spend') && availableWidth > 1366 ?
            {'position':'relative','bottom':'-25px'}
            :{}"
        >
          <div
            class="read-more"
            @click="routeTo({name:eachCase['link']})"
          >
            Read more
          </div>
        </div>
      </div>
    </vs-row>
    <vs-row
      class="case-study-wrapper"
      style="margin-top: 0 !important;"
    >
      <div
        class="case-study-container"
        :class="showForm?'border-radius-class':''"
        :style="!showForm?{'border-radius':'12px'}:{}"
      >
        <div class="case-study-title">
          Case Study
        </div>
        <div class="case-study-main-content">
          <div class="case-study-content-container">
            <!--            <div class="want-to-know">-->
            <!--              Want to know...-->
            <!--            </div>-->
            <div class="want-to-know-content">
              How Explorazor Helped <span class="mid-para">
                Danone India Ltd.
              </span> Revolutionize Data Analysis
            </div>
            <div v-if="!showForm">
              <vs-button
                class="case-study-button"
                @click.native="downloadCaseStudy"
              >
                <a
                  href="#download-whitepaper"
                  style="color:white !important;justify-content: center"
                >
                  Download Case Study
                </a>
              </vs-button>
            </div>
          </div>
          <div
            id="download-whitepaper"
            class="case-study-image-container"
          >
            <img
              src="../../assets/svgs/card-image/CaseStudy.svg"
              alt="case-study"
              class="case-study-image"
            >
          </div>
        </div>
      </div>
    </vs-row>
    <vs-row
      v-if="showForm"
      style="display:flex;justify-content: center;align-content: center"
    >
      <div
        id="whitepaper-download"
        style="width:80%"
      >
        <div
          class="whitepaper-form-container"
          :class="showForm?'form-radius':''"
        >
          <div class="form-title">
            Download Case Study
          </div>
          <div class="form-container">
            <form
              action=""
              class="form-class"
              @submit="handleFormSubmit"
            >
              <vs-row
                vs-w="12"
                vs-justify="center"
              >
                <vs-col
                  :vs-w="screenWidth>768?3.5:12"
                  :class="screenWidth<768?'column-class':''"
                >
                  <div class="form-label">
                    <label
                      for="name"
                    >Name *</label>
                  </div>
                  <div>
                    <input
                      id="name"
                      v-model="name"
                      type="text"
                      name="name"
                      class="form-input-class"
                      placeholder="Name"
                      required
                    >
                  </div>
                </vs-col>
                <vs-col
                  :vs-w="screenWidth>768?3.5:12"
                  :class="screenWidth<768?'column-class':''"
                >
                  <div class="form-label">
                    <label
                      for="email"
                    >Business Email ID *</label>
                  </div>
                  <div>
                    <input
                      id="email"
                      v-model="email"
                      type="text"
                      name="email"
                      placeholder="Business Email ID"
                      class="form-input-class"
                      required
                    >
                  </div>
                </vs-col>
                <vs-col
                  :vs-w="screenWidth>769?1.5:12"
                >
                  <input
                    type="submit"
                    value="Submit"
                    class="form-button"
                  >
                </vs-col>
              </vs-row>
            </form>
          </div>
        </div>
      </div>
    </vs-row>
    <vs-row
      id="uda-12"
      class="unified-data-access-container"
      vs-justify="center"
      style="margin-top:60px !important;"
    >
      <vs-col class="unified-data-access">
        <!--      <h1 class="companies-header">Hypotheses Testing on Steroids</h1>-->
        <vs-row vs-justify="center">
          <h3> Understand Explorazor better with a demo</h3>
        </vs-row>

        <div class="wrapper-button">
          <vs-button
            class="request-demo-button btn-primary"
            @click="routeTo({name:'Demo'})"
          >
            Request a demo
          </vs-button>
        </div>
      </vs-col>
    </vs-row>
    <!--    <app-modal-->
    <!--      v-if="openModal"-->
    <!--      :custom-style="{'width':'70vw','height':'60vh'}"-->
    <!--    >-->
    <!--      <vs-row>-->
    <!--        <vs-col vs-w="9">-->
    <!--          {{ selectedTitle }}-->
    <!--        </vs-col>-->
    <!--        <vs-col vs-w="3">-->
    <!--          x-->
    <!--        </vs-col>-->
    <!--      </vs-row>-->
    <!--      <div>-->
    <!--        <iframe-->
    <!--          width="420"-->
    <!--          height="315"-->
    <!--          src="https://www.youtube.com/embed/tgbNymZ7vqY"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </app-modal>-->
  </div>
</template>

<script>
  import '@/assets/scss/components/change.scss';
  import { imageMixin } from '@/mixins/image';
  import AppTextRender from '@/components/wrapper/TextRender';
  import { pageview } from 'vue-gtag';

  export default {
    name: 'RequestDemo',
    components: {
      AppTextRender,
      // imageRenderer,
      // AppIcon,
      // AppModal
    },
    mixins: [imageMixin],
    data () {
      return {
        showBanner: true,
        showDescription: false,
        selectedTitle: '',
        openModal: false,
        screenWidth: '',
        name: '',
        email: '',
        showForm: false,
        insights: [
          {
            id: 0,
            title: 'Find out how is your Brand performing vs the Category',
            description: 'A quick overview of how to track a brand\'s performance and diagnose any declines.',
            image: require('@/assets/svgs/card-image/brand-category.svg'),
            video: 'https://www.youtube.com/embed/QLMH5OvTFMs'
          },
          {
            id: 1,
            title: 'Finding Opportunity Markets through Competitor Analysis',
            description: 'How brand managers can understand their brand\'s performance to gain market share' +
              ' in opportunity regions.',
            image: require('@/assets/svgs/card-image/opportunity-market.svg'),
            video: 'https://www.youtube.com/embed/w4PcC_f-W-k'
          },
          {
            id: 2,
            title: 'Analyzing Distribution Levels to Improve Market Share',
            description: 'By analyzing the data here, we aim to improve the brand\'s numeric and weighted ' +
              'distribution and eventually rank higher in the entire category.',
            image: require('@/assets/svgs/card-image/analyzing-distribution.svg'),
            video: 'https://www.youtube.com/embed/nh3Y0jkgvzc'
          },
          {
            id: 3,
            title: 'Quick analysis to identify if promotional efforts are paying off',
            description: 'Easy-to-follow tutorial on how businesses can use data to make informed decisions about' +
              ' their promotional strategies along with supply generation.',
            image: require('@/assets/svgs/card-image/analyzing-promotion.svg'),
            video: 'https://www.youtube.com/embed/xDtMJP4gPtE'
          },
          {
            id: 4,
            title: 'Investigating the decline in secondary sales and its impact on market share',
            description: 'This video provides valuable insights on how to analyze market share and sales' +
              ' data to gain actionable business intelligence.',
            image: require('@/assets/svgs/card-image/investigating.svg'),
            video: 'https://www.youtube.com/embed/7HeFOmqfa58'
          }
        ],
        cases: [
          {
            id: 0,
            title: 'How Explorazor Helps Brand Managers with their Spends Correlation Analysis',
            description: 'Is there a way to save time and effort by getting all the datasets in one place,' +
              ' fully standardized and ready for analysis?',
            tagText: '#1 Spend Correlation',
            image: require('@/assets/svgs/card-image/correlation-new.svg'),
            link: 'Detail-List'
          },
          {
            id: 1,
            title: 'Track How Your Brand’s Perception Among Consumers Is Affecting Your Business',
            description: 'Is there also a way to test all the different marketing campaigns that are running in' +
              ' parallel, without investing an absurd amount of time? We believe there is. ',
            tagText: '#2 Consumer Behavior',
            image: require('@/assets/svgs/card-image/consumer-new.svg'),
            link: 'Consumer-Behavior'
          },
        ],
        vm: {},
        availableWidth: ''
      };
    },

    computed: {},
    beforeMount () {
      document.title = 'Explorazor | Use Cases';
      document.querySelector('meta[name="description"]').setAttribute('content', 'Find out how ' +
        'Fortune 500 Companies and Enterprises leverage Search and AI driven insights to excel their business.');
    },
    mounted () {
      pageview({ page_title: 'UseCase', page_path: '/usecases' });
      this.availableWidth = screen.availWidth;
      this.screenWidth = screen.availWidth;
      // this.handleMounting();
    },
    methods: {
      handleTour: function () {
        window.open('https://app.explorazor.com/#/playground', '_blank');
      },
      closeBanner: function () {
        this.showBanner = false;
      },
      handleDescription: function (description, index) {
        this.showDescription = ! this.showDescription;
      },
      downloadCaseStudy: function () {
        this.showForm = true;
      },
      handleFormSubmit: function (e) {
        e.preventDefault();
        let link = location.href;
        let utmSource = link.includes('utm_source=')
          ? link.substring(link.indexOf('utm_source=') + 11, link.lastIndexOf('&utm_medium'))
          : '';
        let utmMedium = link.includes('utm_medium=')
          ? link.substring(link.indexOf('utm_medium=') + 11, link.lastIndexOf('&utm_campaign')) : '';
        let utmCampaign = link.includes('utm_campaign=')
          ? link.substring(link.indexOf('utm_campaign=') + 13)
          : '';
        const params = {
          api_key: process.env.VUE_APP_SENDY_API_KEY,
          name: this.name,
          email: this.email,
          boolean: true,
          list: process.env.VUE_APP_SENDY_LIST_KEY,
          Lead_Source_1: 'Website',
          Lead_Source_2: 'Asset',
          Lead_Source_3: 'case_study',
          Lead_Source_4: 'f_and_b_industry',
          utm_source: utmSource.length ? utmSource : '',
          utm_medium: utmMedium.length ? utmMedium : '',
          utm_campaign_name: utmCampaign.length ? utmCampaign : '',
        };
        let formBody = [];
        for (let property in params) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(params[property]);
          formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');
        const xhr = new XMLHttpRequest();
        xhr.open('POST',
          'https://email.pcube.net/subscribe', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Headers',
          'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
          'Access-Control-Allow-Methods', 'OPTIONS,POST',
          'Access-Control-Allow-Credentials', true,
          'Access-Control-Allow-Origin', '*',
          'X-Requested-With', '*',);
        xhr.send(formBody);
        // sendy subscription call code end
        // hubspot form api call code start
        const xhrHubspot = new XMLHttpRequest();
        // eslint-disable-next-line max-len
        const url = `https://api.hsforms.com/submissions/v3/integration/submit/${ process.env.VUE_APP_HUBSPOT_PORTAL_ID }/${ process.env.VUE_APP_HUBSPOT_FORM_ID }`;
        //  request JSON:
        let data = {
          'fields': [
            {
              'name': 'email',
              'value': this.email
            },
            {
              'name': 'firstname',
              'value': this.name
            },
            {
              'name': 'ga_source',
              'value': 'Website'
            },
            {
              'name': 'ga_medium',
              'value': 'Asset'
            },
            {
              'name': 'ga_campaign',
              'value': 'Case_study_f_and_b_industry'
            }
          ],

        };

        let final_data = JSON.stringify(data);

        xhrHubspot.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhrHubspot.setRequestHeader('Content-Type', 'application/json');

        // Sends the request

        xhrHubspot.send(final_data);
        // hubspot form api call code end
        window.open('https://www.vphrase.com/explorazor-assets/case_study/Explorazor%20Case%20Study%20(Danone).pdf',
          '_blank');
        document.getElementById('whitepaper-download').style.padding = '50px';
        document.getElementById('whitepaper-download').style.color = 'white';
        document.getElementById('whitepaper-download').style.background = '#191948';
        document.getElementById('whitepaper-download').style.fontSize = '22px';
        document.getElementById('whitepaper-download').style.fontWeight = '600';
        document.getElementById('whitepaper-download').style.fontFamily = 'Poppins';
        document.getElementById('whitepaper-download').innerHTML = 'Thank You for downloading the Case Study';
      },
      // handleMounting: function () {
      //   window.addEventListener('click', function (e) {
      //     if (document.getElementById('modal-content').contains(e.target)) {
      //       // Clicked in box
      //     } else {
      //       console.log('open modal');
      //       window.openModal = false;
      //       this.openModal = true;
      //       console.log(window.openModal);
      //     }
      //   });
      // },
      handleOutsideClick: function () {
        console.log('emitt works');
      },
      handleModal: function (title, description) {
        this.openModal = true;
        this.selectedTitle = title;
        this.selectedDescription = description;
        // let vm = this;
        // console.log(vm);
      },
      getArr: function () {
        return this.arrayExplorazor = this.getImg();
      }
    }
  };
</script>

<style scoped>
  button:hover {
    background: rgba(var(--vs-success), 5) !important;
  }


</style>
<style lang="scss">
  .correlation-analysis .text-container p {
    color: #93969F;
  }

  .glimpse-of-search-container {
    gap: 5% !important;
  }

  .insight-card {
    height: auto;
    //max-width: 35% !important;
    //min-width: 35% !important;
    background: #1A0F91;
    border-radius: 16px;
    margin: 20px;
    padding-bottom: 20px
  }

  .insight-card:hover {
    background: #0F066D;
  }

  .insight-image {
    height: 400px;
    width: 95%;
    margin-top: 2.5%;
    margin-bottom: 15px;
    border-radius: 12px;
    border: none
  }

  .insight-title {
    width: 90%;
    color: #FFFFFF;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    margin-bottom: 12px
  }

  .insight-description {
    width: 90%;
    color: #FFFFFF;
    text-align: left;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    overflow-y: hidden;
  }

  .insight-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column
  }

  .tag-class {
    text-align: left !important;
    color: #0243EC;
    border-radius: 20px;
    padding: 8px 16px;
    width: 235px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    margin-left: 0;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    background: #EEF3FF;
  }

  .tag-container {
    width: 95%;
    background: #FFFFFF !important;
    padding-top: 20px
  }

  .cases-container {
    padding: 80px 10%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .insight-container {
    display: grid;
    padding: 80px 10% 80px 10%;
    grid-template-columns: repeat(2, 1fr);
  }

  .insight-content-container {
    display: flex !important;
    justify-content: center;
    align-items: center;
    background: #191948;
    overflow: hidden
  }

  .cases-card {
    width: 500px;
    margin-bottom: 30px;
    padding-bottom: 20px;
    background: #FFFFFF;
    border: 1px solid #ABC9FD;
    border-radius: 12px;
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    cursor: pointer
  }

  .read-more-container {

  }

  .read-more {
    text-align: right !important;
    margin-top: 15px;
    margin-right: 50px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    float: right;
    color: #0243EC
  }

  .read-more:hover {
    text-decoration: underline;
    cursor: pointer
  }

  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 5s ease;
  }

  .modal-content {
    background-color: white;
    border-radius: 8px;
  }

  .pop-up-container {
    position: absolute;
    width: 70vw;
    height: 70vh;
    color: white
  }

  @media only screen and (max-width: 1350px) {
    .cases-card {
      margin-right: 0;
      width: auto !important
    }
    .insight-image {
      height: 300px !important;
    }

    .insight-container {
      grid-template-columns: 1fr;
      padding: 40px 5% 40px 5%
    }
  }

  @media only screen and (max-width: 768px) {

  }
</style>