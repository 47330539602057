import { globalMixin } from '@/mixins/globalMixin';

export const marketingMixin = {
  props: {},
  mixins: [globalMixin],
  methods: {
    addLeadOnZoho:function (data){
        this.axiosPromise({
          headers : {
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Headers':'Content-Type, Authorization, X-Requested-With',
            'Access-Control-Allow-Methods':'DELETE, POST, GET, OPTIONS',
            'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8',
            'Access-Control-Allow-Credentials':true
          },
          url: process.env.VUE_APP_ZOHO_FORM_URL,
          method: 'POST',
          data:data
        }).then(response =>{
         console.log('Response', response);
      });
    },
    addDemoLeadZoho: function (data, formKeys) {
      let payload = {
        'FIRSTNAME': data['firstName'],
        'CONTACT_EMAIL': data['email'],
        'CONTACT_CF5': data['message'],
        'mode': 'OptinCreateView',
        'submitType': 'optinCustomView',
        'zc_trackCode': 'ZCFORMVIEW',
        'scriptless': 'yes',
        'zx': formKeys['zx'],
        'zc_formIx': formKeys['zc_formIx']
      };
      this.addLeadOnZoho(payload);
    }
  }
};