<template>
  <div class="container">
    <div :class="change?'align-text-container':'text-container'">
      <h1>{{ title }}</h1>
      <h3>{{ content }}</h3>
      <div class="app-text-margin">
        <p :class="className">
          <b>{{ boldContent }}</b>{{ subContent }}
        </p>
        <ul>
          <li>{{ listContent }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      listContent: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: null,
      },
      content: {
        type: String,
        default: null,
      },
      subContent: {
        type: String,
        default: null,
      },
      change: {
        type: String,
        default: null,
      },
      className: {
        type: String,
        default: null,
      },
      boldContent: {
        type: String,
        default: null,
      },
    },
  };
</script>

