<template>
  <div ref="explorazor-helps">
    <vs-row
      style="display:flex;justify-content:center;align-content:center;
      background:#DFE8F7;margin-top:80px;padding-bottom:53px !important;"
    >
      <vs-col class="explorazor-helps-wrapper">
        <vs-row class="explorazor-heading-container">
          <h2 class="explorazor-helps-heading">
            Explorazor helps you grow
          </h2>
        </vs-row>
        <div class="grid-class">
          <vs-col v-if="!showImage">
            <img
              src="../../assets/svgs/clients/know-market-performance.svg"
              alt=""
              class="grid-image"
            >
          </vs-col>
          <vs-col class="grid-list-container">
            <h2 class="grid-list-heading">
              Know your Market Performance
            </h2>
            <ul class="grid-list-style">
              <li class="list">
                How is the category moving and how is your brand performing vs the category?
              </li>
              <li class="list">
                Which are your top brands contributing to market share and which states drive their performance?
              </li>
              <li class="list">
                How is your Brand market share trailing vs the competitor brands, quarter on quarter?
              </li>
            </ul>
          </vs-col>
          <vs-col v-show="showImage">
            <img
              src="../../assets/svgs/clients/know-market-performance.svg"
              alt=""
              class="grid-image"
            >
          </vs-col>
        </div>
        <!--    THIS IS REVERTED COMPONENT-->
        <div
          id="dwt-12"
          class="grid-class"
        >
          <vs-col class="">
            <img
              src="../../assets/svgs/clients/improve-distribution.svg"
              alt=""
              class="grid-image"
            >
          </vs-col>
          <vs-col class="grid-list-container">
            <h2 class="grid-list-heading">
              Improve Distribution
            </h2>
            <ul class="grid-list-style">
              <li class="list">
                How many dealers have you added in the current quarter for your brand? How much store-level share (SAH)
                does your brand have with an increased number of dealers?
              </li>
              <li class="list">
                How well is your brand placed in potential stores compared against the competitors?
              </li>
              <li class="list">
                Which are your opportunity regions to grow your market presence?
              </li>
            </ul>
          </vs-col>
        </div>

        <div
          class="grid-class"
        >
          <vs-col v-if="!showImage">
            <img
              src="../../assets/svgs/clients/measure-your-perception.svg"
              alt=""
              class="grid-image"
            >
          </vs-col>
          <vs-col class="grid-list-container">
            <h2 class="grid-list-heading">
              Measure your Brand’s Perception
            </h2>
            <ul class="grid-list-style">
              <li class="list">
                How is brand penetration moving with advertising spends?
              </li>
              <li class="list">
                Are the consumers aware of your brand and is it always on top of their minds?
              </li>
              <li class="list">
                Is your brand continuously gaining new users? Or has there been an unusual lapse in users?
              </li>
            </ul>
          </vs-col>
          <vs-col v-if="showImage">
            <img
              src="../../assets/svgs/clients/measure-your-perception.svg"
              alt=""
              class="grid-image"
            >
          </vs-col>
        </div>
        <div class="grid-class">
          <vs-col>
            <img
              src="../../assets/svgs/clients/plan-for-promotions.svg"
              alt=""
              class="grid-image"
            >
          </vs-col>
          <vs-col class="grid-list-container">
            <h2 class="grid-list-heading">
              Plan for promotions
            </h2>
            <div class="grid-list">
              <ul class="grid-list-style">
                <li class="list">
                  Which brands should be offered on discounts?
                </li>
                <li class="list">
                  Are there any brands that sold better when placed together?
                </li>
                <li class="list">
                  How much volume market share have you gained with promotional activities done in the past?
                </li>
              </ul>
            </div>
          </vs-col>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
  export default {
    name: 'ExplorazorHelps',
    data: function () {
      return {
        showImage: false
      };
    },
    mounted () {
      this.getClientWidthOfContainer();
    },
    methods: {
      getClientWidthOfContainer: function () {
        let clientWidth = screen.availWidth;
        console.log(clientWidth);
        if (clientWidth >= 1280) {
          this.showImage = true;
        } else if (clientWidth < 1280) {
          this.showImage = false;
        }
      }
    }
  };
</script>

<style scoped>
  .explorazor-helps-wrapper {
    width: 70% !important
  }

  .list {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }

  .explorazor-helps-heading {
    text-align: center;
    margin-bottom: 40px;
    font-size: 40px !important
  }

  .grid-class {
    display: grid;
    width: 100% !important;
    margin-bottom: 40px;
    grid-template-columns: repeat(2, 2fr) !important;
  }

  .explorazor-heading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }

  .grid-list-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 10%;
  }

  .grid-list-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  .grid-list-style {
    padding-left: 15px;
    text-align: left;
    list-style: url('../../assets/svgs/app-icons/success.svg');
  }

  @media only screen and (max-width: 1366px) {
    .explorazor-helps-wrapper {
      width: 90% !important
    }

    .grid-list-container {
      padding: 0 12% 0 12%;
    }

    .grid-list-heading {
      line-height: 40px;
    }
  }

  @media only screen and (max-width: 821px) {
    .explorazor-helps-wrapper {
      width: 100% !important
    }

    .grid-list-heading {
      font-size: 24px !important;
    }

    .list {
      font-size: 18px !important;
    }

    .grid-image {
      margin-bottom: 0 !important;
    }
  }

  @media only screen and (max-width: 415px) {
    .explorazor-helps-wrapper {
      width: 100% !important
    }

    .grid-list-heading {
      font-size: 18px !important;
    }

    .list {
      font-size: 14px !important;
    }
  }

  @media only screen and (max-width: 1280px) {
    .list {

    }

    .grid-image {
      width: 80%;
      margin-bottom: 27px;
    }

    .grid-list-container {
      padding: 0 !important
    }

    .grid-class {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
    }

    .grid-list-heading {
      display: flex;
      text-align: left;
      padding: 0 12%;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
    }

    .grid-list-style {
      padding: 0 12%;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400 !important;
      font-size: 14px;
      line-height: 28px;
      margin-left: 15px
    }

    .grid-list {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }


</style>