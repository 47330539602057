<template>
  <vs-row style="width:100% !important;">
    <div
      class="new-main-content-container"
    >
      <div class="new-main-content">
        <div
          class="new-main-sub-content"
        >
          <h2 class="new-main-header">
            The affordable alternative to ThoughtSpot
          </h2>
          <div class="new-main-para">
            Experience the power of search driven analytics without overspending or compromising on quality.
          </div>
          <div
            class="new-main-button-container"
          >
            <span><vs-button
              class="request-button"
              style="font-weight:600"
              @click.native="handleRegister"
            >
              Get Started For Free
            </vs-button></span>
            <span><vs-button
              class="request-tour-button"
              style="font-weight:600"
              color="white"
              @click.native="handleTour"
            >
              Take Product Tour
            </vs-button></span>
          </div>
        </div>
      </div>
      <div class="new-main-image">
        <video
          class="new-main-image-class"
          autoplay
          loop
        >
          <source
            src="../assets/svgs/card-image/Explorazor-main.mp4"
            type="video/mp4"
          >
        </video>
        <!--        <div class="watch-demo-overlay">-->
        <!--          <a-->
        <!--            href="https://www.youtube.com/watch?v=F7QeNf9udgU"-->
        <!--            target="_blank"-->
        <!--          >-->
        <!--            &lt;!&ndash;            <div class="watch-demo-btn">&ndash;&gt;-->
        <!--            &lt;!&ndash;&lt;!&ndash;              <div class="watch-demo-btn-text">&ndash;&gt;&ndash;&gt;-->
        <!--            &lt;!&ndash;&lt;!&ndash;                Watch a Quick Demo&ndash;&gt;&ndash;&gt;-->
        <!--            &lt;!&ndash;&lt;!&ndash;              </div>&ndash;&gt;&ndash;&gt;-->
        <!--            &lt;!&ndash;              <app-icon&ndash;&gt;-->
        <!--            &lt;!&ndash;                icon="app-icons/forword"&ndash;&gt;-->
        <!--            &lt;!&ndash;                width="6"&ndash;&gt;-->
        <!--            &lt;!&ndash;                color="#ffffff"&ndash;&gt;-->
        <!--            &lt;!&ndash;                class="icon"&ndash;&gt;-->
        <!--            &lt;!&ndash;              />&ndash;&gt;-->
        <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
        <!--          </a>-->
        <!--        </div>-->
      </div>
    </div>
    <vs-row class="clients-container">
      <div>
        <h4 class="clients-header-class">
          Trusted by leading CPG & Pharma companies
        </h4>
      </div>
      <div class="clients-logo-container">
        <div
          v-for="img in getClientLogos()"
          :key="img.id"
          class="client-logo"
        >
          <image-renderer
            :group="img"
            type="group"
          />
        </div>
      </div>
    </vs-row>
    <vs-row style="display:flex;justify-content: center;align-content: center">
      <table
        :key="rowHeight"
        class="table-class"
      >
        <thead>
          <tr>
            <th>{{ mainTable[0]['columnOne'] }}</th>
            <th style="background: #E6EEFF;border-top:5px solid #0243EC;">
              <img
                src="../assets/svgs/logo/logo.png"
                alt="explorazor"
                class="logo-class"
                style="margin-top:20px !important;margin-bottom:10px"
              >
              <div>
                <div
                  class="explorazor-price-class"
                >
                  $800
                </div>
                <div
                  style="padding-left:10px;
                         font-family: 'Poppins';
                         font-style: normal;
                         font-weight: 500;
                         font-size: 14px;
                         line-height: 21px;
                         /* identical to box height */
                         color: #7D97F4;"
                >
                  monthly
                </div>
              </div>
              <div class="limit-query">
                Unlimited Queries
              </div>
            </th>
            <th style="margin-top:20px">
              <img
                src="../assets/svgs/logo/thoughtspot-logo.svg"
                alt="thoughtspot"
                class="logo-class"
                style="margin-top:25px !important;margin-bottom:10px"
              >
              <div>
                <div
                  class="thoughtspot-price-class"
                >
                  $2500
                </div>
                <div
                  style="padding-left:10px;
                         font-family: 'Poppins';
                         font-style: normal;
                         font-weight: 500;
                         font-size: 14px;
                         line-height: 21px;
                         /* identical to box height */
                         color: #97A3BD;"
                >
                  monthly
                </div>
              </div>
              <div class="thoughtspot-limit-query">
                limited Queries
              </div>
            </th>
          </tr>
        </thead>
        <tr
          id="table-row"
          style="justify-content: center;align-content: center;"
        >
          <td id="table-row">
            <div
              style="cursor:pointer;display:flex"
              @click="showAIlist = !showAIlist"
            >
              <div>
                {{ mainTable[1]['columnOne'] }}
              </div>
              <div
                v-if="screenWidth>820"
                style="display: flex;justify-content: end;align-content: end;padding-left:20px"
              >
                <app-icon
                  icon="app-icons/list-arrow"
                  width="16"
                  :style="showAIlist?{rotate:'180deg !important','margin-top':'25px !important'}:{}"
                />
              </div>
            </div>

            <ul
              v-if="showAIlist && screenWidth > 820"
              class="list-class"
            >
              <li>Search your data to analyze and visualize</li>
              <li>Search on data values, not just column names</li>
              <li>Keyword suggestion to simplify query building</li>
            </ul>
          </td>
          <td
            id="table-row"
            style="background: #E6EEFF;"
          >
            <app-icon
              icon="app-icons/pricing-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
          <td id="table-row">
            <app-icon
              icon="app-icons/grey-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
        </tr>
        <tr id="table-row">
          <td>
            <div
              style="cursor:pointer;display: flex"
              @click="showDashboardList = !showDashboardList"
            >
              <span>{{ mainTable[2]['columnOne'] }}</span>
              <span
                v-if="screenWidth>820"
                style="padding-left:20px"
              ><app-icon
                icon="app-icons/list-arrow"
                width="16"
                :style="showDashboardList?{rotate:'180deg !important','margin-top':'25px !important'}:{}"
              /></span>
            </div>

            <ul
              v-if="showDashboardList && screenWidth > 820"
              class="list-class"
            >
              <li>Pin charts and tables to your Dashboards</li>
              <li>Dashboard auto refresh with updated data</li>
              <li>Design your dashboard as you need</li>
            </ul>
          </td>
          <td
            style="background: #E6EEFF"
          >
            <app-icon
              icon="app-icons/pricing-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
          <td>
            <app-icon
              icon="app-icons/grey-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
        </tr>
        <tr id="table-row">
          <td>{{ mainTable[3]['columnOne'] }}</td>
          <td style="background: #E6EEFF">
            <app-icon
              icon="app-icons/pricing-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
          <td>
            <app-icon
              icon="app-icons/grey-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
        </tr>
        <tr id="table-row">
          <td>{{ mainTable[4]['columnOne'] }}</td>
          <td style="background: #E6EEFF">
            <app-icon
              icon="app-icons/pricing-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
          <td>
            <app-icon
              icon="app-icons/grey-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
        </tr>
        <tr id="table-row">
          <td>{{ mainTable[5]['columnOne'] }}</td>
          <td style="background: #E6EEFF">
            <app-icon
              icon="app-icons/pricing-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
          <td>
            <app-icon
              icon="app-icons/grey-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
        </tr>
        <tr id="table-row">
          <td>{{ mainTable[6]['columnOne'] }}</td>
          <td style="background: #E6EEFF">
            <app-icon
              icon="app-icons/pricing-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
          <td>
            <app-icon
              icon="app-icons/grey-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
        </tr>
        <tr id="table-row">
          <td>{{ mainTable[7]['columnOne'] }}</td>
          <td style="background: #E6EEFF">
            <app-icon
              icon="app-icons/pricing-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
          <td>
            <app-icon
              icon="app-icons/grey-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
        </tr>
        <tr id="table-row">
          <td>{{ mainTable[8]['columnOne'] }}</td>
          <td style="background: #E6EEFF">
            <app-icon
              icon="app-icons/pricing-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
          <td>
            <app-icon
              icon="app-icons/pricing-cross"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
        </tr>
        <tr id="table-row">
          <td>
            <div
              style="cursor:pointer;display: flex"
              @click="showDataConnectors = !showDataConnectors"
            >
              <span>{{ mainTable[9]['columnOne'] }}</span>
              <span
                v-if="screenWidth>820"
                style="padding-left:20px"
              ><app-icon
                icon="app-icons/list-arrow"
                width="16"
                :style="showDataConnectors?{rotate:'180deg !important','margin-top':'25px !important'}:{}"
              />

              </span>
            </div>
            <ul
              v-if="showDataConnectors && screenWidth > 820"
              class="list-class"
            >
              <li>CSV</li>
              <li>Excel</li>
              <li>Json</li>
              <li>MySQL DB</li>
              <li>PostgresSQL</li>
              <li>SAP Hana DB</li>
              <li>MS SQL DB</li>
              <li>Snowflake DW</li>
              <li>Big Query DW</li>
              <li>RedShift DW</li>
              <li>Google Analytics</li>
              <li>Google Sheets</li>
              <li>Fetch Data from FTP</li>
              <li>Fetch Data from URL</li>
              <li>Fetch Data from Zendesk</li>
              <li>Fetch Data from Hubspot</li>
            </ul>
          </td>
          <td style="background: #E6EEFF">
            <app-icon
              icon="app-icons/pricing-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
          <td>
            <app-icon
              icon="app-icons/grey-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
        </tr>
        <tr id="table-row">
          <td>{{ mainTable[10]['columnOne'] }}</td>
          <td style="background: #E6EEFF">
            <app-icon
              icon="app-icons/pricing-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
          <td>
            <app-icon
              icon="app-icons/grey-tick"
              :width="screenWidth > 415 ? '19':'15'"
            />
          </td>
        </tr>
      </table>
    </vs-row>
    <vs-row class="testimony-container">
      <vs-col
        vs-w="12"
        class="testimony-quote"
      >
        <app-icon
          icon="app-icons/quote"
          width="60"
        />
      </vs-col>
      <vs-col
        vs-w="12"
        class="testimony-comment"
      >
        Thanks Neerav and the entire vPhrase team + Avin Kumpatla. Appreciate the efforts and importantly heart & soul
        that you guys have put in developing this tool. It is definitely helping us bring business impact at Danone.
      </vs-col>
      <vs-col
        vs-w="12"
        class="testimony-client"
      >
        <div v-if="screenWidth < 415">
          <div style="display:flex;margin-bottom:20px">
            <span class="">
              <img
                src="../assets/svgs/clients/testimony-client.svg"
                alt="client"
              >
            </span>
            <span
              style="padding-top:5px;padding-left:15px"
            >
              <div class="client-name">Rahil Shaikh</div>
              <div class="client-designation">Head of Strategy & Insights</div>
            </span>
          </div>
          <div class="testimony-seperation" />
          <div>
            <span class="testimony-image-container"><img
              src="../assets/svgs/clients/danone-testimony.svg"
              alt="danone"
            ></span>
          </div>
        </div>
        <span
          v-if="screenWidth > 415"
        >
          <img
            src="../assets/svgs/clients/testimony-client.svg"
            alt="client"
          >
        </span>
        <span
          v-if="screenWidth > 415"
          style="padding-top:5px;padding-left:15px"
        >
          <div class="client-name">Rahil Shaikh</div>
          <div class="client-designation">Head of Strategy & Insights</div>
        </span>
        <span
          v-if="screenWidth > 415"
          class="testimony-seperation"
        />
        <span
          v-if="screenWidth > 415"
          class="testimony-image-container"
        ><img
          src="../assets/svgs/clients/danone-testimony.svg"
          alt="danone"
        ></span>
      </vs-col>
    </vs-row>
    <vs-row>
      <with-explorazor style="width: 100% !important;" />
    </vs-row>
    <vs-col
      vs-w="12"
      style="background: #191948;"
    >
      <vs-row class="pricing-plans-header">
        Explorazor Pricing Plans
      </vs-row>
      <vs-row
        class="sheets-container"

        style="margin-top:60px"
      >
        <div class="pricing-sheet text-center">
          <div>
            <div class="sheet-header">
              Free
            </div>
            <div class="sheet-subheader">
              Free
            </div>
            <div class="sheet-button-container">
              <vs-button
                class="pricing-sheet-button"
                @click.native="handleRegister"
              >
                Get Started For Free
              </vs-button>
            </div>
          </div>
          <div style="border-top:1px solid white;padding:30px 0 5px 0">
            <div
              v-for="freeList in freePricingList"
              :key="freeList['tick']"
              style="text-align: left;padding-bottom: 25px;display:flex"
            >
              <span style="padding-left:25px;"><img
                :src="freeList['tick'] === 'check'?require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="freeList['tick']"
                width="15"
                class="tick-class"
              ></span>
              <span>
                <div>
                  <span class="list-main-text">{{ freeList['mainText'] }}</span>
                  <span
                    class="list-sub-text"
                  >{{ freeList['subText'] }}</span>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class="professional-pricing-sheet text-center">
          <div>
            <div class="sheet-header">
              Professional
            </div>
            <div class="professional-sheet-subheader">
              $10
            </div>
            <div class="professional-subheader">
              Per User Per Month
            </div>

            <div class="sheet-button-container">
              <a
                href="#sales"
                class="text-white"
              >
                <vs-button
                  style="background: white;color:black"
                  class="pricing-sheet-button"
                  color="white"
                  @click.native="routeTo({name:'Contact'})"
                >
                  Contact Sales
                </vs-button>
              </a>
            </div>
          </div>
          <div style="border-top:1px solid white;padding:30px 0 5px 0">
            <div
              v-for="freeList in professionalPricingList"
              :key="freeList['tick']"
              style="text-align: left;padding-bottom: 25px;display:flex"
            >
              <span style="padding-left:25px;"><img
                :src="freeList['tick'] === 'check'?require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="freeList['tick']"
                width="15"
                class="tick-class"
              ></span>
              <span>
                <div>
                  <span class="list-main-text">{{ freeList['mainText'] }}</span>
                  <span
                    class="list-sub-text"
                  >{{ freeList['subText'] }}</span>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div
          class="pricing-sheet text-center"
          :style="screenWidth > 1366 && screenWidth < 1640 ? {'margin-top':'30px !important'} : {}"
        >
          <div>
            <div class="sheet-header">
              Enterprise
            </div>
            <div class="sheet-subheader">
              Custom
            </div>
            <div class="sheet-button-container">
              <a
                href="#sales"
                class="text-white"
              >
                <vs-button
                  style="background: white;color:black"
                  class="pricing-sheet-button"
                  color="white"
                  @click.native="routeTo({name:'Contact'})"
                >
                  Contact Sales
                </vs-button>
              </a>
            </div>
          </div>
          <div style="border-top:1px solid white;padding:30px 0 5px 0">
            <div
              v-for="freeList in CustomPricingList"
              :key="freeList['tick']"
              style="text-align: left;padding-bottom: 25px;display:flex"
            >
              <span style="padding-left:25px;"><img
                :src="freeList['tick'] === 'check'?require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="freeList['tick']"
                width="15"
                class="tick-class"
              ></span>
              <span>
                <div>
                  <span class="list-main-text">{{ freeList['mainText'] }}</span>
                  <span
                    class="list-sub-text"
                  >{{ freeList['subText'] }}</span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </vs-row>
    </vs-col>
    <vs-row class="available-team-container">
      <div
        class="team-content-container"
      >
        <div class="available-team-header">
          A team which is always available for you.
        </div>
        <div class="available-team-subheader">
          We care, because we know the struggle.
          Experience unparalleled support with our dedicated team, available 24/7 to assist you.
        </div>
      </div>
      <div
        class="team-image-container"
      >
        <img
          src="../assets/svgs/card-image/team.svg"
          alt="team"
          class="team-image-class"
        >
      </div>
    </vs-row>
    <vs-row class="case-study-wrapper">
      <div
        class="case-study-container"
        :class="showForm?'border-radius-class':''"
        :style="!showForm?{'border-radius':'12px'}:{}"
      >
        <div class="case-study-title">
          Case Study
        </div>
        <div class="case-study-main-content">
          <div class="case-study-content-container">
            <!--            <div class="want-to-know">-->
            <!--              Want to know...-->
            <!--            </div>-->
            <div class="want-to-know-content">
              How Explorazor Helped <span class="mid-para">
                Danone India Ltd.
              </span> Revolutionize Data Analysis
            </div>
            <div v-if="!showForm">
              <vs-button
                class="case-study-button"
              >
                <a
                  href="#download-whitepaper"
                  style="color:white !important;justify-content: center"
                >
                  Download Case Study
                </a>
              </vs-button>
            </div>
          </div>
          <div
            id="download-whitepaper"
            class="case-study-image-container"
          >
            <img
              src="../assets/svgs/card-image/CaseStudy.svg"
              alt="case-study"
              class="case-study-image"
            >
          </div>
        </div>
      </div>
    </vs-row>
    <vs-row
      v-if="showForm"
      style="display:flex;justify-content: center;align-content: center"
    >
      <div
        id="whitepaper-download"
        style="width:80%"
      >
        <div
          class="whitepaper-form-container"
          :class="showForm?'form-radius':''"
        >
          <div class="form-title">
            Download Case Study
          </div>
          <div class="form-container">
            <form
              action=""
              class="form-class"
              @submit="handleFormSubmit"
            >
              <vs-row
                vs-w="12"
                vs-justify="center"
              >
                <vs-col
                  :vs-w="screenWidth>768?3.5:12"
                  :class="screenWidth<768?'column-class':''"
                >
                  <div class="form-label">
                    <label
                      for="name"
                    >Name *</label>
                  </div>
                  <div>
                    <input
                      id="name"
                      v-model="name"
                      type="text"
                      name="name"
                      class="form-input-class"
                      placeholder="Name"
                      required
                    >
                  </div>
                </vs-col>
                <vs-col
                  :vs-w="screenWidth>768?3.5:12"
                  :class="screenWidth<768?'column-class':''"
                >
                  <div class="form-label">
                    <label
                      for="email"
                    >Business Email ID *</label>
                  </div>
                  <div>
                    <input
                      id="email"
                      v-model="email"
                      type="text"
                      name="email"
                      placeholder="Business Email ID"
                      class="form-input-class"
                      required
                    >
                  </div>
                </vs-col>
                <vs-col
                  :vs-w="screenWidth>769?1.5:12"
                >
                  <input
                    type="submit"
                    value="Submit"
                    class="form-button"
                  >
                </vs-col>
              </vs-row>
            </form>
          </div>
        </div>
      </div>
    </vs-row>
    <vs-row class="data-exploration-container">
      <div class="data-exploration-header">
        Migrate to Explorazor and Save upto 70%
      </div>
      <div class="data-exploration-subheader">
        See Explorazor in action
      </div>
      <div class="data-exploration-button-container">
        <vs-button
          class="data-exploration-button"
          @click.native="routeTo({name:'Contact'})"
        >
          Switch Now
        </vs-button>
      </div>
    </vs-row>
    <vs-row class="text-center frequently-asked-container">
      <div class="frequently-asked-header">
        Frequently Asked Questions
      </div>
      <div class="questions-container">
        <div
          v-for="(question,index) in questionsList"
          :key="question['header']"
          style="cursor:pointer"
        >
          <vs-row
            class="question-header"
            @click="handleCollapse(index)"
          >
            <vs-col :vs-w="screenWidth > 414 ? 11 : 10">
              {{ question['header'] }}
            </vs-col>
            <vs-col
              :vs-w="screenWidth > 414 ? 1 : 2"
              class="text-center"
            >
              <img
                src="../assets/svgs/app-icons/collapse-icon.svg"
                alt="collapse-icon"
                class="collapse-icon-class"
                :style="question['collapse'] ? {} : { rotate:'180deg' }"
              >
            </vs-col>
          </vs-row>
          <div
            v-if="!question['collapse']"
            class="question-content"
          >
            <span v-html="question['content']" />
          </div>
        </div>
      </div>
    </vs-row>
  </vs-row>
</template>

<script>
  import { defineComponent } from 'vue';
  import imageRenderer from '@/components/helper/ImageRenderer.vue';
  import { imageMixin } from '@/mixins/image';
  import AppIcon from '@/components/helper/AppIcon.vue';
  import WithExplorazor from '@/components/helper/WithExplorazor.vue';

  export default {
    name: 'ThoughtspotVsExplorazor',
    components: { WithExplorazor, AppIcon, imageRenderer },
    mixins: [imageMixin],
    data: function () {
      return {
        screenWidth: null,
        showForm: false,
        showDataConnectors: false,
        showAIlist: false,
        showDashboardList: false,
        mainTable: [
          {
            columnOne: '',
            columnTwo: 'Explorazor',
            columnThree: 'ThoughtSpot'
          },
          {
            columnOne: 'AI Driven Search Based Analysis for your data',
            columnTwo: 'tick',
            columnThree: 'tick'
          },
          {
            columnOne: 'Complete Overview Dashboards',
            columnTwo: 'tick',
            columnThree: 'tick'
          }, {
            columnOne: 'Multiple Data Visualizations',
            columnTwo: 'tick',
            columnThree: 'tick'
          }, {
            columnOne: 'Download Data Tables',
            columnTwo: 'tick',
            columnThree: 'tick'
          }, {
            columnOne: 'Simple Team Collaborations',
            columnTwo: 'tick',
            columnThree: 'tick'
          }, {
            columnOne: 'Multiple Data Joins',
            columnTwo: 'tick',
            columnThree: 'tick'
          }, {
            columnOne: 'Real Time Reporting',
            columnTwo: 'tick',
            columnThree: 'tick'
          }, {
            columnOne: 'Root Cause Analysis',
            columnTwo: 'tick',
            columnThree: 'cross'
          }, {
            columnOne: 'Multiple Data Connectors',
            columnTwo: 'tick',
            columnThree: 'tick'
          }, {
            columnOne: 'Custom Data Connector Support',
            columnTwo: 'tick',
            columnThree: 'tick'
          },
        ],
        questionsList: [
          {
            header: 'How does Explorazor’s pricing work?',
            content: 'Explorazor’s Professional plan starts from $49 per user per month subscription.' +
              ' You can easily sign up for our Free Trial for 14 days and pay using a credit card.' +
              ' Once you subscribe, you will be billed $49/month until you cancel.',
            collapse: true
          },
          {
            header: 'Is there an option for annual subscription on Explorazor? Or can I customize my subscription?',
            // eslint-disable-next-line max-len
            content: '<span>We provide pricing as per enterprise requirements.</span><span><a href="https://www.vphrase.com/explorazor/pricing" style="color:#0243EC" target="_blank"> Please contact our dedicated sales team </a></span><span> to help with your customized/annual pricing.</span>',
            collapse: true
          },
          {
            header: 'Can I cancel my free trial mid-way?',
            content: 'Enjoy a risk-free trial of Explorazor’s professional version for 14 days.' +
              ' If you choose not to continue, you’ll automatically be switched to our free version.',
            collapse: true
          },
          {
            header: 'Can I invite my team to my account?',
            content: 'The team feature enables users to share their reports and dashboards,' +
              ' as well as collaborate with others on their project.',
            collapse: true
          },
          {
            header: 'Have another question?',
            content: '<span><a href="mailto:support@vphrase.com" style="color:#0243EC;white-space: pre;">' +
              'Contact us</a></span> ' +
              'and tell us what you need and' +
              ' we can help you find the right solution.',
            collapse: true
          }
        ],
        freePricingList: [{
          'mainText': '50 MB Data',
          'subText': 'can be uploaded',
          'tick': 'check'
        },
          {
            'mainText': '5',
            'subText': 'datasets',
            'tick': 'check'
          },
          {
            'mainText': 'Community',
            'subText': 'Support',
            'tick': 'check'
          },
          {
            'mainText': '5-7 days',
            'subText': 'Response Time',
            'tick': 'check'
          },
          {
            'mainText': 'Access to Advanced Features',
            'subText': '',
            'tick': 'cross'
          },
          {
            'mainText': 'Advanced Data Modeling',
            'subText': 'Capabilities',
            'tick': 'cross'
          },
          {
            'mainText': 'Dedicated Account Manager',
            'subText': '',
            'tick': 'cross'
          },
          {
            'mainText': 'Calculated Fields',
            'subText': 'for Customized Analytics',
            'tick': 'cross'
          },
          {
            'mainText': 'Admin Panel',
            'subText': 'for User Management',
            'tick': 'cross'
          },
          {
            'mainText': 'Streamlined Deployment Process',
            'subText': '',
            'tick': 'cross'
          }
        ],
        professionalPricingList: [
          {
            'mainText': '10 GB Data',
            'subText': 'can be uploaded',
            'tick': 'check'
          },
          {
            'mainText': 'Unlimited',
            'subText': 'datasets',
            'tick': 'check'
          }, {
            'mainText': '24*7',
            'subText': 'Support',
            'tick': 'check'
          }, {
            'mainText': '2-3 hours',
            'subText': 'Response Time',
            'tick': 'check',
            'title': 'Priority 1: 2 hours , priority 2: 3 hours'
          },
          {
            'mainText': 'Access to Advanced Features',
            'subText': '',
            'tick': 'check'
          },
          {
            'mainText': 'Advanced Data Modeling',
            'subText': 'Capabilities',
            'tick': 'check'
          },
          {
            'mainText': 'Dedicated Account Manager',
            'subText': '',
            'tick': 'cross'
          },
          {
            'mainText': 'Calculated Fields',
            'subText': 'for Customized Analytics',
            'tick': 'cross'
          },
          {
            'mainText': 'Admin Panel',
            'subText': 'for User Management',
            'tick': 'cross'
          },
          {
            'mainText': 'Streamlined Deployment Process',
            'subText': '',
            'tick': 'cross'
          }
        ],
        CustomPricingList: [
          {
            'mainText': 'Unlimited Data',
            'subText': 'can be uploaded',
            'tick': 'check'
          },
          {
            'mainText': 'Unlimited',
            'subText': 'datasets',
            'tick': 'check'
          }, {
            'mainText': '24*7',
            'subText': 'Support',
            'tick': 'check'
          }, {
            'mainText': 'Dedicated',
            'subText': 'Support Team',
            'tick': 'check'
          },
          {
            'mainText': 'Access to Advanced Features',
            'subText': '',
            'tick': 'check'
          },
          {
            'mainText': 'Advanced Data Modeling',
            'subText': 'Capabilities',
            'tick': 'check'
          },
          {
            'mainText': 'Dedicated Account Manager',
            'subText': '',
            'tick': 'check'
          },
          {
            'mainText': 'Calculated Fields',
            'subText': 'for Customized Analytics',
            'tick': 'check'
          },
          {
            'mainText': 'Admin Panel',
            'subText': 'for User Management',
            'tick': 'check'
          },
          {
            'mainText': 'Streamlined Deployment Process',
            'subText': '',
            'tick': 'check'
          }
        ],
        rowHeight: null
      };
    },
    beforeMount () {

    },
    mounted () {
      this.screenWidth = screen.availWidth;
      this.rowHeight = document.getElementById('table-row').offsetHeight;
    },
    methods: {
      handleRegister: function () {
        window.open('https://app.explorazor.com/#/register', '_blank');
      },
      handleTour: function () {
        window.open('https://app.explorazor.com/#/playground', '_blank');
      },
      handleCollapse: function (questionIndex) {
        this.questionsList[questionIndex]['collapse'] = ! this.questionsList[questionIndex]['collapse'];
      },
      handleFormSubmit: function (e) {
        e.preventDefault();
        let link = location.href;
        let utmSource = link.includes('utm_source=')
          ? link.substring(link.indexOf('utm_source=') + 11, link.lastIndexOf('&utm_medium'))
          : '';
        let utmMedium = link.includes('utm_medium=')
          ? link.substring(link.indexOf('utm_medium=') + 11, link.lastIndexOf('&utm_campaign')) : '';
        let utmCampaign = link.includes('utm_campaign=')
          ? link.substring(link.indexOf('utm_campaign=') + 13)
          : '';
        const params = {
          api_key: process.env.VUE_APP_SENDY_API_KEY,
          name: this.name,
          email: this.email,
          boolean: true,
          list: process.env.VUE_APP_SENDY_LIST_KEY,
          Lead_Source_1: 'Website',
          Lead_Source_2: 'Asset',
          Lead_Source_3: 'case_study',
          Lead_Source_4: 'f_and_b_industry',
          utm_source: utmSource.length ? utmSource : '',
          utm_medium: utmMedium.length ? utmMedium : '',
          utm_campaign_name: utmCampaign.length ? utmCampaign : '',
        };
        let formBody = [];
        for (let property in params) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(params[property]);
          formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');
        const xhr = new XMLHttpRequest();
        xhr.open('POST',
          'https://email.pcube.net/subscribe', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Headers',
          'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
          'Access-Control-Allow-Methods', 'OPTIONS,POST',
          'Access-Control-Allow-Credentials', true,
          'Access-Control-Allow-Origin', '*',
          'X-Requested-With', '*',);
        xhr.send(formBody);
        // sendy subscription call code end
        // hubspot form api call code start
        const xhrHubspot = new XMLHttpRequest();
        // eslint-disable-next-line max-len
        const url = `https://api.hsforms.com/submissions/v3/integration/submit/${ process.env.VUE_APP_HUBSPOT_PORTAL_ID }/${ process.env.VUE_APP_HUBSPOT_FORM_ID }`;
        //  request JSON:
        let data = {
          'fields': [
            {
              'name': 'email',
              'value': this.email
            },
            {
              'name': 'firstname',
              'value': this.name
            },
            {
              'name': 'ga_source',
              'value': 'Website'
            },
            {
              'name': 'ga_medium',
              'value': 'Asset'
            },
            {
              'name': 'ga_campaign',
              'value': 'Case_study_f_and_b_industry'
            }
          ],

        };

        let final_data = JSON.stringify(data);

        xhrHubspot.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhrHubspot.setRequestHeader('Content-Type', 'application/json');

        // Sends the request

        xhrHubspot.send(final_data);
        // hubspot form api call code end
        window.open('https://www.vphrase.com/explorazor-assets/case_study/Explorazor%20Case%20Study%20(Danone).pdf',
          '_blank');
        document.getElementById('whitepaper-download').style.padding = '50px';
        document.getElementById('whitepaper-download').style.color = 'white';
        document.getElementById('whitepaper-download').style.background = '#191948';
        document.getElementById('whitepaper-download').style.fontSize = '22px';
        document.getElementById('whitepaper-download').style.fontWeight = '600';
        document.getElementById('whitepaper-download').style.fontFamily = 'Poppins';
        document.getElementById('whitepaper-download').innerHTML = 'Thank You for downloading the Case Study';
      },
    }
  };
</script>

<style scoped>
  .new-main-image {
  }

  .new-main-button-container {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0 15% 0 15% !important
  }


  .data-exploration-header {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 70px;
    padding-bottom: 25px;
    padding-left: 30%;
    padding-right: 30%
  }

  .data-exploration-subheader {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    /* identical to box height, or 30px */
    text-align: center;
    color: #91B3FA;
    padding-bottom: 50px
  }

  .data-exploration-container {
    background: #0243EC;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    margin-top: 100px
  }


  .available-team-container {
    background: #E6EEFF;
  }

  .available-team-header {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    color: #191948;
    padding-top: 65px;
    padding-bottom: 35px;
    padding-left: 10%;
    padding-right: 10%
  }

  .available-team-subheader {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    /* or 175% */
    color: #6A7591;
    padding-left: 15%;
    padding-right: 15%
  }

  .pricing-plans-header {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 50px;
  }

  .team-image-container {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .team-image-container {
    width: 50%;
  }

  .team-content-container {
    width: 50%
  }

  .data-exploration-button-container {
    padding-bottom: 70px
  }

  .data-exploration-button {
    background: #191948 !important;
    color: #FFFFFF !important;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 50px !important;
    padding-right: 50px !important
  }

  table {
    border-collapse: separate;
    border: solid #D2D2D2 1px;
    border-radius: 6px;
    border-spacing: 0;
  }

  td, th {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border-bottom: 1px solid #D2D2D2;
    padding: 25px 0;
    text-align: center;
  }

  td:last-child {

  }

  tr:nth-child(2), tr:nth-child(3) {
    outline-bottom: thin solid #D2D2D2
  }

  th {
    border-top: none;
  }

  td:first-child, th:first-child, td:last-child, th:last-child {
    border-left: none;
  }

  td:nth-child(2), td:nth-child(3) {
    padding-left: 9.5%;
    padding-right: 10%
  }

  .limit-query {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #0243EC;
    padding: 5px 10px;
    background: #FFFFFF;
    margin-left: 20%;
    margin-right: 20%;
    border-radius: 4px;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  .thoughtspot-limit-query {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    color: #6A7591;
    padding: 5px 10px;
    background: #F0F5FF;;
    margin-left: 25%;
    margin-right: 25%;
    border-radius: 4px;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  td:first-child {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #191847;
    text-align: left;
    width: 60%;
    padding-left: 40px
  }

  .list-class {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 21px;
    color: #6A7591 !important;
    list-style: disc;
    padding-left: 20px;
    padding-top: 20px
  }

  .table-class {
    width: 60%;
    margin-bottom: 40px;
    justify-content: center;
    align-content: center
  }

  @media only screen and (max-width: 1850px) {
    .table-class {
      width: 70%;
      margin-bottom: 40px;
      justify-content: center;
      align-content: center
    }
  }

  @media only screen and (max-width: 1580px) {
    .table-class {
      width: 75% !important;
      margin-bottom: 40px;
      justify-content: center;
      align-content: center
    }
  }

  @media only screen and (max-width: 1470px) {
    .table-class {
      width: 80% !important;
      margin-bottom: 40px;
      justify-content: center;
      align-content: center
    }
  }

  @media only screen and (max-width: 1366px) {
    .table-class {
      width: 85% !important;
      margin-bottom: 40px;
      justify-content: center;
      align-content: center
    }
  }

  @media only screen and (max-width: 1250px) {
    .limit-query {
      margin-left: 15%;
      margin-right: 15%
    }

    .thoughtspot-limit-query {
      margin-left: 15%;
      margin-right: 15%
    }
  }

  @media only screen and (max-width: 1100px) {
    .limit-query {
      margin-left: 10%;
      margin-right: 10%
    }

    .thoughtspot-limit-query {
      margin-left: 10%;
      margin-right: 10%
    }
  }

  @media only screen and (max-width: 820px) {
    .data-exploration-header {
      font-size: 36px !important;
      line-height: 48px !important;
      padding-left: 10% !important;
      padding-right: 10% !important
    }

    .team-content-container {
      width: 100% !important
    }

    .team-image-container {
      width: 100% !important
    }

    .available-team-header {
      padding-left: 10% !important;
      padding-right: 10% !important
    }

    .available-team-subheader {
      padding-left: 10% !important;
      padding-right: 10% !important;
    }

    .team-image-class {
      width: 100% !important
    }

    .available-team-container {
      flex-direction: column;
    }

    td:first-child {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      color: #191847;
      margin-left: 10px;
    }

    .logo-class {
      width: 110px !important
    }

    .thoughtspot-price-class {
      font-size: 28px !important;
      padding-top: 5px !important
    }

    .explorazor-price-class {
      font-size: 28px !important
    }

    .limit-query {
      font-size: 9px !important
    }

    .thoughtspot-limit-query {
      font-size: 9px !important;
      margin-top: 12px !important;
    }
  }


  @media only screen and (max-width: 414px) {
    .available-team-header {
      font-family: 'Fraunces';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
      color: #191948;
      padding-left: 5% !important;
      padding-right: 5% !important
    }

    .available-team-subheader {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      /* or 175% */
      color: #6A7591;
      padding-left: 5% !important;
      padding-right: 5% !important
    }

    .team-image-container {
      padding-bottom: 0 !important
    }

    .explorazor-price-class {
      line-height: 30px !important
    }

    .thoughtspot-price-class {
      line-height: 30px !important
    }

    td:first-child {
      font-size: 11px !important;
      padding-left: 12px !important;
      line-height: 14px !important;
      padding-right: 10px !important;
    }

    td:nth-child(2), td:nth-child(3) {
      padding-left: 12% !important
    }

    .explorazor-price-class {
      font-size: 20px !important
    }

    .thoughtspot-price-class {
      font-size: 20px !important
    }

    .table-class {
      width: 95% !important
    }

    .limit-query {
      font-size: 8px !important;
      padding: 5px;
      margin-bottom: 15px !important;
    }

    .thoughtspot-limit-query {
      font-size: 8px !important;
      padding: 5px;
      margin-bottom: 15px !important;
    }

    .logo-class {
      padding-right: 8px !important;
      padding-left: 8px !important
    }

    .new-main-button-container {
      display: flex;
      flex-direction: column;
    }

    .new-main-image {
      top: 575px !important
    }
  }

  .team-image-class {
    width: 90%
  }

  .logo-class {
    width: 160px;
  }

  .thoughtspot-price-class {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    /* identical to box height */
    color: #6A7591;
  }

  .explorazor-price-class {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    /* identical to box height */
    color: #0243EC;
  }

  tr:hover {
    background: rgb(230, 238, 255) !important;
  }

  tr:first-child {
    background: white !important
  }

  tr {
    height: 30% !important
  }
</style>