<template>
  <vs-row
    style="margin-top:60px;width:100% !important;overflow-x:hidden !important;"
    class="pricing-class"
  >
    <vs-row class="banner-wrapper">
      <div
        v-if="showBanner"
        class="banner-class"
      >
        <div
          class="banner-text"
          @click="handleTour"
        >
          Take an Interactive product tour of Explorazor
        </div>
        <div
          class="banner-cross"
          @click="closeBanner"
        >
          <img
            src="../assets/svgs/app-icons/cross-icon.svg"
            alt="cross-icon"
          >
        </div>
      </div>
    </vs-row>
    <vs-row class="text-center pricing-title">
      PRICING
    </vs-row>
    <vs-row class="text-center pricing-subheader">
      Accessible Data Exploration for Every Budget
    </vs-row>
    <vs-row class="text-center pricing-subheader-child">
      Experience the power of search driven analytics without overspending or compromising on quality.
    </vs-row>
    <!--    <vs-row class="text-center">-->
    <!--      <vs-button class="get-started-button">-->
    <!--        Get Started For Free-->
    <!--      </vs-button>-->
    <!--    </vs-row>-->
    <!--    <vs-row class="text-center">-->
    <!--      <vs-col-->
    <!--        vs-w="12"-->
    <!--        class="pricing-banner"-->
    <!--      >-->
    <!--        <div class="banner-content">-->
    <!--          <div class="banner-subcontent">-->
    <!--            <span><img-->
    <!--              src="../assets/svgs/app-icons/tick.svg"-->
    <!--              alt="tick"-->
    <!--              width="15"-->
    <!--              class="tick-class"-->
    <!--            ></span>-->
    <!--            <span>Get started with one user</span>-->
    <!--          </div>-->
    <!--          <div class="banner-subcontent">-->
    <!--            <span><img-->
    <!--              src="../assets/svgs/app-icons/tick.svg"-->
    <!--              alt="tick"-->
    <!--              width="15"-->
    <!--              class="tick-class"-->
    <!--            ></span>-->
    <!--            <span>Unlimited Queries</span>-->
    <!--          </div>-->
    <!--          <div class="banner-subcontent">-->
    <!--            <span><img-->
    <!--              src="../assets/svgs/app-icons/tick.svg"-->
    <!--              alt="tick"-->
    <!--              width="15"-->
    <!--              class="tick-class"-->
    <!--            ></span>-->
    <!--            <span>+16 Data Connectors-->
    <!--            </span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </vs-col>-->
    <!--    </vs-row>-->
    <vs-row
      class="pricing-sheet-container text-center"
    >
      <vs-col
        vs-w="12"
        class="pricing-sheet-title"
      >
        Pricing chart
      </vs-col>
      <!--      <vs-col-->
      <!--        vs-w="12"-->
      <!--      >-->
      <!--        <vs-row class="sheets-container">-->
      <!--          <div class="pricing-sheet text-center">-->
      <!--            <div>-->
      <!--              <div class="sheet-header">-->
      <!--                Free-->
      <!--              </div>-->
      <!--              <div class="sheet-subheader">-->
      <!--                Free-->
      <!--              </div>-->
      <!--              <div class="sheet-button-container">-->
      <!--                <vs-button class="pricing-sheet-button">-->
      <!--                  Get Started For Free-->
      <!--                </vs-button>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div style="border-top:1px solid white;padding:30px 0 5px 0">-->
      <!--              <div-->
      <!--                v-for="freeList in freePricingList"-->
      <!--                :key="freeList['tick']"-->
      <!--                style="text-align: left;padding-bottom: 25px;display:flex"-->
      <!--              >-->
      <!--                <span style="padding-left:25px;"><img-->
      <!--                  :src="freeList['tick'] === 'check'?require('@/assets/svgs/app-icons/green-tick.svg')-->
      <!--                    :require('@/assets/svgs/app-icons/red-cross.svg')"-->
      <!--                  :alt="freeList['tick']"-->
      <!--                  width="15"-->
      <!--                  class="tick-class"-->
      <!--                ></span>-->
      <!--                <span>-->
      <!--                  <div>-->
      <!--                    <span class="list-main-text">{{ freeList['mainText'] }}</span>-->
      <!--                    <span-->
      <!--                      class="list-sub-text"-->
      <!--                    >{{ freeList['subText'] }}</span>-->
      <!--                  </div>-->
      <!--                </span>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="professional-pricing-sheet text-center">-->
      <!--            <div>-->
      <!--              <div class="sheet-header">-->
      <!--                Professional-->
      <!--              </div>-->
      <!--              <div class="professional-sheet-subheader">-->
      <!--                $49-->
      <!--              </div>-->
      <!--              <div class="professional-subheader">-->
      <!--                Per User Per Month-->
      <!--              </div>-->

      <!--              <div class="sheet-button-container">-->
      <!--                <a-->
      <!--                  href="#sales"-->
      <!--                  class="text-white"-->
      <!--                >-->
      <!--                  <vs-button-->
      <!--                    style="background: white;color:black"-->
      <!--                    class="pricing-sheet-button"-->
      <!--                    color="white"-->
      <!--                  >-->
      <!--                    Contact Sales-->
      <!--                  </vs-button>-->
      <!--                </a>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div style="border-top:1px solid white;padding:30px 0 5px 0">-->
      <!--              <div-->
      <!--                v-for="freeList in professionalPricingList"-->
      <!--                :key="freeList['tick']"-->
      <!--                style="text-align: left;padding-bottom: 25px;display:flex"-->
      <!--              >-->
      <!--                <span style="padding-left:25px;"><img-->
      <!--                  :src="freeList['tick'] === 'check'?require('@/assets/svgs/app-icons/green-tick.svg')-->
      <!--                    :require('@/assets/svgs/app-icons/red-cross.svg')"-->
      <!--                  :alt="freeList['tick']"-->
      <!--                  width="15"-->
      <!--                  class="tick-class"-->
      <!--                ></span>-->
      <!--                <span>-->
      <!--                  <div>-->
      <!--                    <span class="list-main-text">{{ freeList['mainText'] }}</span>-->
      <!--                    <span-->
      <!--                      class="list-sub-text"-->
      <!--                    >{{ freeList['subText'] }}</span>-->
      <!--                  </div>-->
      <!--                </span>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div-->
      <!--            class="pricing-sheet text-center"-->
      <!--            :style="screenWidth > 1366 && screenWidth < 1640 ? {'margin-top':'30px !important'} : {}"-->
      <!--          >-->
      <!--            <div>-->
      <!--              <div class="sheet-header">-->
      <!--                Enterprise-->
      <!--              </div>-->
      <!--              <div class="sheet-subheader">-->
      <!--                Custom-->
      <!--              </div>-->
      <!--              <div class="sheet-button-container">-->
      <!--                <a-->
      <!--                  href="#sales"-->
      <!--                  class="text-white"-->
      <!--                >-->
      <!--                  <vs-button-->
      <!--                    style="background: white;color:black"-->
      <!--                    class="pricing-sheet-button"-->
      <!--                    color="white"-->
      <!--                  >-->
      <!--                    Contact Sales-->
      <!--                  </vs-button>-->
      <!--                </a>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div style="border-top:1px solid white;padding:30px 0 5px 0">-->
      <!--              <div-->
      <!--                v-for="freeList in CustomPricingList"-->
      <!--                :key="freeList['tick']"-->
      <!--                style="text-align: left;padding-bottom: 25px;display:flex"-->
      <!--              >-->
      <!--                <span style="padding-left:25px;"><img-->
      <!--                  :src="freeList['tick'] === 'check'?require('@/assets/svgs/app-icons/green-tick.svg')-->
      <!--                    :require('@/assets/svgs/app-icons/red-cross.svg')"-->
      <!--                  :alt="freeList['tick']"-->
      <!--                  width="15"-->
      <!--                  class="tick-class"-->
      <!--                ></span>-->
      <!--                <span>-->
      <!--                  <div>-->
      <!--                    <span class="list-main-text">{{ freeList['mainText'] }}</span>-->
      <!--                    <span-->
      <!--                      class="list-sub-text"-->
      <!--                    >{{ freeList['subText'] }}</span>-->
      <!--                  </div>-->
      <!--                </span>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </vs-row>-->
      <!--      </vs-col>-->
      <vs-col
        vs-w="12"
        style="display:flex;justify-content: center;align-content: center;margin-bottom:80px"
      >
        <table
          v-if="screenWidth>820"
          style="color:white"
        >
          <thead>
            <tr>
              <th />
              <th>
                <div class="list-main-title">
                  {{ mainPricingList[1]['main_title'] }}
                </div>
                <div class="list-sub-title">
                  Free
                </div>
                <div class="list-period">
                  Forever
                </div>
                <div style="display:flex;justify-content: center;align-content: center;padding-bottom:30px">
                  <vs-button
                    class="pricing-list-button"
                    @click.native="handleSignup"
                  >
                    Get Started
                  </vs-button>
                </div>
              </th>
              <th>
                <div class="list-main-title">
                  {{ mainPricingList[2]['main_title'] }}
                </div>
                <div class="list-sub-title">
                  $10
                </div>
                <div class="list-period">
                  Per User Per Month
                </div>
                <div style="display:flex;justify-content: center;align-content: center;padding-bottom:30px">
                  <vs-button
                    class="basic-button"
                    style="background: white;color:#0243EC"
                    @click.native="routeTo({name:'Contact'})"
                  >
                    Contact Sales
                  </vs-button>
                </div>
              </th>
              <th>
                <div class="list-main-title">
                  {{ mainPricingList[3]['main_title'] }}
                </div>
                <div class="list-sub-title">
                  $800
                </div>
                <div class="list-period">
                  Monthly
                </div>
                <div style="display:flex;justify-content: center;align-content: center;padding-bottom:30px">
                  <vs-button
                    class="pricing-list-button"
                    @click.native="routeTo({name:'Contact'})"
                  >
                    Contact Sales
                  </vs-button>
                </div>
              </th>
              <th>
                <div class="list-main-title">
                  {{ mainPricingList[4]['main_title'] }}
                </div>
                <div class="list-sub-title">
                  Custom
                </div>
                <div style="height:43px;" />
                <div style="display:flex;justify-content: center;align-content: center;padding-bottom:30px">
                  <vs-button
                    class="pricing-list-button"
                    @click.native="routeTo({name:'Contact'})"
                  >
                    Contact Sales
                  </vs-button>
                </div>
              </th>
            </tr>
          </thead>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][0]['mainText'] }}</td>
            <td>
              <span><img
                :src="mainPricingList[1]['columnData'][0]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[1]['columnData'][0]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[1]['columnData'][0]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[2]['columnData'][0]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[2]['columnData'][0]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[2]['columnData'][0]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[3]['columnData'][0]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[3]['columnData'][0]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[3]['columnData'][0]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[4]['columnData'][0]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[4]['columnData'][0]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[4]['columnData'][0]['mainText'] }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][1]['mainText'] }}</td>
            <td>
              <span><img
                :src="mainPricingList[1]['columnData'][1]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[1]['columnData'][1]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[1]['columnData'][1]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[2]['columnData'][1]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[2]['columnData'][1]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[2]['columnData'][1]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[3]['columnData'][1]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[3]['columnData'][1]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[3]['columnData'][1]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[4]['columnData'][1]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[4]['columnData'][1]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[4]['columnData'][1]['mainText'] }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][2]['mainText'] }}</td>
            <td>
              <span><img
                :src="mainPricingList[1]['columnData'][2]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[1]['columnData'][2]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[1]['columnData'][2]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[2]['columnData'][2]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[2]['columnData'][2]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[2]['columnData'][2]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[3]['columnData'][2]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[3]['columnData'][2]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[3]['columnData'][2]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[4]['columnData'][2]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[4]['columnData'][2]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[4]['columnData'][2]['mainText'] }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][3]['mainText'] }}</td>
            <td>
              <span><img
                :src="mainPricingList[1]['columnData'][3]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[1]['columnData'][3]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[1]['columnData'][3]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[2]['columnData'][3]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[2]['columnData'][3]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[2]['columnData'][3]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[3]['columnData'][3]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[3]['columnData'][3]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[3]['columnData'][3]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[4]['columnData'][3]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[4]['columnData'][3]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[4]['columnData'][3]['mainText'] }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][4]['mainText'] }}</td>
            <td>
              <span><img
                :src="mainPricingList[1]['columnData'][4]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[1]['columnData'][4]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[1]['columnData'][4]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[2]['columnData'][4]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[2]['columnData'][4]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[2]['columnData'][4]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[2]['columnData'][4]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[2]['columnData'][4]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[3]['columnData'][4]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[1]['columnData'][4]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[1]['columnData'][4]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[4]['columnData'][4]['mainText'] }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][5]['mainText'] }}</td>
            <td>
              <span><img
                :src="mainPricingList[1]['columnData'][5]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[1]['columnData'][5]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[1]['columnData'][5]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[2]['columnData'][5]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[2]['columnData'][5]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[2]['columnData'][5]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[3]['columnData'][5]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[3]['columnData'][5]['tick']"
                width="15"
                class="tick-class"
              ></span>
              <span
                style="text-decoration: underline;cursor:pointer"
                @click="titleClick($event)"
              >
                {{ mainPricingList[3]['columnData'][5]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[2]['columnData'][5]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[2]['columnData'][5]['tick']"
                width="15"
                class="tick-class"
              ></span>

              <span
                style="text-decoration: underline;cursor:pointer"
                @click="titleClick($event)"
              >
                {{ mainPricingList[4]['columnData'][5]['mainText'] }}
              </span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][6]['mainText'] }}</td>
            <td>
              <span><img
                :src="mainPricingList[1]['columnData'][6]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[1]['columnData'][6]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[1]['columnData'][6]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[2]['columnData'][6]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[2]['columnData'][6]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[2]['columnData'][6]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[3]['columnData'][6]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[3]['columnData'][6]['tick']"
                width="15"
                class="tick-class"
              ></span>
              <span>
                {{ mainPricingList[3]['columnData'][6]['mainText'] }}
              </span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[4]['columnData'][6]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[4]['columnData'][6]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[4]['columnData'][6]['mainText'] }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][7]['mainText'] }}</td>
            <td>
              <span><img
                :src="mainPricingList[1]['columnData'][7]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[1]['columnData'][7]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[1]['columnData'][7]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[2]['columnData'][7]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[2]['columnData'][7]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[2]['columnData'][7]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[3]['columnData'][7]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[3]['columnData'][7]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[3]['columnData'][7]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[4]['columnData'][7]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[4]['columnData'][7]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[4]['columnData'][7]['mainText'] }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][8]['mainText'] }}</td>
            <td>
              <span><img
                :src="mainPricingList[1]['columnData'][8]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[1]['columnData'][8]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[1]['columnData'][8]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[2]['columnData'][8]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[2]['columnData'][8]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[2]['columnData'][8]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[3]['columnData'][8]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[3]['columnData'][8]['tick']"
                width="15"
                class="tick-class"
              ></span><span>{{ mainPricingList[3]['columnData'][8]['mainText'] }}</span>
            </td>
            <td>
              <span><img
                :src="mainPricingList[2]['columnData'][8]['tick'] === 'check'?
                  require('@/assets/svgs/app-icons/green-tick.svg')
                  :require('@/assets/svgs/app-icons/red-cross.svg')"
                :alt="mainPricingList[2]['columnData'][8]['tick']"
                width="15"
                class="tick-class"
              ></span>
              <span>{{ mainPricingList[4]['columnData'][8]['mainText'] }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][9]['mainText'] }}</td>
            <td>
              <span>
                <img
                  :src="mainPricingList[1]['columnData'][9]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[1]['columnData'][9]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span>
              <span>{{ mainPricingList[1]['columnData'][9]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[2]['columnData'][9]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[2]['columnData'][9]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[2]['columnData'][9]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[3]['columnData'][9]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[3]['columnData'][9]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[3]['columnData'][9]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[4]['columnData'][9]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[4]['columnData'][9]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[4]['columnData'][9]['mainText'] }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][10]['mainText'] }}</td>
            <td>
              <span>
                <img
                  :src="mainPricingList[1]['columnData'][10]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[1]['columnData'][10]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[1]['columnData'][10]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[2]['columnData'][10]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[2]['columnData'][10]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[2]['columnData'][10]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[3]['columnData'][10]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[3]['columnData'][10]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[3]['columnData'][10]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[4]['columnData'][10]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[4]['columnData'][10]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[4]['columnData'][10]['mainText'] }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][11]['mainText'] }}</td>
            <td>
              <span>
                <img
                  :src="mainPricingList[1]['columnData'][11]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[1]['columnData'][11]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[1]['columnData'][11]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[2]['columnData'][11]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[2]['columnData'][11]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[2]['columnData'][11]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[3]['columnData'][11]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[3]['columnData'][11]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[3]['columnData'][11]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[4]['columnData'][11]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[4]['columnData'][11]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[4]['columnData'][11]['mainText'] }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][12]['mainText'] }}</td>
            <td>
              <span>
                <img
                  :src="mainPricingList[1]['columnData'][12]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[1]['columnData'][12]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[1]['columnData'][12]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[2]['columnData'][12]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[2]['columnData'][12]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[2]['columnData'][12]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[3]['columnData'][12]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[3]['columnData'][12]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[3]['columnData'][12]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[4]['columnData'][12]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[4]['columnData'][12]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[4]['columnData'][12]['mainText'] }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][13]['mainText'] }}</td>
            <td>
              <span>
                <img
                  :src="mainPricingList[1]['columnData'][13]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[1]['columnData'][13]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span>
              <span>{{ mainPricingList[1]['columnData'][13]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[2]['columnData'][13]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[2]['columnData'][13]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[2]['columnData'][13]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[3]['columnData'][13]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[3]['columnData'][13]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[3]['columnData'][13]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[4]['columnData'][13]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[4]['columnData'][13]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[4]['columnData'][13]['mainText'] }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][14]['mainText'] }}</td>
            <td>
              <span>
                <img
                  :src="mainPricingList[1]['columnData'][14]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[1]['columnData'][14]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[1]['columnData'][14]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[2]['columnData'][14]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[2]['columnData'][14]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[2]['columnData'][14]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[3]['columnData'][14]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[3]['columnData'][14]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[3]['columnData'][14]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[4]['columnData'][14]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[4]['columnData'][14]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[4]['columnData'][14]['mainText'] }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][15]['mainText'] }}</td>
            <td>
              <span>
                <img
                  :src="mainPricingList[1]['columnData'][15]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[1]['columnData'][15]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[1]['columnData'][15]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[2]['columnData'][15]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[2]['columnData'][15]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[2]['columnData'][15]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[3]['columnData'][15]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[3]['columnData'][15]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[3]['columnData'][15]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[4]['columnData'][15]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[4]['columnData'][15]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[4]['columnData'][15]['mainText'] }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][16]['mainText'] }}</td>
            <td>
              <span>
                <img
                  :src="mainPricingList[1]['columnData'][16]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[1]['columnData'][16]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[1]['columnData'][16]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[2]['columnData'][16]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[2]['columnData'][16]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[2]['columnData'][16]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[3]['columnData'][16]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[3]['columnData'][16]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[3]['columnData'][16]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[4]['columnData'][16]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[4]['columnData'][16]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[4]['columnData'][16]['mainText'] }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][17]['mainText'] }}</td>
            <td>
              <span>
                <img
                  :src="mainPricingList[1]['columnData'][17]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[1]['columnData'][17]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[1]['columnData'][17]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[2]['columnData'][17]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[2]['columnData'][17]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[2]['columnData'][17]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[3]['columnData'][17]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[3]['columnData'][17]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[3]['columnData'][17]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[4]['columnData'][17]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[4]['columnData'][17]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[4]['columnData'][17]['mainText'] }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][18]['mainText'] }}</td>
            <td>
              <span>
                <img
                  :src="mainPricingList[1]['columnData'][18]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[1]['columnData'][18]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[1]['columnData'][18]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[2]['columnData'][18]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[2]['columnData'][18]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[2]['columnData'][18]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[3]['columnData'][18]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[3]['columnData'][18]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[3]['columnData'][18]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[4]['columnData'][18]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[4]['columnData'][18]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[4]['columnData'][18]['mainText'] }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ mainPricingList[0]['columnData'][19]['mainText'] }}</td>
            <td>
              <span>
                <img
                  :src="mainPricingList[1]['columnData'][19]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[1]['columnData'][19]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[1]['columnData'][19]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[2]['columnData'][19]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[2]['columnData'][19]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[2]['columnData'][19]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[3]['columnData'][19]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[3]['columnData'][19]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[3]['columnData'][19]['mainText'] }}</span>
            </td>
            <td>
              <span>
                <img
                  :src="mainPricingList[4]['columnData'][19]['tick'] === 'check'?
                    require('@/assets/svgs/app-icons/green-tick.svg')
                    :require('@/assets/svgs/app-icons/red-cross.svg')"
                  :alt="mainPricingList[4]['columnData'][19]['tick']"
                  width="15"
                  class="tick-class"
                >
              </span><span>{{ mainPricingList[4]['columnData'][19]['mainText'] }}</span>
            </td>
          </tr>
        </table>
        <vs-row v-if="screenWidth <= 820">
          <vs-row
            class="mobile-list"
          >
            <div class="list-main-title">
              {{ mainPricingList[1]['main_title'] }}
            </div>
            <div class="list-sub-title">
              Free
            </div>
            <div class="list-period">
              Forever
            </div>
            <div style="display:flex;justify-content: center;align-content: center;padding-bottom:50px">
              <vs-button
                class="pricing-list-button"
              >
                Get Started
              </vs-button>
            </div>
            <vs-row
              v-if="!showFreeFeatures"
              class="show-features"
              @click="showFreeFeatures = true"
            >
              <vs-col vs-w="10">
                Show Features
              </vs-col>
              <vs-col
                vs-w="2"
                style="padding-left:6%"
              >
                <img
                  src="../assets/svgs/app-icons/section-arrow.svg"
                  alt="section-arrow"
                  width="14"
                >
              </vs-col>
            </vs-row>
            <div
              v-for="freeFeature in freeFeatures"
              :key="freeFeature['mainText']"
            >
              <div
                v-if="showFreeFeatures"
                class="feature-maintext"
              >
                {{ freeFeature['mainText'] }}
              </div>
              <div
                v-if="showFreeFeatures"
                class="feature-subtext"
              >
                {{ freeFeature['subText'] }}
              </div>
            </div>
            <vs-row
              v-if="showFreeFeatures"
              class="show-features"
              @click="showFreeFeatures = false"
            >
              <vs-col vs-w="10">
                Hide Features
              </vs-col>
              <vs-col
                vs-w="2"
                style="padding-left:6%"
              >
                <img
                  src="../assets/svgs/app-icons/section-arrow.svg"
                  alt="section-arrow"
                  width="14"
                  class="hide-features-icon"
                >
              </vs-col>
            </vs-row>
          </vs-row>
          <vs-row
            class="mobile-bottom-list"
          >
            <div class="list-main-title">
              {{ mainPricingList[2]['main_title'] }}
            </div>
            <div class="list-sub-title">
              $10
            </div>
            <div class="list-period">
              Per User Per Month
            </div>
            <div style="display:flex;justify-content: center;align-content: center;padding-bottom:50px">
              <vs-button
                class="pricing-list-button"
                style="color:#0243EC !important;background:white !important"
              >
                Contact Sales
              </vs-button>
            </div>
            <vs-row
              v-if="!showBasicFeatures"
              class="show-features"
              @click="showBasicFeatures = true"
            >
              <vs-col vs-w="10">
                Show Features
              </vs-col>
              <vs-col
                vs-w="2"
                style="padding-left:6%"
              >
                <img
                  src="../assets/svgs/app-icons/section-arrow.svg"
                  alt="section-arrow"
                  width="14"
                >
              </vs-col>
            </vs-row>
            <div
              v-for="basicFeature in basicFeatures"
              :key="basicFeature['mainText']"
            >
              <div
                v-if="showBasicFeatures"
                class="feature-maintext"
              >
                {{ basicFeature['mainText'] }}
              </div>
              <div
                v-if="showBasicFeatures"
                class="feature-subtext"
              >
                {{ basicFeature['subText'] }}
              </div>
            </div>
            <vs-row
              v-if="showBasicFeatures"
              class="show-features"
              @click="showBasicFeatures = false"
            >
              <vs-col
                v-if="showBasicFeatures"
                vs-w="10"
              >
                Hide Features
              </vs-col>
              <vs-col
                v-if="showBasicFeatures"
                vs-w="2"
                style="padding-left:6%"
              >
                <img
                  src="../assets/svgs/app-icons/section-arrow.svg"
                  alt="section-arrow"
                  width="14"
                  class="hide-features-icon"
                >
              </vs-col>
            </vs-row>
          </vs-row>
          <vs-row

            class="mobile-bottom-list"
          >
            <div class="list-main-title">
              {{ mainPricingList[3]['main_title'] }}
            </div>
            <div class="list-sub-title">
              $800
            </div>
            <div class="list-period">
              Monthly
            </div>
            <div style="display:flex;justify-content: center;align-content: center;padding-bottom:50px">
              <vs-button
                class="pricing-list-button"
                style="color:#0243EC !important;background: white !important;"
              >
                Contact sales
              </vs-button>
            </div>
            <vs-row
              v-if="!showProfessionalFeatures"
              class="show-features"
              @click="showProfessionalFeatures = true"
            >
              <vs-col vs-w="10">
                Show Features
              </vs-col>
              <vs-col
                vs-w="2"
                style="padding-left:6%"
              >
                <img
                  src="../assets/svgs/app-icons/section-arrow.svg"
                  alt="section-arrow"
                  width="14"
                >
              </vs-col>
            </vs-row>
            <div
              v-for="basicFeature in professionalFeatures"
              :key="basicFeature['mainText']"
            >
              <div
                v-if="showProfessionalFeatures"
                class="feature-maintext"
              >
                {{ basicFeature['mainText'] }}
              </div>
              <div
                v-if="showProfessionalFeatures"
                class="feature-subtext"
              >
                {{ basicFeature['subText'] }}
              </div>
            </div>
            <vs-row
              v-if="showProfessionalFeatures"
              class="show-features"
              @click="showProfessionalFeatures = false"
            >
              <vs-col vs-w="10">
                Hide Features
              </vs-col>
              <vs-col
                vs-w="2"
                style="padding-left:6%"
              >
                <img
                  src="../assets/svgs/app-icons/section-arrow.svg"
                  alt="section-arrow"
                  width="14"
                  class="hide-features-icon"
                >
              </vs-col>
            </vs-row>
          </vs-row>
          <vs-row
            class="mobile-bottom-list"
          >
            <div class="list-main-title">
              {{ mainPricingList[4]['main_title'] }}
            </div>
            <div class="list-sub-title">
              Custom
            </div>
            <div class="list-period" />
            <div style="display:flex;justify-content: center;align-content: center;padding-bottom:50px">
              <vs-button
                class="pricing-list-button"
                style="color:#0243EC !important;background: white !important;"
              >
                Contact sales
              </vs-button>
            </div>
            <vs-row
              v-if="!showCustomFeatures"
              class="show-features"
              @click="showCustomFeatures = true"
            >
              <vs-col
                v-if="!showCustomFeatures"
                vs-w="10"
              >
                Show Features
              </vs-col>
              <vs-col
                v-if="!showCustomFeatures"
                vs-w="2"
                style="padding-left:6%"
              >
                <img
                  src="../assets/svgs/app-icons/section-arrow.svg"
                  alt="section-arrow"
                  width="14"
                >
              </vs-col>
            </vs-row>
            <div
              v-for="basicFeature in customFeatures"
              :key="basicFeature['mainText']"
            >
              <div
                v-if="showCustomFeatures"
                class="feature-maintext"
              >
                {{ basicFeature['mainText'] }}
              </div>
              <div
                v-if="showCustomFeatures"
                class="feature-subtext"
              >
                {{ basicFeature['subText'] }}
              </div>
            </div>
            <vs-row
              v-if="showCustomFeatures"
              class="show-features"
              @click="showCustomFeatures = false"
            >
              <vs-col
                v-if="showCustomFeatures"
                vs-w="10"
              >
                Hide Features
              </vs-col>
              <vs-col
                v-if="showCustomFeatures"
                vs-w="2"
                style="padding-left:6%"
              >
                <img
                  src="../assets/svgs/app-icons/section-arrow.svg"
                  alt="section-arrow"
                  width="14"
                  class="hide-features-icon"
                >
              </vs-col>
            </vs-row>
          </vs-row>
        </vs-row>
      </vs-col>
    </vs-row>
    <vs-row class="clients-container">
      <div>
        <h4 class="clients-header-class">
          Trusted by leading CPG & Pharma companies
        </h4>
      </div>
      <div class="clients-logo-container">
        <div
          v-for="img in getClientLogos()"
          :key="img.id"
          class="client-logo"
        >
          <image-renderer
            :group="img"
            type="group"
          />
        </div>
      </div>
    </vs-row>
    <vs-row class="text-center">
      <div
        class="experience-banner"
        style="width: 100%"
      >
        <div
          class="experience-content "
        >
          Experience the power of Google-Like Search for your Ad-Hoc Queries on Explorazor
        </div>
        <div class="experience-button-container">
          <vs-button class="get-started-button">
            Get Started for free
          </vs-button>
        </div>
      </div>
    </vs-row>
    <vs-row
      class="text-center"
      style="flex-direction: column;padding-bottom:90px;"
    >
      <div class="leaders-content text-center">
        Work with a trusted industry leader
      </div>
      <div>
        <img
          src="../assets/svgs/card-image/leaders.svg"
          alt="leaders"
          class="leaders-image"
        >
      </div>
    </vs-row>
    <vs-row
      id="sales"
      class="text-center contact-sales-container"
    >
      <div class="contact-sales-header">
        Contact Sales for more info
      </div>
      <div class="contact-sales-subheader">
        Request a Price Quote
      </div>
      <div class="form-container">
        <form
          action=""
          class="form-class"
          @submit="handleFormSubmit"
        >
          <vs-row
            vs-w="12"
            vs-justify="center"
          >
            <vs-col
              :vs-w="screenWidth>768?3.5:12"
              :class="screenWidth<768?'column-class':''"
            >
              <div class="form-label">
                <label
                  for="name"
                >Name *</label>
              </div>
              <div>
                <input
                  id="name"
                  v-model="name"
                  type="text"
                  name="name"
                  class="form-input-class"
                  placeholder="Name"
                  required
                >
              </div>
            </vs-col>
            <vs-col
              :vs-w="screenWidth>768?3.5:12"
              :class="screenWidth<768?'column-class':''"
            >
              <div class="form-label">
                <label
                  for="email"
                >Business Email ID *</label>
              </div>
              <div>
                <input
                  id="email"
                  v-model="email"
                  type="text"
                  name="email"
                  placeholder="Business Email ID"
                  class="form-input-class"
                  required
                >
              </div>
            </vs-col>
            <vs-col
              :vs-w="screenWidth>768?3.5:12"
              :class="screenWidth<768?'column-class':''"
            >
              <div class="form-label">
                <label
                  for="message"
                >Message *</label>
              </div>
              <div>
                <input
                  id="message"
                  v-model="message"
                  type="text"
                  name="message"
                  placeholder="Enter your message"
                  class="form-input-class"
                  required
                >
              </div>
            </vs-col>
            <vs-col
              :vs-w="screenWidth>769?1.5:12"
            >
              <input
                type="submit"
                value="Submit"
                class="form-button"
              >
            </vs-col>
          </vs-row>
        </form>
      </div>
    </vs-row>
    <vs-row class="text-center frequently-asked-container">
      <div class="frequently-asked-header">
        Frequently Asked Questions
      </div>
      <div class="questions-container">
        <div
          v-for="(question,index) in questionsList"
          :key="question['header']"
          style="cursor:pointer"
        >
          <vs-row
            class="question-header"
            @click="handleCollapse(index)"
          >
            <vs-col :vs-w="screenWidth > 414 ? 11 : 10">
              {{ question['header'] }}
            </vs-col>
            <vs-col
              :vs-w="screenWidth > 414 ? 1 : 2"
              class="text-center"
            >
              <img
                src="../assets/svgs/app-icons/collapse-icon.svg"
                alt="collapse-icon"
                class="collapse-icon-class"
                :style="question['collapse'] ? {} : { rotate:'180deg' }"
              >
            </vs-col>
          </vs-row>
          <div
            v-if="!question['collapse']"
            class="question-content"
          >
            <div
              v-html="question['content']"
            />
          </div>
        </div>
      </div>
    </vs-row>
    <div
      v-if="showTitle"
      v-clickoutside="handleOutsideClick"
      :style="{'position':'absolute','left':`${titlePosition['pageX']}px`,'top':`${titlePosition['pageY']}px`}"
      style="background: white;padding-bottom:10px;padding-left:10px;padding-right:10px;color:black;
      z-index:999 !important;text-align:left;font-family:Poppins, sans-serif;font-size:12px;border-radius:4px"
    >
      <div
        style="display:flex;justify-content: end;align-content: end;font-weight:700;font-size:14px;cursor:pointer"
        @click="showTitle=false"
      >
        x
      </div>

      <div

        style="padding-bottom:5px"
      >
        <span>Priority 0: </span><span style="font-weight:700">8 hrs</span>
      </div>
      <div
        style="padding-bottom:5px"
      >
        <span>Priority 1: </span><span style="font-weight:700">1 day</span>
      </div>
      <div style="padding-bottom:5px">
        <span>Priority 2: </span><span style="font-weight:700">2 days</span>
      </div>
    </div>
  </vs-row>
</template>

<script>
  import '@/assets/scss/components/change.scss';
  import imageRenderer from '@/components/helper/ImageRenderer';
  import { VueRecaptcha } from 'vue-recaptcha';
  import { imageMixin } from '@/mixins/image';
  import AppTextRender from '@/components/wrapper/TextRender';
  import { gTagMixin } from '@/mixins/gTagMixin';

  export default {
    name: 'AppPricing',
    components: { imageRenderer },
    mixins: [imageMixin, gTagMixin],
    data () {
      return {
        showTitle: false,
        showFreeFeatures: false,
        showBasicFeatures: false,
        showProfessionalFeatures: false,
        showCustomFeatures: false,
        content: ['Single sign-on', 'Row and column level security',
          'Data encryption', 'Available on cloud and on-premise'],
        captchaKey: process.env.VUE_APP_CAPTCHA_KEY,
        message: '',
        screenWidth: null,
        name: '',
        email: '',
        buttonType: '',
        validateData: false,
        userInput: '',
        userEmail: '',
        isEmpty: '',
        isEmptyMessage: '',
        captchaMessage: '',
        formMessage: '',
        pricingContentList: [{
          mainText: 'Number of Users'
        }, {
          mainText: 'Number of queries'
        }, {
          mainText: 'Data size upload limit'
        }, {
          mainText: 'Number of data limit'
        }, {
          mainText: 'Support'
        }, {
          mainText: 'Support Response Time'
        }, {
          mainText: 'Data Downloads'
        }, {
          mainText: 'Data Security and Encryption'
        }, {
          mainText: 'Complete Overview Dashboards'
        }, {
          mainText: 'Connect & Combine Multiple Data Sources'
        }, {
          mainText: 'Pin charts & tables to your Dashboards'
        }, {
          mainText: 'Share and Collaborate'
        }, {
          mainText: 'Root Cause Analysis'
        }, {
          mainText: 'Security Controls'
        }, {
          mainText: 'Dedicated Account Manager'
        }, {
          mainText: 'Advanced Data Modeling services'
        }, {
          mainText: 'Calculated Fields for Customized Analytics'
        }, {
          mainText: 'Admin Panel for User Management'
        }, {
          mainText: 'Flexible deployment options'
        }, {
          mainText: 'Unlimited permission group'
        },],

        questionsList: [
          {
            header: 'How does Explorazor’s pricing work?',
            content: 'Explorazor’s Professional plan starts from $49 per user per month subscription.' +
              ' You can easily sign up for our Free Trial for 14 days and pay using a credit card.' +
              ' Once you subscribe, you will be billed $49/month until you cancel.',
            collapse: true
          },
          {
            header: 'Is there an option for annual subscription on Explorazor? Or can I customize my subscription?',
            // eslint-disable-next-line max-len
            content: '<span style="display:inline">We provide pricing as per enterprise requirements.</span><a href="https://www.vphrase.com/explorazor/pricing" style="color:#0243EC;display:inline" target="_blank"> Please contact our dedicated sales team </a> <span style="display: inline">to help with your customized/annual pricing.</span>',
            collapse: true
          },
          {
            header: 'Can I cancel my free trial mid-way?',
            content: 'Enjoy a risk-free trial of Explorazor’s professional version for 14 days.' +
              ' If you choose not to continue, you’ll automatically be switched to our free version.',
            collapse: true
          },
          {
            header: 'Can I invite my team to my account?',
            content: 'The team feature enables users to share their reports and dashboards,' +
              ' as well as collaborate with others on their project.',
            collapse: true
          },
          {
            header: 'Have another question?',
            content: '<span style="display:inline"> Contact us at ' +
              '<a href="mailto:support@vphrase.com" style="color:#0243EC;display:inline;white-space: pre;">' +
              'support@vphrase.com</a></span> ' +
              'and tell us what you need and' +
              ' we can help you find the right solution.',
            collapse: true
          }
        ],
        freeFeatures: [
          {
            'mainText': 'Number of Users',
            'subText': '1'
          }, {
            'mainText': 'Number of queries',
            'subText': 'Unlimited'
          }, {
            'mainText': 'Data size upload limit',
            'subText': '100K'
          }, {
            'mainText': 'Number of data limit',
            'subText': '5'
          }, {
            'mainText': 'Support',
            'subText': 'Community Support'
          }, {
            'mainText': 'Support Response Time',
            'subText': '5-7 days'
          }, {
            'mainText': 'Support Response Time',
            'subText': '5-7 days'
          }, {
            'mainText': 'Data Downloads',
            'subText': ''
          }, {
            'mainText': 'Data Security and Encryption',
            'subText': ''
          }, {
            'mainText': 'Complete Overview Dashboards',
            'subText': ''
          }, {
            'mainText': 'Connect & Combine Multiple Data Sources',
            'subText': ''
          }, {
            'mainText': 'Pin charts & tables to your Dashboards',
            'subText': ''
          }, {
            'mainText': 'Share and Collaborate',
            'subText': ''
          }, {
            'mainText': 'Root Cause Analysis',
            'subText': ''
          },
        ],
        basicFeatures: [
          {
            'mainText': 'Number of Users',
            'subText': '1'
          }, {
            'mainText': 'Number of queries',
            'subText': 'Unlimited'
          }, {
            'mainText': 'Data size upload limit',
            'subText': '100K'
          }, {
            'mainText': 'Number of data limit',
            'subText': '5'
          }, {
            'mainText': 'Support',
            'subText': 'Community Support'
          }, {
            'mainText': 'Support Response Time',
            'subText': '5-7 days'
          }, {
            'mainText': 'Support Response Time',
            'subText': '5-7 days'
          }, {
            'mainText': 'Data Downloads',
            'subText': ''
          }, {
            'mainText': 'Data Security and Encryption',
            'subText': ''
          }, {
            'mainText': 'Complete Overview Dashboards',
            'subText': ''
          }, {
            'mainText': 'Connect & Combine Multiple Data Sources',
            'subText': ''
          }, {
            'mainText': 'Pin charts & tables to your Dashboards',
            'subText': ''
          }, {
            'mainText': 'Share and Collaborate',
            'subText': ''
          }, {
            'mainText': 'Root Cause Analysis',
            'subText': ''
          },
        ],
        professionalFeatures: [
          {
            'mainText': 'Number of Users',
            'subText': 'Unlimited '
          }, {
            'mainText': 'Number of queries',
            'subText': '25,000 queries'
          }, {
            'mainText': 'Data size upload limit',
            'subText': '500 million rows of data'
          }, {
            'mainText': 'Number of data limit',
            'subText': 'Unlimited'
          }, {
            'mainText': 'Support',
            'subText': 'Customer Support'
          }, {
            'mainText': 'Support Response Time',
            'subText': '8 hrs - 2 days'
          }, {
            'mainText': 'Support Response Time',
            'subText': '5-7 days'
          }, {
            'mainText': 'Data Downloads',
            'subText': ''
          }, {
            'mainText': 'Data Security and Encryption',
            'subText': ''
          }, {
            'mainText': 'Complete Overview Dashboards',
            'subText': ''
          }, {
            'mainText': 'Connect & Combine Multiple Data Sources',
            'subText': ''
          }, {
            'mainText': 'Pin charts & tables to your Dashboards',
            'subText': ''
          }, {
            'mainText': 'Share and Collaborate',
            'subText': ''
          }, {
            'mainText': 'Root Cause Analysis',
            'subText': ''
          }, {
            'mainText': 'Security Controls',
            'subText': 'Standard'
          }, {
            'mainText': 'Dedicated Account Manager',
            'subText': ''
          },
        ],
        customFeatures: [
          {
            'mainText': 'Number of Users',
            'subText': 'Unlimited '
          }, {
            'mainText': 'Number of queries',
            'subText': '25,000 queries'
          }, {
            'mainText': 'Data size upload limit',
            'subText': '500 million rows of data'
          }, {
            'mainText': 'Number of data limit',
            'subText': 'Unlimited'
          }, {
            'mainText': 'Support',
            'subText': 'Customer Support'
          }, {
            'mainText': 'Support Response Time',
            'subText': '8 hrs - 2 days'
          }, {
            'mainText': 'Support Response Time',
            'subText': '5-7 days'
          }, {
            'mainText': 'Data Downloads',
            'subText': ''
          }, {
            'mainText': 'Data Security and Encryption',
            'subText': ''
          }, {
            'mainText': 'Complete Overview Dashboards',
            'subText': ''
          }, {
            'mainText': 'Connect & Combine Multiple Data Sources',
            'subText': ''
          }, {
            'mainText': 'Pin charts & tables to your Dashboards',
            'subText': ''
          }, {
            'mainText': 'Share and Collaborate',
            'subText': ''
          }, {
            'mainText': 'Root Cause Analysis',
            'subText': ''
          }, {
            'mainText': 'Security Controls',
            'subText': 'Enterprise'
          }, {
            'mainText': 'Dedicated Account Manager',
            'subText': ''
          }, {
            'mainText': 'Advanced Data Modeling services',
            'subText': ''
          }, {
            'mainText': 'Calculated Fields for Customized Analytics',
            'subText': ''
          }, {
            'mainText': 'Admin Panel for User Management',
            'subText': ''
          }, {
            'mainText': 'Flexible deployment options',
            'subText': ''
          }, {
            'mainText': 'Unlimited permission group',
            'subText': ''
          },
        ],
        mainPricingList: [{
          'main_title': '',
          columnData: [{
            mainText: 'Number of Users'
          }, {
            mainText: 'Number of queries'
          }, {
            mainText: 'Data size upload limit'
          }, {
            mainText: 'Number of data limit'
          }, {
            mainText: 'Support'
          }, {
            mainText: 'Support Response Time'
          }, {
            mainText: 'Data Downloads'
          }, {
            mainText: 'Data Security and Encryption'
          }, {
            mainText: 'Complete Overview Dashboards'
          }, {
            mainText: 'Connect & Combine Multiple Data Sources'
          }, {
            mainText: 'Pin charts & tables to your Dashboards'
          }, {
            mainText: 'Share and Collaborate'
          }, {
            mainText: 'Root Cause Analysis'
          }, {
            mainText: 'Security Controls'
          }, {
            mainText: 'Dedicated Account Manager'
          }, {
            mainText: 'Advanced Data Modeling services'
          }, {
            mainText: 'Calculated Fields for Customized Analytics'
          }, {
            mainText: 'Admin Panel for User Management'
          }, {
            mainText: 'Flexible deployment options'
          }, {
            mainText: 'Unlimited permission group'
          },],
        },
          {
            'main_title': 'Free',
            columnData: [{
              'mainText': '1',
              'tick': 'check'
            },
              {
                'mainText': 'Unlimited',
                'tick': 'check'
              },
              {
                'mainText': '100K rows',
                'tick': 'check'
              },
              {
                'mainText': '5',
                'tick': 'check'
              },
              {
                'mainText': 'Community Support',
                'tick': 'check'
              },
              {
                'mainText': '5-7 days',
                'tick': 'check'
              },
              {
                'mainText': '',
                'tick': 'check'
              },
              {
                'mainText': '',
                'tick': 'check'
              },
              {
                'mainText': '',
                'tick': 'cross'
              },
              {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'cross'
              }, {
                'mainText': '',
                'tick': 'cross'
              }, {
                'mainText': '',
                'tick': 'cross'
              }, {
                'mainText': '',
                'tick': 'cross'
              }, {
                'mainText': '',
                'tick': 'cross'
              }, {
                'mainText': '',
                'tick': 'cross'
              }, {
                'mainText': '',
                'tick': 'cross'
              },
            ],
          }, {
            'main_title': 'Basic',
            columnData: [{
              'mainText': '1',
              'tick': 'check'
            },
              {
                'mainText': 'Unlimited',
                'tick': 'check'
              },
              {
                'mainText': '5 million rows of data',
                'tick': 'check'
              },
              {
                'mainText': 'Unlimited',
                'tick': 'check'
              },
              {
                'mainText': 'Community Support',
                'tick': 'check'
              },
              {
                'mainText': '5-7 days',
                'tick': 'check'
              },
              {
                'mainText': '',
                'tick': 'check'
              },
              {
                'mainText': '',
                'tick': 'check'
              },
              {
                'mainText': '',
                'tick': 'cross'
              },
              {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': 'Standard',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'cross'
              }, {
                'mainText': '',
                'tick': 'cross'
              }, {
                'mainText': '',
                'tick': 'cross'
              }, {
                'mainText': '',
                'tick': 'cross'
              }, {
                'mainText': '',
                'tick': 'cross'
              }, {
                'mainText': '',
                'tick': 'cross'
              },
            ],
          }, {
            'main_title': 'Professional',
            columnData: [{
              'mainText': 'Unlimited',
              'tick': 'check'
            },
              {
                'mainText': '25000 queries',
                'tick': 'check'
              },
              {
                'mainText': '500 million rows of data',
                'tick': 'check'
              },
              {
                'mainText': 'Unlimited',
                'tick': 'check'
              },
              {
                'mainText': 'Customer Support',
                'tick': 'check'
              },
              {
                'mainText': '8 hrs - 2 days',
                'tick': 'check'
              },
              {
                'mainText': '',
                'tick': 'check'
              },
              {
                'mainText': '',
                'tick': 'check'
              },
              {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': 'Standard',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'cross'
              }, {
                'mainText': '',
                'tick': 'cross'
              }, {
                'mainText': '',
                'tick': 'cross'
              }, {
                'mainText': 'On request (Billed seperately)',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'cross'
              },
            ],
          }, {
            'main_title': 'Enterprise',
            columnData: [{
              'mainText': 'Unlimited',
              'tick': 'check'
            },
              {
                'mainText': '25000 queries',
                'tick': 'check'
              },
              {
                'mainText': '500 million rows of data',
                'tick': 'check'
              },
              {
                'mainText': 'Unlimited',
                'tick': 'check'
              },
              {
                'mainText': 'Customer Support',
                'tick': 'check'
              },
              {
                'mainText': '8 hrs - 2 days',
                'tick': 'check'
              },
              {
                'mainText': '',
                'tick': 'check'
              },
              {
                'mainText': '',
                'tick': 'check'
              },
              {
                'mainText': '',
                'tick': 'check'
              },
              {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': 'Enterprise',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              }, {
                'mainText': '',
                'tick': 'check'
              },
            ],
          }
        ],
        titlePosition: {},
        showBanner: true,
      };
    },
    computed: {
      enableDemoButton: function () {
        let show = false;
        if (this.userEmail.length && this.userInput.length && this.validateData) {
          show = true;
        }
        return show;
      }
    },
    beforeMount () {
      document.title = 'Affordable Pricing Plans for Explorazor';
      document.querySelector('meta[name="description"]').setAttribute('content', 'Explorazor' +
        ' professional pricing plan starts from $49 per user. Through our search and AI driven analytics,' +
        ' analysts can create personalized and actionable insights from your cloud');
    },
    mounted () {
      this.updatePageView();
      this.screenWidth = screen.availWidth;
    },
    methods: {
      handleTour: function () {
        window.open('https://app.explorazor.com/#/playground', '_blank');
      },
      closeBanner: function () {
        this.showBanner = false;
      },
      handleSignup: function () {
        window.open('https://app.explorazor.com/#/register', '_blank');
      },
      handleOutsideClick: function () {
        console.log('outside click');
        this.showTitle = false;
      },
      titleClick: function (event) {
        console.log(event);
        this.titlePosition['pageX'] = event['pageX'];
        this.titlePosition['pageY'] = event['pageY'];
        this.showTitle = true;
      },
      handleCollapse: function (questionIndex) {
        this.questionsList[questionIndex]['collapse'] = ! this.questionsList[questionIndex]['collapse'];
      },
      onCaptchaExpired: function () {
        this.validateData = false;
      },
      validate: function () {
        this.validateData = true;
      },
      handleFormSubmit: function () {
        // sendy subscription call code start
        let link = location.href;
        let utmSource = link.includes('utm_source=')
          ? link.substring(link.indexOf('utm_source=') + 11, link.lastIndexOf('&utm_medium'))
          : '';
        let utmMedium = link.includes('utm_medium=')
          ? link.substring(link.indexOf('utm_medium=') + 11, link.lastIndexOf('&utm_campaign')) : '';
        let utmCampaign = link.includes('utm_campaign=')
          ? link.substring(link.indexOf('utm_campaign=') + 13)
          : '';
        const params = {
          api_key: process.env.VUE_APP_SENDY_API_KEY,
          name: this.name,
          email: this.email,
          message: this.message,
          boolean: true,
          list: process.env.VUE_APP_SENDY_LIST_KEY,
          Lead_Source_1: 'Website',
          Lead_Source_2: 'Meeting',
          Lead_Source_3: 'Pricing',
          utm_source: utmSource.length ? utmSource : '',
          utm_medium: utmMedium.length ? utmMedium : '',
          utm_campaign_name: utmCampaign.length ? utmCampaign : ''
        };
        let formBody = [];
        for (let property in params) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(params[property]);
          formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');
        const xhr = new XMLHttpRequest();
        xhr.open('POST',
          'https://email.pcube.net/subscribe', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Headers',
          'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
          'Access-Control-Allow-Methods', 'OPTIONS,POST',
          'Access-Control-Allow-Credentials', true,
          'Access-Control-Allow-Origin', '*',
          'X-Requested-With', '*',);
        xhr.send(formBody);
        // sendy subscription call code end
        // hubspot form api call code start
        const xhrHubspot = new XMLHttpRequest();
        // eslint-disable-next-line max-len
        const url = `https://api.hsforms.com/submissions/v3/integration/submit/${ process.env.VUE_APP_HUBSPOT_PORTAL_ID }/${ process.env.VUE_APP_HUBSPOT_FORM_ID }`;

        //  request JSON:
        let data = {
          'fields': [
            {
              'name': 'email',
              'value': this.userEmail
            },
            {
              'name': 'firstname',
              'value': this.userInput
            },
            {
              'name': 'message',
              'value': this.formMessage
            },
            {
              'name': 'ga_source',
              'value': 'Website'
            },
            {
              'name': 'ga_medium',
              'value': 'Meeting'
            },
            {
              'name': 'ga_campaign',
              'value': 'Pricing'
            }
          ],

        };

        let final_data = JSON.stringify(data);

        xhrHubspot.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhrHubspot.setRequestHeader('Content-Type', 'application/json');

        // Sends the request

        xhrHubspot.send(final_data);
        // hubspot form api call code end
      },
    },
  };
</script>

<style>
  .text-white:hover {
    color: white !important
  }

  .basic-button {
    width: 80%;
    height: 40px;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    color: #FFFFFF;
  }

  .contact-sales-header {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    color: #191948;
    padding-bottom: 10px;
    padding-top: 60px;
  }

  .pricing-list-button {
    color: #0243EC !important;
    background-color: #FFFFFF !important;
    width: 80%;
    height: 40px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    border-radius: 4px;
    /* identical to box height */
  }

  .pricing-list-button:hover {
    color: #ffffff !important;
  }

  .contact-sales-subheader {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */
    text-align: center;
    color: #6A7591;
    padding-bottom: 80px;
  }

  .contact-sales-container {
    flex-direction: column;
    background: #FAFAFA;
  }

  .pricing-class {
    display: flex;
    justify-content: center !important;
    align-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
  }

  .text-center {
    text-align: center !important;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .pricing-title {
    color: #0243EC;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    padding-top: 100px;
  }

  .pricing-subheader {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 62px;
    line-height: 76px;
    text-align: center;
    color: #191948;
    width: 50% !important;
    padding-bottom: 25px;
  }

  .pricing-subheader-child {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */
    text-align: center;
    color: #6A7591;
    width: 40% !important;
    padding-bottom: 100px;
  }

  .get-started-button {
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 17px 28px !important;
    margin-bottom: 50px;
  }

  .pricing-banner {
    background: #E6EEFF;
    width: 55% !important;
    height: 100px;
    border-radius: 12px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: row !important;
    justify-content: space-evenly !important;
  }

  .banner-content {
    display: flex;
    flex-direction: row !important;
    padding-top: 40px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    color: #191948;
    justify-content: space-evenly !important;
    width: 100% !important;
  }

  .banner-subcontent {
  //margin-left:100px;
  }

  .tick-class {
    margin-right: 15px;
  }

  .pricing-sheet-container {
    background: #191948;
  }

  .pricing-sheet-title {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .pricing-sheet-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    width: 35% !important;
    padding-top: 25px;
    padding-bottom: 60px;
  }

  .pricing-sheet-button {
    padding: 10px 15px;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
  }

  .pricing-sheet-button:hover {
    color: #FFFFFF !important;
  }

  .pricing-sheet {
    flex-direction: column;
    color: white;
    border: 1px solid white;
    border-radius: 20px;
    width: 380px
  }

  .sheet-header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */
    text-align: center;
    color: #ABC9FD;
    padding-top: 55px;
  }

  .sheet-subheader {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 62px;
    line-height: 76px;
    text-align: center;
    color: #FFFFFF;
    padding-bottom: 60px;
  }

  .form-input-class {
    border: 1px solid #E2E1E5;
  }

  .sheets-container {
    display: flex;
    flex-direction: row !important;
    justify-content: space-evenly !important;
    margin-bottom: 50px;
    padding: 0 15%;
  }

  .sheet-button-container {
    display: flex;
    justify-content: center;
    align-content: center;
    padding-bottom: 40px;
  }

  .list-period {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    padding-bottom: 25px;
    text-align: center;
    color: #6A7591;
  }

  .professional-pricing-sheet {
    flex-direction: column;
    color: white;
    border-left: 1px solid #F28E2B;
    border-right: 1px solid #F28E2B;
    border-bottom: 1px solid #F28E2B;
    border-top: 10px solid #F28E2B;
    border-radius: 20px;
    width: 380px
  }

  .list-main-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #FFFFFF;
  }

  .list-sub-title {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 5px;
    color: #FFFFFF;
  }

  .list-sub-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #6A7591;
    padding-left: 5px
  }

  .experience-banner {
    background-image: url('../assets/svgs/card-image/pricing-experiece-background.svg');
    height: auto !important;
    width: 100% !important;
    flex-direction: column;
  }

  .experience-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    color: #FFFFFF;
    width: 100%;
    padding-top: 65px;
    padding-bottom: 35px;
    padding-left: 30%;
    padding-right: 30%;
  }

  .experience-button-container {
    padding-bottom: 20px;
  }

  .leaders-content {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    color: #191948;
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .professional-sheet-subheader {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 62px;
    line-height: 76px;
    text-align: center;
    color: #FFFFFF;
  }

  .professional-subheader {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    text-align: center;
    color: #6A7591;
    padding-bottom: 20px;
  }

  .frequently-asked-header {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    color: #191948;
    padding-top: 90px;
    padding-bottom: 60px;
  }

  .frequently-asked-container {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column !important;
    margin-bottom: 100px;
  }

  .questions-container {
    width: 70%;
    border: 1px solid #E2E1E5;
    border-radius: 12px;
    text-align: left
  }

  .question-header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */
    color: #191948;
    padding-left: 50px;
    padding-top: 35px;
    padding-bottom: 35px;
    border-bottom: 1px solid #E2E1E5;
    border-top: 1px solid #E2E1E5;
  }

  .question-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    /* or 27px */
    color: #191948;
    padding-left: 50px;
    padding-right: 90px;
    padding-top: 35px;
    padding-bottom: 35px;
    transition: 0.2s ease-in;
    display: flex;
  }

  .collapse-icon-class {
    width: 17px;
    cursor: pointer;
  }


  .pricing-list-header {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #FFFFFF;
    padding-top: 60px;
    padding-bottom: 35px;
  }

  .pricing-table {
    border: 1px solid #4D6EC1;
    border-collapse: collapse;
    border-radius: 12px;
  }

  .pricing-table-header {
    border-bottom: 1px solid #4D6EC1 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #ABC9FD;
  //border-bottom: 1px solid white !important; margin: 0; padding: 0
  }

  .table-body {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #FFFFFF;
  }

  .table-body, td {
    padding-top: 15px;
    padding-left: 50px;
  }

  .table-body {
    margin-bottom: 15px !important;
  }


  .pricing-tables-container {
    padding: 0 5%;
    margin-bottom: 70px;
  }

  .form-container {
    width: 70%
  }

  .last-cell {
    padding-bottom: 15px;
  }

  @media only screen and (max-width: 1366px) {
    .pricing-subheader {
      width: 70% !important
    }

    .pricing-subheader-child {
      width: 60% !important;
    }

    .pricing-sheet-content {
      width: 50% !important;
    }

    .sheets-container {
      padding: 0 5% !important;
    }

    .pricing-list-container {
      width: 80% !important
    }

    .experience-content {
      padding-left: 15% !important;
      padding-right: 15% !important
    }

    .questions-container {
      width: 80% !important;
    }

    .form-container {
      width: 80% !important;
    }
  }

  @media only screen and (max-width: 820px) {
    .pricing-list-header {
      padding-left: 10% !important;
      padding-right: 10% !important
    }


    .pricing-subheader {
      width: 90% !important;
      font-family: 'Fraunces';
      font-style: normal;
      font-weight: 600;
      font-size: 42px;
      line-height: 52px;
      text-align: center;
      color: #191948;
    }

    .pricing-subheader-child {
      width: 70% !important;
    }

    .pricing-sheet {
      margin-bottom: 30px !important
    }

    .professional-pricing-sheet {
      margin-bottom: 30px !important;
    }

    .experience-content {
      padding-left: 10% !important;
      padding-right: 10% !important;
    }

    .leaders-content {
      width: 70% !important;
    }

    .form-container {
      width: 85% !important
    }

    .pricing-list-container {
      width: 90% !important;
    }

  }

  @media only screen and (max-width: 414px) {
    .pricing-list-header {
      font-family: 'Fraunces';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
      text-align: center;
      color: #FFFFFF;
      padding-left: 5% !important;
      padding-right: 5% !important;
    }

    .contact-sales-subheader {
      padding-bottom: 30px !important
    }

    .pricing-subheader-child {
      ont-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      /* or 162% */
      text-align: center;
      color: #6A7591;
      width: 90% !important
    }

    .pricing-sheet-content {
      width: 90% !important
    }

    .form-container {
      width: 100% !important;
    }

    .frequently-asked-header {
      font-family: 'Fraunces';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
      text-align: center;
      color: #191948;
    }

    .question-header {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      /* or 162% */
      color: #191948;
      padding-left: 20px !important
    }

    .questions-container {
      width: 95% !important
    }

    .question-content {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      /* or 171% */
      color: #191948;
      padding-right: 30px !important;
      padding-left: 20px !important
    }

    .leaders-content {
      width: 105% !important;
      font-family: 'Fraunces';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
      text-align: center;
      color: #191948;
    }

    .leaders-image {
      width: 85% !important
    }

    .contact-sales-header {
      font-family: 'Fraunces';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
      text-align: center;
      color: #191948;
      padding: 30px 10%
    }

    .list-main-title {
      padding-right: 30px !important;
    }
  }

  @media only screen and (max-width: 1440px) {

  }

  .first-box {
    border: 1px solid #4D6EC1;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .second-box {
    border-right: 1px solid #4D6EC1;
    border-top: 1px solid #4D6EC1;
    border-bottom: 1px solid #4D6EC1;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .mid-box {
    border-right: 1px solid #4D6EC1;
    border-top: 1px solid #4D6EC1;
    border-bottom: 1px solid #4D6EC1;
  }

  .list-main-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    text-align: center;
    color: #ABC9FD;
    text-align: center;
    padding-top: 24px;
  }

  .list-main-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #FFFFFF;
    padding-bottom: 15px;
    padding-top: 15px;
    padding-left: 25px;
    border-bottom: 1px solid #4D6EC1;

  }

  .list-content-container {
  //padding-top: 25px; //padding-bottom: 25px;
  }


  @media only screen and (max-width: 760px) {
    .list-main-title {
      padding-left: 30px !important;
    }

    .list-main-content {
      padding-left: 30px !important;
    }

    .pricing-tables-container {
      padding: 0 5% !important;
      width: 100% !important;
    }
  }

  @media only screen and (max-width: 1670px) {
    .sheets-container {
      padding: 0 !important;
    }
  }

  .feature-maintext {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    color: #FFFFFF;
    text-align: left;
    padding-left: 5%
  }

  .feature-subtext {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #828AA0;
    text-align: left;
    padding-top: 5px;
    padding-left: 5% !important;
  }

  .mobile-list {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    border: 1px solid #6A7591;
    margin-left: 25% !important;
    margin-right: 25% !important;
    border-radius: 14px
  }

  .mobile-bottom-list {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    border: 1px solid #6A7591;
    margin-left: 25% !important;
    margin-right: 25% !important;
    border-radius: 14px;
    margin-top: 30px;
  }

  .show-features {
    border-top: 1px solid #6A7591;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */
    color: #FFFFFF;
    text-align: left;
    padding-left: 5%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media only screen and (max-width: 414px) {
    .mobile-list {
      margin-left: 8% !important;
      margin-right: 8% !important
    }

    .mobile-bottom-list {
      margin-left: 8% !important;
      margin-right: 8% !important
    }

    .feature-maintext {
      font-size: 14px !important
    }

    .feature-subtext {
      font-size: 13px !important
    }
  }
</style>

<style scoped>
  @media only screen and (max-width: 820px) {
    .list-main-title {
      width: 100%;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      /* identical to box height */
      text-align: center;
      color: #ABC9FD;
    }

    .list-sub-title {
      font-family: 'Fraunces';
      font-style: normal;
      font-weight: 600;
      font-size: 42px;
      line-height: 52px;
      /* identical to box height */
      text-align: center;
      color: #FFFFFF;
    }

    .list-period {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      /* identical to box height, or 186% */
      text-align: center;
      color: #6A7591;
    }

    .pricing-list-button {
      font-weight: 600;
      font-size: 16px;
      background: #0243EC !important;
      color: white !important;
      padding: 10px 25px !important;
      width: 90% !important
    }
  }

  table {
    border-collapse: separate;
    border-radius: 6px;
    border-spacing: 0;
    width: 90% !important;
  }

  td {
    text-align: left;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px
  }

  td:nth-child(1), td:nth-child(2), td:nth-child(3), td:nth-child(4) {
    border-right: 1px solid #6A7591;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #FFFFFF;
  }

  td:nth-child(5) {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #FFFFFF;
  }

  th:nth-child(1), th:nth-child(2), th:nth-child(3), th:nth-child(4) {
    border-right: 1px solid #6A7591
  }

  tr {
    outline: 1px solid #6A7591;
  }

  tr:first-child {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  tr:first-child:hover {
    background: #191948 !important
  }

  tr:hover {
    background: #0C0C39 !important;
  }

  tr:last-child {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  td:first-child {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #FFFFFF;
  }

  .hide-features-icon {
    rotate: 180deg;
  }

  .basic-button:hover {
    color: white !important
  }
</style>