<template>
  <vs-row id="app">
    <app-nav-bar />
    <router-view />
    <br>
    <app-footer />
  </vs-row>
</template>

<script>
  import appNavBar from '@/layout/NavBar';
  import appFooter from '@/layout/AppFooter';

  export default {
    name: 'App',
    components: {
      appNavBar,
      appFooter,
    },
    beforeMount () {
      // As now we have removed hash, this is written to replace hash route with normal route
      const location = window.location;
      if( location.hash ){
        window.location.href = location.href.replace('#/', '');
      }
    },
  };
</script>
<style scoped>
  #app {
    font-style: unset;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
  }

  ul li {
    margin-top: 5px;
    margin-left: 30px;
    padding: 5px;
  }
</style>
