<template>
  <vs-row style="overflow:hidden">
    <div
      class="main-content-container"
    >
      <div class="main-content">
        <div
          class="main-sub-content"
        >
          <h2 class="main-header">
            Harmonize CPG data, power Insights
          </h2>
          <p class="main-para">
            With deep CPG expertise, we harmonize all your data and serve it on our data exploration tool Explorazor for
            you to arrive at the root-cause of issues and identify opportunities to increase market share.
          </p>
          <div class="demo-button">
            <span><vs-button
              class="request-button"
              style="font-weight:600"
              @click="handleRegister"
            >
              Get Started For Free
            </vs-button></span>
            <span><vs-button
              class="request-tour-button"
              style="font-weight:600"
              color="white"
              @click.native="handleTour"
            >
              Take Product Tour
            </vs-button></span>
          </div>
        </div>
      </div>
      <div class="main-image">
        <img
          src="../assets/svgs/clients/hero-image.svg"
          alt="RCA"
          class="main-image-class"
        >
        <div class="watch-demo-overlay">
          <a
            href="https://www.youtube.com/watch?v=F7QeNf9udgU"
            target="_blank"
          >
            <div class="watch-demo-btn">
              <div class="watch-demo-btn-text">
                Watch a Quick Demo
              </div>
              <app-icon
                icon="app-icons/forword"
                width="6"
                color="#ffffff"
                class="icon"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
    <vs-row>
      <div class="strategy-class">
        <div class="sub-strategy-class">
          Know your market performance vs category and which are the opportunity regions for sales
        </div>
        <div class="sub-strategy-class">
          Know how the performance is trailing for your top distributors
        </div>
        <div class="sub-strategy-class">
          Is your pricing strategy correct as per your brand's adoption by the users?
        </div>
        <div class="sub-strategy-class">
          Where do you stand against your competitors and what is the benchmark that you need to achieve?
        </div>
      </div>
    </vs-row>
    <vs-row class="clients-container">
      <div>
        <h4 class="clients-header-class">
          Trusted by leading CPG & Pharma companies
        </h4>
      </div>
      <div class="clients-logo-container">
        <div
          v-for="img in getClientLogos()"
          :key="img.id"
          class="client-logo"
        >
          <image-renderer
            :group="img"
            type="group"
          />
        </div>
      </div>
    </vs-row>
    <vs-row style="width:100% !important;">
      <data-platform />
    </vs-row>
    <vs-row>
      <decision-makers />
    </vs-row>
    <vs-row class="case-study-wrapper">
      <div
        class="case-study-container"
        :class="showForm?'border-radius-class':''"
        :style="!showForm?{'border-radius':'12px'}:{}"
      >
        <div class="case-study-title">
          Case Study
        </div>
        <div class="case-study-main-content">
          <div class="case-study-content-container">
            <!--            <div class="want-to-know">-->
            <!--              Want to know...-->
            <!--            </div>-->
            <div class="want-to-know-content">
              How Explorazor Helped <span class="mid-para">
                Danone India Ltd.
              </span> Revolutionize Data Analysis
            </div>
            <div v-if="!showForm">
              <vs-button
                class="case-study-button"
                @click.native="downloadCaseStudy"
              >
                <a
                  href="#download-whitepaper"
                  style="color:white !important;justify-content: center"
                >
                  Download Case Study
                </a>
              </vs-button>
            </div>
          </div>
          <div
            id="download-whitepaper"
            class="case-study-image-container"
          >
            <img
              src="../assets/svgs/card-image/CaseStudy.svg"
              alt="case-study"
              class="case-study-image"
            >
          </div>
        </div>
      </div>
    </vs-row>
    <vs-row
      v-if="showForm"
      style="display:flex;justify-content: center;align-content: center"
    >
      <div
        id="whitepaper-download"
        style="width:80%"
      >
        <div
          class="whitepaper-form-container"
          :class="showForm?'form-radius':''"
        >
          <div class="form-title">
            Download Case Study
          </div>
          <div class="form-container">
            <form
              action=""
              class="form-class"
              @submit="handleFormSubmit"
            >
              <vs-row
                vs-w="12"
                vs-justify="center"
              >
                <vs-col
                  :vs-w="screenWidth>768?3.5:12"
                  :class="screenWidth<768?'column-class':''"
                >
                  <div class="form-label">
                    <label
                      for="name"
                    >Name *</label>
                  </div>
                  <div>
                    <input
                      id="name"
                      v-model="name"
                      type="text"
                      name="name"
                      class="form-input-class"
                      placeholder="Name"
                      required
                    >
                  </div>
                </vs-col>
                <vs-col
                  :vs-w="screenWidth>768?3.5:12"
                  :class="screenWidth<768?'column-class':''"
                >
                  <div class="form-label">
                    <label
                      for="email"
                    >Business Email ID *</label>
                  </div>
                  <div>
                    <input
                      id="email"
                      v-model="email"
                      type="text"
                      name="email"
                      placeholder="Business Email ID"
                      class="form-input-class"
                      required
                    >
                  </div>
                </vs-col>
                <vs-col
                  :vs-w="screenWidth>769?1.5:12"
                >
                  <input
                    type="submit"
                    value="Submit"
                    class="form-button"
                  >
                </vs-col>
              </vs-row>
            </form>
          </div>
        </div>
      </div>
    </vs-row>
    <vs-row style="width:100% !important;">
      <explorazor-helps />
    </vs-row>
    <vs-row
      class="usecase-container"
    >
      <div
        class="brand-manager-usecase"
      >
        <div
          class="usecase-content"
        >
          Brand Managers Love Explorazor
        </div>
        <div
          class="usecase-button"
        >
          <vs-button
            class="usecase-button-class"
            @click.native="routeTo({name:'UseCase'})"
          >
            Explore Use-cases
          </vs-button>
        </div>
      </div>
    </vs-row>
    <!--    <vs-row class="content-enterprises banner-container">-->
    <!--      <h2>-->
    <!--        Built for large enterprises-->
    <!--      </h2>-->
    <!--      <vs-row-->
    <!--        class="image-container-enterprises"-->
    <!--        vs-justify="center"-->
    <!--      >-->
    <!--        <vs-col>-->
    <!--          <image-renderer-->
    <!--            :cards="getEnterpriseFeatureImages()"-->
    <!--            :content="content"-->
    <!--            type="card"-->
    <!--          />-->
    <!--        </vs-col>-->
    <!--      </vs-row>-->
    <!--    </vs-row>-->
    <vs-row style="display: flex;justify-content: center;align-items:center;">
      <div class="partners-container">
        <h2 class="partners-header">
          Partners
        </h2>
        <vs-row
          style="width:100%; justify-content: center"
        >
          <div
            v-for="img in getPartnerLogos()"
            :key="img.id"
            class="partner-images"
          >
            <img
              :alt="img"
              :src="getImage(img)"
              class="partner-image"
            >
          </div>
        </vs-row>
      </div>
    </vs-row>
    <vs-row
      class="loved-by-container"
    >
      <div class="loved-by-wrapper">
        <div
          class="loved-by-content"
        >
          Loved by users around the world
        </div>
        <div class="loved-by-image-container">
          <img
            src="../assets/svgs/clients/user-love-us.svg"
            alt="users-love-us"
            class="loved-by-image"
          >
        </div>
      </div>
    </vs-row>

    <!--    <vs-row class="market-container banner-container">-->
    <!--      <div class="market-sub-content">-->
    <!--        <h2 class="patents-header">-->
    <!--          Our Patents-->
    <!--        </h2>-->
    <!--      </div>-->
    <!--      <div class="market-research-img">-->
    <!--        <div class="market-container-paragraph-1">-->
    <!--          <app-text-render-->
    <!--            bold-content="Patent 1"-->
    <!--            sub-content=" - System and Method for generating Dynamic Visualizations and Narrations
   of Digital Data"-->
    <!--          />-->
    <!--        </div>-->
    <!--        <image-renderer-->
    <!--          client="our-patents"-->
    <!--          type="client"-->
    <!--        />-->
    <!--        <div class="market-container-paragraph-2">-->
    <!--          <app-text-render-->
    <!--            bold-content="Patent 2"-->
    <!--            sub-content=" - System and Method for explaining actions taken in real-time on Event Stream
   using NLG"-->
    <!--          />-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </vs-row>-->
    <vs-row style="display: flex;justify-content: center;align-items:center;margin-top:100px;flex-direction: row">
      <div class="partners-container">
        <h2 class="partners-header">
          Featured In
        </h2>
        <vs-row
          style="width:100%;justify-content: center"
          vs-justify="center"
        >
          <div
            v-for="png in featuredIn()"
            :key="png.id"
            class="featured-images"
          >
            <image-renderer
              :group="png"
              type="group"
            />
          </div>
        </vs-row>
      </div>
    </vs-row>

    <!--    <div-->
    <!--      id="help-resolve"-->
    <!--      class="use-cases-container banner-container-read-about"-->
    <!--    >-->
    <!--      <div class="application-text-container">-->
    <!--        <h2>-->
    <!--          Understand Explorazor better with a demo-->
    <!--        </h2>-->
    <!--        <vs-col-->
    <!--          id="Ab-12"-->
    <!--          class="application-btn"-->
    <!--        >-->
    <!--          <vs-button-->
    <!--            class="button"-->
    <!--            @click="routeTo({name:'Demo'})"-->
    <!--          >-->
    <!--            Request a demo-->
    <!--          </vs-button>-->
    <!--        </vs-col>-->
    <!--      </div>-->
    <!--      <vs-col class="application-sub-container">-->
    <!--        <image-renderer-->
    <!--          client="pivots"-->
    <!--          type="client"-->
    <!--        />-->
    <!--      </vs-col>-->
    <!--    </div>-->


    <!--    This is temporarily commented-->
    <!--    <vs-row class="blogs">-->
    <!--      <div class="blogs-container">-->
    <!--        <h2 class="blogs-heading">-->
    <!--          Latest from our blog-->
    <!--        </h2>-->
    <!--        <div class="blogs-content-wrapper">-->
    <!--          <div-->
    <!--            v-for="blog in blogs"-->
    <!--            :key="blog.title"-->
    <!--            class="blogs-content-container"-->
    <!--            style="margin-top:62px;"-->
    <!--          >-->
    <!--            <a-->
    <!--              :href="blog['link']"-->
    <!--              target="_blank"-->
    <!--            >-->
    <!--              <div class="blog-wrapper">-->
    <!--                <img-->
    <!--                  :src="blog.image"-->
    <!--                  :alt="blog.title"-->
    <!--                  style="border-radius:12px; width: 100%"-->
    <!--                >-->
    <!--                <div-->
    <!--                  class="blogs-content-title"-->
    <!--                  v-html="blog.title"-->
    <!--                />-->
    <!--              </div>-->
    <!--            </a>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </vs-row>-->
    <vs-row
      class="fortune-500-container"
    >
      <div class="fortune-500-text">
        Brand Managers from Fortune 500 companies love Explorazor
      </div>
      <div>
        <vs-button
          class="fortune-500-button"
          @click.native="routeTo({name:'Demo'})"
        >
          Request a Demo
        </vs-button>
      </div>
    </vs-row>
  </vs-row>
</template>

<script>
  import imageRenderer from '@/components/helper/ImageRenderer';
  import appTextRender from '@/components/wrapper/TextRender';
  import { imageMixin } from '@/mixins/image';
  import DataPlatform from '@/components/helper/DataPlatform.vue';
  import DecisionMakers from '@/components/helper/DecisionMakers.vue';
  import ExplorazorHelps from '@/components/helper/ExplorazorHelps.vue';
  import { rssParserMixins } from '@/mixins/rssParserMixin';
  import AppIcon from '@/components/helper/AppIcon.vue';
  import { gTagMixin } from '@/mixins/gTagMixin';

  export default {
    name: 'HomePage',
    components: {
      AppIcon,
      ExplorazorHelps,
      DecisionMakers,
      DataPlatform,
      imageRenderer,
    },
    mixins: [imageMixin, rssParserMixins, gTagMixin],
    data () {
      return {
        content: ['Single sign-on', 'Row and column level security',
          'Data encryption', 'Available on cloud and on-premise'],
        blogs: [],
        screenWidth: '',
        name: '',
        email: '',
        showForm: false
      };
    },
    beforeMount () {
      document.title = 'Explorazor | Search and AI Driven Data Exploration Platform';
      document.querySelector('meta[name="description"]').setAttribute('content', 'Explorazor is a AI' +
        ' and Search driven data exploration platform, that empowers users to ask any data questions in Natural ' +
        'language with our No-SQL interface to get accuration answers to take Action.');
    },
    mounted () {
      this.updatePageView();
      this.screenWidth = screen.availWidth;
    },
    methods: {
      handleRegister: function () {
        window.open('https://app.explorazor.com/#/register', '_blank');
      },
      handleTour: function () {
        window.open('https://app.explorazor.com/#/playground', '_blank');
      },
      downloadCaseStudy: function () {
        this.showForm = true;
      },
      handleFormSubmit: function (e) {
        e.preventDefault();
        let link = location.href;
        let utmSource = link.includes('utm_source=')
          ? link.substring(link.indexOf('utm_source=') + 11, link.lastIndexOf('&utm_medium'))
          : '';
        let utmMedium = link.includes('utm_medium=')
          ? link.substring(link.indexOf('utm_medium=') + 11, link.lastIndexOf('&utm_campaign')) : '';
        let utmCampaign = link.includes('utm_campaign=')
          ? link.substring(link.indexOf('utm_campaign=') + 13)
          : '';
        const params = {
          api_key: process.env.VUE_APP_SENDY_API_KEY,
          name: this.name,
          email: this.email,
          boolean: true,
          list: process.env.VUE_APP_SENDY_LIST_KEY,
          Lead_Source_1: 'Website',
          Lead_Source_2: 'Asset',
          Lead_Source_3: 'case_study',
          Lead_Source_4: 'f_and_b_industry',
          utm_source: utmSource.length ? utmSource : '',
          utm_medium: utmMedium.length ? utmMedium : '',
          utm_campaign_name: utmCampaign.length ? utmCampaign : '',
        };
        let formBody = [];
        for (let property in params) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(params[property]);
          formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');
        const xhr = new XMLHttpRequest();
        xhr.open('POST',
          'https://email.pcube.net/subscribe', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Headers',
          'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
          'Access-Control-Allow-Methods', 'OPTIONS,POST',
          'Access-Control-Allow-Credentials', true,
          'Access-Control-Allow-Origin', '*',
          'X-Requested-With', '*',);
        xhr.send(formBody);
        // sendy subscription call code end
        // hubspot form api call code start
        const xhrHubspot = new XMLHttpRequest();
        // eslint-disable-next-line max-len
        const url = `https://api.hsforms.com/submissions/v3/integration/submit/${ process.env.VUE_APP_HUBSPOT_PORTAL_ID }/${ process.env.VUE_APP_HUBSPOT_FORM_ID }`;
        //  request JSON:
        let data = {
          'fields': [
            {
              'name': 'email',
              'value': this.email
            },
            {
              'name': 'firstname',
              'value': this.name
            },
            {
              'name': 'ga_source',
              'value': 'Website'
            },
            {
              'name': 'ga_medium',
              'value': 'Asset'
            },
            {
              'name': 'ga_campaign',
              'value': 'Case_study_f_and_b_industry'
            }
          ],

        };

        let final_data = JSON.stringify(data);

        xhrHubspot.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhrHubspot.setRequestHeader('Content-Type', 'application/json');

        // Sends the request

        xhrHubspot.send(final_data);
        // hubspot form api call code end
        window.open('https://www.vphrase.com/explorazor-assets/case_study/Explorazor%20Case%20Study%20(Danone).pdf',
          '_blank');
        document.getElementById('whitepaper-download').style.padding = '50px';
        document.getElementById('whitepaper-download').style.color = 'white';
        document.getElementById('whitepaper-download').style.background = '#191948';
        document.getElementById('whitepaper-download').style.fontSize = '22px';
        document.getElementById('whitepaper-download').style.fontWeight = '600';
        document.getElementById('whitepaper-download').style.fontFamily = 'Poppins';
        document.getElementById('whitepaper-download').innerHTML = 'Thank You for downloading the Case Study';
      },
      // routeToDemo: function () {
      //   this.$router.push('/demo');
      // },
      fetchBlogs: async function () {
        const response = await this.fetchParsedFeed({ url: 'https://www.vphrase.com/explorazor-blog/feed/atom' });
        this.blogs = response['items'].slice(0, 3);
        console.log(response);
        this.blogs.forEach((blog) => {
          const parser = new DOMParser();
          const htmlDoc = parser.parseFromString(blog['summary'], 'text/html');
          let image = htmlDoc.getElementsByTagName('img');
          let imageUrl = '';
          if (Array.isArray(Array.from(image))) imageUrl = image[0]['src'];
          else imageUrl = image['src'];
          blog['image'] = imageUrl;
        });
      },
      getImage: function (img) {
        return require(`@/assets/png/${ img }.png`);
      }
    }
  };
</script>
<style>
  .form-input-class {
    height: 44px;
    padding-left: 10px;
    width: 90%;
    margin-left: 10px !important;
    border-radius: 4px;
    border: none
  }

  .form-class {
    width: 100%
  }

  .whitepaper-form-container {
    background: #191948;
    padding-top: 45px
  }

  .form-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    color: #FFFFFF;
  }

  .form-container {
    padding-top: 20px;
    padding-bottom: 60px;
    display: flex;
    width: 100% !important;
    justify-content: center;
    align-content: center;
  }

  .form-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 10px 20px !important;
    border-radius: 4px;
    margin-top: 30px;
    margin-left: 10px;
    background: #0243EC;
    border: none;
    cursor: pointer;
  }

  .form-label {
    margin-bottom: 10px !important;
    text-align: left !important;
    margin-left: 8%;
    width: 100%
  }

  label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #D0DDFF;
  }

  .request-button {
    width: 206px;
    height: 60px;
    background: #0243EC;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    float: left
  }

  .partners-header {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 59px;
    text-align: center;
    margin-bottom: 40px
  }

  .partner-images {
    border-radius: 6px;
    margin-left: 12px;
    padding: 22px 30px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .partner-image {
    max-width: 100%;
    max-height: 100%;
  }

  .featured-images {
    border-radius: 6px;
    margin-left: 12px;
    padding: 10px 5px;
    margin-bottom: 12px;
  }

  .partners-wrapper {
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
  }

  .partners-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }

  .grouped-images {
    padding-left: 20px;
  }

  .fortune-500-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    border-radius: 4px;
    margin-top: 40px;
    width: 200px;
    height: auto;
  }

  .fortune-500-container {
    background-image: url('../assets/png/fotune-500.png') !important;
    width: 100%;
    height: 443px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
  }

  .fortune-500-text {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 59px;
    text-align: center;
    color: #FFFFFF;
    width: 50%;
    padding: 0 30px;
  }

  .loved-by-image {
    width: 100%;
    height: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    padding-left: 40px
  }

  .loved-by-image-container {
    width: 60%
  }

  .loved-by-content {
    width: 40%;
    margin: auto;
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 59px;
    text-align: left;
    color: #191948;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10%
  }

  .loved-by-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loved-by-wrapper {
    width: 100%;
    background-color: #DFE8F7;
    display: flex;
    height: 306px
  }

  .blogs-content-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    color: #191948;
    justify-content: start;
    align-items: start;
    padding-left: 10px;
    text-align: left;
    width: 100%;
    margin-top: 24px;
  }

  .blogs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 90px;
  }

  .blogs-container {
    width: 100%;
    height: auto
  }

  .blogs-content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 450px;
  }

  .blogs-heading {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 59px;
    text-align: center;
    color: #191948;
    text-align: center
  }

  .brand-manager-usecase {
    display: flex;
    width: 80%;
    padding: 60px 0;
    background-color: #1A0F91;
    border-radius: 20px;
  }

  .usecase-content {
    color: white;
    font-family: 'Fraunces';
    font-style: normal;
    margin: auto;
    font-weight: 600;
    font-size: 32px;
    padding-left: 60px;
    text-align: left;
    line-height: 39px;
    width: 70%;
  }

  .usecase-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%
  }

  .usecase-button-class {
    width: 250px;
    height: auto;
    background: #0243EC;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }

  .usecase-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px 0;
  }

  .strategy-class {
    display: flex;
    flex-direction: row;
    padding: 80px 8%;
    background-color: #DFE8F7;
    text-align: left;
  }

  .sub-strategy-class {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #191948;
    padding-left: 30px;
    margin-left: 40px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-bottomleft: 4px;
    line-height: 28px;
    font-size: 16px;
    border-left: 6px solid #0243EC;
  }


  button:hover {
    background: rgba(var(--vs-success), 5) !important;
  }

  h1 {
    font-size: 40px;
    font-weight: 600;
  }

  .client-logo {
    width: 9.5rem;
    height: 7.5rem;
    margin: 0 1rem;
    margin-left: 2.6% !important;
  }

  .image-container-enterprises {
    margin-left: 3.5%;
    margin-top: 5%;
  }

  .container-companies {
    margin-top: 5%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .clients-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    padding: 80px 0 40px 0;
  }

  .clients-header-class {
    text-align: center;
    padding-bottom: 20px
  }

  .clients-logo-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    width: 85% !important;
    height: auto;
  }

  .main-content-container {
    width: 100vw;
    display: flex;
    height: auto;
    margin-top: 60px;
    padding: 0 0;
    background-color: #191948;
  }

  .main-content {
    width: 50vw;
    margin: auto;
    padding-top: 20px
  }

  .main-image {
    width: 50vw;
    margin: auto;
    position: relative;
  }

  .main-image-class {
    width: 100% !important;
  }

  .watch-demo-overlay {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    transform: translate(-7%, -4%);
    transition: all 300ms ease-in;
  }

  .main-image:hover .watch-demo-overlay {
    display: flex;
    opacity: 1;
  }

  .watch-demo-overlay .watch-demo-btn {
    font-family: 'Poppins';
    border: none;
    border-radius: 20px;
    padding: 9px 17px;
    background: #9DD3FF;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }

  .watch-demo-overlay .watch-demo-btn .icon {
    display: none;
    transform: translate(3px, -2px);
  }

  .main-sub-content {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding-left: 20%;
    padding-right: 10%
  }

  .main-header {
    color: white;
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 62px;
  }

  .main-para {
    color: #B3CDE3;
    padding-top: 30px;
    padding-bottom: 50px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px !important;
    line-height: 28px;
  }

  .strategy-container {
    display: flex;
    flex-wrap: wrap;
  }

  .blogs-content-container {
    margin-left: 0 !important;
    width: 30% !important;
    padding: 0 16px;
  }

  .blog-wrapper {
    display: flex;
    flex-direction: column;
  }

  /*  media query start */
  @media only screen and (max-width: 1366px) {
    .partners-wrapper {
      padding: 0 8% !important
    }

    .brand-manager-usecase {
      width: 85% !important
    }

    .main-sub-content {
      padding-left: 14%
    }

    .strategy-class {
      padding: 80px 10% 80px 4.5%;
    }

    .clients-logo-container {
      width: 90% !important;
    }

    .case-study-image {
      /*margin-bottom:100px !important*/
    }

    .want-to-know-content {
      padding-top: 80px !important
    }
  }

  @media only screen and (max-width: 821px) {
    .partners-header {
      font-size: 40px !important
    }

    .fortune-500-button {
      height: 60px !important
    }

    .strategy-class {
      padding: 60px 10% 20px 4.5% !important
    }

    .blogs-content-wrapper {
      width: 100% !important;
    }

    .loved-by-content {
      font-size: 36px !important
    }

    .clients-logo-container {
      width: 80%
    }

    .main-para {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 18px !important;
      line-height: 26px !important;
    }

    .main-image {
      margin-bottom: 20px;

    }

    .main-image-class {
      width: 120vw !important
    }

    .watch-demo-overlay {
      transform: translate(0);
    }

    .watch-demo-overlay {
      opacity: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      bottom: 0;
      transition: all 300ms ease-in;
    }

    .watch-demo-overlay .watch-demo-btn {
      font-family: 'Poppins';
      border: none;
      font-size: 12px;
      font-weight: 400;
      background: none;
      padding: 0 0 2px 2px;
      color: white;
      border-radius: 0;
    }

    .watch-demo-btn .watch-demo-btn-text {
      border-bottom: 1px solid white;
      font-size: 14px;
    }

    .watch-demo-overlay .watch-demo-btn .icon {
      display: block;
      padding-left: 5px;
    }
  }

  @media only screen and (max-width: 426px) {
    .partners-header {
      font-size: 32px !important;
    }

    .blogs-heading {
      font-size: 32px !important
    }

    .blogs-content-title {
      font-size: 18px !important
    }

    .partners-wrapper {
      display: inline-block !important;
    }

    .main-para {
      font-size: 17px !important
    }

    .sub-strategy-class {
      margin-left: 0 !important;
    }

    .strategy-class {
      padding: 80px 8% 40px 8% !important
    }

    .strategy-class > div {
      flex: 100% !important
    }

    .main-image {
      margin-bottom: 50px !important
    }

    .request-button {
      width: 100% !important
    }

    .blogs-content-container {
      margin-left: 0 !important;
      width: 300px !important;
    }

    .blog-wrapper {
      display: flex;
      flex-direction: column;
    }

    .loved-by-content {
      line-height: 44px !important;
      text-align: center !important;
    }

    .partner-images {
      width: 30%;
      padding: 8px 12px;
      margin-left: 4px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }


  @media only screen and (max-width: 1456px) {
    .vertical-line {
      height: 120px
    }
  }

  @media only screen and (max-width: 705px) {
    .fortune-500-text {
      width: 100%
    }
  }

  @media only screen and (max-width: 1280px) {
    .partners-container {
      flex-direction: column;
    }

    .partners-wrapper {
      width: 80% !important;
    }

    .partner-wrapper {
      width: 80% !important;
    }

    .clients-logo-container {
      height: auto;
    }

    .fortune-500-container {
      background-size: auto;
      background-repeat: no-repeat;
      background-position-x: center;

    }

    .blogs-container {
      width: 100% !important;
    }

    .brand-manager-usecase {
      flex-direction: column;
    }

    .usecase-content {
      width: 80%;
      padding-left: 0;
      text-align: center;
    }

    .usecase-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 30px;
      margin: auto;
      width: 300px
    }

    .usecase-button-class {

    }

    .loved-by-wrapper {
      width: 100%;
      flex-direction: column;
      height: auto;
      padding: 40px 0;
      justify-content: center;
      align-items: center;
    }

    .loved-by-content {
      font-family: 'Fraunces';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      text-align: left;
      color: #191948;
      width: 80%;
      padding-left: 0
    }

    .loved-by-image {
      padding-left: 0
    }

    .loved-by-image-container {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }

    .blogs-content-wrapper {
      flex-wrap: wrap;
      height: auto;
      width: 90%
    }

    .main-content-container {
      display: flex;
      flex-direction: column;
      padding-top: 80px !important;
    }

    .main-image-class {
      max-width: 80vw
    }

    .main-image {
      display: flex;
      justify-content: center;
      align-content: center;
      margin-bottom: 80px;
      width: 50vw !important;
      padding-right: 15%;
      padding-left: 15%;
      margin-top: 0;
      padding-top: 0
    }

    .main-content {
      text-align: center;
      display: flex;
      justify-content: center;
      align-content: center;
      width: 80vw;
      margin-bottom: 40px;
      padding-bottom: 0;
      margin-top: 0;
      padding-top: 0
    }

    .main-content-container {
      height: auto;
      padding-bottom: 0;
      margin-bottom: 0
    }

    .main-header {
      text-align: center;
    }

    .main-para {
      text-align: center;
      font-family: Poppins, sans-serif;
      padding-bottom: 30px
    }

    .main-sub-content {
      padding: 0;
      text-align: center !important;
    }

    .demo-button {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
    }

    .strategy-class {
      flex-wrap: wrap;
    }

    .strategy-class > div {
      flex: 40%
    }

    .sub-strategy-class {
      width: 100%;
      margin-bottom: 40px;
    }
  }


  @media only screen and (max-width: 1177px) {
    .main-image-class {
    }
  }

  @media only screen and (max-width: 445px) {
    .sub-strategy-class {
      margin-left: 30px
    }
  }

  @media only screen and (max-width: 935px) {
    .blogs-content-title {
      font-size: 14px;
      line-height: calc(14px * 1.8);
    }
  }

  @media only screen and (max-width: 660px) {
    .main-image-class {
      width: 80vw
    }

    .main-content-container {
      height: auto
    }
  }

  @media only screen and (max-width: 420px) {
    .fortune-500-text {
      font-size: 36px;
      line-height: 45px;
    }

    .blogs-content-container {
      width: 100% !important;
    }

  }

  @media only screen and (max-width: 800px) {

  }

  /* media query end */

  .case-study-container {
    background: #191948;
    width: 80%;
  }

  .case-study-wrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 120px;
  }

  .case-study-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #ABC9FD;
    margin-top: 60px;
  }

  .case-study-content-container {
    padding-left: 20%;
    text-align: left;
  }

  /*.case-study-image-container {*/
  /*    width: 50% !important*/
  /*}*/

  .case-study-main-content {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
  }

  .want-to-know {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    color: #FFFFFF;
    padding-top: 80px;
    padding-bottom: 25px;
  }

  .want-to-know-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #FFFFFF;
    padding-bottom: 40px;
    padding-top: 100px !important
  }

  .case-study-button {
    padding: 15px 30px !important;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
  }

  .case-study-image {
    margin-bottom: 50px;
    padding-top: 50px;
    padding-right: 10%;
    width: 100%
  }

  .mid-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 36px !important;
    background: linear-gradient(to right, #ABC9FD, #91B3FA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (max-width: 820px) {
    .case-study-image {
      padding: 0 !important;
      margin-top: 50px !important;
    }
  }

  @media screen and (max-width: 1350px) {
    .case-study-main-content {
      grid-template-columns: repeat(1, 1fr);
    }

    .case-study-content-container {
      padding: 0 10% !important;
      text-align: center !important;
    }

    .mid-para {
      text-align: center !important;
    }

    .case-study-image {
      width: 80%;
    }
  }

  @media screen and (max-width: 414px) {
    .demo-button {
      flex-direction: column;
    }

    .request-button {
      width: 100% !important
    }

    .request-tour-button {
      width: 100% !important
    }

    .form-label {
      padding-top: 20px !important;
    }

    .want-to-know {
      font-family: 'Fraunces';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
      text-align: center;
      color: #FFFFFF;
    }

    .want-to-know-content {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #FFFFFF;
    }

    .case-study-image {
      width: 90% !important
    }

    .case-study-button {
      width: 100% !important;
      font-size: 14px;
    }
  }

  .border-radius-class {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .form-radius {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
</style>
