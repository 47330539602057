<template>
  <div class="use-case">
    <vs-row class="use-route">
      <p class="breadcrumb">
        <a
          href="https://www.vphrase.com/explorazor/usecases"
          style="padding-right: 10px"
        >Use cases</a>
        <app-icon
          class="icon-arrow"
          icon="app-icons/forword"
          width="9"
          color="#191948"
        />
        &nbsp;&nbsp;<span class="animation"> Track How your Brand’s Perception
          among Consumers is Affecting Your Business</span>
      </p>
    </vs-row>
    <vs-row
      class="unified-data-access-container"
      vs-justify="center"
    >
      <vs-col class="unified-data-access">
        <vs-row vs-justify="center">
          <h3 class="unified-data-header ">
            CONSUMER BEHAVIOR
          </h3>
          <h2 class="correlation-header">
            Track How your Brand’s Perception among Consumers is Affecting Your Business
          </h2>
        </vs-row>
      </vs-col>
    </vs-row>

    <vs-row
      id="goc-12"
      class="glimpse-of-search-container consumer-behavior "
    >
      <div
        class="correlation-analysis"
      >
        <ul style="list-style-type: circle">
          <li>
            <app-text-render
              sub-content="Find new shoppers and broaden your shopper base.
          Engage existing shoppers and build consumer loyalty"
            />
          </li>
          <li>
            <app-text-render
              sub-content="Anticipate trends before your competition, and transform threats into opportunities"
            />
          </li>
          <li>
            <app-text-render
              sub-content="See how your brand is performing versus your competitors,
          so you can justify marketing investment"
            />
          </li>
          <li>
            <app-text-render
              sub-content="Maximize the success of your marketing campaigns, optimize your media mix or touchpoint
               strategy,
           or invest in your strongest ads and creative content"
            />
          </li>
        </ul>
      </div>
      <div class="glimpse-of-img">
        <image-renderer
          client="consumer-behaviour"
          type="client"
        />
      </div>
    </vs-row>

    <vs-row class="challenge-faced-container ">
      <h3>
        As a Consumer Goods Brand and Sales teams
      </h3>
      <p>
        <br>A vital part of your job is to maintain and improve the brand's image in the consumers eyes.
        Companies like Kantar provide advertising effectiveness, consumer and shopper behaviour, and public opinion
        data.
        However, it is still up to you as a Brand and Sales teams to efficiently use this data to ascertain the impact
        of marketing spends,
        be it via TV ad spends or digital campaigns.
      </p>
      <br>
      <p>
        Subscribing to and looking at the data individually however, does not
        lead to the best output that a Brand and Sales teams can drive out of these data points. Clubbing
        your consumer behavior data with your Internal Spends data and Market Share data allows you
        to best gauge whether your decisions are improving brands perception or not.
      </p>
    </vs-row>

    <vs-row class="necessary-datasets ">
      <h3>
        Time Crunch - The Challenges That Brand and Sales Teams Face on this Journey
      </h3>
      <ul>
        <br>
        <li>
          <p>
            The necessary datasets to conduct such analysis, end-to-end, are many,
            and need to be procured via the right internal source, which takes time
          </p>
        </li>
        <li>
          <p>
            Repeating the process of applying pivots across datasets takes time
          </p>
        </li>
        <li>
          <p>
            One cannot test and get the data for all the different marketing initiatives running
            simultaneously in different regions, as it will take an absurd amount of time
          </p>
        </li>
        <li>
          <p>
            The time required to get to a decision about what changes need
            to be made has to be deferred, resulting in opportunity loss
          </p>
        </li>
      </ul>
    </vs-row>
    <div class="bm-logs">
      <div>
        <span><h3>How <span>Explorazor</span> Helps
        </h3> </span>
        <p>
          <br>
          Explorazor is a data exploration platform built specifically for
          Brand and Sales teams to query their data better and extract instant data cuts from. What
          Explorazor does is combine all the datasets currently residing in Excel, and provides a unified,
          single view access for Brand and Sales teams to explore. Examples of such datasets would be primary sales,
          secondary sales, Kantar, IQVIA, and on.
        </p>
        <p>
          <br><b>Explorazor helps Brand and Sales teams with their consumer research</b>, by allowing them to view
          which brand
          marketing strategies work best. At any given time, a Brand and Sales teams will be running multiple different
          marketing strategies and campaigns in different markets across the country. For them to get an
          idea of which strategies are working best, they need to simply hop on to Explorazor and compare
          the performance of their output metrics like Market Share, GRPs, Kantar consumer behavior data in those
          different markets.
        </p>
      </div>
      <div class="bm-logs-img">
        <image-renderer
          client="consumer-behavior-analysis"
          type="client"
        />
        <p class="image-caption">
          Track how consumers are reacting to your marketing efforts
        </p>
      </div>
    </div>
    <vs-row class="business-expectation-container">
      <p>
        Brand and Sales teams can thus gauge, in a single view, which of their strategies are efficient and which
        require immediate tweaking or, to put it another way, which highly
        performing campaign can be copied over from one market to another.
      </p>
    </vs-row>
    <div class="bm-logs">
      <div>
        <span><h3>Bird’s-Eye View
        </h3> </span>
        <p>
          <br>Another major benefit for Brand and Sales teams is the ability to obtain a bird’s eye view
          over a longer time period as to what content changes are being put forward to the customer.
        </p>
        <p>
          <br><b>For example</b>, X content change results in higher Trial Ratio but has no real impact on Avg
          Consumption
          by a consumer. Previously this would have taken up days and days to test and drive such minute improvements.
          With Explorazor,
          however, it gets done in hours if not minutes, encouraging Brand and Sales teams to continuously engage and
          optimize.
        </p>
      </div>
    </div>

    <vs-row
      id="uda-12"
      class="unified-data-access-container"
      vs-justify="center"
    >
      <vs-col class="unified-data-access">
        <vs-row
          class="want-to-see-container"
          vs-justify="center"
        >
          <div>
            <h3> Want to see Explorazor in action?</h3>
            <!--            <p class="access-header">-->
            <!--              Schedule a no-obligation demo call with us-->
            <!--            </p>-->
          </div>
        </vs-row>
        <div class="wrapper-button height">
          <vs-button
            class="request-demo-button "
            @click="handleTour"
          >
            Take Product Tour
          </vs-button>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
  import '@/assets/scss/components/change.scss';
  import imageRenderer from '@/components/helper/ImageRenderer';
  import { imageMixin } from '@/mixins/image';
  import AppTextRender from '@/components/wrapper/TextRender';
  import appIcon from '@/components/helper/AppIcon';
  import { gTagMixin } from '@/mixins/gTagMixin';

  export default {
    name: 'RequestDemo',
    components: {
      AppTextRender,
      imageRenderer,
      appIcon,
    },
    mixins: [imageMixin, gTagMixin],
    beforeMount () {
      document.title = 'Explorazor Use Case | Search and AI driven analytics on Consumer Behaviour Data';
      document.querySelector('meta[name="description"]').setAttribute('content', 'Find out how you' +
        ' can leverage AI and Search driven analytics to find your brand\'s perception among the consumers');
    },
    mounted () {
      this.scrollToTop();
      this.updatePageView();
    },
    methods: {
      handleTour: function () {
        window.open('https://app.explorazor.com/#/playground', '_blank');
      }
    }
  };
</script>

<style scoped>

  button:hover {
    background: rgba(var(--vs-success), 5) !important;
  }

  p {
    color: #191948;
  }

  .wrapper-button {
    margin-bottom: 2% !important;

  }

  .correlation-header {
    text-align: center;
    width: 80%;
  }

  #goc-12 {
    background-color: unset;
  }

  ul li {
    list-style-type: disc;


  }

  ul {
    padding-left: 5%;
  }

  ul li {
    font-weight: 400;
    padding-left: 2%;


  }

  .unified-data-access {
    padding-bottom: 0;
  }

  .glimpse-of-search-container {
    padding: 0 11.5%;

  }

  h3 span {
    color: #0243EC;
  }

  #uda-12 .unified-data-access {
    width: 100% !important;
    display: flex;
    padding: 5% 10%;
    flex-direction: column;
    background: #E6EEFF;
    border-radius: 12px;
  }

  #uda-12 {
    padding: 0 10%;
  }

  #uda-12 h3 {
    text-align: center;
    margin-bottom: 5%;
    font-family: 'Fraunces';
    font-size: 2rem;
  }

  #uda-12 .wrapper-button {
    margin: 0;
    margin-top: 5%;
  }

  #uda-12 .access-header {
    text-align: center;
  }

</style>