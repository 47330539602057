<template>
  <div class="demo">
    <vs-row class="bi-tools-in-the-market ">
      <vs-col
        class="bi-tools"
        vs-w="6"
      >
        <div class="container-bi-tools">
          <h1>Brand and Sales teams Love <i>Explorazor.</i></h1>
          <div>
            <img
              src="@/assets/backgrounds/invertedc.png"
              alt=""
            >
          </div>
          <div class="sub-container-bi-tools">
            <p>
              Plenty of BI tools in the market, but we still have to rely on good old Microsoft
              Excel for every type of analysis even though it can be cumbersome and takes time. Why I enjoy using
              Explorazor is because it is custom made for ad-hoc analysis,
              giving a unified view of all the different datasets I need to analyze.
              <b>It makes the process of analysis a breeze.</b>
            </p>
            <div class="consumer-goods">
              <p><b>- </b>Brand and Sales teams at a Leading Consumer Goods Company</p>
            </div>
          </div>
        </div>
      </vs-col>
      <div class="understand-better-container">
        <div class="sub-container">
          <div
            v-show="!message"
            class="alert-success"
          >
            <div>
              <img
                src="@/assets/png/success.svg"
                alt=""
              >
              <h3>Thank you for reaching out</h3>
              <p>We will get back to you at the earliest</p>
            </div>
          </div>
          <div v-show="message">
            <vs-row vs-justify="center">
              <h3>Understand Explorazor Better</h3>
            </vs-row>
            <div>
              <meta
                content="width=device-width,initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                name="viewport"
              >
              <div
                id="sf3zd9bf5d9946f605225b90a8c90d017d8d4b145e10cada4adc7d9a9f1f7d2edc8c"
                data-type="signupform"
              >
                <div id="customForm">
                  <input
                    id="recapTheme"
                    type="hidden"
                    value="2"
                  >
                  <input
                    id="recapMode"
                    type="hidden"
                    value="94075000002682121"
                  >
                  <input
                    id="signupFormType"
                    type="hidden"
                    value="LargeForm_Vertical"
                  >
                  <div
                    id="SIGNUP_PAGE"
                    class="SIGNUP_PAGE large_form_2_css"
                    name="SIGNUP_PAGE"
                  >
                    <div>
                      <div
                        changeid=""
                        changename=""
                        name=""
                      >
                        <div
                          id="imgBlock"
                          logo="true"
                          name="LOGO_DIV"
                        />
                      </div>
                      <br>
                      <div
                        id="signupMainDiv"
                        changeid="SIGNUPFORM"
                        changename="SIGNUPFORM"
                        name="SIGNUPFORM"
                      >
                        <div>
                          <div style="position:relative;">
                            <div
                              id="Zc_SignupSuccess"
                              style="display:none;position:absolute;margin-left:4%;width:90%;
                                 background-color: white; padding: 3px; border: 3px solid rgb(194, 225, 154);
                                   margin-top: 10px;margin-bottom:10px;word-break:break-all "
                            >
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td width="10%">
                                      <img
                                        align="absmiddle"
                                        alt="thumsup"
                                        class="successicon"
                                        src="https://vphr-zc1.maillist-manage.in/images/challangeiconenable.jpg"
                                      >
                                    </td>
                                    <td>
                                      <span
                                        id="signupSuccessMsg"
                                        style="color: rgb(73, 140, 132);
                                            font-family: sans-serif; font-size: 14px;word-break:break-word"
                                      >&nbsp;&nbsp;Thank you for Signing Up</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <iframe
                            border="0"
                            height="0"
                            name="hiddenFrame"
                            style="display: none;"
                            width="0"
                          />
                          <form
                            target="hiddenFrame"
                            @submit="message=!message"
                          >
                            <div
                              id="SIGNUP_BODY_ALL"
                              name="SIGNUP_BODY_ALL"
                            >
                              <h1
                                id="SIGNUP_HEADING"
                                changeid="SIGNUP_MSG"
                                changetype="SIGNUP_HEADER"
                                name="SIGNUP_HEADING"
                              />
                              <div
                                id="SIGNUP_BODY"
                                name="SIGNUP_BODY"
                              >
                                <div>
                                  <div
                                    id="SIGNUP_DESCRIPTION"
                                    changeid="SIGNUP_MSG"
                                    changetype="SIGNUP_DESCRIPTION"
                                    class=""
                                  />
                                  <div id="errorMsgDiv">
                                    &nbsp;&nbsp;
                                  </div>
                                  <div>
                                    <div
                                      class="zcsffieldsdiv"
                                      name="fieldsdivSf"
                                    >
                                      <div
                                        class="zcsffield "
                                        fieldid="94075000000000029"
                                      >
                                        <div>
                                          <div name="SIGNUP_FORM_LABEL">
                                            First name&nbsp;

                                            <span name="SIGNUP_REQUIRED">*</span>
                                          </div>
                                          <div>
                                            <!-- check to mark emailid field
                                            as type email, and other mandatory fields as type required -->
                                            <input
                                              v-model="userInput"
                                              changeitem="SIGNUP_FORM_FIELD"
                                              placeholder="First name"
                                              maxlength="100"
                                              name="FIRSTNAME"
                                              required="true"
                                              type="text"
                                            >
                                            <span class="message">{{ isEmpty }}</span>
                                          </div>
                                        </div>


                                        <div />
                                      </div>
                                      <div
                                        class="zcsffield "
                                        fieldid="94075000000000023"
                                      >
                                        <div>
                                          <div name="SIGNUP_FORM_LABEL">
                                            Business Email ID&nbsp;
                                            <span
                                              name="SIGNUP_REQUIRED"
                                            >*</span>
                                          </div>
                                          <div>
                                            <!-- check to mark emailid
                                            field as type email, and other mandatory fields as type required -->
                                            <input
                                              v-model="userEmail"
                                              changeitem="SIGNUP_FORM_FIELD"
                                              maxlength="100"
                                              name="CONTACT_EMAIL"
                                              placeholder="Business Email ID"
                                              required="true"
                                              type="email"
                                            >
                                            <span class="message">{{ isEmptyMessage }}</span>
                                          </div>
                                        </div>
                                        <div />
                                      </div>
                                      <div
                                        class="zcsffield "
                                        fieldid="94075000000137464"
                                      >
                                        <div>
                                          <div name="SIGNUP_FORM_LABEL">
                                            Message&nbsp;
                                          </div>
                                          <div>
                                            <!-- check to mark emailid
                                            field as type email, and other mandatory fields as type required -->
                                            <input
                                              v-model="formMessage"
                                              changeitem="SIGNUP_FORM_FIELD"
                                              maxlength="100"
                                              name="CONTACT_CF5"
                                              placeholder="What are you looking for?"
                                              type="text"
                                            >
                                          </div>
                                        </div>
                                        <div />
                                      </div>
                                      <div
                                        ishidden="true"
                                        style="display:none"
                                      >
                                        <div>
                                          <div name="SIGNUP_FORM_LABEL">
                                            Lead Source 1&nbsp;
                                          </div>
                                          <div>
                                            <!-- check to mark emailid field
                                            as type email, and other mandatory fields as type required -->
                                            <input
                                              changeitem="SIGNUP_FORM_FIELD"
                                              maxlength="100"
                                              name="CONTACT_CF68"
                                              type="text"
                                              value="Website"
                                            >
                                          </div>
                                        </div>
                                        <div />
                                      </div>
                                      <div
                                        ishidden="true"
                                        style="display:none"
                                      >
                                        <div>
                                          <div name="SIGNUP_FORM_LABEL">
                                            Lead Source 2&nbsp;
                                          </div>
                                          <div>
                                            <!-- check to mark emailid field as type email,
                                            and other mandatory fields as type required -->
                                            <input
                                              changeitem="SIGNUP_FORM_FIELD"
                                              maxlength="100"
                                              name="CONTACT_CF69"
                                              type="text"
                                              value="Meeting"
                                            >
                                          </div>
                                        </div>
                                        <div />
                                      </div>
                                      <div
                                        ishidden="true"
                                        style="display:none"
                                      >
                                        <div>
                                          <div name="SIGNUP_FORM_LABEL">
                                            Lead Source 3&nbsp;
                                          </div>
                                          <div>
                                            <!-- check to mark emailid field as type email,
                                            and other mandatory fields as type required -->
                                            <input
                                              changeitem="SIGNUP_FORM_FIELD"
                                              maxlength="100"
                                              name="CONTACT_CF70"
                                              type="text"
                                              value="Request a Demo"
                                            >
                                          </div>
                                        </div>
                                        <div />
                                      </div>
                                    </div><!-- Captcha for Signup -->
                                    <div
                                      id="recapDiv"
                                      class="recapDivlight recaptcha"
                                    />
                                    <!-- Captcha for Signup End--><!-- Other Lists Subscription Start-->
                                    <!-- Other Lists Subscription End-->
                                    <div />
                                    <div
                                      id=" REQUIRED_FIELD_TEXT"
                                      changetype="REQUIRED_FIELD_TEXT"
                                      name="SIGNUP_REQUIRED"
                                    />
                                    <label>
                                      <vue-recaptcha
                                        :sitekey="captchaKey"
                                        class="captcha-container"
                                        @verify="validate"
                                        @expired="onCaptchaExpired"
                                      />
                                      <span class="message">{{ captchaMessage }}</span>
                                    </label>
                                    <div class="request-demo" />
                                    <div class="request-demo">
                                      <input
                                        changetype="SIGNUP_SUBMIT_BUTTON_TEXT"
                                        :class="enableDemoButton?'request-demo-button btn-primary':'disabled-class'"
                                        :disabled="!enableDemoButton"
                                        type="submit"
                                        value="Request a demo"
                                        @click="isSubmit"
                                      >
                                    </div><!-- Do not edit the below Zoho Campaigns hidden tags -->
                                    <input
                                      id="fieldBorder"
                                      type="hidden"
                                      value="rgb(222, 222, 222)"
                                    >
                                    <input
                                      id="zc_trackCode"
                                      name="zc_trackCode"
                                      onload=""
                                      type="hidden"
                                      value="ZCFORMVIEW"
                                    >
                                    <input
                                      id="submitType"
                                      name="submitType"
                                      type="hidden"
                                      value="optinCustomView"
                                    >
                                    <input
                                      id="lD"
                                      name="lD"
                                      type="hidden"
                                      value="114e38b6cd69627b"
                                    >
                                    <input
                                      id="emailReportId"
                                      name="emailReportId"
                                      type="hidden"
                                      value=""
                                    >
                                    <input
                                      id="cmpZuid"
                                      name="zx"
                                      type="hidden"
                                    >
                                    <input
                                      name="zcvers"
                                      type="hidden"
                                      value="2.0"
                                    >
                                    <input
                                      id="allCheckedListIds"
                                      name="oldListIds"
                                      type="hidden"
                                      value=""
                                    >
                                    <input
                                      id="mode"
                                      name="mode"
                                      type="hidden"
                                      value="OptinCreateView"
                                    >
                                    <input
                                      id="zcld"
                                      name="zcld"
                                      type="hidden"
                                      value="114e38b6cd69627b"
                                    >
                                    <input
                                      id="zctd"
                                      name="zctd"
                                      type="hidden"
                                      value="114e38b6cd3edee1"
                                    >
                                    <input
                                      id="document_domain"
                                      type="hidden"
                                      value="zoho.in"
                                    >
                                    <input
                                      id="zc_Url"
                                      type="hidden"
                                      value="vphr-zc1.maillist-manage.in"
                                    >
                                    <input
                                      id="new_optin_response_in"
                                      type="hidden"
                                      value="2"
                                    >
                                    <input
                                      id="duplicate_optin_response_in"
                                      type="hidden"
                                      value="2"
                                    >
                                    <input
                                      id="zc_formIx"
                                      name="zc_formIx"
                                      type="hidden"
                                    >
                                    <!-- End of the campaigns hidden tags -->
                                  </div>
                                </div>
                                <input
                                  id="isCaptchaNeeded"
                                  type="hidden"
                                  value="false"
                                >
                              </div>
                            </div>
                            <input
                              id="scriptless"
                              name="scriptless"
                              type="hidden"
                              value="yes"
                            >
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="zcOptinOverLay"
                oncontextmenu="return false"
                style="display:none;text-align: center; background-color: rgb(0, 0, 0); opacity: 0.5; z-index: 100;
                    position: fixed; width: 100%; top: 0px; left: 0px; height: 988px;"
              />
              <div
                id="zcOptinSuccessPopup"
                style="display:none;z-index: 9999;width: 800px; height: 40%;top: 84px;position: fixed; left: 26%;
                   background-color: #FFFFFF;border-color: #E6E6E6; border-style: solid; border-width: 1px;
                    box-shadow: 0 1px 10px #424242;padding: 35px;"
              >
                <span
                  id="closeSuccess"
                  style="position: absolute;top: -16px;right:-14px;z-index:99999;cursor: pointer;"
                >
                  <img
                    alt="close"
                    src="https://vphr-zc1.maillist-manage.in/images/videoclose.png"
                  >
                </span>
                <div id="zcOptinSuccessPanel" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-row>

    <vs-row class="integrated-View-container">
      <vs-row vs-justify="center">
        <h2>With Explorazor, Brand and Sales teams</h2>
      </vs-row>
      <div class="sub-container">
        <div>
          <div class="integrated-View-img">
            <img
              src="@/assets/backgrounds/check.svg"
              alt=""
            >
          </div>
          <app-text-render
            content="Get an Integrated View of All Datasets"
            sub-content="Multiple datasets like market research data (Nielsen, IMRB, IQVIA, Kantar, etc.),
             primary sales, secondary
              sales, marketing, media, and others
               are standardized, integrated, and available on a single
               Explorazor platform for analysis"
          />
        </div>
        <div>
          <div class="integrated-View-img">
            <img
              src="@/assets/backgrounds/check.svg"
              alt=""
            >
          </div>
          <app-text-render
            content="Obtain Instant Data Pivots"
            sub-content="Just mention the column names you want a
              pivot on, across datasets. Explorazor will do the rest.
              The data cuts are also downloadable as CSV files"
          />
        </div>
        <div>
          <div class="integrated-View-img">
            <img
              src="@/assets/backgrounds/check.svg"
              alt=""
            >
          </div>
          <app-text-render
            content="Accelerate Speed of Hypothesis Testing"
            sub-content="When switching between tabs and sheets is eliminated, laptops
              don’t take time to process data, and dependence on Insights/BI teams is reduced,
              hypothesis testing naturally speeds up"
          />
        </div>
      </div>
    </vs-row>

    <vs-row class="clients-section-container">
      <h2 class="clients-section-title">
        Trusted By
      </h2>
      <div class="companies-content">
        <div
          v-for="img in getClientLogos()"
          :key="img.id"
          class="image-content"
        >
          <image-renderer
            :group="img"
            type="group"
          />
        </div>
      </div>
    </vs-row>
    <vs-row
      class="badge-container"
      vs-justify="center"
    >
      <div class="badges-content">
        <app-text-render content="Loved by users around the world" />
      </div>
      <div class="banner-text-container">
        <image-renderer
          client="user-love-us"
          type="client"
        />
      </div>
    </vs-row>
  </div>
</template>

<script>
  import '@/assets/scss/components/change.scss';
  import imageRenderer from '@/components/helper/ImageRenderer';
  import { VueRecaptcha } from 'vue-recaptcha';
  import '@/assets/scss/components/zoho-form.scss';
  import { imageMixin } from '@/mixins/image';
  import AppTextRender from '@/components/wrapper/TextRender';
  import { marketingMixin } from '@/mixins/marketingMixin';
  import { gTagMixin } from '@/mixins/gTagMixin';

  export default {
    name: 'RequestDemo',
    components: {
      AppTextRender,
      imageRenderer,
      VueRecaptcha,
    },
    mixins: [imageMixin, marketingMixin, gTagMixin],
    data () {
      return {
        captchaKey: process.env.VUE_APP_CAPTCHA_KEY,
        message: true,
        validateData: false,
        userEmail: '',
        userInput: '',
        isEmptyMessage: '',
        isEmpty: '',
        captchaMessage: '',
        formMessage: ''
      };
    },

    computed: {
      enableDemoButton: function () {
        let show = false;
        if (this.userEmail.length && this.userInput.length && this.validateData) {
          show = true;
        }
        return show;
      }
    },
    beforeMount () {
      document.title = 'Explorazor Demo | Search Data like never before';
      document.querySelector('meta[name="description"]').setAttribute('content', 'Watch Explorazor' +
        ' Demo on how data analyst can easily connect multiple datasets and cross query through our search ' +
        'and AI driven analytics platform');
    },
    mounted () {
      this.updatePageView();
    },
    methods: {
      onCaptchaExpired: function () {
        this.validateData = false;
      },
      validate: function () {
        this.validateData = true;
      },
      isSubmit: function () {
        if (this.userInput.length === 0) {
          this.isEmpty = 'this field is required';
          this.captchaMessage = '';
        }
        if (this.userEmail.length === 0) {
          this.isEmptyMessage = 'this field is required';
          this.captchaMessage = '';
        } else {
          this.isEmpty = '';
          this.isEmptyMessage = '';
          this.captchaMessage = 'captcha is required';
        }
        // sendy subscription call code start
        let link = location.href;
        let utmSource = link.includes('utm_source=')
          ? link.substring(link.indexOf('utm_source=') + 11, link.lastIndexOf('&utm_medium'))
          : '';
        let utmMedium = link.includes('utm_medium=')
          ? link.substring(link.indexOf('utm_medium=') + 11, link.lastIndexOf('&utm_campaign')) : '';
        let utmCampaign = link.includes('utm_campaign=')
          ? link.substring(link.indexOf('utm_campaign=') + 13)
          : '';
        const params = {
          api_key: process.env.VUE_APP_SENDY_API_KEY,
          name: this.userInput.charAt(0).toUpperCase() + this.userInput.slice(1),
          email: this.userEmail,
          boolean: true,
          list: process.env.VUE_APP_SENDY_LIST_KEY,
          Lead_Source_1: 'Website',
          Lead_Source_2: 'Meeting',
          Lead_Source_3: 'Request a Demo',
          utm_source: utmSource.length ? utmSource : '',
          utm_medium: utmMedium.length ? utmMedium : '',
          utm_campaign_name: utmCampaign.length ? utmCampaign : ''
        };
        let formBody = [];
        for (let property in params) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(params[property]);
          formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');
        const xhr = new XMLHttpRequest();
        xhr.open('POST',
          'https://email.pcube.net/subscribe', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Headers',
          'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
          'Access-Control-Allow-Methods', 'OPTIONS,POST',
          'Access-Control-Allow-Credentials', true,
          'Access-Control-Allow-Origin', '*',
          'X-Requested-With', '*',);
        xhr.send(formBody);
        // sendy subscription call code end
        // hubspot form api call code start
        const xhrHubspot = new XMLHttpRequest();
        // eslint-disable-next-line max-len
        const url = `https://api.hsforms.com/submissions/v3/integration/submit/${ process.env.VUE_APP_HUBSPOT_PORTAL_ID }/${ process.env.VUE_APP_HUBSPOT_FORM_ID }`;

        //  request JSON:
        let data = {
          'fields': [
            {
              'name': 'email',
              'value': this.userEmail
            },
            {
              'name': 'firstname',
              'value': this.userInput
            },
            {
              'name': 'message',
              'value': this.formMessage
            },
            {
              'name': 'ga_source',
              'value': 'Website'
            },
            {
              'name': 'ga_medium',
              'value': 'Meeting'
            },
            {
              'name': 'ga_campaign',
              'value': 'Request a Demo'
            },
          ],

        };

        let final_data = JSON.stringify(data);

        xhrHubspot.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhrHubspot.setRequestHeader('Content-Type', 'application/json');

        // Sends the request

        xhrHubspot.send(final_data);
        // hubspot form api call code end

      },
    }
  };
</script>

<style scoped>
  .clients-section-container {
    flex-direction: column;
    width: 100% !important;
    background-color: #FFFFFF;
  }

  .clients-section-title {
    margin-bottom: 5%;
  }

  .bi-tools-in-the-market p {
    color: #191948;
  }

  .clients-section-container {
    padding-top: 0 !important;;
  }

  .companies-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
    justify-content: center;
  }

  .companies-content .image-content {
    width: 9rem;
    margin: 20px 12px;
  }

  .badge-container {
    padding-top: 4%;
  }


</style>