<template>
  <div
    v-if="icon && icon.length && isSvgAvailable"
    class="vertical-align display-inline-block pointer-cursor"
    :style="[{'width': newWidth + 'px' , 'height': newHeight + 'px'},
             !showSvg && !customSize ? { 'visibility' : 'hidden'} : {}]"
  >
    <!--BEFORE USING THIS COMPONENT PLEASE REMOVE HEIGHT, WIDTH FROM <svg> TAG IN THE .svg FILE AND REMOVE fill
    ATTRIBUTE FROM ALL SUBTAGS OF THE svg ELEMENT-->
    <svg
      :style="[isCustom ? containerStyle : {}, {'width': newWidth + 'px' , 'height': newHeight + 'px'},
               color ? {fill: color}: {}]"
      :class="[`svg-${color} hover-color-${hoverColor} svg  hover-background-${hoverBackgroundColor} display-block
      rotate-${rotate}`, spin ? 'spinner-icon' : '']"
      xmlns="http://www.w3.org/2000/svg"
      @click="$emit('input', $event)"
      @mouseenter="handleHover(true)"
      @mouseleave="handleHover(false)"
    >
      <use
        ref="icon"
        :href="svgFile + '#icon'"
        :style="[isCustom ? svgStyle : {}, showSvg ? {'opacity' : 1} : {'opacity' : 0}, color ? {fill: color}: {}]"
        :class="`svg-${color} svg-use hover-color`"
        @load="setSvgSize"
      />
    </svg>
  </div>
  <div v-else-if="!isSvgAvailable">
    <p>{{ icon.slice(0, 3) }}</p>
  </div>
</template>

<script>
export default {
  name: 'AppIcon',
  props: {
    icon: {
      type: String,
      default: ''
    },
    containerStyle: {
      type: Object,
      default: null
    },
    basicStyle: {
      type: Object,
      default: null
    },
    hoverStyle: {
      type: Object,
      default: null
    },
    isCustom: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    hoverColor: {
      type: String,
      default: ''
    },
    hoverBackgroundColor: {
      type: String,
      default: ''
    },
    rotate: {
      type: String,
      default: '0'
    },
    customSize: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    spin: {
      type: Boolean,
      default: false,
    }
  },
  data: function () {
    return {
      hoverState: false,
      newWidth: '',
      newHeight: '',
      showSvg: false,
      isSvgAvailable: false
    };
  },
  computed: {
    svgFile: function () {
      try {
        return require(`@/assets/svgs/${ this.icon }.svg`);
      } catch (error) {
        console.log(error);
        return '';
      }
    },
    svgStyle: function () {
      if (this.hoverState) {
        return { ...this.basicStyle, ...this.hoverStyle };
      } else {
        return this.basicStyle;
      }
    },
    /*
    * This returns height of svg according to aspect ratio of svg i.e original height of svg
     */
    getHeight: function () {
      if (this.customSize) return this.height;
      if (this.showSvg) {
        let width = this.$refs['icon'].getBoundingClientRect()['width'];
        let height = this.$refs['icon'].getBoundingClientRect()['height'];
        return (height / width) * this.getWidth;
      }
      return this.getWidth;
    },
    getWidth: function () {
      return this.width;
    }
  },
  watch: {
    icon: function () {
      this.showSvg = false;
      this.isSvgAvailable = false;
      this.isValidIcon();
      this.setSvgSize();
    },
    width: function () {
      this.setSvgSize();
    },
    height: function () {
      this.setSvgSize();
    }
  },
  mounted () {
    this.newHeight = this.getHeight;
  },
  beforeMount () {
    this.newWidth = this.width;
    this.newHeight = this.height;
    this.isValidIcon();
  },
  methods: {
    handleHover: function (state) {
      this.hoverState = state;
    },
    // setting up the showSvg so now width and height can be calculated as svg is loaded
    // Added on load event rather than directly because svg was taking some undefined time after mounting.
    // so was not able to get the original svg aspect ratio before loading on svg
    setSvgSize: function () {
      this.showSvg = true;
      this.newWidth = this.getWidth;
      this.newHeight = this.getHeight;
    },
    isValidIcon: function () {
      try {
        require(`@/assets/svgs/${ this.icon }.svg`);
        this.isSvgAvailable = true;
      } catch (error) {
        this.isSvgAvailable = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
//@import '../../assets/scss/customGlobalStyles/variables';
//
//$iconSizes: (
//    smallest:10px,
//    smaller:12px,
//    small:14px,
//    small-medium:10.5px,
//    medium:16px,
//    medium-regular:12.25px,
//    regular:18px,
//    regular-large:13.25px,
//    large:20px,
//    huge:22px,
//    largest: 24px,
//);
//@each $sizeName, $iconSize in $iconSizes {
//  .svg-#{$sizeName} {
//    height: #{$iconSize};
//    width: #{$iconSize};
//  }
//}
//
//.svg, .svg-use {
//  fill: currentColor;
//}
//
//$colors: (
//    primary: rgba(var(--vs-primary), 1),
//    secondary: rgba(var(--vs-secondary), 1),
//    success: rgba(var(--vs-success), 1),
//    danger: rgba(var(--vs-danger), 1),
//    warning: rgba(var(--vs-warning), 1),
//    dark: rgba(var(--vs-dark), 1),
//    info: rgba(var(--vs-info), 1),
//    white: #ffffff,
//    text: $dimensionsColor,
//    numeric: $measuresColor,
//    timestamp: $datesColor,
//    variable: $variablesColor
//);
//@each $colorName, $color in $colors {
//  .svg-#{$colorName} {
//    fill: #{$color};
//  }
//}
//
//@each $colorName, $color in $colors {
//  .hover-color-#{$colorName} {
//    &:hover {
//      .hover-color {
//        fill: #{$color} !important;
//      }
//    }
//  }
//}
//
//@each $bgColorName, $color in $colors {
//  .hover-background-#{$bgColorName} {
//    &:hover {
//      background-color: #{$color};
//    }
//  }
//}
</style>