<template>
  <div class="policy">
    <div class="policy-header">
      <h4>PRIVACY POLICY</h4>
    </div>
    <p>
      The internet is an amazing tool. It has the power to change the way we live, and we're starting to see that
      potential today. With only a few clicks, anyone can follow the news, look up facts, buy goods and services, and
      communicate with others from around the world. It's important to the Company to help the User accessing the
      Website to retain their privacy when they use the Website.
    </p><br><br>
    <p>
      Privacy of every User is important to us and as well as to the User. The Website protects the information the User
      shares on the Website and/or through the Services (if requested by the Company) for provision of the Services. To
      protect privacy of the Users, the Company follows different principles in accordance with world-wide practices for
      customer privacy and data protection. We use state-of-the-art security measures to protect information of the
      Users.
    </p><br><br>
    <p>
      This privacy policy (“<b>Privacy Policy</b>”) applies to the personal information which (a) will be provided by
      the User
      in the course of accessing the Website or availing the Services or registering for the Services, (b) will be
      collected, used, shared, stored, protected or disclosed to the Company. By accessing the Website or availing the
      Services, the User agrees to be bound by the terms of this Privacy Policy, and it shall govern any and all data
      collected (or provided by the User) thereof. If the User does not accept the terms of the Privacy Policy, the User
      is directed to discontinue accessing the Website in any manner. It is strongly recommended that this Privacy
      Policy is read carefully, along with the Terms of Use and such other documents or policies that may be applicable
      for availing the Services, before proceeding with using/accessing the Website or availing the Services.
    </p><br><br>
    <p class="information-collection">
      ANY CAPITALIZED TERMS USED HEREIN WITHOUT DEFINITION SHALL HAVE THE MEANING GIVEN TO THEM IN THE TERMS OF USE
      AVAILABLE AT:
      <span class="explorazor-links">
        <a href="https://www.vphrase.com//explorazor/#/terms-and-conditions">
          TERMS AND CONDITIONS
        </a>
        AND
        <a href="https://www.vphrase.com//explorazor/#/term-of-use">
          TERM OF USE
        </a>
      </span>
    </p><br><br><br>
    <p class="information-collection">
      <b>Information collection – manner and how?</b>
    </p>
    <p class="information-collection">
      Information is collected in the manner listed hereinbelow:
    </p>
    <br>
    <p>
      (a) Information that the User shares: For getting in touch with the Company, and/or availing the Services, the
      User provides information such as name, email address and contact information, bank account related information
      (for making the payment towards the Subscription Fees) and such other information that the User thinks is required
      to be provided to the Company for availing the Services.
    </p><br>
    <p>
      (b) Information that is collected while the User accesses the Website with help of technology: We collect certain
      data (such as User’s IP address, information about the device through which the User accesses the Website, User’s
      geographic locations, if permitted by the User) through different mechanisms, such as cookies (for details refer
      cookies clause below), third party tools. Information collected here is primarily to improve User’s experience on
      the Website.
    </p><br>
    <p class="information-collection">
      (c) Information provided by third parties about the User: We may also collect and
      store the information provided
      by others about the User.
    </p><br>
    <p>
      (d) Information collected for free resources made available on the Website: In case the User requests (i) for a
      demo of the Services, or (ii) to download the white papers and case studies made available to the user for free of
      cost on the Website or uploaded on the Website, or (iii) to attend a free webinar, Users will be required to
      submit information as may be sought by the Company.
    </p><br><br>
    <p class="information-collection">
      <b>
        Why the Website collects User’s information?
      </b>
    </p><br>
    <p>
      The Website will ask the User when it needs information that personally identifies the User (personal
      information). Generally, this information is requested when the User registers for the Services and request for
      access to the Website. Information about the User is collected / obtained, as the case may be, primarily, for the
      general purposes, which includes:
    </p><br>
    <div class="information-collection">
      <p>
        (a) to make the Website easier for the Users to use by not having to enter information more than once;
      </p><br>
      <p>
        (b) to help to create content most relevant to the User;
      </p><br>
      <p>
        (c) to understand the User’s preferences to enhance the User’s experience;
      </p><br>
      <p>
        (d) to facilitate User’s access to and use of the Website and/or Services;
      </p><br>
      <p>
        (e) to communicate with the User in general, including to provide information about us;
      </p><br>
      <p>
        (f) to respond to User’s questions and comments, if any;
      </p><br>
      <p>
        (g) to understand the User’s behaviour and the manner in which the User uses and accesses the Website;
      </p><br>
      <p>
        (h) to alert the User of product upgrades, updates, updated information and other new services from the
        Website;
      </p><br>
      <p>
        (i) to provide service and support, such as sending confirmations, invoices, technical notices, updates,
        security alerts, and administrative messages and providing customer support and troubleshooting; and/or
      </p><br>
      <p>
        (j) to protect against, investigate, and deter fraudulent, unauthorized, or illegal activity;
      </p><br><br>
    </div>
    <p class="information-collection">
      <b>
        With whom the Company shares User’s information?
      </b>
    </p><br>
    <p>
      We neither rent, sell nor lease User’s information in personally identifiable form to anyone, unless prior written
      intimation is provided to the User. However, this does not include such third parties who assist the Company in
      operating and managing the Website or serving each User, so long as such third parties (as described hereinbelow)
      agree to keep the information confidential.
    </p><br>
    <div class="information-collection">
      <p>
        (a) Links to External websites: The Website may link to other websites/channels that may collect the User’s
        personal information. Website will not be responsible for the privacy practices or the content of those third
        party linked websites/channels. The Company makes all efforts to ensure that the Company collects and shares
        only such information that it believes to be relevant in order to record, support, and facilitate the User’s
        transactions on the Website.
      </p><br>
      <p>
        (b) Third-party vendors: The Company engages with third-party vendors for various purposes like business
        analytics, customer service, marketing, distribution, etc. The Company may authorize such vendors to collect
        information on behalf of the Company in order to operate features of the Company or to deliver services tailored
        to the User’s needs. The Company assures that such information used by third-party vendors is only to perform
        their functions and they are not permitted to share or use that information for any other purpose.
      </p><br>
      <p>
        (c) Business Partners: The Company takes help of various business partners in conjunction with whom it manages
        the Website or offers the Services. If the User chooses to access these optional services provided by the
        Company’s business partners, the Company may share information about the User with such partners. Please note
        that the Company does not control the privacy practices of these third-party business partners.
      </p><br>
      <p>
        (d) Affiliated websites/channels: If the User was referred to us from another website/channel, the Company may
        need information pertaining to the User from such website/channel. In such instances the Company encourages the
        User to read and review the privacy policy of such website/channel that referred the User to the Website. The
        Company will not be responsible for the handling of such information by them.
      </p><br>
      <p>
        (e) Business transfers: The Company may choose to buy or sell assets. In these types of transactions,
        information is typically one of the business assets that would be transferred. Also, if the Company (or assets
        of the Company), as applicable, are acquired, or if the Company goes out of business, enters bankruptcy, or goes
        through some other change of control, the User’s information would be one of the assets transferred to or
        acquired by a third party.
      </p><br>
      <p>
        (f) Government bodies: The Company may disclose User’s information as and when required in response to a
        sub-poena, court order, government demand or to comply with any legal requirements. The Company will contact the
        User by e-mail when the Company has to adhere to such legal request. All communications sent by the Company at
        such email address shall be deemed to have been communicated to the User.
      </p><br><br>
    </div>
    <p class="information-collection">
      <b>
        How does Website protect information of the User?
      </b>
    </p><br>
    <p>
      The Company wants the User to have full faith in having the User’s information safe and secure with the Company.
      The Company generally follows accepted standards to protect the User’s information. While no services can
      guarantee full-proof security, the Company has appropriate administrative, technical and security procedures to
      keep User’s information intact, secure and confidential. The Company strictly protects the security of User’s
      information and honors User’s choices for the intended use. Subject to the User’s compliance with the Terms of Use
      and such policies applicable to the User, the Company takes strong precautions to protect User’s information from
      loss, misuse, unauthorized access or disclosure, alteration, or destruction. The Company employs appropriate
      firewalls, encryption technologies and other security measures to keep User’s information secure and safe. The
      User’s information is encrypted and stored in controlled servers with limited access.
    </p><br><br>
    <p class="information-collection">
      <b>
        Whether data of the User moves internationally?
      </b>
    </p><br>
    <p>
      If User is accessing the Website from outside India, please be mindful that such information may be transferred,
      stored or processed in the countries where our servers or central databases are located, resulting in transferring
      or processing of such User information in countries other than the country in which the User is a resident or the
      country from which the Company has received User’s information. Even though User accesses it from a foreign land,
      the Company ensures that it will follow the best practices in keeping the User information protected. Please be
      informed that every country has difference data protection laws which may be different than the laws of country
      from where the Services are being accessed, and in certain cases it may not be as protective.
    </p><br>
    <p>
      Additionally, in case Company collects and uses User’s personal information in reliance on legitimate interests
      (or those of any third party) of the Company, it will make clear to the User at the relevant time what those
      legitimate interests are.
    </p><br><br>
    <p class="information-collection">
      <b>
        Use of ‘Cookies’
      </b>
    </p><br>
    <p>
      If the User is accessing the Website through an electronic device (either mobile or computers) the Website places
      “Cookies” on such electronic device to identify it. “Cookies” are identifiers the Website transfers to electronic
      device used by the User that allow the Website to recognize device of the User and tell the Website how the
      features in Website are visited. The User may be able to change the preferences on the device/computer to prevent
      or limit device’s acceptance of cookies, but this may prevent the User from taking advantage of some of our
      features provided on the Website. If the User clicks on a link to a third-party website, such third party may also
      transmit cookies to the User. The User hereby agrees that this Privacy Policy does not cover the use of cookies by
      any third parties.
    </p><br><br>
    <p class="information-collection">
      <b>
        Modification to this Privacy Policy
      </b>
    </p><br>
    <p>
      The Company reserves the right to update and/or change the terms of this Privacy Policy, and the same shall be
      posted on the Website and intimated to the Users of the Website, so that users and/or visitors are always aware of
      the type of information the Website collects, how it will be used, and under what circumstances, if any, Website
      may disclose such information.
    </p><br><br>
    <p class="information-collection">
      <b>
        Changes to Privacy Policy

      </b>
    </p><br>
    <p>
      The Company has and continues to have the right to modify, change or update this Privacy Policy at any time by
      reasonably highlighting on the Website about such change or notifying the User of the change in some manner. Such
      changes shall be effective immediately upon posting on the Website or notifying the User, whichever is earlier.
      The User is advised to keep a regular check and update on the Privacy Policy updates on the Website.
    </p><br><br>
    <p class="information-collection">
      <b>
        Agreement
      </b>
    </p><br>
    <p>
      This Privacy Policy along with the Terms of Use of the Website and any other relevant policies or disclosures
      contains the entire understanding and agreement between the Company and the User and supersedes any and all prior
      understandings relating to the Service or the Website. This Policy cannot be changed or terminated orally. If any
      provision of this Privacy Policy is held to be illegal, invalid or unenforceable, this will not affect any other
      provisions and the Privacy Policy will be deemed amended to the extent necessary. Any provision which must survive
      in order to allow the Website to enforce its meaning, shall survive the termination of this Privacy Policy. This
      Privacy Policy should be at all times read along with the Terms of Use and any other policies of the Website.
    </p><br><br>
    <p class="information-collection">
      <b>
        Legal Obligation
      </b>
    </p><br>
    <p>
      The Company is bound by the Information Technology Act, 2000, and complies with all its provisions. In accordance
      with the Section 43A of Information Technology Act, 2000, the Company is obliged to maintain reasonable security
      procedures to safeguard the User Data received or provided by the User.
    </p><br>
    <p>
      In accordance with Regulation 4 of the Information Technology (Reasonable Security and Procedures and Sensitive
      Personal Data or Information) Rules, 2011, the Company is obliged to provide every registered user of the Website
      with a readable copy of the information that it keeps about the User. If the User would like to have a copy of the
      same, User can reach out to the
      Company at <a href="contact@vphrase.com/explorazor/#/contact-us">Contact US</a>. Upon receiving
      such
      request from the
      User, Website
      shall within period of 30 (thirty) days, provide the information sought by the User.
    </p><br><br>
    <p class="information-collection">
      <b>
        Contacting the Website
      </b>
    </p><br>
    <p>
      For questions related to updating or changing User account information or any other information provided by the
      User and for valuable feedback regarding this Privacy Policy, please send e-mail to
      <span><a href="contact@vphrase.com/explorazor/#/contact-us">Contact Us</a></span>.
    </p><br><br>
  </div>
</template>

<script>
  import { globalMixin } from '@/mixins/globalMixin';

  export default {
    mixins: [globalMixin],

  };
</script>
<style scoped>


  .explorazor-links {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-content: center;
  }

</style>
