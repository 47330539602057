// import RSSParser from 'rss-parser/dist/rss-parser.min';

export const rssParserMixins = {
  props: {},
  data: function () {
    return {
      // Note: some RSS feeds can't be loaded in the browser due to CORS security.
      // To get around this, you can use a proxy
      corsProxy: 'https://cors-anywhere.herokuapp.com/',
    };
  },
  methods: {
    getParser: function () {
      return '';
    },

    fetchParsedFeed: async function ({ url }) {
      return {};
      try {
        const response = '';
        return Promise.resolve(response);
      } catch (err) {
        console.log(err);
        return Promise.reject(err);
      }
    },
  }
};