<template>
  <div style="margin-top:60px;text-align: center;height:auto;width:100%">
    <vs-row class="error-container">
      <vs-col
        :vs-w="screenWidth < 1100 ? 5 : 12"
        class="error-content"
      >
        <div class="error-content-wrapper">
          <div class="error-header-container">
            <div class="error-header">
              Oops...
            </div>
            <div class="error-header">
              Page not found
            </div>
          </div>
          <div class="error-text-container">
            <div class="error-text">
              This Page doesn`t exist or was removed!
            </div>
            <div class="error-text">
              We suggest you back to home.
            </div>
          </div>
          <div
            class="home-button-container"
          >
            <vs-button
              class="home-button"
              style="float: left"
              @click.native="routeTo({name:'NewHomePage'})"
            >
              Back to Home
            </vs-button>
          </div>
        </div>
      </vs-col>
      <vs-col
        :vs-w="screenWidth < 1100 ? 7 : 12"
        class="error-image-container"
      >
        <img
          src="../../assets/svgs/card-image/error-image.svg"
          alt="error-image"
          class="error-image"
        >
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
  export default {
    name: 'ErrorRoute',
    data: function () {
      return {
        screenWidth: null
      };
    },
    mounted () {
      this.screenWidth = screen.availWidth;
    }
  };
</script>

<style scoped>
  .error-content {
    display: flex;
    justify-content: end;
    align-content: end;
    align-items: end;
    flex-direction: column;
    margin: auto;
  }

  .error-header {
    color: #191847;
    font-family: Fraunces;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
  }

  .error-text {
    color: #191847;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
  }

  .error-text-container {
    margin-top: 25px;
  }

  .home-button-container {
    margin-top: 25px;
  }

  .home-button {
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px 20px !important;
    border-radius: 4px;
  }


  @media only screen and (max-width: 1100px) {
    .error-content {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      order: 2;
      margin-bottom: 50px;
      text-align: center;
    }

    .error-image-container {
      order: 1;
      justify-content: center;
      align-content: center;
      align-items: center;
    }

    .error-content {
      width: 100% !important
    }

    .error-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    .error-content-wrapper {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
    }

    .error-text {
      text-align: center !important
    }

    .error-header {
      text-align: center !important;
    }

    .error-image {
      width: 100%
    }
  }

  @media only screen and (max-width: 414px) {
    .error-image-container {
      width: 90% !important
    }

    .error-header {
      color: #191948;
      text-align: center;
      /* Mobile - Heading 1 */
      font-family: Fraunces;
      font-size: 42px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .error-text {
      color: #6A7591;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }

    .error-content-wrapper {
      padding: 0 5% !important;
    }

    .home-button-container {
      width: 100% !important
    }

    .home-button {
      width: 100% !important
    }
  }
</style>