<template>
  <div
    class="footer"
    style="overflow-x:hidden !important "
  >
    <vs-row class="footer-container">
      <div class="analysis-content">
        <div>
          <img
            alt=""
            class="footer-logo"
            src="@/assets/svgs/logo/Group.svg"
          >
        </div>
        <app-text-render
          sub-content="Explorazor is a data exploration and analysis tool where
          Brand and Sales teams get an integrated view of all their datasets,
          obtain instant data pivots, and test out hypotheses much faster than otherwise."
        />
      </div>
      <div
        v-if="screenWidth <650"
        class="footer-analysis-table"
      >
        <div>
          <table>
            <tr>
              <th><h3>Product</h3></th>
              <th><h3>Company</h3></th>
            </tr>
            <tr>
              <td @click="scrollToTop()">
                <router-link :to="{name:'Demo'}">
                  <p>Request A Demo</p>
                </router-link>
              </td>
              <td>
                <a
                  href="https://www.vphrase.com/about-us"
                  target="_blank"
                ><p>About Us</p></a>
              </td>
            </tr>
            <tr>
              <td @click="scrollToTop()">
                <router-link :to="{name:'Features'}">
                  <p>Product</p>
                </router-link>
              </td>
              <td>
                <a
                  href="https://www.vphrase.com/awards-recognition//"
                  target="_blank"
                ><p>Awards</p></a>
              </td>
            </tr>
            <tr>
              <td>
                <router-link :to="{name:'ThoughspotVsExplorazor'}">
                  <p>Explorazor v/s ThoughtSpot</p>
                </router-link>
              </td>
              <td @click="scrollToTop()">
                <a
                  href="https://www.vphrase.com/news"
                  target="_blank"
                ><p>Press & Media</p></a>
              </td>
            </tr>
            <tr>
              <td @click="scrollToTop()">
                <router-link
                  :to="{name:'Pricing'}"
                >
                  <p>Pricing</p>
                </router-link>
              </td>
              <td @click="scrollToTop()">
                <a
                  href="https://folkflow.com/co/vphrase"
                  target="_blank"
                ><p>Careers</p></a>
              </td>
            </tr>
            <tr>
              <td>
                <router-link :to="{name:'Home'}">
                  <p style="padding-top:0px;">
                    Explorazor for CPG
                  </p>
                </router-link>
              </td>
              <td>
                <a
                  href="https://phrazor.ai/"
                  target="_blank"
                ><p>Phrazor</p></a>
              </td>
            </tr>
            <tr>
              <td @click="scrollToTop()">
                <router-link :to="{name:'UseCase'}">
                  <p>Use Cases</p>
                </router-link>
              </td>
              <td @click="scrollToTop()">
                <router-link
                  :to="{name:'Contact'}"
                >
                  <p>Contact Us</p>
                </router-link>
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href="https://www.vphrase.com/explorazor-blog/"
                  target="_blank"
                ><p>Blogs</p></a>
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href="https://www.vphrase.com/explorazor-docs/"
                  target="_blank"
                ><p>Documentation</p></a>
              </td>
            </tr>
            <tr>
              <td @click="scrollToTop()">
                <router-link
                  :to="{name:'DanoneUsecase'}"
                >
                  <p>Testimonial</p>
                </router-link>
              </td>
              <td />
            </tr>
          </table>
        </div>
      </div>
      <div
        v-if="screenWidth > 650"

        class="footer-analysis-table"
      >
        <div>
          <table>
            <tr>
              <th><h3>Product</h3></th>
              <th><h3 /></th>
              <th><h3>Company</h3></th>
            </tr>
            <tr>
              <td @click="scrollToTop()">
                <router-link :to="{name:'Features'}">
                  <p>Product</p>
                </router-link>
              </td>
              <td @click="scrollToTop()">
                <router-link :to="{name:'Demo'}">
                  <p>Request A Demo</p>
                </router-link>
              </td>
              <td>
                <a
                  href="https://www.vphrase.com/about-us"
                  target="_blank"
                ><p>About Us</p></a>
              </td>
            </tr>
            <tr>
              <td @click="scrollToTop()">
                <router-link
                  :to="{name:'Pricing'}"
                >
                  <p>Pricing</p>
                </router-link>
              </td>
              <td>
                <a
                  href="https://www.vphrase.com/explorazor-blog/"
                  target="_blank"
                ><p>Blogs</p></a>
              </td>
              <td @click="scrollToTop()">
                <a
                  href="https://www.vphrase.com/news"
                  target="_blank"
                ><p>Press & Media</p></a>
              </td>
            </tr>
            <tr>
              <td @click="scrollToTop()">
                <router-link :to="{name:'ThoughspotVsExplorazor'}">
                  <p>Explorazor V/S ThoughtSpot</p>
                </router-link>
              </td>
              <td>
                <a
                  href="https://www.vphrase.com/explorazor-docs/"
                  target="_blank"
                ><p>Documentation</p></a>
              </td>
              <td @click="scrollToTop()">
                <a
                  href="https://folkflow.com/co/vphrase"
                  target="_blank"
                ><p>Careers</p></a>
              </td>
            </tr>
            <tr>
              <td @click="scrollToTop()">
                <router-link :to="{name:'Home'}">
                  <p style="padding-top:0px;">
                    Explorazor for CPG
                  </p>
                </router-link>
              </td>
              <td />
              <td>
                <a
                  href="https://phrazor.ai/"
                  target="_blank"
                ><p>Phrazor</p></a>
              </td>
            </tr>
            <tr>
              <td @click="scrollToTop()">
                <router-link :to="{name:'UseCase'}">
                  <p>Use Cases</p>
                </router-link>
              </td>
              <td />
              <td @click="scrollToTop()">
                <router-link
                  :to="{name:'Contact'}"
                >
                  <p>Contact Us</p>
                </router-link>
              </td>
            </tr>
            <tr>
              <td @click="scrollToTop()">
                <router-link :to="{name:'DanoneUsecase'}">
                  <p>Testimonial</p>
                </router-link>
              </td>
              <td />
              <td>
                <a
                  href="https://www.vphrase.com/awards-recognition//"
                  target="_blank"
                ><p>Awards</p></a>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="icon-container">
        <img
          alt=""
          class="footer-nasscom-img"
          src="https://www.vphrase.com/explorazor-blog/wp-content/uploads/2022/10/NASSCOM-DeepTech-Badge.png"
        >
        <img
          alt=""
          class="footer-side-img"
          src="https://www.vphrase.com/explorazor-blog/wp-content/uploads/2022/10/intel-badge.png"
        >
        <div class="footer-icons">
          <a
            class="fa fa-facebook"
            href="https://www.facebook.com/vphrase"
            target="_blank"
          />
          <a
            class="fa fa-twitter"
            href="https://twitter.com/vphrase"
            target="_blank "
          />
          <a
            class="fa fa-instagram"
            href="https://www.instagram.com/vphrase_ai/?hl=en"
            target="_blank"
          />
          <a
            class="fa fa-linkedin"
            href=" https://www.linkedin.com/company/vphrase/"
            target="_blank"
          />
          <a
            class="fa fa-youtube"
            href=" https://www.youtube.com/channel/UCKvuev34c6LVN2JiiylOuIQ"
            target="_blank"
          />
        </div>
      </div>
    </vs-row>
    <vs-row
      class="footer-analysis-all-right-reserved"
      vs-justify="center"
    >
      <div
        class="term-and-policy"
        @click="scrollToTop()"
      >
        <router-link
          :to="{name:'Term-Of-Use'}"
          class="term-link"
        >
          Term Of Use
        </router-link>
        <router-link
          :to="{name:'Terms-And-Conditions'}"
          class="term-link"
        >
          Terms and Conditions
        </router-link>
        <router-link :to="{name:'privacy'}">
          Privacy Policy
        </router-link>
      </div>
      <div class="vphrase-analytics">
        <p>
          All Rights Reserved. Explorazor is brought to you by&ensp;
          <a href="http://www.vphrase.com">vPhrase Analytics Solutions Pvt. Ltd.</a>
        </p>
      </div>
    </vs-row>
  </div>
</template>

<script>
  import AppTextRender from '@/components/wrapper/TextRender';

  export default {
    components: {
      AppTextRender
    },
    data: function () {
      return {
        screenWidth: null
      };
    },
    mounted () {
      this.screenWidth = screen.availWidth;
    }
  };
</script>

<style scoped>
  td {
    padding-top: 0px !important;
    padding-left: 30px !important
  }

  th {
    padding-left: 30px !important
  }

  table {

    justify-content: space-around !important;
  }

  .footer-analysis-table {
    width: 50% !important
  }

  .analysis-content {
    width: 25% !important;
    margin-left: 5% !important
  }

  .footer .footer-analysis-table {
    justify-content: space-between !important;
  }

  @media screen and (max-width: 1200px) {
    .footer-container {
      flex-direction: column !important;
    }

    .analysis-content {
      width: 100% !important
    }

    .footer-analysis-table {
      width: 100% !important;
      justify-content: start !important;
    }
  }

  @media screen and (max-width: 414px) {
    table {
      display: flex !important;
      flex-direction: column !important;
    }
  }
</style>
