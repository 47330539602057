<template>
  <div class="policy">
    <div class="policy-header">
      <h4>EXPLORAZOR | SUBSCRIPTION AGREEMENT</h4>
    </div>
    <p>
      BY USING OR ACCESSING ALL OR ANY PORTION OF EXPLORAZOR, YOU AGREE AND ACKNOWLEDGE THAT ALL OF THE TERMS AND
      CONDITIONS OF THIS SUBSCRIPTION AGREEMENT AS PUBLISHED ON THE WEBSITE <a
        href="https://www.vphrase.com/explorazor"
      >EXPLORAZOR</a>, OR SUCH
      OTHER URL
      AS
      MAY BE USED BY VPHRASE ANALYTICS SOLUTIONS PRIVATE LIMITED OR ITS AFFILIATES (“<b>COMPANY</b>”). YOU AGREE THAT
      THIS
      SUBSCRIPTION AGREEMENT IS BINDING AND ENFORCEABLE LIKE ANY WRITTEN AGREEMENT SIGNED BETWEEN YOU AND COMPANY. IF
      YOU DO NOT AGREE TO ALL OF THESE TERMS AND CONDITIONS STATED UNDER THIS SUBSCRIPTION AGREEMENT, DO NOT ACCESS OR
      USE EXPLORAZOR. IF YOU WISH TO USE EXPLORAZOR AND THE SERVICES ASSOCIATED WITH EXPLORAZOR AS AN EMPLOYEE,
      CONTRACTOR, OR
      AGENT OF ANY LEGAL ENTITY (INCLUDING PARTNERSHIP FIRM OR LIMITED LIABILITY PARTNERSHIP), THEN YOU MUST BE
      AUTHORIZED TO SIGN FOR AND BIND THE CONCERNED LEGAL ENTITY IN ORDER TO ACCEPT THE TERMS OF THIS SUBSCRIPTION
      AGREEMENT AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE ALL THE RIGHT AND AUTHORITY REQUIRED IN ORDER TO USE
      EXPLORAZOR. YOU AGREE THAT YOUR USE IS SUBJECT TO ANY OTHER TERMS OF SERVICE AND POLICIES POSTED BY THE COMPANY ON
      THE WEBSITE OF EXPLORAZOR.
    </p><br><br>
    <p>
      This Subscription Agreement (“<b>Agreement</b>”) is between the Company and the user (individual or an entity, as
      the
      case may be) (“<b>you</b>” or “<b>your</b>”) that has subscribed to the services provided by the Company through
      its online
      platform ‘Explorazor’.
    </p><br><br>
    <h4 class="information-collection header">
      1. DEFINITIONS
    </h4><br>
    <div class="information-collection">
      <p>
        1.1 “<b>Authorized User</b>” means those uniquely identified individuals who are authorized by you to access and
        use the Subscription Service;
      </p><br>
      <p>
        1.2 “<b>Affiliate</b>” means, in relation to: (a) a natural Person, the relatives of such natural Person and/or
        a
        Person controlled by such natural Person; and (b) a Person other than a natural Person: (i) any other Person
        controlled by such Person (directly or indirectly); (ii) any other Person controlling such Person (directly or
        indirectly); or (iii) any other Person controlled (directly or indirectly) by any other Person controlling such
        Person;
      </p><br>
      <p>
        1.3 “<b>Confidential Information</b>” means: (a) the Subscription Service (which is solely Company’s
        confidential
        information); (b) any information of a party that is disclosed in writing or orally and designated confidential
        at time of disclosure (and, for oral disclosures, summarized in writing within 30 (thirty) days of initial
        disclosure and delivered in written summary form to receiving party), or that, due to the nature of the
        information or circumstances of disclosure, receiving party should reasonably understand to be disclosing
        party’s confidential information; and (c) the terms of this Agreement, any Subscription Documents, and any
        amendment or attachment to any of these (which will be deemed Confidential Information of both parties);
      </p><br>
      <p>
        1.4 “<b>Documentation</b>" shall mean and include all operator and user manuals, training materials, guides,
        listings,
        specifications, and other materials for use in conjunction with the Subscription Service;
      </p><br>
      <p>
        1.5 “<b>Effective Date</b>” shall mean the date of your relevant subscription to the Subscription Service (as
        stated
        in the concerned Subscription Document, as applicable) or the initial delivery date of credentials to access the
        Subscription Service (whichever is earlier);
      </p><br>
      <p>
        1.6 “<b>Intellectual Property Rights</b>” means all intellectual property or other proprietary rights
        worldwide,
        including patent, trademark, service mark, copyright, trade secret, know-how, moral right, and any other
        intellectual and intangible property rights, including all continuations, continuations in part, applications,
        renewals, and extensions of any of the foregoing, whether registered or unregistered;
      </p><br>
      <p>
        1.7 “<b>Person</b>” shall mean any individual, firm, company, government, state or agency of a state, local or
        municipal authority or government body or any joint venture, a Hindu undivided family (whether or not
        registered), an estate, a trust, a limited or unlimited liability company, a company limited by guarantee, a
        body corporate, a limited liability partnership, association or partnership (whether or not having separate
        legal personality) or any other legal entity;
      </p><br>
      <p>
        1.8 “<b>Subscription Service</b>” shall mean a subscription based software-as-a-service provided by the Company
        to the
        you for its system ‘Explorazor’;
      </p><br>
      <p>
        1.9 “<b>Subscriber Data</b>” means the data, information, material uploaded/provided by or for you or your
        agents,
        employees, or contractors, and processed using the Subscription Service;
      </p><br>
      <p>
        1.10 “<b>Subscription Fee</b>” shall mean any fee paid by you to avail the Subscription Services of the
        Company;
      </p><br>
      <p>
        1.11 “<b>Subscription Term</b>” means the period of authorized access to and use of the Subscription Service
        depending
        on the subscription plans as may be chosen by you, or any such specific date as set forth in the relevant
        Subscription Document, as the case may be; and
      </p><br>
      <p>
        1.12 “<b>Subscription Document(s)</b>” means any documents executed between the Company and you which
        references this
        Agreement. Each Subscription Document which references this Agreement shall be deemed a part of this Agreement,
        including not limited to (a) any work order (solely with respect to that work order); and (b) any other document
        incorporated by reference herein.
      </p><br>
      <br>
    </div>
    <h4 class="information-collection header">
      2. SOFTWARE AS A SERVICE SUBSCRIPTION
    </h4><br>
    <div class="information-collection">
      <p>
        2.1 Subscription: Subject to the terms of this Agreement, the Company hereby grants to you a non-sub licensable,
        non-transferable, non-exclusive subscription to access and use the Service, solely for your internal business
        purposes. You agree to comply with the terms and conditions set out in this Agreement. You also agree to refrain
        from taking any steps, such as reverse assembly or reverse compilation, to derive a source code equivalent to
        the Company’s Intellectual Property Rights. All Company’s Intellectual Property Rights licensed to you under
        this Agreement shall be used for your internal business purposes, shall not be reproduced, distributed or copied
        in whole or in part. You shall have the access to necessary troubleshooting support services to use the
        Subscription Service in relation to periodical maintenance and updation of Explorazor from time to time.
      </p><br>
      <p>
        2.2 Authorized User: In order to use the Subscription Service, you can access the Subscription Services on the
        Company’s website (as stated above). To access the Subscription Services, you will need to register yourself on
        the Company’s website and create a username and password of your choice. If required, and upon request from you,
        the Company shall assign a unique combination of a username and password to you to access the Subscription
        Services, provided that, the organisation which has subscribed for the Subscription Service have provided the
        Company with a list of Authorized Users (along with the email addresses) which are to be provided access to the
        Subscription Service and the Company shall generate the usernames and one-time passwords for the relevant
        Authorized Users. It is your sole responsibility to (a) keep the username and password secure and confidential;
        and (b) provide the username(s) and password(s) to its employee / Authorized Users for accessing the
        Subscription Service. You may allow your contractors and Affiliates to access Subscription Service as Authorized
        Users in accordance with this Agreement, provided you shall remain liable for all acts and omissions of your
        Affiliates and Contractors as if their breach were your own.
      </p><br>
      <p>
        2.3 Trial Access. If Company has made available to you free or trial access to Explorazor (depending on the
        relevant subscription plan and functionality as specified on the website <a
          href="https://www.vphrase.com/explorazor"
        >Explorazor</a>,
        at no cost but
        for a limited period and limited features, for purposes of you being able to evaluate and subscribe to the
        Subscription Service (“Trial Access”), you may use such Trial Access solely for the purpose of evaluating
        Explorazor to determine whether to purchase / avail the Subscription Service. You may not use Trial Access for
        any
        other purposes, including but not limited to competitive analysis, commercial, professional or for-profit
        purposes. Trial Access is limited to 30 (thirty) days from the date you activate your Trial Access, unless
        otherwise specified by the Company in the applicable Subscription Documents or a separate writing from the
        Company (“Trial Period”). Unless you subscribe for the Subscription Service, your Trial Access may be terminated
        and, in any event your right to access and use Explorazor automatically expires at the end of the Trial Period.
        Upon any termination or expiration of the Trial Period, your Trial Access will cease and you will no longer have
        access to any Subscriber Data used in connection with such Trial Access. Notwithstanding any other provision of
        this Agreement, Trial Access is provided “as is” without warranty or support of any kind, express or implied.
        The Company may terminate your Trial Access at any time for any reason and without liability of any kind. If you
        subsequently subscribe to for the Subscription Service, your Trial Access shall immediately terminate and you
        expressly agree that, unless you have a separate signed agreement governing your access to and use of the
        Subscription Service, this agreement, and the terms and conditions herein, shall govern your use of Explorazor.
      </p><br>
      <p>
        2.4 Free Access. If the Company has made available to you free access to Explorazor (depending on the relevant
        subscription plan and functionality as specified on the website <a
          href="https://www.vphrase.com/explorazor"
        >Explorazor</a>,, at no cost
        but with
        limited features/access (“Free Access”), you may use such Free Access, provided that you are the only person who
        may use the Free Access under this Agreement. The Company may collect, use and store any data or information
        with respect to your usage of the Explorazor to modify and improve any product of the Company or provide
        additional
        software or services, without any compensation to you. You may not use Free Access for any other purposes,
        including but not limited to competitive analysis, commercial, professional or for-profit purposes. Free Access
        with limited functionality and features will be available for lifetime, subject to the discretion of the Company
        (“Free Period”), and the Free Access will be activated from the date you activate and subscribe for the Free
        Access. You acknowledge and agree that the Company, at its sole discretion, shall have the right to terminate,
        cancel or suspend the Free Access by giving to you a notice of any such period which shall not be more than 30
        (thirty) days. Unless you subscribe for the Free Access to avail the Subscription Service, your Free Access will
        not be activated. Upon any termination of the Free Period, your Trial Access will cease and you will no longer
        have access to any Subscriber Data used in connection with such Free Access. Notwithstanding any other provision
        of this Agreement, Free Access is provided “as is” without warranty or support of any kind, express or implied.
        The Company may terminate your Free Access at any time for any reason and without liability of any kind. If you
        subsequently subscribe to for the Subscription Service, your Free Access shall be converted to the relevant
        subscribed Subscription Service immediately. You expressly agree and acknowledge that, your access to use of the
        Free Access and/or the Subscription Service shall be governed by this Agreement, and the terms and conditions
        herein, shall govern your use of Explorazor.
      </p><br>
      <br>
    </div>
    <h4 class="information-collection header">
      3. SUBSCRIBER DATA
    </h4><br>
    <div class="information-collection">
      <p>
        3.1 You will be solely and absolutely responsible for the Subscriber Data uploaded/provided through the
        Subscription Service including without limitation accuracy and content of all the Subscriber Data. You shall
        ensure that the Subscriber Data does not: (a) infringe or violate any intellectual property rights,
        publicity/privacy rights, law or regulation; or (b) contains any viruses or programming routines intended to
        damage, surreptitiously intercept or expropriate any system, data or personal information. You shall fully
        indemnify the Company should damage be caused to the Company due to any such actions. The Company is not and
        shall not be obliged to review the Subscriber Data for accuracy or potential liability. The Company shall not
        have any obligation to moderate any Subscriber Data provided.
      </p><br>
      <p>
        3.2 You represent and warrant that: (a) it will not use the Services in a manner that: (i) is prohibited by any
        law or regulation; (ii) will disrupt a third parties’ similar use of the Subscripting Service; (b) it will be
        responsible for any acts or omissions on the part of the User who uses the Services and any content/data posted
        by such User; (c) it will not violate or tamper with the security of any of the Company’s Intellectual Property;
        and (d) it has sufficient rights in the Subscriber Data to authorize the Company to process, distribute and
        display the Subscriber Data as contemplated by this Agreement.
      </p><br>
      <p>
        3.3 As between the parties, you shall retain all right, title and interest (including any and all intellectual
        property rights) in and to Subscriber Data as published using Subscription Service. Subject to the terms of this
        Agreement, you hereby grant to the Company a non-exclusive, worldwide, royalty-free right to use, copy, store,
        transmit, and distribute, perform and display (including publicly), modify and create derivative works of the
        Subscriber Data solely to the extent necessary to provide the Subscription Service in accordance with this
        Agreement.
      </p><br>
      <p>
        3.4 The Company does not provide an archiving service. The Company agrees it shall not intentionally delete any
        Subscriber Data prior to termination or expiration of the applicable subscription. Except as otherwise set forth
        herein, the Company expressly disclaims all other obligations with respect to storage of Subscriber Data.
      </p><br>
      <br>
    </div>
    <h4 class="information-collection header">
      4. SUBSCRIPTION FEE
    </h4><br>
    <div class="information-collection">
      <p>
        4.1 The detailed structure of the Subscription Fee for the Subscription Services is detailed in
        https://vphrase.com/explorazor/#/pricing, as may be modified and amended from time to time. Payments shall be
        due in advance
        and should be made prior to accessing the Subscription Service. Except as expressly set forth herein, all fees
        are non-refundable once paid. For the avoidance of all doubts, the Subscription Service shall automatically be
        renewed upon expiry as per your already purchased subscription plan and you agree and authorize the Company (and
        any such third party payment services provider which provides the payment gateway services for Explorazor) to
        debit
        your payment method (through which the payment is made towards the subscription plan of your choice) linked to
        Subscription Service, depending on the type of Subscription Service availed by you. In the event your payment
        method is not auto-debited for the next cycle of the Subscription Service, the Company shall have the right to
        suspend, terminate or cancel your Subscription Service with immediate effect. In the event you do not wish to
        renew the subscription, you can cancel the subscription for renewal at any time during the validity of your
        Subscription Term. When you request for cancellation of auto-renewal of your subscription, your Subscription
        Term will not be auto-renewed and you can continue to access the Subscription Service only during your existing
        subscription plan (i.e. the then existing Subscription Term) until the expiry of the validity period. To request
        for cancellation of auto-renewal of your subscription, you are required to go to your Account page on the
        Subscription Service and cancel your subscription.
      </p><br>
      <p>
        4.2 If any withholding tax is required by applicable law to be paid by you in relation to payments due to the
        Company hereunder, you will provide the Company with official receipts and/or certificates from the appropriate
        taxing authorities to establish that any applicable taxes have been paid.
      </p><br>
      <br>
    </div>
    <p class="information-collection">
      <b>
        5. TERM AND TERMINATION
      </b>
    </p><br>
    <div class="information-collection">
      <p>
        5.1 This Agreement is effective as of the Effective Date and expires on the date of expiration or termination of
        the Subscription Services.
      </p><br>
      <p>
        5.2 The Company shall have the right to immediately terminate this Agreement:
      </p><br>
      <div class="performance-list">
        <p>
          (a) if you fail to perform any obligation required of you under this Agreement; or
        </p><br>
        <p>
          (b) If you breach any of the terms of this agreement; or
        </p><br>
        <p>
          (c) if (save in relation to a re-organisation, re-construction or amalgamation not affecting the
          credit-worthiness of the other Party):
        </p><br>
        <p>
          (i) an order is made or resolution is passed for the winding-up of the other Party or if a provisional
          liquidator is appointed in respect of the other Party; or
        </p><br>
        <p>
          (ii) a receiver (which expression shall include administrative receiver) is appointed in respect of the
          other
          Party or all or any of its assets.
        </p><br>
      </div>
      <p>
        5.3 Effect of Termination. Upon any termination or expiration of this Agreement, you shall immediately cease
        any and all use of and access to Subscription Service and destroy (or, at Company’s request, return), any
        Company’s Confidential Information in its possession. You acknowledge that, except as exported or printed prior
        to termination or expiration by you as may be permitted through the functionality of Explorazor, following
        termination or expiration it shall have no further access to any Data input into Explorazor, and that the
        Company
        may delete any such data at any time. Except where an exclusive remedy is specified in this Agreement, the
        exercise by either party of any remedy under this Agreement, including termination, will be without prejudice to
        any other remedies it may have under this Agreement, by law, or otherwise.
      </p><br>
      <p>
        5.4 Survival: The following Clauses shall survive any expiration or termination of this Agreement: 4
        (Subscription Fee), 5 (Term and Termination), 6 (Intellectual Property), 7 (Confidentiality), 9.1 (Indemnity),
        10 (Limitation of Liability), 11 (Limited Warranty and Disclaimer) and 12 (Miscellaneous).
      </p><br><br>
    </div>
    <h4 class="information-collection header">
      6. INTELLECTUAL PROPERTY
    </h4><br>
    <p>
      You agree that the Subscription Service, Documentation and the authorship, systems, ideas, methods of operation
      and other information contained in Explorazor and the Subscription Service are proprietary information and
      intellectual property and/or the valuable trade secrets of the Company and that the Company is protected by civil
      and criminal law, and by the law of copyright, trade secret, trademark and patent in India, other countries and
      international treaties. This Agreement does not grant you any rights to the intellectual property, including any
      trademarks or service marks of the Company. The Company owns and retains all right, title, and interest in and to
      the Subscription Service, including without limitation any error corrections, enhancements, updates or other
      modifications to the Subscription Service, whether made by the Company or any third party, and all copyrights,
      patents, trade secret rights, trademarks, and other intellectual property rights therein. Your possession,
      installation or use of the Subscription Service does not transfer any title to the intellectual property in the
      Subscription Service to you, and you will not acquire any rights to the Subscription Service except as expressly
      set forth in this Agreement. Except as stated herein, this Agreement does not grant any intellectual property
      rights in the Subscription Service to you and you acknowledge that the subscription, as granted under this
      Agreement only provides you with a right of limited use under the terms of this Agreement. The Company reserves
      all rights not expressly granted to you in this Agreement.
    </p>
    <br>
    <br>
    <h4 class="information-collection header">
      7. CONFIDENTIALITY
    </h4><br>
    <div class="information-collection">
      <p>
        7.1 Each party (as “<b>Receiving Party</b>”) agrees that all code, inventions, know-how, business, technical and
        financial information it obtains from the disclosing party (“<b>Disclosing Party</b>”) constitute the
        confidential
        property of the Disclosing Party, provided that it is identified as confidential at the time of disclosure or
        should be reasonably known by the Receiving Party to be Confidential Information due to the nature of the
        information disclosed and the circumstances surrounding the disclosure. Any software, pricing, documentation or
        technical information provided by the Company (or its agents), performance information relating to Subscription
        Service, and the terms and conditions of this Agreement shall be deemed Confidential Information of the Company
        without any marking or further designation.
      </p><br>
      <p>
        7.2 Subscriber Data shall be deemed your Confidential Information without any marking or further designation.
        Except as expressly authorized herein, the Receiving Party will hold in confidence and not use or disclose any
        Confidential Information to anyone other than its affiliates, employees and consultants (“Representatives”) who
        have a need to know and who agree in writing to keep the information confidential on terms no less restrictive
        than those contained in this Agreement. Both the Company and you will ensure that their respective
        Representatives comply with this Agreement and will be responsible for any unauthorized use or disclosure of
        Confidential Information by such Representatives.
      </p><br>
      <p>
        7.3 Exceptions: The Receiving Party’s nondisclosure obligation shall not apply to information which the
        Receiving Party can document: (a) was rightfully in its possession or known to it prior to receipt of the
        Confidential Information; (b) is or has become public knowledge through no fault of the Receiving Party; (c) is
        rightfully obtained by the Receiving Party from a third party without breach of any confidentiality obligation;
        (d) is independently developed by employees of the Receiving Party who had no access to such information; or (e)
        is required to be disclosed pursuant to a regulation, law or court order (but only to the minimum extent
        required to comply with such regulation or order and with advance notice to the Disclosing Party).
      </p><br>
      <p>
        7.4 The Receiving Party acknowledges that disclosure of Confidential Information could cause substantial harm
        for which damages alone would not be a sufficient remedy, and therefore upon any such disclosure by the
        Receiving Party the Disclosing Party shall be entitled to seek appropriate equitable relief in addition to
        whatever other remedies it might have at law.
      </p><br>
      <p>
        7.5 Privacy Policy: Your use of the Subscription Service is subject to Company’s privacy policy, a current
        version of which can be accessed at: https://vphrase.com/explorazor/#/privacy-policy.
      </p><br>
      <p>
        7.6 Export Control laws: You agree that (a) all use, exports, and imports related to this Agreement will be in
        compliance with the export control laws and regulations and (b) you shall not allow any third party to export,
        re-export, or transfer any part of Subscription Service in violation of any such laws and regulations. The
        foregoing obligations include but are not limited to you or a third party accessing, exporting, transferring, or
        importing any Subscriber Data or the Subscription Service to: (i) to any country subject to export control
        embargo or economic sanctions implemented by any agency of the respective country or foreign governments; or
        (ii) otherwise in violation of any export or import laws, regulations or requirements of any such country or
        foreign agency or authority.
      </p><br>
      <br>
    </div>
    <p class="information-collection">
      <b>
        8. YOUR OBLIGATIONS
      </b>
    </p><br>
    <div class="information-collection">
      <p>
        8.1 You covenant to the Company that it shall not:
      </p><br>
      <div class="performance-list">
        <p>
          (a) emulate, clone, rent, lend, lease, sell, modify, decompile, or reverse-engineer the Subscription Service
          or create derivative works based on the Subscription Service or any portion thereof. All rights not expressly
          granted herein are reserved by the Company. Any such unauthorized use of the Subscription Service shall result
          in immediate and automatic termination of this Agreement and the subscription granted hereunder and will
          result in criminal and/or civil prosecution against you;
        </p><br>
        <p>
          (b) make any efforts to reverse engineer, decompile, or disassemble the Subscription Service or Explorazor,
          or
          make any modifications or enhancements without the Company's express written consent;
        </p><br>
        <p>
          (c) use the Subscription Service in hazardous environments requiring fail-safe performance where the failure
          of Subscription Service could lead directly or indirectly to death, personal injury, or severe property or
          environmental damage;
        </p><br>
        <p>
          (d) give any competitor of Company direct or indirect access to the Subscription Service;
        </p><br>
        <p>
          (e) permit any third parties or non-licensed entities, including contractors, to use the Subscription
          Service;
        </p><br>
        <p>
          (f) use the Subscription Service for other than the use permitted hereunder;
        </p><br>
        <p>
          (g) create derivative or merged works of the Subscription Service;
        </p><br>
        <p>
          (h) use or allow use of Subscription Service for processing data of any person or entity other than you;
        </p><br>
        <p>
          (i) perform, publish or release any benchmarks or other comparisons regarding Subscription Service;
        </p><br>
        <p>
          (j) alter or remove any proprietary rights notices or legends on or in Subscription Service;
        </p><br>
        <p>
          (k) upload or publish any payment card information on Explorazor or using Subscription Service. You
          acknowledge
          that Subscription Service is not compliant with the Payment Card Industry Data Security Standards;
        </p><br>
        <p>
          (l) enable access to the Subscription Service for a greater number of Authorized Users than the sum quantity
          of subscriptions or credentials purchased in the applicable Subscription Document; and
        </p><br>
        <p>
          (m) upload to the Subscription Service or publish thereon any patient, medical or other protected health
          information regulated by the such laws of the concerned country from where you access the Subscription
          Service, and acknowledges that the Company is not associated with any medical association. The Company will
          have no liability under this Agreement for health information, notwithstanding anything to the contrary
          herein.
        </p><br><br>
      </div>
    </div>
    <h4 class="information-collection header">
      9. INDEMNITY
    </h4><br>
    <div class="information-collection">
      <p>
        9.1 You hereby agree to defend, indemnify and hold the Company harmless from and against all claims or demands
        made against the Company (and any related losses, expenses or attorneys’ fees) arising out of or relating to
        your breach of the terms of the agreement, negligent use or wilful misuse of or negligent conduct or wilful
        misconduct regarding the use of the Service or derivatives, including but not limited to, any claims of personal
        injury, death, damage to property or violation of any laws or regulations.
      </p><br>
      <p>
        9.2 The Company agrees to indemnify and hold you harmless, against any and all losses, liabilities, judgments,
        awards and costs (including legal fees and expenses) arising out of or related to any third party claim that
        your use or possession of the Subscription Service or Documentation, or the license granted hereunder, infringes
        or violates the copyright, trade secret or other proprietary right of any third party. The Company shall defend
        and settle at its sole expense all suits or proceedings arising out of the foregoing, provided that you give the
        Company prompt notice of any such claim of which it learns.
      </p><br>
      <br>
    </div>
    <h4 class="information-collection header">
      10. LIMITATION OF LIABILITY
    </h4><br>
    <div class="information-collection">
      <p>
        In no event shall either Party be liable for any special, incidental, punitive, indirect, or consequential
        damages whatsoever (including, but not limited to, damages for loss of profits or confidential or other
        information, for business interruption, for loss of privacy, for corruption, damage and loss of data or
        programs, for failure to meet any duty including any statutory duty, duty of good faith or duty of reasonable
        care, for negligence, for economic loss, and for any other pecuniary or other loss whatsoever) arising out of or
        in any way related to the use of or inability to use the Service, the provision of or failure to provide support
        or other services, information, software, and related content through the software or otherwise arising out of
        the use of the Subscription Service, or otherwise under or in connection with any provision of this Agreement,
        or arising out of any breach of contract or any tort (including negligence, misrepresentation, any strict
        liability obligation or duty), or any breach of statutory duty, or any breach of warranty of the Company. The
        Company’s entire liability and obligation to you shall not exceed the fees paid or owed by you to the Company
        under this Agreement during the 6 (six) months preceding the claim. In case where no amount was paid for the
        Subscription Service giving rise to the claim, the Company and its licensors’ shall not have any liability to
        you under this Agreement.
      </p><br>
      <br>
    </div>
    <h4 class="information-collection header">
      11. LIMITED WARRANTY AND DISCLAIMER
    </h4><br>
    <div class="information-collection">
      <p>
        11.1. The Company warrants to you that Subscription Service will operate in substantial conformity with the
        applicable Documentation. The Company does not warrant that your use of Subscription Service will be
        uninterrupted or error-free, nor does the Company warrant that it will review the Subscriber Data for accuracy
        or that it will preserve or maintain the Subscriber Data without loss. The Company’s sole liability (and your
        sole and exclusive remedy) for any breach of this warranty shall be, in the Company’s sole discretion and at no
        charge to you, to use commercially reasonable efforts to correct the reported non-conformity, or if the Company
        determines such remedy to be impracticable, to allow you to terminate the applicable Subscription Service and
        receive as its sole remedy a refund of: (a) the monthly subscription fees (on pro-rata basis) paid by you or as
        may be specified in the applicable Subscription Documents and (b) any fees you have pre-paid for use of
        Subscription Service or related services that you have not received as of the date of the warranty claim.
      </p><br>
      <p>
        11.2 <b>Exclusions</b>. The above warranty shall not apply: (i) unless you make a claim within 30 (thirty) days
        of the
        date on which the condition giving rise to the claim first appeared; (ii) if Subscription Service is used with
        hardware or software not authorized in the Documentation; (iii) if the error was caused by misuse, unauthorized
        modifications or third-party hardware, software or services; (iv) to any Trail Access or other use provided on a
        no charge or evaluation basis; or (v) to any such material using such functionality that is provided purely for
        reference purposes.
      </p><br>
      <p>
        11.3 <b>Warranty Disclaimer</b>. THIS CLAUSE 11 IS A LIMITED WARRANTY CLAUSE AND, EXCEPT AS EXPRESSLY SET FORTH
        IN THIS
        CLAUSE 11, SUBSCRIPTION SERVICE, INCLUDING WITHOUT LIMITATION THE THIRD-PARTY CODE ARE PROVIDED “AS IS”. NEITHER
        THE COMPANY NOR ITS LICENSORS MAKES ANY OTHER WARRANTIES, CONDITIONS OR UNDERTAKINGS, EXPRESS OR IMPLIED,
        STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, OR NON INFRINGEMENT. YOU MAY HAVE OTHER STATUTORY RIGHTS. HOWEVER, TO THE FULL EXTENT
        PERMITTED BY LAW, THE DURATION OF STATUTORILY REQUIRED WARRANTIES SHALL BE LIMITED AS PROVIDED HEREIN. THE
        COMPANY SHALL NOT BE LIABLE FOR DELAYS, INTERRUPTIONS, SERVICE FAILURES AND OTHER PROBLEMS INHERENT IN USE OF
        THE INTERNET AND ELECTRONIC COMMUNICATIONS OR OTHER SYSTEMS OUTSIDE THE REASONABLE CONTROL OF THE COMPANY.
      </p><br>
      <br>
    </div>
    <h4 class="information-collection header">
      12. MISCELLANEOUS
    </h4><br>
    <div class="information-collection">
      <p>
        12.1 <b>Notices</b>. Any notice or communication required or permitted under this Agreement shall be in writing
        or in
        electronic format. If to the Company by mail, such notice or report shall be sent to the Company at 301,
        T-Square, Saki Vihar Road, Andheri (E), Mumbai - 400072, Maharashtra, India to the attention of “Legal
        Department”. If to the Company by email, such notice or report shall be sent to:
        contact@vphrase.com/explorazor/#/contact-us.
        If to you
        such notice or report shall be sent to the mailing or email address you provided upon subscribing to the
        Subscription Service. Notices and reports sent by mail shall be deemed given: (a) upon receipt if by personal
        delivery; (b) upon receipt if sent by certified or registered mail (return receipt requested); or (c) one day
        after it is sent if by next day delivery by a major commercial delivery service. Any notices and reports sent by
        email shall be effective upon receipt of the same.
      </p><br>
      <p>
        12.2 <b>Reservation of rights</b>. Failure by either Party to insist upon strict performance of any of the
        provisions
        contained in this Agreement shall in no way constitute a waiver of its rights as set forth in this Agreement, at
        law or in equity, or a waiver of any other provisions or subsequent default by the other Party in the
        performance of or compliance with any of the terms and conditions set forth in this Agreement. No forbearance,
        indulgence or relaxation or inaction by a Party at any time to require performance of any of the provisions of
        this Agreement shall in any way affect, diminish or prejudice the right of such Party to require performance of
        that provision. Any waiver or acquiescence by a Party of any breach of any of the provisions of this Agreement
        shall not be construed as a waiver or acquiescence of any right under or arising out of this Agreement or of the
        subsequent breach, or acquiescence to or recognition of rights other than as expressly stipulated in this
        Agreement.
      </p><br>
      <p>
        12.3 <b>Severability</b>. If any part or all of any provision of this Agreement is illegal or unenforceable, it
        may be
        severed from this Agreement and the remaining provisions of this Agreement shall continue to remain in force.
        Any invalid or unenforceable provision of this Agreement shall be replaced with a provision that is valid and
        enforceable and most nearly reflects the original intent of the unenforceable provision.
      </p><br>
      <p>
        12.4 <b>Governing law and jurisdiction</b>. The provisions of this Agreement shall, in all respects, be governed
        by,
        and construed in accordance with the laws of India. The courts at Mumbai, India shall have exclusive supervisory
        jurisdiction to settle any claim or matter arising under this Agreement.
      </p><br>
      <p>
        12.5 <b>Entirety</b>. This Agreement (including each Subscription Document, as applicable, and any other
        mutually
        agreed exhibits or attachments) constitutes the entire agreement between the Parties with respect to the subject
        matter hereof to the exclusion of all other understandings and assurances, relating to such subject matter
        either written or oral and supersedes all prior agreements and understandings between the parties relating to
        the subject matter hereof. In the event of any conflict between terms and conditions in this Agreement and any
        such Subscription Documents, the following order of precedence will apply: (a) any work order (solely with
        respect to that work order); (b) this Agreement; and (c) any such other document incorporated by reference
        herein.
      </p><br>
      <p>
        12.6 <b>Amendment</b>. From time to time, the Company may modify this Agreement and the Documentation. Unless
        otherwise
        specified by the Company, changes become effective for you upon renewal of the then-current Subscription Term of
        the Subscription Service. The Company will use reasonable efforts to notify you of the changes through
        communications through its website (where the Subscription Services are accessed), email, or other means. You
        may be required to click to accept the modified Agreement before using accessing the Subscription Service, and
        in any event continued use of Subscription Service during the renewed Subscription Term will constitute your
        acceptance of the version of the Agreement in effect at the time the renewed Subscription Term of the
        Subscription Service begins.
      </p><br>
      <p>
        12.7 <b>Reseller Arrangement</b>. If you received the access to the Subscription Service under an agreement
        (“<b>Reseller
          Agreement</b>”) with an authorized reseller/partner of the Company (“Authorized Reseller”), then,
        notwithstanding
        anything to the contrary in this Agreement: (a) your use of Subscription Service is subject to any additional
        terms in the Reseller Agreement, including any limitations on use of Subscription Service in conjunction with
        third-party applications; and (b) you agree to pay the Authorized Reseller the fees agreed in the Reseller
        Agreement and you have no direct payment obligations to the Company for that purchase under Clause 4 above. If
        your warranty and support terms stated in your Reseller Agreement are different than what is stated in this
        Agreement herein, then the Company has no warranty or support obligations to you under this Agreement (although
        the disclaimers of warranties in Clause 11 still apply to you). If your warranty and support terms passed on in
        your Reseller Agreement are as stated herein, then Clause 11.3 shall apply to you as written. Notwithstanding
        anything in this Agreement to the contrary, (i) the Reseller Agreement may not modify any of the remaining terms
        of this Agreement and (ii) the Reseller Agreement is between you and the Authorized Reseller and is not binding
        on the Company. The Company may terminate this Agreement (including your right to use Subscription Service) in
        the event the Company fails to receive payment for your use of Subscription Service from the Authorized Reseller
        or if you breach any term of this Agreement.
      </p><br>
      <p>
        12.8 <b>Assignment and Sub-contracting</b>. No Party shall be entitled to assign or sub-contract its rights
        and/or
        obligations under this Agreement without the prior written consent of the other Party provided that the Company
        shall be entitled to assign any or all of its rights and obligations under this Agreement to any of its
        Affiliates (current or future) without your prior consent.
      </p><br>
      <p>
        12.9 Force Majeure
      </p><br>
      <div class="performance-list">
        <p>
          (a) “<b>Force Majeure Event</b>” shall mean any of the following events or combination of such events or
          circumstances as are beyond the control of a Party and which cannot: (i) by the exercise of reasonable
          diligence; or (ii) despite the adoption of reasonable precautions and / or alternative measures, be prevented,
          or caused to be prevented, or which materially and adversely affects the party’s ability to perform its
          obligations under this Agreement, including: (a) acts of God, comprising fire, drought, flood, earthquake,
          pandemic (including without limitation Covid-19), epidemics and other natural disasters; (b) explosions or
          accidents, and terrorist attacks; (c) strikes, labour unrest or lock-outs; (e) any order, directive,
          regulation or law imposing any quarantine, social distancing and restrictions on the opening and functioning
          of any businesses, Government offices or any other body, regulatory authority or bank in India; (d) any event
          or circumstance analogous to the foregoing; and/or (e) other circumstances beyond the control of either Party.
        </p><br>
        <p>
          (b) In the event that either party is unable to perform its obligations under this Agreement, or if the
          performance of its obligations under this Agreement would be hindered or delayed by, the occurrence of a Force
          Majeure Event or the subsistence of a Force Majeure Event that has occurred prior to or during the
          Subscription Term, then the party so affected in the discharge of its obligation shall promptly, but not later
          than 5 (five) days give written notice of such Force Majeure Event to the other party. The affected party
          shall make every reasonable effort to remove or remedy the cause of such Force Majeure Event or mitigate its
          effect as quickly as possible.
        </p><br>
      </div>
      <p>
        12.10 <b>Relationship</b>. Nothing contained in this Agreement shall be construed a joint venture, partnership
        or
        agency between the Parties. The relationship between the Parties shall be on a principal to principal basis. You
        shall not be entitled to, by act, word, deed or otherwise, make any statement on behalf of the Company or in any
        manner bind the Company. This document is an electronic record in terms of Information Technology Act, 2000 and
        rules made there under as applicable and the amended provisions pertaining to electronic records in various
        statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer
        system and does not require any physical or digital signatures.
      </p>
    </div>
  </div>
</template>

<script>
  import { globalMixin } from '@/mixins/globalMixin';

  export default {
    mixins: [globalMixin],
    beforeMount () {
      document.title = 'Explorazor | Terms and Conditions';
      document.querySelector('meta[name="description"]').setAttribute('content', 'Refer to' +
        ' Explorazor\'s Users Terms and Conditions. Explorazor is a AI and Search driven data exploration platform,' +
        ' that empowers users to ask any data questions in Natural language');
    }
  };
</script>
