import Main from '@/pages/HomePage.vue';
import appRequestDemo from '@/pages/AppRequestDemo.vue';
import appPricing from '@/pages/AppPricing.vue';
import appFeature from '@/pages/AppFeature.vue';
import appContact from '@/pages/AppContact.vue';
import appUseCase1 from '@/pages/use-case/AppUseCase.vue';
import appDetaileList from '@/pages/use-case/detailed_list/SpendCorrelation.vue';
import consumerBehavior from '@/pages/use-case/detailed_list/ConsumerBehavior.vue';
import privacyPolicy from '@/pages/PrivacyPolicy.vue';
import termsAndConditions from '@/pages/TermsAndConditions.vue';
import termOfUse from '@/pages/TermOfUse.vue';
import WhitePaper from '@/pages/WhitePaper.vue';
import { createRouter, createWebHistory, createMemoryHistory } from 'vue-router';
import DataServices from '@/pages/DataServices.vue';
import ErrorRoute from '@/components/helper/ErrorRoute.vue';
import newHomePage from '@/pages/NewHomePage.vue';
import ThoughtspotVsExplorazor from '@/pages/ThoughtspotVsExplorazor.vue';
import DanoneUsecase from '@/pages/DanoneUsecase.vue';


const baseRoute = process.env.VUE_APP_BASE_ROUTE;
const routes = [
  { path: `/${ baseRoute }/`, component: newHomePage, name: 'NewHomePage' },
  { path: `/${ baseRoute }/demo`, component: appRequestDemo, name: 'Demo' },
  { path: `/${ baseRoute }/pricing`, component: appPricing, meta: { color: 'red' }, name: 'Pricing' },
  { path: `/${ baseRoute }/features`, component: appFeature, name: 'Features' },
  { path: `/${ baseRoute }/contact-us`, component: appContact, name: 'Contact' },
  { path: `/${ baseRoute }/usecases`, component: appUseCase1, name: 'UseCase' },
  { path: `/${ baseRoute }/usecases/spends-correlation`, component: appDetaileList, name: 'Detail-List' },
  { path: `/${ baseRoute }/usecases/consumer-behavior`, component: consumerBehavior, name: 'Consumer-Behavior' },
  { path: `/${ baseRoute }/privacy-policy`, component: privacyPolicy, name: 'privacy' },
  { path: `/${ baseRoute }/terms-and-conditions`, component: termsAndConditions, name: 'Terms-And-Conditions' },
  { path: `/${ baseRoute }/term-of-use`, component: termOfUse, name: 'Term-Of-Use' },
  { path: `/${ baseRoute }/white-paper`, component: WhitePaper, name: 'WhitePaper' },
  { path: `/${ baseRoute }/data-services`, component: DataServices, name: 'DataServices' },
  { path: `/${ baseRoute }/explorazor-for-cpg`, component: Main, name: 'Home' },
  {
    path: `/${ baseRoute }/explorazor-vs-thoughtspot`,
    component: ThoughtspotVsExplorazor,
    name: 'ThoughspotVsExplorazor'
  },
  { path: '/:pathMatch(.*)*', component: ErrorRoute, name: 'error-404' },
  { path: `/${ baseRoute }/danone-usecase`, component: DanoneUsecase, name: 'DanoneUsecase' }
];

const isServer = typeof window === 'undefined';

export const router = createRouter({
  history: isServer ? createMemoryHistory() : createWebHistory(),
  routes,
  scrollBehavior: to => ({ top: 0 })
});