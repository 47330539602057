import axios from 'axios';

export const globalMixin = {
  props: {},
  methods: {
    scrollToTop: function () {
      window.scrollTo({ top: 0 });
    },
    routeTo: function (path) {
      window.scrollTo({ top: 0 });
      return this.$router.push(path);
    },

    axiosPromise: function (
      {
        url = null, method = null, data = null, headers = null, responseType = 'json',
        onUploadProgress = null,
      }
    ) {
      /**
       * Global function for making server request
       * @param: {
       *   url:               url to hit,
       *   method:            request type, 'GET', 'POST', 'PUT', 'PATCH', 'Delete', etc
       *   data:              data to send with request
       *   header:            custom headers for the request
       *   queryParams:       query params for url,
       *   responseType:      what format the response needs to be converted
       *   onUploadProgress:  if upload progress percent is needed
       *   notify:            flag for automated notifications on error
       *   setUser:           should the user data be updated after `this` API call
       * }
       **/

      // this order matters, put static first, then override everything requester sends
      headers = {
        ...{
          'Content-Type': 'application/json; charset=utf-8;'
        },
        ...headers,
      };

      return new Promise((resolve, reject) => {
        axios({
          url: url,
          baseURL: this.getBaseURL(),
          method: method,
          data: data,
          headers: headers,
          responseType: responseType,
          onUploadProgress: onUploadProgress
        }).then(response => {
          resolve(response.data);
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      });
    },
    getBaseURL: function () {
      /** Function to get the base url for API request */
      let baseUrl;
      if (process.env.NODE_ENV === 'development') {
        baseUrl = window.location.protocol + '//' +
          window.location.hostname + `:8000${ process.env.VUE_APP_SERVER_SUFFIX || '' }`;
      } else {
        if (! window.location.origin) {
          window.location.origin = window.location.protocol + '//' + window.location.hostname + (
            window.location.port ? ':' + window.location.port : ''
          );
        }
        baseUrl = window.location.origin + `${ process.env.VUE_APP_SERVER_SUFFIX || '' }`;
      }
      return baseUrl;
    },
  }
};