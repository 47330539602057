import { createSSRApp, h, createApp } from 'vue';
import App from './App.vue';
import { router } from './router/router';
import { globalMixin } from '@/mixins/globalMixin';
import VsRow from '@/components/helper/VsRow.vue';
import VsCol from '@/components/helper/VsCol.vue';
import VsButton from '@/components/helper/VsButton.vue';
import './assets/scss/components/global.scss';
import VueGtag from 'vue-gtag';

export default function (args) {
  const rootComponent = {
    render: () => h(App),
    components: { App },
    setup () {
    }
  };
  const isSSR = typeof window === 'undefined';

  const app = (isSSR ? createSSRApp : createApp)(rootComponent);

  app.use(router);

  app.mixin(globalMixin);
  app.component('VsRow', VsRow);
  app.component('VsCol', VsCol);
  app.component('VsButton', VsButton);

  if (process.env.NODE_ENV === 'production') {
    app.use(VueGtag, {
      config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID },
    });
  }

  return {
    app,
    router,
  };
}