<template>
  <div class="use-case">
    <vs-row class="use-route">
      <p>
        <a
          href="https://www.vphrase.com/explorazor/usecases"
          style="padding-right: 10px"
        >Use cases</a>
        <app-icon
          class="icon-arrow"
          icon="app-icons/forword"
          width="9"
          color="#191948"
        />
        &nbsp;&nbsp; <span class="animation">How Explorazor Helps Brand
          and Sales teams with their Spends Correlation Analysis</span>
      </p>
    </vs-row>
    <vs-row
      class="unified-data-access-container "
      vs-justify="center"
    >
      <div class="unified-data-access ">
        <vs-row vs-justify="center">
          <h3 class="unified-data-header ">
            SPENDS CORRELATION
          </h3>
          <h2 class="correlation-header">
            How Explorazor Helps Brand and Sales teams with their Spends Correlation
            Analysis
          </h2>
        </vs-row>
      </div>
    </vs-row>

    <vs-row
      id="goc-12"
      class="glimpse-of-search-container consumer-behavior "
    >
      <div class="correlation-analysis">
        <app-text-render
          sub-content="A Brand and Sales teams makes a plan every quarter on how much money and efforts
          should be spent on promoting a brand via various channels.
           These plans and efforts need to be tweaked every so often to get the best results."
        />
        <app-text-render
          sub-content="To do this, the Brand and Sales teams has to look at the :"
        />
        <br><br>
        <ul>
          <li>
            <p>
              The impact of the money spent on various channels and
            </p>
          </li>
          <li>
            <p>
              The effort put in by different teams in acquiring more dealers or getting better shelf space in retail
              outlets
            </p>
          </li>
        </ul>
        <app-text-render
          sub-content="All of this data is spread across different datasets like Media
          spends, Market Research data, Primary and Secondary Sales data, Efforts data, etc."
        />
      </div>
      <div class="glimpse-of-img">
        <image-renderer
          client="spend-correlation"
          type="client"
        />
      </div>
    </vs-row>

    <vs-row class="challenge-faced-container ">
      <h3>Challenges Faced</h3>
      <p>
        <br>Since all these datasets are managed by different stakeholders, obtaining them in a timely manner requires
        additional effort.
        To arrive at the numbers that help in taking a decision, you have to go through all these separate datasets and
        get the
        exact same pivot tables in each one, just to get the few required KPIs.
      </p><br>
      <p>
        Once in a while is okay,
        but doing this to check every single region/territory
        behavior takes a lot of time.
        People are not motivated to carry out such analysis regularly, restricting a Brand and Sales teams
        ability to drive the best results for the spends, both in terms of money and effort.
      </p>
    </vs-row>
    <div class="bm-logs">
      <div>
        <span><h3>How <span>Explorazor</span> Solves These Problems</h3> </span>
        <p>
          <br>
          With Explorazor, Brand and Sales teams get all their relevant datasets standardized,
          transformed,
          merged, and available as a single dataset.
          The issue of not being able to make timely adjustments due to the lack of availability of
          data is eliminated.
        </p>
        <p>
          <br>
          Once the Brand and Sales team logs in to Explorazor, they can get all the relevant information in
          a single view by just asking for the desired KPIs via a simple keyword based querying interface.
          Compare this to the hours spent on reaching these KPIs by applying pivots on 5 different datasets - Brand and
          Sales teams
          will find that the speed of hypothesis testing has shot up. The effort to arrive at the right numbers to, for
          example, see if the spends are yielding the desired results, is significantly reduced.
        </p>
      </div>
      <vs-row class="bm-logs-img">
        <image-renderer
          client="spend-correlation-analysis"
          type="client"
        />
        <p class="image-caption">
          See what value you are getting for every dollar you are spending
        </p>
      </vs-row>
    </div>
    <vs-row class="business-expectation-container">
      <p>
        Also, a benefit that Explorazor can provide Brand and Sales teams with is the
        <b>ability to look at a large number of data points and highlight</b> the ones which do not
        follow the business expectations.
        This allows the Brand and Sales teams to know, at a glance, which are the areas (Las Vegas and Philadelphia from
        the
        example above)
        that they need to dive deeper into, making it possible for the Brand and Sales teams to look at the right data
        points in
        a hierarchy, everytime.
      </p>
    </vs-row>

    <vs-row
      id="uda-12"
      class="unified-data-access-container outer-space"
      vs-justify="center"
    >
      <vs-col class="unified-data-access ">
        <vs-row
          class="want-to-see-container"
          vs-justify="center"
        >
          <div>
            <h3> Want to see Explorazor in action?</h3>
            <!--            <p class="access-header">-->
            <!--              Schedule a no-obligation demo call with us-->
            <!--            </p>-->
          </div>
        </vs-row>
        <div class="wrapper-button  height">
          <vs-button
            class="request-demo-button btn-primary"
            @click="handleTour"
          >
            Take Product Tour
          </vs-button>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>

  import '@/assets/scss/components/change.scss';
  import imageRenderer from '@/components/helper/ImageRenderer';
  import { imageMixin } from '@/mixins/image';
  import AppTextRender from '@/components/wrapper/TextRender';
  import AppIcon from '@/components/helper/AppIcon';
  import { gTagMixin } from '@/mixins/gTagMixin';

  export default {
    name: 'RequestDemo',
    components: {
      AppIcon,
      AppTextRender,
      imageRenderer,
    },
    mixins: [imageMixin, gTagMixin],
    beforeMount () {
      document.title = 'Explorazor Use Case | Search and AI driven analytics on Spend Correlation Data';
      document.querySelector('meta[name="description"]').setAttribute('content', 'Find out how you' +
        ' can leverage AI and Search driven analytics to help your Spends Correlation data.');
    },
    mounted () {
      this.scrollToTop();
      this.updatePageView();
    },
    methods:{
      handleTour:function(){
        window.open('https://app.explorazor.com/#/playground','_blank');
      }
    }
  };
</script>

<style scoped>
  button:hover {
    background: rgba(var(--vs-success), 5) !important;
  }

  p {
    color: #191948;
  }

  .wrapper-button {
    margin-bottom: 2% !important;

  }

  .correlation-header {
    text-align: center;
    width: 80%;
  }

  #goc-12 {
    background-color: unset;
  }

  ul li {
    list-style-type: disc;
  }

  ul {
    padding-left: 5%;
  }

  ul li {
    font-weight: 400;
    padding-left: 1%;
  }

  .unified-data-access {
    padding: 0;
  }

  .glimpse-of-search-container {
    padding: 0 11.5%;
  }

  h3 span {
    color: #0243EC;
  }

  #uda-12 .unified-data-access {
    width: 100% !important;
    display: flex;
    padding: 5% 10%;
    flex-direction: column;
    background: #E6EEFF;
    border-radius: 12px;
  }

  #uda-12 {
    padding: 3% 10% 0 10%;
  }

  #uda-12 h3 {
    text-align: center;
    margin-bottom: 5%;
    font-family: 'Fraunces';
    font-size: 2rem;
  }

  #uda-12 .wrapper-button {
    margin: 0;
    margin-top: 5%;
  }

  #uda-12 .access-header {
    text-align: center;
  }


</style>