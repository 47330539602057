<template>
  <div
    style="margin-top:60px;width:100% !important;justify-content: center;align-content: center;display: flex;
    flex-direction: column"
  >
    <vs-row
      class="banner-wrapper"
      style=" background: #DFE8F7;"
    >
      <div
        v-if="showBanner"
        class="banner-class"
      >
        <div
          class="banner-text"
          @click="handleTour"
        >
          Take an Interactive product tour of Explorazor
        </div>
        <div
          class="banner-cross"
          @click="closeBanner"
        >
          <img
            src="../assets/svgs/app-icons/cross-icon.svg"
            alt="cross-icon"
          >
        </div>
      </div>
    </vs-row>
    <vs-row class="service-header-wrapper">
      <div class="service-header-container">
        <div class="services-main-header">
          Data Harmonization Services
        </div>
        <div class="service-header-content">
          Our data engineering team, with deep expertise in CPG industry, will combine and harmonize all your datasets
          to
          create a single source of truth.
        </div>
        <div class="services-header-sub-content">
          We’ll harmonize :: We’ll maintain
        </div>
      </div>
    </vs-row>
    <vs-row class="data-engineering-container">
      <img
        src="../assets/svgs/clients/Datasets-Unified-Harmonized.svg"
        alt="Explorazor combines all the different data sets such as Kantar Data, Nielsen IQ, Barclay,
        Internal ERPs and Promotional data which are then ingested and stored in a single data set.
         Once the data is stored, the processing and transformation of data takes place, where the data is converted
          into foundation data which is further transformed into enriched/harmonized data to be queried upon."
        class="dataset-service-image"
      >
      <div class="data-engineering-wrapper">
        <div class="data-engineering">
          Leave the Data Engineering to us
        </div>
        <div class="services-header-content">
          Data engineering can be a complex and time-consuming process, especially for CPG and Pharma companies. With
          Explorazor handling data engineering tasks like data mapping, validation, and pipeline maintenance, companies
          can focus on their core business objectives without worrying about the technical details. Our expertise and
          streamlined approach ensure that your data infrastructure stays reliable and up-to-date, so you can make
          informed decisions and drive growth with confidence.
        </div>
      </div>
    </vs-row>
    <vs-row class="data-engineering-image-container">
      <img
        src="../assets/svgs/clients/DataEngineering.svg"
        alt="Explorazor provides data engineering services such as Building Pipelines,
         Data Mapping and Modeling charges, Data Validation and Integrity checks, Scheduling Pipeline runs,
          Maintaining health of Pipeline and adding new data sources."
        class="data-engineering-image"
      >
    </vs-row>
    <vs-row
      id="arrow-content"
      ref="arrow-content"
      class="how-to-model-container"
    >
      <div class="how-to-model-title">
        We know how to model data for CPG industry
      </div>
      <div class="services-header-content">
        Modeling CPG data can be a complex process that requires expertise and
        attention to detail. With Explorazor's
        data modeling solutions, CPG and Pharma companies can benefit from a streamlined approach that combines,
        harmonizes, and integrates datasets as they become available throughout the month.
        Our unification of data
        periods across different datasets and automated data pipeline processes make it easier for companies to make
        informed decisions and drive growth with confidence.
      </div>
      <div class="arrow-container">
        <div>
          <img
            src="../assets/svgs/clients/contentArrow.svg"
            alt="container-arrow"
            class="container-arrow-class"
          >
        </div>
        <div
          class="arrow-content"
        >
          <vs-col vs-w="12">
            <vs-row
              v-for="arrowContent in arrowContents"
              :key="arrowContent['id']"
              class="arrow-content-container"
            >
              <img
                :src="arrowContent['bulletPoint']"
                alt="bulletPoint"
                class="bullet-point-class"
              >
              <div class="bullet-content">
                <div class="arrow-content-title">
                  {{ arrowContent['title'] }}
                </div>
                <div class="arrow-sub-content">
                  {{ arrowContent['content'] }}
                </div>
              </div>
              <div class="bullet-image">
                <img
                  :src="arrowContent['image']"
                  :alt="arrowContent['alt']"
                  class="arrow-content-image"
                >
              </div>
            </vs-row>
          </vs-col>
        </div>
      </div>
    </vs-row>
    <vs-row style="display: flex;justify-content: center;align-items:center;">
      <div class="partners-container">
        <div class="partners-header">
          Fortune 500 Companies Trust Us With Their Data Harmonization
        </div>
        <vs-row
          style="width:100%; justify-content: center"
        >
          <div
            v-for="img in getPartnerLogos()"
            :key="img.id"
            class="partner-images"
          >
            <img
              alt="Danone, SANOFI, Olam, Abbott, Alkem and GSK are using Explorazor as their data Exploration tool."
              :src="getImage(img)"
              class="partner-image"
            >
          </div>
        </vs-row>
      </div>
    </vs-row>
    <vs-row class="data-in-firewall">
      <div class="firewall-content">
        <div class="firewall-text">
          Your Data Stays within Your Firewall
        </div>
      </div>
      <div class="firewall-image-container">
        <img
          src="../assets/svgs/card-image/Firewall.svg"
          alt="firewall"
          class="firewall-image"
        >
      </div>
    </vs-row>
    <vs-row
      class="fortune-500-container"
    >
      <div class="fortune-500-text">
        Unlock the power of your data
      </div>
      <div>
        <vs-button
          class="fortune-500-button"
          @click.native="routeTo({name:'Contact'})"
        >
          Talk to Us
        </vs-button>
      </div>
    </vs-row>
  </div>
</template>

<script>
  import { imageMixin } from '@/mixins/image';

  export default {
    name: 'DataServices',
    mixins: [imageMixin],
    data: function () {
      return {
        arrowContents: [
          {
            id: 1,
            title: 'Standardizing',
            content: 'Our data engineering team, with deep expertise in CPG industry, will combine' +
              ' and harmonize all your datasets to create a single source of truth.',
            image: require('@/assets/svgs/clients/Standardizing.svg'),
            bulletPoint: require('@/assets/svgs/app-icons/Ellipse1.svg'),
            alt: 'Image shows how all the different datasets are standardized and harmonized into one.'
          },
          {
            id: 2,
            title: 'Managing Lag',
            content: 'We enable integration of datasets as and when they become available throughout the month.' +
              ' This lets you access the available data points as and when they become available without having' +
              ' to wait till all the data comes in.',
            image: require('@/assets/svgs/clients/ManagingLag.svg'),
            bulletPoint: require('@/assets/svgs/app-icons/Ellipse2.svg'),
            alt: 'Explorazor enables integration of datasets as and when they become available throughout the month.'
          },
          {
            id: 3,
            title: 'Period Rolling',
            content: 'We unify the period across different types of datasets.',
            image: require('@/assets/svgs/clients/PeriodRolling.svg'),
            bulletPoint: require('@/assets/svgs/app-icons/Ellipse3.svg'),
            alt: 'Explorazor unifies the period across different type of datasets. For example,' +
              ' different months can be unified as 4 quarters in a year.'
          },
          {
            id: 4,
            title: 'Transposing',
            content: 'We transpose the data to make it easier to automate via data pipeline ' +
              'making the data scripting and data-warehouse friendly.',
            image: require('@/assets/svgs/clients/Transposing.svg'),
            bulletPoint: require('@/assets/svgs/app-icons/Ellipse4.svg'),
            alt: 'Explorazor transposes the data to make it easier to automate via a data pipeline.' +
              ' The image shows the difference between the data once the data has been transposed.'
          }
        ],
        arrowContentHeight: null,
        showBanner: true,
      };
    },
    beforeMount () {
      document.title = 'Explorazor | Data Harmonization and Cleaning Services for Enterprises ';
      document.querySelector('meta[name="description"]').setAttribute('content', 'Explorazor\'s data' +
        ' engineering team with their deep expertise in Financial, CPG and Pharma sector will combine and' +
        ' harmonize all your datasets to create a single source of truth');
    },
    mounted () {
      this.arrowContentHeight = document.getElementById('arrow-content').clientHeight;
      // console.log(this.arrowContentHeight);
      this.getHeight();
    },
    methods: {
      closeBanner: function () {
        this.showBanner = false;
      },
      handleTour: function () {
        window.open('https://app.explorazor.com/#/playground', '_blank');
      },
      getImage: function (img) {
        return require(`@/assets/png/${ img }.png`);
      },
      getHeight: function () {
        let height = this.$refs['arrow-content'].clientHeight;
      }
    }
  };
</script>

<style scoped>
  .firewall-image-container {
    height: 0 !important
  }

  .service-header-container {
  }

  .firewall-text {
    width: 60% !important
  }

  .data-engineering-wrapper {
    margin-top: 550px;
    padding-bottom: 60px;
  }

  .services-main-header {
    padding-top: 90px;
    padding-bottom: 30px;
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 62px;
    text-align: center;
    color: #191948;
  }

  .services-header-content {
    padding-bottom: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #191948;
    padding-right: 10%;
    padding-left: 10%;
  }

  .service-header-content {
    padding-bottom: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #191948;
    padding-right: 20%;
    padding-left: 20%;
  }

  .services-header-sub-content {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    color: #191948;
  }

  .service-header-wrapper {
    display: flex;
    flex-direction: column;
    height: 707px;
    background: #DFE8F7;
    width: 100% !important;
  }

  .data-engineering {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    color: #191948;
    /*position: relative;*/
    /*top: 450px;*/
    padding-bottom: 30px;
  //margin-top: 250px;
  }

  .data-engineering-container {
    justify-content: center;
    height: auto;
  }

  .dataset-service-image {
    position: absolute;
    top: 491px;
    width: 80%
  }

  .data-engineering-image-container {
    background: #191948;
    justify-content: center;
  }

  .data-engineering-image {
    width: 60%;
    padding: 107px 0;
  }

  .how-to-model-container {
    justify-content: center;
    width: 100% !important;
    flex-direction: column;
  }

  .how-to-model-title {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    color: #191948;
    padding-top: 75px;
    padding-bottom: 40px;
  }

  .container-arrow-class {
    position: relative;
    top: 60px;
    left: 0px;
    justify-content: start;
    margin-bottom: 120px;
    height: 100%;
  }

  .arrow-container {
    display: flex;
    flex-direction: row !important;
    padding: 0 10%;
    margin-bottom: 100px;
  }

  .arrow-content-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
    color: #191948;
    text-align: left;
    padding-bottom: 15px;
  }

  .arrow-sub-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #191948;
    text-align: left;
  }

  .arrow-content-container {
    margin-top: 170px;
    padding-left: 35px;
  }

  .arrow-content-image {
    width: 90%
  }

  .partner-images {
    border-radius: 6px;
    margin-left: 12px;
    padding: 22px 30px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .partners-header {
    padding: 80px 0 40px 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #191948;
  }

  .data-in-firewall {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 100px 0;
    /*background-image: url('../assets/svgs/card-image/background-image.svg');*/
  }

  .firewall-content {
    width: 80%;
    padding: 65px 140px;
    border-radius: 20px !important;
    /*border: 20px solid;*/
    /*border-image: linear-gradient(to right, #0242EB, #A7C1EB) 10;*/
    background-origin: border-box;
    background-clip: padding-box, border-box;
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    display: flex;
    align-items: start;
    text-align: left !important;
    color: #191948;
    background: #DFE8F7;
    justify-content: start;
  }

  .fortune-500-container {
    background-image: url('../assets/png/fotune-500.png') !important;
    width: 100%;
    height: 443px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
  }

  .fortune-500-text {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 59px;
    text-align: center;
    color: #FFFFFF;
    width: 50%;
    padding: 0 30px;
  }

  .fortune-500-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    border-radius: 4px;
    margin-top: 40px;
    width: 200px;
    height: auto;
  }

  .bullet-point-class {
    position: relative;
    left: -53px;
    top: -153px;
  }

  .bullet-content {
    width: 25%;
  }

  .bullet-image {
    width: 70%;
  }

  @media screen and (max-width: 1650px) {
    .data-engineering-wrapper {
      margin-top: 450px;
    }
  }

  @media screen and (max-width: 1450px) {
    .data-engineering-wrapper {
      margin-top: 350px;
    }
  }

  @media screen and (max-width: 1366px) {
    .firewall-image {
      right: -320px !important
    }

    .data-engineering-wrapper {
      margin-top: 300px !important;
    }

    .fortune-500-container {
      background-size: auto;
      background-repeat: no-repeat;
      background-position-x: center;
    }

    .firewall-content {
      padding-left: 100px;
    }

    .data-engineering-container {
      height: auto !important;
    }

    .data-engineering {
      top: 300px !important;
    }

    .bullet-point-class {
      left: -51px !important;
      top: -140px !important
    }
  }

  @media screen and (max-width: 820px) {
    .firewall-image-container {
      background: #DFE8F7;
      width: 80%;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      height: auto !important
    }

    .firewall-content {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      text-align: center !important;
      padding: 40px 20px;
    }

    .firewall-text {
      width: 100% !important
    }

    .firewall-image {
      position: static !important
    }

    .data-engineering-wrapper {
      margin-top: 330px !important;
    }

    .services-header-content {
      font-size: 20px !important;
    }

    .bullet-content {
      width: 100% !important
    }

    .bullet-image {
      width: 100% !important;
    }

    .arrow-content-image {
      width: 100%;
      padding-top: 25px;
    }

    .arrow-content-title {
      padding-left: 15px
    }

    .arrow-sub-content {
      padding-left: 15px
    }

    .how-to-model-title {
      padding-right: 10%;
      padding-left: 10%;
    }

    .services-main-header {
      padding-top: 100px !important;
      /*font-weight: 600;*/
      /*font-size: 42px;*/
      /*line-height: 52px;*/
    }

    .data-engineering-image {
      width: 90%
    }

    .service-header-wrapper {
      height: 530px !important
    }

    .dataset-service-image {
      width: 90% !important;
    }

    .bullet-point-class {
      top: 20px !important;
      left: -53px !important
    }

    .arrow-container {
      margin-bottom: 60px !important;
    }

    .data-engineering-container {
      height: auto !important;
    }

    .data-engineering {
      top: 230px !important;
    }

  }

  @media screen and (max-width: 414px) {
    .firewall-image {
      width: 80% !important;
    }

    .firewall-image-container {
      width: 90% !important;
      height: auto !important
    }

    .service-header-content {
      padding-right: 10% !important;
      padding-left: 10% !important
    }

    .data-engineering-wrapper {
      margin-top: 150px !important;
    }

    .partners-header {
      padding: 60px 5% 40px 5%;
    }

    .partner-images {
      padding: 15px 15px !important
    }

    .fortune-500-text {
      font-size: 36px;
      line-height: 45px;
      width: 100% !important
    }

    .services-main-header {
      font-weight: 600;
      font-size: 42px;
      line-height: 52px;
      padding-left: 5%;
      padding-right: 5%;
    }

    .services-header-content {
      font-weight: 400;
      font-size: 16px !important;
      line-height: 26px;
      padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 0 !important
    }

    .service-header-wrapper {
      height: 570px !important;
    }

    .dataset-service-image {
      top: 600px !important;
    }

    .data-engineering {
      top: 75px !important;
      font-weight: 600;
      font-size: 28px;
      line-height: 30px;
      padding-left: 5%;
      padding-right: 5%
    }

    .services-header-sub-content {
      font-weight: 600;
      font-size: 28px;
      line-height: 35px;
    }

    .data-engineering-container {
      height: auto !important
    }

    .how-to-model-title {
      font-weight: 600;
      font-size: 28px !important;
      line-height: 38px;
    }

    .arrow-content-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
    }

    .arrow-content-container {
      margin-top: 80px !important;
      padding-left: 15px !important;
    }

    .bullet-point-class {
      top: 24px !important;
      left: -32px !important
    }

    .firewall-content {
      padding: 30px 40px !important;
      width: 90% !important;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
    }

    .data-engineering-image {
      padding: 30px 0;
    }
  }

  @media screen and (max-width: 384px) {
    .dataset-service-image {
      top: 610px !important;
    }
  }

  .firewall-image {
    position: relative;
    right: -500px;
    bottom: 320px;
  }

  .try-now-class:hover {
    text-decoration: underline;
    font-weight: 600
  }
</style>