<template>
  <div class="policy">
    <div class="policy-header">
      <h4>TERMS OF USE</h4>
    </div>
    <p>
      The services that vPhrase Analytics Solutions Private Limited (“Company”) makes available on
      <a href="https://www.vphrase.com/explorazor">Explorazor</a>
      (“Website”) and is provided to you (“User”) is subject to the following terms of use (“Terms of Use”). The Company
      reserves the right to update the Terms of Use at any time without notice to the User. The most current version of
      the Terms of Use can be reviewed by clicking on the "Terms of Use" located on the Website.
    </p><br><br>
    <p>
      This Terms of Use, which incorporates by reference other provisions applicable to use of the Website, including,
      but not limited to, supplemental terms and conditions set forth hereof governing the use of certain specific
      features of the Website, sets forth the terms and conditions that apply to use of Website by User. This Terms of
      Use is a legal agreement between the User and the Company (“Agreement”) and governs the User’s use of the Website
      and the Services. By using the Website (other than to read this Agreement for the first time), User agrees to
      comply with all of the terms and conditions hereof. The right to use Website is personal to User and is not
      transferable to any other person or entity.
    </p><br>
    <p>
      The Company shall have the right at any time to change or discontinue any aspect or feature of the Website,
      including, but not limited to, content, hours of availability, and equipment needed for access or use. The Company
      shall have the right at any time to change or modify the terms and conditions applicable to Users or to impose new
      conditions, including, but not limited to, adding fees and charges for use. Such changes, modifications, additions
      or deletions shall be effective immediately upon notice thereof, which may be given by means including, but not
      limited to, posting on the Website, or by electronic or conventional mail, or by any other means by which User
      obtains notice thereof. Any use of the Website or the Services by the User after such notice shall be deemed to
      constitute acceptance by User of such changes, modifications or additions. It is clarified that these Terms of Use
      shall also be applicable to such Users who do not avail the Services but use the demo / trial Services or visit
      the Website.
    </p><br><br>
    <h4 class="information-collection header">
      1. DEFINITIONS
    </h4><br>
    <div class="information-collection">
      <p>
        1.1 “<b>Company Data</b>” means the aggregated and analyzed data made available to the User on the Website, and
        such
        data derived as an output from the Website, specifically for the User;
      </p><br>
      <p>
        1.2 “<b>Personal Data</b>” means any information relating to an identified or identifiable natural person (“data
        subject”); an “identifiable natural person” being one who can be identified, directly or indirectly by reference
        to an identifier such as a name, an identification number, location data, an online identifier or to one or more
        factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that
        natural person. Personal Data includes PII;
      </p><br>
      <p>
        1.3 “<b>Personally Identifiable Information</b>” or “<b>PII</b>” means any (i) personally identifiable
        information
        related to
        a data subject; (ii) social security number with the associated name; (iii) mother’s maiden name with the
        associated name; (iv) driver’s license or other government issued identification card numbers with the
        associated name; (v) telephone numbers identified as unlisted or unpublished; (vi) credit, debit card or
        financial account numbers with the associated name and any required PIN or access code; (vii) personally
        identifiable health information; (viii) personally identifiable payroll/financial information including employee
        identification numbers; or (ix) any non-public personal information, as that term is known under the applicable
        laws, including privacy and data protection laws and/or regulations;
      </p><br>
      <p>
        1.4 “<b>Report</b>” shall mean the personalised reports created by the User using the Services;
      </p><br>
      <p>
        1.5 “<b>Service(s)</b>” shall mean a software-as-a-service and related services provided by the Company
        pursuant to
        payment of the Subscription Fee by the User to the Company;
      </p><br>
      <p>
        1.6 “<b>User Data</b>” means the data, information, material uploaded/provided by the User; and
      </p><br>
      <p>
        1.7 “<b>Subscription Fee</b>” shall mean any fee payable by the User to avail the Services.
      </p><br>
      <br>
    </div>
    <h4 class="information-collection header">
      2. DESCRIPTION OF SERVICES
    </h4><br>
    <p>
      In consideration of the Subscription Fee paid by the User, the Company shall grant limited rights to the User to
      use the Services, and any new features that augment or enhance the current Service, including new releases, shall
      be subject to these or other applicable Terms of Use. The User understands and acknowledges that the Company may
      modify, terminate, suspend, or otherwise adjust any and all functions, features, options, utilities, tools or
      other aspects of the Service at any time without prior notice to the User.
    </p><br>
    <br>
    <h4 class="information-collection header">
      3. MEMBER ACCOUNT, PASSWORD, AND SECURITY
    </h4><br>
    <p>
      To avail the Services, the User would need to have an account, and the User must complete the registration process
      by providing current, complete and accurate information as prompted by the applicable registration form. In the
      event (a) the registration is done by the User on the Website, the User will primarily have to choose a password
      and a user name (and provide other necessary details to the Website to facilitate the same), and/or (b) the
      registration is facilitated by the Company offline, then a user name and a one-time password shall be provided to
      the User. User is entirely responsible for maintaining the confidentiality of User’s password and account.
      Furthermore, the User is entirely responsible for any and all activities that occur under User’s account. User
      agrees to notify the Company immediately of any unauthorized use of User’s account or any other breach of
      security. The Company will not be liable for any loss that User may incur as a result of someone else using User’s
      password or account, either with or without User’s knowledge. However, User could be held liable for losses
      incurred by the Company or another party due to someone else using User’s account or password. User may not use
      anyone else's account at any time, without the permission of the account holder.
    </p><br>
    <br>
    <h4 class="information-collection header">
      4. CANCELLATION AND REFUNDS
    </h4><br>
    <p>
      Once a User has opted to avail the Services and made payment towards the Subscription Fee, the User can any time
      cancel the subscription availed for the Services, however, no refunds of the Subscription Fee or any part thereof
      will be provided to User on account of any cancellations of the Services. Please note that any request for
      cancellations cannot be made on the Website by the User, and in order to cancel the Services a User is required to
      reach out to the Company directly, and such request is to be dealt between the User and the Company. If any
      fraudulent transaction is suspected or any transaction which defies the Terms of Use, the Company at its sole
      discretion could cancel such orders. Further, as the transaction performed by the User on the Website occurs upon
      User’s consent, no refunds request can be made to the Company.
    </p><br>
    <br>
    <h4 class="information-collection header">
      5. EQUIPMENT
    </h4><br>
    <p>
      User shall be responsible for obtaining and maintaining all the User Data, telephone, computer hardware, software
      and other equipment needed for access to, and use, the Services.
    </p><br>
    <br>
    <h4 class="information-collection header">
      6. USAGE AND RESTRICTIONS
    </h4><br>
    <p>
      (a) All rights not expressly granted herein are reserved by the Company and its licensors. The User may not create
      any white-labelled version of the Website / the Services for the specific use of the User or its customer with
      User’s branding and collaterals upon mutual consent.
    </p><br>
    <p>
      (c) The Company will use commercially reasonable security technologies in providing the Services and the User
      shall comply with the applicable Company security policies, as may be made known to the User. However, the Company
      does not control the transfer of data, including but not limited to the User Data, over telecommunications
      facilities used by the User, including the internet, and the User acknowledges and agrees that the Company shall
      have no responsibility for any computer viruses, worms, software bombs, bugs or similar items that affect the
      User’s computers, computer systems, software, infrastructure or data as a result of the User’s access to or use of
      the Services.
    </p><br>
    <p>
      (d) The User acknowledges and agrees that (i) nothing in these Terms of Use guarantees that the Services will be
      free from interruption or errors, (ii) there will be periods when the Service is unavailable and cannot be
      accessed and (iii) the Company accepts no liability for any loss or damage the User may suffer or incur as a
      result of such unavailability at any time.
    </p><br>
    <p>
      (e) The User shall not: (i) license, sublicense, sell, resell, transfer, assign, distribute or otherwise
      commercially exploit or make available to any third party access to the Website in any way; (ii) copy, translate,
      decompile, reverse-engineer or otherwise modify any parts of the Website (including the Company Data), modify or
      make derivative works based upon the Service offered; (iii) transmit any content, data or information that is
      unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libellous, invasive of
      another’s privacy or right of publicity, hateful, or racially, ethnically or otherwise objectionable; (iv) create
      internet "links" to the Website "frame" or "mirror" the Website on any other server or wireless or internet-based
      device; (v) interfere with or disrupt the Company systems used to host the Company Data, other equipment or
      networks connected to Company Data, or disobey any requirements, procedures, policies or regulations of networks
      accessed; (iv) circumvent the user authentication/login provided; (vii) access the Website in order to (x) build a
      competitive product or service, (y) build a product using similar ideas, features, functions or graphics of the
      Website, or (z) copy any codes, ideas, features, functions or graphics of the Website; (viii) except for access
      provided to the User as permitted in these Terms of Use, allow any third party to use any user identification(s),
      code(s), password(s), procedure(s) and user keys issued to, or selected by the User to access the Website and/or
      the Services.
    </p><br>
    <p>
      (f) The User is not relieved of any of its liabilities or obligations under these Terms of Use due to the fact
      that the User engages the services of a third party and in so far as the acts, omissions, defaults and neglects of
      the User or any employee or agent of the User results in a breach of the User’s obligations under these Terms of
      Use, then such acts, omissions, defaults and neglects will be treated as if they were the acts, defaults or
      neglects of the User.
    </p><br>
    <p class="information-collection">
      (g) By way of example, and not as a limitation, User agrees that when using the Services, the User will not:
    </p><br>
    <div class="performance-list">
      <p>
        i. Use the Services in connection with surveys, contests, pyramid schemes, chain letters, junk email, spamming
        or any duplicative or unsolicited messages (commercial or otherwise);
      </p><br>
      <p>
        ii. Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and
        publicity) of others;
      </p><br>
      <p>
        iii. Publish, upload, distribute or disseminate any inappropriate, profane, defamatory, obscene, indecent or
        unlawful topic, name, material or information;
      </p><br>
      <p>
        iv. Upload, or otherwise make available, files that contain images, photographs, software or other material
        protected by intellectual property laws, including, by way of example, and not as limitation, copyright or
        trademark laws (or by rights of privacy or publicity) unless the User owns or controls the rights thereto or has
        received all necessary consent to do the same;
      </p><br>
      <p>
        v. Use any material or information, including images or photographs, which are made available through the
        Services in any manner that infringes any copyright, trademark, patent, trade secret, or other proprietary right
        of any party;
      </p><br>
      <p>
        vi. Upload files that contain viruses, trojan horses, worms, time bombs, cancelbots, corrupted files, or any
        other similar software or programs that may damage the operation of another's computer or property of another;
      </p><br>
      <p>
        vii. Violate any code of conduct or other guidelines which may be applicable for the Service or any part
        thereof;
      </p><br>
      <p>
        viii. Violate any applicable laws or regulations;
      </p><br>
      <p>
        ix. Create a false identity for the purpose of misleading others; and
      </p><br>
      <p>
        x. Use, download or otherwise copy, or provide (whether or not for a fee) to a person or entity any directory of
        users of the Services or other user or usage information or any portion thereof.
      </p><br>
    </div>
    <p>
      (h) Always use caution when giving out any Personally Identifiable Information in any Report or User Data. The
      User agrees and acknowledges that the Company and/or Website, as applicable, does not control or endorse the
      content, messages or information found in any User Data or Report and, therefore, the Company and/or the Website
      specifically disclaims any liability with regard to the Services and any actions resulting from User’s use of the
      Services.
    </p><br>
    <p>
      (i) The User acknowledges that the Company may or may not pre-screen User Data, but that the Company shall have
      the right (but not the obligation) to remove the User Data from the Service that the Company determines, in its
      discretion, may create liability or damages to the Company. The User agrees to evaluate, and bear all risks
      associated with, the use of any User Data, including any reliance on the accuracy, completeness, or usefulness of
      such User Data. The User undertakes that payments related data shall not be made available on the Website or
      uploaded while using the Services; and the User acknowledges that the Company does not collect or store any
      financial data of the User, and considering that the applicable security standard (across the world) in relation
      to storing or accessing the payments data of the User is different and complex, the Company shall only on best
      effort basis (and not as an obligation) try to comply with the applicable security standard (across the world) in
      case the Company as part of the Services collects or stores any financial data of the User. However, the Company
      will not take any responsibility for any such financial data provided by the User (voluntarily) while using the
      Services. In this regard, the User acknowledges that the Services are made available to the User without any
      warranties by the Company of any kind, and that the User has an option to not rely on any content made available
      through the Services.
    </p><br><br>
    <h4 class="information-collection header">
      7. USER CONDUCT
    </h4><br>
    <p>
      (a) User shall use the Website for lawful purposes only. Any conduct by a User that in the Company's discretion
      restricts or inhibits any other User from using or enjoying the Services will not be permitted. The User shall not
      use the Services or the Website to advertise or perform any commercial solicitation, including, but not limited
      to, the solicitation of users to become subscribers of other online information services competitive with Website.
    </p><br>
    <p>
      (b) The Website contains copyrighted material, trademarks and other proprietary information, including, but not
      limited to, text, software, photos, video, graphics, music and sound, and the entire contents of the Website are
      copyrighted as a collective work under the Indian copyright laws. The Company owns a copyright in the selection,
      coordination, arrangement and enhancement of such content, as well as in the content original to it. The User may
      not modify, publish, transmit, participate in the transfer or sale or in any way exploit, any of the contents of
      the Website, in whole or in part. Except as otherwise expressly permitted under copyright law, no copying,
      redistribution, retransmission, publication or commercial exploitation of downloaded material (except for the User
      Data) will be permitted without the express permission of the Company. The User acknowledges that it shall not
      acquire any ownership rights by downloading or accessing copyrighted material of the Company from the Website.
    </p><br>
    <p>
      (c) User shall not upload, post or otherwise make available on the Website any material protected by copyright,
      trademark or other proprietary right without the express permission of the owner of the copyright, trademark or
      other proprietary right and the burden of determining that any material is not protected by copyright rests with
      the User. The User shall be solely liable for any damage resulting from any infringement of copyrights,
      proprietary rights, or any other harm resulting from such a submission. By uploading the material on the Website,
      the User automatically grants, or warrants that the owner of such material has expressly granted the Company, a
      royalty-free, perpetual, irrevocable, non-exclusive right and license to use, reproduce, modify, adapt, publish,
      translate and distribute such material (in whole or in part) worldwide and/or to incorporate it in other works in
      any form, media or technology now known or hereafter developed for the full term of any copyright that may exist
      in such material.
    </p><br>
    <p>
      (d) The foregoing provisions of this Section and Section 5 and 6 are for purpose of the Website, and the Company
      and/or its subsidiaries, affiliates and its third party content providers and licensors and each shall have the
      right to assert and enforce such provisions directly or on its own behalf.
    </p><br>
    <br>
    <h4 class="information-collection header">
      8. REPRESENTATION AND WARRANTIES
    </h4><br>
    <p class="information-collection">
      By registering on or using the Website, the User represents and warrants that:
    </p><br>
    <div class="performance-list">
      <p>
        (a) User has all the required licenses under the applicable laws to avail the Services;
      </p><br>
      <p>
        (b) There are no legal or regulatory impediments which prevent the User from availing the Services;
      </p><br>
      <p>
        (c) It is User’s responsibility to manage the usage or access of User’s account by any such third party
        (including assistants, juniors, associates, etc.) and User shall be responsible for any damage caused by such
        third party through usage of User account; and
      </p><br>
      <p>
        (d) All such documents and their particulars submitted to the Company and/or the Website by User for the
        registration are true and have not been obtained through any unfair, fraudulent, unethical, suspect or illegal
        means.
      </p><br><br>
    </div>
    <h4 class="information-collection header">
      9. DISCLAIMER SPECIFIC TO THE WEBSITE / SERVICES
    </h4><br>
    <p>
      (a) Any software, if any, that is made available to download from the Services ("Software") is the copyrighted
      work of the Company and/or its licensors. The use of the Software is governed by the terms of the end user license
      agreement, if any, and such other policies which accompanies or are included with the Software ("License
      Agreement"). An end user will be unable to install the Software or any part thereof that is accompanied by or
      includes a License Agreement, unless he or she first agrees to the terms of the License Agreement.
    </p><br>
    <p>
      (b) The Software is made available for download solely for use by end users according to the License Agreement.
      Any reproduction or redistribution of the Software not in accordance with the License Agreement is expressly
      prohibited by law, and may result in severe civil and criminal penalties. Violators will be prosecuted to the
      maximum extent possible.
    </p><br>
    <p>
      (c) Without limiting the foregoing, copying or reproduction of the Software to any other server or location for
      further reproduction or redistribution is expressly prohibited, unless such reproduction or redistribution is
      expressly permitted by the License Agreement accompanying such Software. The Software is warranted, if at all,
      only according to the terms of the License Agreement. Except as warranted in the License Agreement, the Company
      and/or the Website, as applicable hereby disclaims all warranties and conditions with regard to the Software,
      including all warranties and conditions of merchantability, whether express, implied or statutory, fitness for a
      particular purpose, title and non-infringement.
    </p><br>
    <p>
      (d) For User’s convenience, the Website may make available as part of the Services or in its software products,
      tools and utilities for use and/or download. The Website does not make any assurances with regard to the accuracy
      of the results or output that derives from such use of any such tools and utilities.
    </p><br><br>
    <h4 class="information-collection header">
      10. NOTICE SPECIFIC TO DOCUMENTS AVAILABLE ON THE WEBSITE
    </h4><br>
    <p>
      (a) Permission to use any content and/or documents, as may be provided, from the Services is granted, provided
      that (i) the copyright notice (as may be provided by the Website) appears in all copies and that both the
      copyright notice and this permission notice appear, (ii) use of such content and/or documents from the Services is
      for informational and non-commercial or personal use only and will not be copied or posted on any network computer
      or broadcast in any media, and (iii) no modifications of any content and/or documents should be made.
    </p><br>
    <p>
      (b) The content and/or documents and related graphics published on the Website as part of the Services could
      include technical inaccuracies or typographical errors. Changes are periodically added to the information herein.
    </p><br><br>
    <h4 class="information-collection header">
      11. NOTICES REGARDING CONTENT, DOCUMENTS AND SERVICES AVAILABLE ON WEBSITE
    </h4><br>
    <p>
      In no event shall the Company and/or the Website and/or its respective suppliers/third-party service providers
      be liable for any special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
      data or profits, whether in an action of contract, negligence or other tortious action, arising out of or in
      connection with the use or performance of content, documents. software (if any), documents, provision of or
      failure to provide services, or information available from the Services.
    </p><br><br>
    <h4 class="information-collection header">
      12. CONFIDENTIALITY
    </h4><br>
    <p>
      The User acknowledges that the Company Data made available to the User through the Services is confidential data
      and the User shall not disclose such data to any third person without consent of the Company. The User further
      acknowledges that the User Data is confidential information of the User and the User is willingly using the User
      Data on the Website and the Company will not be responsible for any disclosure or misuse of the User Data that is
      disclosed by the User, at its sole discretion.
    </p><br><br>
    <h4 class="information-collection header">
      13. INTELLECTUAL PROPERTY RIGHTS
    </h4><br>
    <p>
      (a) By agreeing to these Terms of Use, the User irrevocably acknowledges that the User has no ownership interest
      in the Website and/or the Company Data and/or the Services. Subject to any limitations associated with
      intellectual property rights of third parties, the Company and its licensors, where applicable, shall retain sole
      and exclusive ownership in any and all patent rights, copyrights, trademark rights and other intellectual property
      rights in the Website and/or the Company Data and/or the Services, as well as any enhancements, changes,
      revisions, modifications, design contributions or derivative works conceived, made or created to the same,
      including the know-how, techniques, or procedures acquired or used by the Company. Except for the rights granted
      herein to the User, nothing herein shall be construed to assign or transfer any intellectual property rights to
      the User. All suggestions, solutions, improvements, corrections, and other contributions (“Feedback”) provided by
      the User regarding the Website, Company Data, or the Services, while using the Services, shall be owned by the
      Company, and the User hereby agrees to assign all rights in such Feedback to the Company. Upon written request
      from Company, and unless otherwise requested or required by law, the User agrees to expunge or destroy all the
      Company Data from its servers, computers, or networks within 15 (fifteen) days of Company’s written notice.
    </p><br>
    <p>
      (b) The User owns and retains all right, title and interest (including without limitation all intellectual
      property rights) in and to the User Data and any updates or modifications to the foregoing, unless expressly
      agreed otherwise between the Company and the User. In case the User shares/uploads any User Data on the Website or
      requires the Company to use any User Data in combination with Company Data or in any other manner required for
      provision of the Services, the User grants to Company the non-exclusive, fully paid-up, worldwide, irrevocable,
      perpetual license to use the User Data for use in the Website, Company Data, and/or the Services as well as its
      internal business purposes and to further modify, compile, store, validate, integrate, normalise, aggregate, sort,
      manipulate, analyse, combine with other third party data and create derivative works of the User Data.
    </p><br><br>
    <h4 class="information-collection header">
      14. PRIVACY
    </h4><br>
    <p>
      (a) The User warrants that it will not provide any Personal Data to the Company. The User shall ensure that it
      will review all User Data prior to using the User Data on the Website for generation of the Report or using the
      Services or any part thereof.
    </p><br>
    <p>
      (b) In case the User Content contains any Personal Data, or the User otherwise discloses Personal Data to the
      Company, the User represents and warrants that it will always have explicit consents from the respective data
      subjects who own such Personal Data (including, as applicable, the end users or customers of the User) in respect
      of the following:
    </p><br>
    <div class="performance-list">
      <p>
        (i) sharing such Personal Data with the Company;
      </p><br>
      <p>
        (ii) the Company’s processing of such Personal Data for data enrichment activity, which includes, but is not
        limited to, identifying the behaviour of such data subjects and profiling them based on their physical/digital
        world behaviour to create an enriched dataset from such Personal Data (“Enriched Data”);
      </p><br>
      <p>
        (ii) the Company’s processing of such Personal Data for data enrichment activity, which includes, but is not
        limited to, identifying the behaviour of such data subjects and profiling them based on their physical/digital
        world behaviour to create an enriched dataset from such Personal Data (“Enriched Data”);
      </p><br>
    </div>
    <p>
      Please refer to the terms of the Privacy Policy, which forms part of this Terms of Use, for more details in
      relation to the privacy of the information disclosed to us by the User.
    </p><br><br>
    <h4 class="information-collection header">
      15. DISCLAIMER OF WARRANTY; LIMITATION OF LIABILITY
    </h4><br>
    <p>
      (a) The User expressly agrees that use of the Services is at the User's sole risk. Neither the Company, its
      affiliates nor any of their respective employees, agents, third-party content providers or licensors warrant that
      the Website and/or the Services will be uninterrupted or error free; nor do they make any warranty as to the
      results that may be obtained from use of the Website, or as to the accuracy, reliability or content of any
      information, service, or merchandise provided through the Website.
    </p><br>
    <p>
      (b) The Services (including, without limitation, any content) is provided “as is” and “as available” basis, and
      the Company hereby disclaims and expressly waives all other conditions, representations and guarantees, whether
      express or implied, arising by law, custom, oral or written statements of Website or third parties including, but
      not limited to, any warranty of merchantability or fitness for particular purpose or of error-free and
      uninterrupted use of the Services.
    </p><br>
    <p>
      (c) This disclaimer of liability applies to any damages or injury caused by any failure of performance, error,
      omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line
      failure, theft or destruction or unauthorized access to, alteration of, or use of record, whether for breach of
      contract, tortious behavior, negligence, or under any other cause of action. The User specifically acknowledges
      that the Website is not liable for the defamatory, offensive or illegal conduct of other users or third-parties
      and that the risk of injury from the foregoing rests entirely with the User.
    </p><br>
    <p>
      (d) In no event will the Website, or any person or entity involved in creating, producing or distributing the
      Website or the Services, be liable for any damages, including, without limitation, direct, indirect, incidental,
      special, consequential or punitive damages arising out of the use of or inability to use the Website or the
      Services. The User hereby acknowledges that the provisions of this Section shall apply to all types of the content
      on the Website.
    </p><br>
    <p>
      (e) In addition to the terms set forth above neither, the Company, nor its affiliates, information providers or
      content partners shall be liable regardless of the cause or duration, for any errors, inaccuracies, omissions, or
      other defects in, or untimeliness or unauthenticity of, the information contained within the Website, or for any
      delay or interruption in the transmission thereof to the User, or for any claims or losses arising therefrom or
      occasioned thereby. None of the foregoing parties shall be liable for any third-party claims or losses of any
      nature, including, but not limited to, lost profits, punitive or consequential damages.
    </p><br><br>
    <h4 class="information-collection header">
      16. LINKS TO THIRD PARTY SITES
    </h4><br>
    <p>
      The Service may include functionality to provide access to products, services, information, data, content and
      other materials made available by or on behalf of third parties, including third party software applications made
      available through the Website (collectively, “Third Party Materials”) or allow for the routing or transmission of
      Third Party Materials, including via links. By using such functionality, the User acknowledges that it shall be
      construed as directing the Company to access, route and transmit to the User the applicable Third Party Materials.
      Any installation or other use of Third Party Materials by the User may result in the transmission of the User’s
      information, data, content or other materials outside of the User or the Company’s systems, or otherwise result in
      third party access to, or use of, the User’s information, data, content or other materials. Third Party Materials
      are not part of the Services provided by the Company under these Terms of Use. The Company does not control, has
      no obligation to monitor, and will have no responsibility or liability for any Third Party Materials. The Company
      makes no representation or warranty regarding any Third Party Materials or any information, content, data or other
      materials made available through any Third Party Materials. The Company may block or disable access to any Third
      Party Materials (in whole or part) through the Services at any time. In addition, the availability of any Third
      Party Materials through the Website or any other Services does not imply the Company’s endorsement of, or
      affiliation with, any provider of such Third Party Materials. The use of Third Party Materials is subject to any
      additional terms, conditions and policies applicable to such Third Party Materials (such as terms of service or
      privacy policies of the providers of such Third Party Materials) or applicable to the Website.
    </p><br><br>
    <h4 class="information-collection header">
      17. MONITORING
    </h4><br>
    <p>
      The Company shall have the right, but not the obligation, to monitor the content of the Website, to determine
      compliance with this Terms of Use and any operating rules established by the Company and to satisfy any law,
      regulation or authorized government request. Without limiting the foregoing, the Company shall have the right to
      remove any material from the Website, in its sole discretion, if it finds such material or the content to be in
      violation of the provisions hereof or otherwise objectionable.
    </p><br><br>
    <h4 class="information-collection header">
      18. INDEMNIFICATION
    </h4><br>
    <p>
      The User agrees to indemnify and hold the Company and its subsidiaries, affiliates, officers, agents, employees,
      partners and licensors harmless from any claim or demand, including reasonable attorneys' fees, made by any third
      party due to or arising out of: (a) content the User submits, posts, transmits or otherwise makes available
      through the Service; (b) the User’s use of the Service; (c) the User’s connection to the Service; (d) the User’s
      violation of these Terms of Use; or (e) the User’s violation of any third party’s rights.
    </p><br><br>
    <h4 class="information-collection header">
      19. LIMITATION OF LIABILITY
    </h4><br>
    <p>
      The Company will not be liable for any indirect, special, incidental, exemplary, any attorney fees punitive or
      consequential damages or for any loss of data, loss of goodwill, loss of profit or revenue, arising out of or in
      connection with these Terms of Use, however caused, and under whatever cause of action or theory of liability
      brought including under any contract, negligence or other tort, even if the Company has been advised of the
      possibility of such damages, notwithstanding the failure of essential purpose of any remedy.
    </p><br><br>
    <h4 class="information-collection header">
      20. OUR REACH RIGHTS
    </h4><br>
    <p>
      The Company reserves the rights to display sponsored ads on the Website. These ads would be marked as “Sponsored
      Listings”. Without prejudice to the status of other content, the Company will not be liable for the accuracy of
      information or the claims made in the Sponsored Listings. The Company does not (in any manner) encourage the User
      to visit the Sponsored Listings page or to avail any services from them. The Company will not be liable for the
      services of the providers of the Sponsored Listings.
    </p><br><br>
    <h4 class="information-collection header">
      21. TERMINATION
    </h4><br>
    <p>
      The User agrees that the Company may, under any circumstances and without prior notice, immediately terminate the
      User’s account, and access to the Service(s) and/or any and all content or materials. Cause for such termination
      shall include, but not be limited to: (a) breaches or violations of these Terms of Use or other incorporated
      agreements or guidelines, (b) requests by law enforcement or other governmental authorities, (c) a request by the
      User (self-initiated account deletions), (d) discontinuance or material modification to the Services (or any part
      thereof), (e) unexpected technical or security issues or problems, (f) extended periods of inactivity, (g)
      engagement by the User in fraudulent or illegal activities, and/or (h) non-payment of any fees owed by the User in
      connection with the Services. The Company may also terminate this Agreement, User’s account, access to the
      Services and/or any and all content or materials for any reason or no reason whatsoever. Termination of the User’s
      account includes: (a) removal of access to all offerings within the Services, (b) deletion of User’s password and
      all related information, files and content associated with or inside the User’s account (or any part thereof), and
      (c) barring of further use of the Services. Further, the User agrees that all terminations shall be made in the
      Company’s sole discretion and that the Company shall not be liable to the User or any third party for any
      termination of User’s account, any associated email address, or access to the Service or content.
    </p><br><br>
    <h4 class="information-collection header">
      22. MISCELLANEOUS
    </h4><br>
    <p>
      (a) Except for payment obligations, neither party will be responsible for any failure or delay in its
      performance under these Terms of Use due to causes beyond its reasonable control, including, but not limited to,
      epidemics, pandemics, lockdowns by governmental authorities, labor disputes, strikes, lockouts, internet or
      telecommunications failures, shortages of or inability to obtain labor, energy, or supplies, war, terrorism, riot,
      acts of God or governmental action, acts by hackers or other malicious third parties and problems with the
      internet generally, and such performance shall be excused to the extent that it is prevented or delayed by reason
      of any of the foregoing.
    </p><br>
    <p>
      (b) The parties shall be independent contractors under these Terms of Use, and nothing herein will constitute
      either party as the employer, employee, agent or representative of the other party, or both parties as joint
      venturers or partners for any purpose.
    </p><br>
    <p>
      (c) The User authorizes the Company to use the trade name, trademark and logo of the User for the purpose of
      listing the User in its general list of customers. The User also permits the Company to bring out press releases,
      create case studies on anonymized basis and will be open to provide quotes from time to time solely for the
      Company’s marketing purposes, provided the Company obtains prior written approval of the User specific to such
      quotes.
    </p><br>
    <p>
      (d) The User shall not have the right to assign, transfer, resell or sublicense User’s rights or obligations
      hereunder. Any attempt to assign, transfer, resell or sub-license such rights or obligations without the Company’s
      prior written approval will be null and void.
    </p><br>
    <p>
      (e) If one or more of the provisions contained in these Terms of Use is found by a court of competent
      jurisdiction to be invalid, illegal or unenforceable in any respect, the validity, legality and enforceability of
      the remaining provisions will not be affected. The provisions will be revised only to the extent necessary to make
      them enforceable.
    </p><br>
    <p>
      (f) No action of the Company other than an express written waiver may be construed as a waiver of any provision
      of these Terms of Use. A delay on the part of the Company in the exercise of its rights or remedies will not
      operate as a waiver of those rights or remedies, and a single or partial exercise by the Company of any of the
      rights or remedies will not preclude other or further exercise of that right or remedy. A waiver of a right or
      remedy on any one occasion will not be construed as a bar to or waiver of rights or remedies on any other
      occasion.
    </p><br>
    <p>
      (g) These Terms of Use together with any such agreement as may be entered into between the User and the Company
      in writing will constitute the entire agreement between the parties with respect to the subject matter thereof and
      supersede all previous communications, representations, understandings, and agreements, either oral or written,
      between the parties with respect to the subject matter covered herein. If there are any conflicts or
      inconsistencies between a specific terms or conditions of these Terms of Use and terms or conditions of the
      agreement, the term or condition of this Terms of Use will prevail, unless specifically stated in the agreement.
    </p><br>
    <p>
      (h) No modification to these Terms of Use will be binding unless in writing and signed by both parties. If any
      provision herein is held to be unenforceable, the remaining provisions will remain in full force and effect. All
      rights and remedies hereunder are cumulative.
    </p><br>
    <p>
      (i) The User agrees that the Company can and/or may communicate with the User via phone calls, SMS, E-Mails
      and/or any other method and will not be liable to the User and/or any other authority for such an act. If User’s
      number is registered with any Do Not Call (DNC) and/or Do Not Disturb (DND) service, the User hereby authorizes
      the Company to communicate with the User via telephone calls, SMS, E-Mails and/or any other method and the User
      will not in any case hold the Company liable for any communication in this regard.
    </p><br><br>
    <h4 class="information-collection header">
      23. GOVERNING LAW
    </h4><br>
    <p>
      These Terms of Use are governed by the laws of India. The User irrevocably consents to the exclusive
      jurisdiction and venue of the competent courts located at Mumbai, Maharashtra, India for all disputes arising out
      of or relating to these Terms of Use.
    </p><br><br>
    <h4 class="information-collection header">
      24. NOTICE/CORRESPONDENCES
    </h4><br>
    <p>
      Any notice required to be delivered hereunder will be deemed delivered: (a) upon delivery, if delivered by
      courier or by hand (against receipt); or (b) three (3) days after posting, if sent by electronic mail, fax, or
      certified or registered mail, return receipt requested. All notices to the Company and the User will be sent to
      the address provided by each party to the other. If no address is specified for the User, notice will be sent to
      User’s last known address. All notices/correspondences sent by User to the Company in relation to the Services,
      shall be in writing and shall be made either via (a) email to the attention of the Company at
      <a href="contact@vphrase.com/explorazor/#/contact-us">Contact Us</a>,
      or (b) to address of the Company as provided on the Website.
    </p><br>
    <p class="information-collection">
      Last Updated: [13th July 2020]
    </p><br>
    <p>
      This document is an electronic record in terms of Information Technology Act, 2000 and rules made there under as
      applicable and the amended provisions pertaining to electronic records in various statutes as amended by the
      Information Technology Act, 2000. This electronic record is generated by a computer system and does not require
      any physical or digital signatures. This document is published in accordance with the provisions of Rule 3 (1) of
      the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and
      regulations, Privacy Policy and Terms of Use for access or usage of Website.
    </p><br>
  </div>
</template>

<script>
  import { globalMixin } from '@/mixins/globalMixin';

  export default {
    mixins: [globalMixin],
    beforeMount () {
      document.title = 'Explorazor | Terms of Use';
      document.querySelector('meta[name="description"]').setAttribute('content', 'Explorazor ' +
        'Terms of Use. The right to use Website is personal to User and is not transferable to any other ' +
        'person or entity.');
    },
  };
</script>
