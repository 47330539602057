<template>
  <div class="nav">
    <div
      class="navbar-logo-container"
      @click="scrollToTop"
    >
      <router-link :to="{name:'NewHomePage'}">
        <img
          alt="Explorazor"
          class="navbar-logo  "
          src="@/assets/svgs/logo/logo.png"
          @click="toggle=false"
        >
      </router-link>
    </div>
    <div :class="toggle?'navbar-container  active':'navbar-container'">
      <div>
        <ul :class="toggle?'navbar-link-container':'navbar-link'">
          <li
            style="padding-right:0px;"
            @click="toggleNav"
          >
            <router-link :to="{name:'Features'}">
              Product
            </router-link>
          </li>
          <li
            style="padding-right:20px;"
            @click="toggleNav"
          >
            <router-link :to="{name:'DataServices'}">
              Data Services
            </router-link>
          </li>
          <li
            style="padding-right:20px;"
            class="navbar-menu-use-case"
            @click="scrollToTop"
          >
            <div
              class="navbar-sub-menu-list-container"
            >
              <router-link
                :to="{name:'UseCase'}"
                @click.native="toggle=false"
              >
                Use Cases
              </router-link>
              <!--              <span @click="toggleEnabled"><img-->
              <!--                class="navbar-icons navbar-vector"-->
              <!--                src="https://www.vphrase.com/images/vector_explorazor.svg"-->
              <!--              ></span>-->
            </div>
            <!--            <div :class="toggleSubMenu?'sub-menu active':'sub-menu'">-->
            <!--              <ul>-->
            <!--                <li @click="toggle=false">-->
            <!--                  <router-link :to="{name:'Detail-List'}">-->
            <!--                    Spends Correlation-->
            <!--                  </router-link>-->
            <!--                </li>-->
            <!--                <li @click="toggle=false">-->
            <!--                  <router-link :to="{name:'Consumer-Behavior'}">-->
            <!--                    Consumer Behavior-->
            <!--                  </router-link>-->
            <!--                </li>-->
            <!--              </ul>-->
            <!--            </div>-->
          </li>
          <li
            style="padding-right:20px;"
            class="navbar-menu"
            @click="toggleNav"
          >
            <router-link :to="{name:'Pricing'}">
              Pricing
            </router-link>
          </li>
          <li
            class="navbar-menu-use-case "
            @click="scrollToTop"
          >
            <div
              class="navbar-sub-menu-list-container"
            >
              <a @click="toggle=false">Resources</a>
              <span @click="toggleResources"><img
                class="navbar-icons navbar-vector"
                src="https://www.vphrase.com/images/vector_explorazor.svg"
              ></span>
            </div>

            <div :class="toggleResource?'sub-menu active':'sub-menu'">
              <ul>
                <li @click="toggle=false">
                  <a
                    href="https://www.vphrase.com/explorazor-blog/"
                    target="_blank"
                  >Blog</a>
                </li>
                <li @click="toggle=false">
                  <a
                    href="https://www.vphrase.com/explorazor-docs/"
                    target="_blank"
                  >Documentation</a>
                </li>
                <li @click="toggle=false">
                  <a
                    @click.prevent="routeTo({name:'WhitePaper'})"
                  >
                    White Paper
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li
            @click="toggleNav"
          >
            <router-link :to="{name:'Contact'}">
              Contact Us
            </router-link>
          </li>
        </ul>
        <router-link :to="{name:'Demo'}">
          <vs-button
            class="navbar-button"
            @click.native="toggleNav"
          >
            Request a demo
          </vs-button>
        </router-link>
      </div>
    </div>
    <div
      :class="toggle?'hamburger active':'hamburger '"
      @click="toggleNav"
    >
      <div class="bar" />
      <div class="bar" />
      <div class="bar" />
    </div>
  </div>
</template>
<script>

  export default {
    components: {},
    data () {
      return {

        toggle: false,
        toggleSubMenu: false,
        toggleResource: false,
        space: false,
        spaceForResource: false,

      };
    },
    methods: {

      toggleEnabled: function () {
        this.space = ! this.space;
        this.toggleSubMenu = ! this.toggleSubMenu;
      },
      toggleResources: function () {
        this.spaceForResource = ! this.spaceForResource;
        this.toggleResource = ! this.toggleResource;
      },
      toggleNav: function () {
        this.scrollToTop();
        if (window.innerWidth < 1053) {
          this.toggle = ! this.toggle;
        }
        return this.toggle;
      },

    }
  };
</script>
<style scoped>
    button:hover {
        background: rgba(var(--vs-success), 5) !important;
    }

    .navbar-button {
        font-family: Poppins;
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 14px;
        width: 10.6rem;
        height: 2.75rem;
        right: 25Px;
        border-radius: 4px;
    }

    .navbar-button :hover {

    }

    .navbar-vector {
        height: 10px;
        width: 10px;
    }

</style>
