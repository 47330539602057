<template>
  <div
    ref="data-platform"
    class="data-platform-class"
  >
    <div
      v-show="showTabs"
      class="platform-content-class"
    >
      <p class="platform-header">
        Everything related to data on one platform
      </p>
      <div class="platform-tabs-class">
        <div
          id="dataset"
          class="platform-tab"
          @click="datasetsTabSelection"
        >
          Datasets - Unified & Harmonized
        </div>
        <div
          id="ai"
          class="platform-tab"
          @click="AITabSelection"
        >
          AI generated <br> Insights
        </div>
        <div
          id="natural-language"
          class="platform-tab"
          @click="naturalLanguageTabSelection"
        >
          Natural Language Search
        </div>
        <div
          id="drill-down"
          class="platform-tab"
          @click="drillDownTabSelection"
        >
          Drill-down to the root-cause
        </div>
      </div>
      <div style="display: flex;justify-content:center;align-items:center;">
        <p
          v-if="datasetsTab"
          class="platform-image-para"
        >
          We combine all your datasets (Nielsen, Kantar, Primary Sales, Secondary Sales, Media, and more) into one
          harmonized dataset making it the single source of truth. No more running around data custodians or
          extracting pivots from multiple excel files.
        </p>
        <p
          v-if="drillDownTab"
          class="platform-image-para"
        >
          Losing market share? Drill down across dimensions to
          figure out if the problem is in distribution or trade
          promotion and what exactly is the problem.
        </p>
        <p
          v-if="naturalLanguageTab"
          class="platform-image-para"
        >
          Once you look at the performance, you have questions. Rather than
          troubling the insights team, ask those questions to Explorazor in simple language.
        </p>
        <p
          v-if="AITab"
          class="platform-image-para"
        >
          An AI engine, trained on data of Fortune 500 CPG companies, sends alerts and suggests action
          items.
        </p>
      </div>
      <div class="platform-image-container">
        <img
          :src="imagesOnTabSelection"
          alt="data-platform-layer"
          class="data-platform-image"
        >
      </div>
    </div>
    <div
      v-show="!showTabs"
      class="no-tabs-container"
    >
      <div>
        <h2 class="no-tabs-header">
          Everything related to data on one platform
        </h2>
      </div>
      <div class="no-tabs-content">
        <p class="no-tabs-para">
          Datasets - Unified & Harmonized
        </p>
        <p
          class="no-tabs-description"
        >
          We combine all your datasets (Nielsen, Kantar, Primary Sales, Secondary Sales, Media, and more) into one
          harmonized dataset making it the single source of truth. No more running around data custodians or
          extracting pivots from multiple excel files.
        </p>
        <img
          src="@/assets/svgs/clients/unified-harmonized.svg"
          alt="harmonized"
          class="no-tabs-image"
        >
      </div>
      <div class="no-tabs-content">
        <p class="no-tabs-para">
          AI generated Insights
        </p>
        <p
          class="no-tabs-description"
        >
          An AI engine, trained on data of Fortune 500 CPG companies, sends alerts and suggests action
          items.
        </p>
        <img
          src="@/assets/svgs/clients/ai-genetated-insights.svg"
          alt="harmonized"
          class="no-tabs-image"
        >
      </div>
      <div class="no-tabs-content">
        <p class="no-tabs-para">
          Natural Language Search
        </p>
        <p
          class="no-tabs-description"
        >
          Once you look at the performance, you have questions. Rather than
          troubling the insights team, ask those questions to Explorazor in simple language.
        </p>
        <img
          src="@/assets/svgs/clients/natural-language.svg"
          alt="harmonized"
          class="no-tabs-image"
        >
      </div>
      <div class="no-tabs-content">
        <p class="no-tabs-para">
          Drill-down to the root-cause
        </p>
        <p
          class="no-tabs-description"
        >
          Losing market share? Drill down across dimensions to
          figure out if the problem is in distribution or trade
          promotion and what exactly is the problem.
        </p>
        <img
          src="@/assets/svgs/clients/drill-down.svg"
          alt="harmonized"
          class="no-tabs-image"
        >
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'DataPlatform',
    components: {},
    data: function () {
      return {
        dataSetImg: '@/assets/png/unified-harmonized.png',
        datasetsTab: false,
        drillDownTab: false,
        AITab: false,
        naturalLanguageTab: false,
        showTabs: false
      };
    },
    computed: {
      imagesOnTabSelection: function () {
        let imgSource = '';
        if (this.datasetsTab) {
          imgSource = require('@/assets/svgs/clients/unified-harmonized.svg');
        } else if (this.drillDownTab) {
          imgSource = require('@/assets/svgs/clients/drill-down.svg');
        } else if (this.AITab) {
          imgSource = require('@/assets/svgs/clients/ai-genetated-insights.svg');
        } else if (this.naturalLanguageTab) {
          imgSource = require('@/assets/svgs/clients/natural-language.svg');
        }
        return imgSource;
      }
    },
    mounted () {
      this.datasetsTabSelection();
      this.getClientWidth();
    },
    methods: {
      datasetsTabSelection: function () {
        this.datasetsTab = true;
        this.drillDownTab = false;
        this.naturalLanguageTab = false;
        this.AITab = false;
        document.getElementById('dataset').style.background = '#1A0F91';
        document.getElementById('ai').style.background = '#19146C';
        document.getElementById('natural-language').style.background = '#19146C';
        document.getElementById('drill-down').style.background = '#19146C';
        document.getElementById('dataset').style.fontWeight = '700';
        document.getElementById('ai').style.fontWeight = '400';
        document.getElementById('natural-language').style.fontWeight = '400';
        document.getElementById('drill-down').style.fontWeight = '400';
        //   font-color
        document.getElementById('dataset').style.color = 'white';
        document.getElementById('ai').style.color = '#B3CDE3';
        document.getElementById('natural-language').style.color = '#B3CDE3';
        document.getElementById('drill-down').style.color = '#B3CDE3';

      },
      naturalLanguageTabSelection: function () {
        this.naturalLanguageTab = true;
        this.datasetsTab = false;
        this.drillDownTab = false;
        this.AITab = false;
        // background
        document.getElementById('dataset').style.background = '#19146C';
        document.getElementById('ai').style.background = '#19146C';
        document.getElementById('natural-language').style.background = '#1A0F91';
        document.getElementById('drill-down').style.background = '#19146C';
        // font weight
        document.getElementById('dataset').style.fontWeight = '400';
        document.getElementById('ai').style.fontWeight = '400';
        document.getElementById('natural-language').style.fontWeight = '700';
        document.getElementById('drill-down').style.fontWeight = '400';
        //   font-color
        document.getElementById('dataset').style.color = '#B3CDE3';
        document.getElementById('ai').style.color = '#B3CDE3';
        document.getElementById('natural-language').style.color = 'white';
        document.getElementById('drill-down').style.color = '#B3CDE3';
      },
      drillDownTabSelection: function () {
        this.drillDownTab = true;
        this.naturalLanguageTab = false;
        this.datasetsTab = false;
        this.AITab = false;
        document.getElementById('dataset').style.background = '#19146C';
        document.getElementById('ai').style.background = '#19146C';
        document.getElementById('natural-language').style.background = '#19146C';
        document.getElementById('drill-down').style.background = '#1A0F91';
        document.getElementById('dataset').style.fontWeight = '400';
        document.getElementById('ai').style.fontWeight = '400';
        document.getElementById('natural-language').style.fontWeight = '400';
        document.getElementById('drill-down').style.fontWeight = '700';
        //   font-color
        document.getElementById('dataset').style.color = '#B3CDE3';
        document.getElementById('ai').style.color = '#B3CDE3';
        document.getElementById('natural-language').style.color = '#B3CDE3';
        document.getElementById('drill-down').style.color = 'white';
      },
      AITabSelection: function () {
        this.AITab = true;
        this.datasetsTab = false;
        this.drillDownTab = false;
        this.naturalLanguageTab = false;
        document.getElementById('dataset').style.background = '#19146C';
        document.getElementById('ai').style.background = '#1A0F91';
        document.getElementById('natural-language').style.background = '#19146C';
        document.getElementById('drill-down').style.background = '#19146C';
        document.getElementById('dataset').style.fontWeight = '400';
        document.getElementById('ai').style.fontWeight = '700';
        document.getElementById('natural-language').style.fontWeight = '400';
        document.getElementById('drill-down').style.fontWeight = '400';
        //   font-color
        document.getElementById('dataset').style.color = '#B3CDE3';
        document.getElementById('ai').style.color = 'white';
        document.getElementById('natural-language').style.color = '#B3CDE3';
        document.getElementById('drill-down').style.color = '#B3CDE3';
      },
      getClientWidth: function () {
        let clientWidth = screen.availWidth;
        console.log('vail width', clientWidth);
        if (clientWidth >= 820) {
          this.showTabs = true;
        } else if (clientWidth < 820) {
          this.showTabs = false;
        }
      }
    }
  };
</script>

<style scoped>
  .no-tabs-description {
    padding: 0 15%;
    margin-bottom: 30px;
    font-size: 16px;
    color: white;
    line-height: 28px
  }

  .platform-image-para {
    text-align: center !important;
    width: 60vw;
    margin-top: 40px;
    color: white;
    line-height: 28px;
  }

  .platform-image-container {
    margin-top: 30px;
    display: flex;
    align-content: center;
    justify-content: center;
    height: 30%;
    margin-bottom: 80px;
  }

  .platform-tab {
    width: 280px;
    height: 108px;
    padding: 30px 42px;
    background: #19146C;
    margin-right: 15px;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    border-radius: 10px;
    cursor: pointer
  }

  .data-platform-class {
    background-image: url("../../assets/png/data-platform.png");
    width: 100%;
  }

  .platform-content-class {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: auto;
    flex-direction: column;
  }

  .platform-header {
    font-weight: 600;
    color: white;
    font-size: 40px !important;
    padding-top: 80px;
    padding-bottom: 60px;
    text-align: center;
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    line-height: 49px !important;
    text-align: center;
  }

  .platform-tabs-class {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row !important;
    justify-content: center;
    align-content: center;
    width: 100%;
  }

  /*  media query start */
  @media only screen and (max-width: 1366px) {
    .platform-image-container {
      width: 100% !important;
      justify-content: normal;
    }

    .data-platform-image {
      width: 100%;
      padding: 0 7%;
      height: auto
    }

    .platform-image-para {
      width: 84vw !important
    }
  }

  @media only screen and (max-height: 850px) {
    .data-platform-class {
      height: auto
    }
  }

  @media only screen and (max-width: 415px) {
    .no-tabs-header {
      font-family: 'Fraunces';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
      text-align: center;
    }

    .no-tabs-para {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px !important;
      text-align: left !important;
      padding: 0 15% !important;
    }

    .no-tabs-container {
      margin: 60px 0 40px 0 !important;
    }
  }

  @media only screen and (max-width: 821px) {
    .no-tabs-description {
      margin-bottom: 24px !important;
    }

    .no-tabs-header {
      font-family: 'Fraunces';
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 50px;
      text-align: center;
      margin-bottom: 50px !important
    }

    .no-tabs-para {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 24px !important;
      line-height: 24px;
      margin-bottom: 10px !important;
      padding: 0 15% !important;
      text-align: left !important
    }

    .no-tabs-image {
      margin-bottom: 50px !important
    }
  }

  @media only screen and (max-width: 1280px) {
    .data-platform-class {
      background-image: url("../../assets/png/data-platform.png");
    }
  }

  /* media query end */
  .no-tabs-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 80px 0;
  }

  .no-tabs-content {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .no-tabs-para {
    text-align: center;
    color: white;
    margin-bottom: 25px
  }

  .no-tabs-image {
    padding-left: 15%;
    padding-right: 15%;
    margin-bottom: 40px;
  }

  .no-tabs-header {
    text-align: center;
    color: white;
    margin-bottom: 40px;
    padding-left: 5%;
    padding-right: 5%
  }
</style>