<template>
  <button class="vs-btn">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'VsButton'
};
</script>

<style scoped>
.vs-btn {
  padding: 10px;
  overflow: hidden;
  cursor: pointer;
  border: none;
  position: relative;
  background-color: var(--primary);
  color: #ffffff;
}

.vs-btn:hover {
  background-color: var(--vs-success) !important;
  box-shadow: 0 8px 25px -8px var(--primary);
}
</style>